<template>
  <img src="/img/ui/ball.svg" title="Goal" :class="getStyle()" v-if="type === 'goal'"/>
  <img src="/img/ui/yellow-card.svg" title="Yellow Cards" :class="getStyle()" v-if="type === 'yellow_card'"/>
  <img src="/img/ui/red-card.svg" title="Red Cards" :class="getStyle()" v-if="type === 'red_card'"/>
  <img src="/img/ui/yellow-red-card.svg" title="Second Yellow" :class="getStyle()" v-if="type === 'second_yellow'"/>
  <img src="/img/ui/penalty-goal.svg" title="Penalty Goal" :class="getStyle()" v-if="type === 'penaltytrue'"/>
  <img src="/img/ui/penalty-miss.svg" title="Penalty Miss" :class="getStyle()" v-if="type === 'penaltyfalse'"/>
</template>

<script>

export default {
  name: 'eventImg',
  props: {
    type: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'small',
    },
  },
  setup(props) {
    function getStyle() {
      if (props.size === 'small') return 'h-4';
      if (props.size === 'medium') return 'h-6';
      return 'h-8';
    }

    return {
      getStyle,
    };
  },
};
</script>

<style scoped>

</style>
