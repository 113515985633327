<template>
  <div v-if="ready" class="overflow-y-scroll no-scroll bg-white h-full">
    <div class="flex mt-2 px-2">
      <div @click="goBack"><img src="/img/ui/back-arrow.svg" width="15"></div>
    </div>
    <div class="flex px-4 my-4 w-full">
      <div class="flex flex-col items-center w-5/12 cursor-pointer" @click="$router.push({ name: 'ClubView', params: { clubId: match.home } })">
        <club-logo :club-id="match.home" class="w-8 h-8"></club-logo>
        <div class="text-[11px] font-semibold mt-1 text-neobit-black">{{ store.state.userStore.clubNames[match.home] }}</div>
      </div>
      <div class="w-2/12 text-2xl font-bold justify-center">
        <div class="flex">
          <div>{{ match.home_score }}</div>
          <div class="px-2">-</div>
          <div>{{ match.away_score }}</div>
        </div>
      </div>
      <div class="flex flex-col items-center w-5/12 cursor-pointer" @click="$router.push({ name: 'ClubView', params: { clubId: match.away } })">
        <club-logo :club-id="match.away" class="w-8 h-8"></club-logo>
        <div class="text-[11px] font-semibold mt-1 text-neobit-black">{{ store.state.userStore.clubNames[match.away] }}</div>
      </div>
    </div>
    <div class="flex items-center text-sm flex-grow bg-[#C8EBE0] h-6">
      <div class="text-center w-1/2 text-[13px] font-bold p-0.5"
           @click="switchItemMenu('lineup')"
           :class="itemTypeSelected === 'lineup' ? 'bg-neobit-green text-white' : 'text-neobit-green'">
        Lineup
      </div>
      <div class="text-center w-1/2 text-[13px] font-bold p-0.5"
           @click="switchItemMenu('stats')"
           :class="itemTypeSelected === 'stats' ? 'bg-neobit-green text-white' : 'text-neobit-green'">
        Match Stats
      </div>
    </div>
    <div v-show="itemTypeSelected === 'lineup'">
      <div class="w-full mb-4 mt-1">
        <div class="w-full flex text-[12px] text-gray-500 py-1" v-for="event in events" :key="event.item + event.min">
          <div v-if="Object.values(match.home_lineup).includes(event.item)" class="flex justify-end items-center w-1/2">
            <div class="relative mr-[10px] text-[11px] font-semibold text-neobit-black">
              <div v-if="event.event !== 'penalty'">{{ match.items[event.item].name }} {{ event.min }}'</div>
              <div v-if="event.event === 'penalty'">{{ match.items[event.item].name }} {{ 90 + Number(event.penalty_nr) }}'</div>
              <div class="absolute -right-4 top-0.5" v-if="event.event === 'goal'"><img src="/img/ui/ball.svg" height="12" width="12"></div>
              <div class="absolute -right-4 top-0.5" v-if="event.event === 'red_card'"><img src="/img/ui/red-card.svg" height="12" width="12"></div>
              <div class="absolute -right-4 top-0.5" v-if="event.event === 'yellow_card'"><img src="/img/ui/yellow-card.svg" height="12" width="12"></div>
              <div class="absolute -right-4 top-0.5" v-if="event.event === 'second_yellow'"><img src="/img/ui/yellow-red-card.svg" height="12" width="12"></div>
              <div class="absolute -right-4 top-0.5" v-if="event.event === 'penalty' && event.result === true"><img src="/img/ui/penalty-goal.svg" height="13" width="13"></div>
              <div class="absolute -right-4 top-0.5" v-if="event.event === 'penalty' && event.result === false"><img src="/img/ui/penalty-miss.svg" height="13" width="13"></div>
            </div>
          </div>
          <div class="w-1/2"></div>
          <div v-if="Object.values(match.away_lineup).includes(event.item)" class="flex justify-start items-center w-1/2">
            <div class="relative ml-[10px] text-[11px] font-semibold text-neobit-black">
              <div v-if="event.event !== 'penalty'">{{ match.items[event.item].name }} {{ event.min }}'</div>
              <div v-if="event.event === 'penalty'">{{ match.items[event.item].name }} {{ 90 + Number(event.penalty_nr) }}'</div>
              <div class="absolute -left-4 top-0.5" v-if="event.event === 'goal'"><img src="/img/ui/ball.svg" height="12" width="12"></div>
              <div class="absolute -left-4 top-0.5" v-if="event.event === 'red_card'"><img src="/img/ui/red-card.svg" height="12" width="12"></div>
              <div class="absolute -left-4 top-0.5" v-if="event.event === 'yellow_card'"><img src="/img/ui/yellow-card.svg" height="12" width="12"></div>
              <div class="absolute -left-4 top-0.5" v-if="event.event === 'second_yellow'"><img src="/img/ui/yellow-red-card.svg" height="12" width="12"></div>
              <div class="absolute -left-4 top-0.5" v-if="event.event === 'penalty' && event.result === true"><img src="/img/ui/penalty-goal.svg" height="13" width="13"></div>
              <div class="absolute -left-4 top-0.5" v-if="event.event === 'penalty' && event.result === false"><img src="/img/ui/penalty-miss.svg" height="13" width="13"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="field w-full relative min-h-[806px]">
          <div class="border-2 border-t-0 border-gray-300 mx-auto w-[56%] h-[96px] rounded-b-lg">
            <div class="border-2 border-t-0 border-gray-300 mx-auto w-[56%] rounded-b-lg h-[46px]">
              <pitch-player :player="match.items[match.home_lineup.goalkeeper]"></pitch-player>
            </div>
          </div>
          <div class="flex justify-around">
            <pitch-player class="w-1/4" :player="match.items[match.home_lineup.defender1]"></pitch-player>
            <pitch-player class="w-1/4" :player="match.items[match.home_lineup.defender2]"></pitch-player>
            <pitch-player class="w-1/4" :player="match.items[match.home_lineup.defender3]"></pitch-player>
            <pitch-player class="w-1/4" :player="match.items[match.home_lineup.defender4]"></pitch-player>
          </div>
          <div class="flex justify-around">
            <pitch-player class="w-1/3" :player="match.items[match.home_lineup.midfielder1]"></pitch-player>
            <pitch-player class="w-1/3" :player="match.items[match.home_lineup.midfielder2]"></pitch-player>
            <pitch-player class="w-1/3" :player="match.items[match.home_lineup.midfielder3]"></pitch-player>
          </div>
          <div class="flex justify-around">
            <pitch-player class="w-1/3" :player="match.items[match.home_lineup.attacker1]"></pitch-player>
            <pitch-player class="w-1/3" :player="match.items[match.home_lineup.attacker2]"></pitch-player>
            <pitch-player class="w-1/3" :player="match.items[match.home_lineup.attacker3]"></pitch-player>
          </div>
          <div class="my-4 border border-gray-300 w-full relative">
            <div class="mid w-20 h-20 rounded-full border border-gray-300 absolute"></div>
          </div>
          <div class="flex justify-around">
            <pitch-player class="w-1/3" :player="match.items[match.away_lineup.attacker1]"></pitch-player>
            <pitch-player class="w-1/3" :player="match.items[match.away_lineup.attacker2]"></pitch-player>
            <pitch-player class="w-1/3" :player="match.items[match.away_lineup.attacker3]"></pitch-player>
          </div>
          <div class="flex justify-around">
            <pitch-player class="w-1/3" :player="match.items[match.away_lineup.midfielder1]"></pitch-player>
            <pitch-player class="w-1/3" :player="match.items[match.away_lineup.midfielder2]"></pitch-player>
            <pitch-player class="w-1/3" :player="match.items[match.away_lineup.midfielder3]"></pitch-player>
          </div>
          <div class="flex justify-around">
            <pitch-player class="w-1/4" :player="match.items[match.away_lineup.defender1]"></pitch-player>
            <pitch-player class="w-1/4" :player="match.items[match.away_lineup.defender2]"></pitch-player>
            <pitch-player class="w-1/4" :player="match.items[match.away_lineup.defender3]"></pitch-player>
            <pitch-player class="w-1/4" :player="match.items[match.away_lineup.defender4]"></pitch-player>
          </div>
          <div class="relative border-2 border-b-0 border-gray-300 mx-auto w-[56%] h-[96px] rounded-t-lg flex items-end">
            <div class="border-2 border-b-0 border-gray-300 mx-auto w-[56%] rounded-t-lg h-[46px]">
              <div class="-mt-14">
                <pitch-player :player="match.items[match.away_lineup.goalkeeper]"></pitch-player>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="text-center w-full font-semibold my-3 text-[14px]">Managers</div>
        <div class="flex w-full justify-center mb-4">
          <div class="w-1/2">
            <pitch-player :bg="true" :player="match.items[match.home_lineup.manager]"></pitch-player>
          </div>
          <div class="w-1/2">
            <pitch-player :bg="true" :player="match.items[match.away_lineup.manager]"></pitch-player>
          </div>
        </div>
      </div>
    </div>
    <div v-show="itemTypeSelected === 'stats'">
      <div class="p-2 text-sm">
        <div class="flex" v-if="match.type === 'league'">
          <div class="w-[140px]">Home Prize:</div>
          <sexy-number
            :number="homeMatchPrize / 100000000"
            :decimals="6"
            class="font-bold font-rubik text-[13px]"
          />
          <img src="/img/tokens/transparent/bNEO.svg" class="w-5 h-5"/>
        </div>
        <div class="flex" v-if="match.type === 'league'">
          <div class="w-[140px]">Away Prize:</div>
          <sexy-number
            :number="awayMatchPrize / 100000000"
            :decimals="6"
            class="font-bold font-rubik text-[13px]"
          />
          <img src="/img/tokens/transparent/bNEO.svg" class="w-5 h-5"/>
        </div>
        <div class="mt-2"></div>
        <div class="flex" v-if="match.items[match.home_lineup.stadium]">
          <div class="w-[140px]">Weather:</div>
          <div class="font-bold text-[13px]">{{ weatherTypes[Math.ceil(match.items[match.home_lineup.stadium].weather * 10)] }}</div>
        </div>
        <div class="flex" v-if="match.items[match.home_lineup.stadium]">
          <div v-if="match.type === 'league'" class="w-[140px]">Tickets Sold:</div>
          <div v-else class="w-[140px]">Attendees:</div>
          <sexy-number
            :number="match.items[match.home_lineup.stadium].attendees"
            :decimals="0"
            class="font-bold font-rubik text-[13px]"
          />
          <div class="px-1">of</div>
          <sexy-number
            :number="match.items[match.home_lineup.stadium].capacity"
            :decimals="0"
            class="font-bold font-rubik text-[13px]"
          />
        </div>
        <div class="flex" v-if="match.items[match.home_lineup.stadium] && match.type === 'league'">
          <div class="w-[140px]">Match-day income:</div>
          <sexy-number
            :number="match.items[match.home_lineup.stadium].match_day_income / 100000000"
            :decimals="6"
            class="font-bold font-rubik text-[13px]"
          />
          <img src="/img/tokens/transparent/bNEO.svg" class="w-5 h-5"/>
        </div>
      </div>
    </div>
  </div>
  <spinner v-if="!ready"></spinner>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import backendAPI from '@/os/APIs/backendAPI';
import store from '@/store';
import PitchPlayer from '@/apps/globalComponents/components/pitchPlayer.vue';
import Spinner from '@/os/components/Spinner.vue';
import { weatherTypes } from '@/os/utils/config';
import ClubLogo from '@/apps/globalComponents/components/clubLogo.vue';

export default {
  name: 'view-match',
  components: { ClubLogo, Spinner, PitchPlayer },
  setup() {
    const ready = ref(false);
    const route = useRoute();
    const router = useRouter();
    const match = ref(null);
    const events = ref(null);
    const ratings = ref({});
    const itemTypeSelected = ref('lineup');
    const params = computed(() => route.params);
    const homeMatchPrize = computed(() => {
      if (match.value.result === 'home') {
        return match.value.match_prize * 0.8;
      }
      if (match.value.result === 'away') {
        return match.value.match_prize * 0.2;
      }
      return match.value.match_prize * 0.3;
    });
    const awayMatchPrize = computed(() => {
      if (match.value.result === 'home') {
        return match.value.match_prize * 0.2;
      }
      if (match.value.result === 'away') {
        return match.value.match_prize * 0.8;
      }
      return match.value.match_prize * 0.3;
    });

    async function getMatch() {
      if (Object.keys(store.state.userStore.clubNames).length < 1) {
        await store.dispatch('userStore/getClubGetNames');
      }
      match.value = await backendAPI.match(params.value.match);
      events.value = match.value.events.sort((a, b) => (a.min - b.min));
      ready.value = true;
    }

    getMatch();

    watch(params, () => {
      if (typeof params.value.matchId !== 'undefined') {
        getMatch();
      }
    });

    function goBack() {
      const history = window.history.length;
      if (history > 2) {
        router.back();
      } else {
        router.push({ name: 'Overview' });
      }
    }

    function switchItemMenu(itemType) {
      itemTypeSelected.value = itemType;
    }

    return {
      store,
      match,
      events,
      goBack,
      ready,
      ratings,
      switchItemMenu,
      itemTypeSelected,
      homeMatchPrize,
      awayMatchPrize,
      weatherTypes,
    };
  },
};
</script>

<style scoped>
.field {
  background-color: #0d9f68;
}

.mid {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.penalty {
  border-bottom: rgba(202, 201, 201, 1);
  width: 50%;
  height: 20%;
}
</style>
