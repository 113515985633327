<template>
  <app-interface>
    <div class="p-4">
      <div class="box">
        <div class="flex items-center justify-between">
          <span class="text-lg text-neobit-green font-bold">MY CLUBS</span>
          <span class="text-[13px] font-semibold text-neobit-black">Count: {{ store.state.userStore.clubIds.length }}</span>
        </div>
        <div class="my-3 mx-9 border-t border-neobit-green border-opacity-10"></div>
        <div class="text-sm font-medium text-neobit-black tracking-wide">
          Here you can switch current managed club and create new clubs.
        </div>
        <div class="border-2 border-neobit-black border-opacity-10 rounded-lg bg-neobit-black bg-opacity-5 px-4 py-3 mt-3" v-if="store.state.userStore.clubIds < 1">
          <div class="text-[13px] leading-[14px] font-semibold text-neobit-black">No other clubs available</div>
          <div class="text-[11px] leading-[14px] font-semibold text-neobit-black text-opacity-50 mt-1">Please create a new club...</div>
        </div>
        <div v-else class="gap-3">
          <div
            class="border-2 border-neobit-greenlight cursor-pointer rounded-lg bg-neobit-greenlight bg-opacity-30 px-4 py-3 mt-3 flex items-center justify-between"
            v-for="(clubId, index) of store.state.userStore.clubIds"
            :key="index"
            @click="changeClub(clubId)"
          >
            <div>
              <div class="text-[13px] leading-[14px] font-semibold text-neobit-black flex items-center">
                <div class="mr-2">
                  <club-logo :club-id="clubId" class="w-5 h-5"></club-logo>
                </div>
                <div class="font-bold">{{ store.state.userStore.clubNames[clubId] }}</div>
              </div>
            </div>
            <img src="/img/icons/arrow-right.svg" class="w-6 h-6 cursor-pointer"/>
          </div>
        </div>
        <button @click="makeNewClub = true" class="mt-4 bg-neobit-greenlight rounded-xl text-neobit-green py-2.5 w-full text-[15px] font-bold">ADD NEW CLUB</button>
      </div>
    </div>
    <div class="absolute inset-0 bg-black bg-opacity-40 text-black" v-if="makeNewClub">
      <make-club-modal @close="makeNewClub = false"></make-club-modal>
    </div>
  </app-interface>
</template>

<script>
import AppInterface from '@/os/components/templates/AppInterface.vue';
import store from '@/store';
import ClubLogo from '@/apps/globalComponents/components/clubLogo.vue';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import MakeClubModal from '@/apps/user/clubs/modals/makeClubModal.vue';

export default {
  name: 'Clubs',
  components: {
    MakeClubModal,
    ClubLogo,
    AppInterface,
  },
  setup() {
    const router = useRouter();
    const makeNewClub = ref(false);

    async function changeClub(clubId) {
      await store.dispatch('userStore/getUserClub', { clubId });
      await store.dispatch('userStore/getClubItems', { clubId });
      await router.push({ name: 'Overview' });
    }

    return {
      changeClub,
      makeNewClub,
      store,
    };
  },
};
</script>

<style scoped>
.box {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.06);
  border-radius: 8px;
  padding: 12px 18px;
}
</style>
