// eslint-disable-next-line
export const tableShare = {
  1: 0.10,
  2: 0.09,
  3: 0.08,
  4: 0.07,
  5: 0.065,
  6: 0.06,
  7: 0.055,
  8: 0.05,
  9: 0.048,
  10: 0.046,
  11: 0.045,
  12: 0.042,
  13: 0.04,
  14: 0.038,
  15: 0.036,
  16: 0.032,
  17: 0.029,
  18: 0.027,
  19: 0.024,
  20: 0.023,
};

export const weatherTypes = {
  1: 'Cyclone',
  2: 'Tornadoes ',
  3: 'Thunderstorm',
  4: 'Stormy',
  5: 'Snow',
  6: 'Rain',
  7: 'Cloudy',
  8: 'Partially Cloudy',
  9: 'Sun',
  10: 'Paradise',
};
