<template>
  <div class="flex flex-col flex-grow">
    <div class="flex h-[280px] justify-center">
      <div class="w-1/2 flex justify-center cursor-pointer pl-2 py-1 relative" @click="mint('player')">
        <img src="/img/cards/recruit/rec-player.png">
        <div class="absolute bottom-[25px] right-[54px]">
          <sexy-number
            :number="store.state.userStore.playerPrice"
            :decimals="2"
            class="text-center w-full"
          />
        </div>
      </div>
      <div class="w-1/2 flex justify-center cursor-pointer pr-2 py-1 relative" @click="mint('manager')">
        <img src="/img/cards/recruit/rec-manager.png">
        <div class="absolute bottom-[25px] right-[62px]">
          <sexy-number
            :number="store.state.userStore.managerPrice"
            :decimals="2"
            class="text-center w-full"
          />
        </div>
      </div>
    </div>
    <div class="flex h-[280px] justify-center">
      <div class="w-1/2 justify-center flex cursor-pointer pl-2 py-1 relative" @click="mint('stadium')">
        <img src="/img/cards/recruit/rec-stadium.png">
        <div class="absolute bottom-[25px] right-[54px]">
          <sexy-number
            :number="store.state.userStore.stadiumPrice"
            :decimals="2"
            class="text-center w-full"
          />
        </div>
      </div>
      <div class="w-1/2 justify-center flex cursor-pointer pr-2 py-1 relative" @click="mint('consumable')">
        <img src="/img/cards/recruit/rec-consumable.png">
        <div class="absolute bottom-[25px] right-[64px]">
          <sexy-number
            :number="10"
            :decimals="0"
            class="text-center w-full"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import osAPI from '@/os/APIs/osAPI';
import store from '@/store';

export default {
  name: 'mint-app',
  setup() {
    function mint(itemType) {
      osAPI.triggerModal('mint-modal', itemType);
    }

    return {
      mint,
      store,
    };
  },
};
</script>

<style scoped>

</style>
