import Overview from './views/Overview.vue';

const routes = [
  {
    path: '/market',
    name: 'MarketMain',
    component: Overview,
    meta: { title: 'Marketplace - Neo FC' },
  },
];

export default routes;
