<template>
  <div class="relative p-2 flex flex-col bg-white rounded-2xl mx-4">
    <div class="flex items-center mb-2">
      <div class="flex flex-grow text-[18px] font-bold mt-1 text-neobit-green uppercase">BUY {{ item.item_type }}</div>
      <div class="" @click="$emit('close')">
        <img :src="`/img/ui/cross.svg`" width="26"/>
      </div>
    </div>
    <div>
      <div class="text-[12px]">You’re about to buy: {{ item.name }}</div>
      <div class="relative flex items-center justify-center input h-10 mt-4">
        <div class="flex justify-center font-number">
          For:
          <sexy-number
            :number="item.on_sale_price / 100000000"
            :decimals="4"
            class="mx-1"
          />
          bNEO
        </div>

      </div>

    </div>
    <div class="confirm flex items-center justify-center text-[15px] mt-4" @click="$emit('buy')">CONFIRM</div>
    <div class="cancel flex items-center justify-center text-[15px] mt-3" @click="$emit('close')">CANCEL</div>
  </div>
</template>

<script>
export default {
  name: 'buy-item',
  props: {
    item: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.input {
  border: 2px solid #C8EBE0;
}

.confirm {
  background: #00AF92;
  border-radius: 12px;
  height: 46px;
  color: #FFFFFF;
}

.cancel {
  background: #C8EBE0;
  border-radius: 12px;
  height: 46px;
  color: #00AF92;
}
</style>
