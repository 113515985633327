<template>
  <div v-if="loading" class="absolute inset-0 mx-4 rounded-[20px] overflow-hidden">
    <spinner></spinner>
  </div>
  <div class="bg-white shadow-md p-4 flex flex-col items-center justify-center font-montserrat rounded-[20px] w-full">
    <div class="text-lg font-bold text-neobit-green text-center">RESET PASSWORD</div>
    <div class="mt-1 text-neobit-black font-medium text-center text-[15px]">Make a new NeoBits password</div>
    <input class="border-2 border-neobit-greenlight rounded-md bg-transparent outline-none mt-3 w-full px-3 py-2.5" v-model="password" placeholder="New Password..." type="password"/>
    <button class="bg-neobit-green rounded-xl py-2.5 text-center w-full text-white text-[15px] font-bold mt-6" @click="resetPassword">
      RESET PASSWORD
    </button>
    <button class="bg-neobit-greenlight rounded-xl py-2.5 text-center w-full text-neobit-green text-[15px] font-bold mt-4" @click="$router.push({ name: 'Login' })">
      CANCEL
    </button>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import backendAPI from '@/os/APIs/backendAPI';
import Spinner from '@/os/components/Spinner.vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'login-user',
  components: { Spinner },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const loading = ref(false);
    const password = ref('');
    const code = computed(() => route.query.id);

    async function resetPassword() {
      loading.value = true;
      try {
        await backendAPI.userResetPassword(code.value, password.value);
        window.toast.success('Successfully Activated');
        await router.push({ name: 'Login' });
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        throw err;
      } finally {
        loading.value = false;
      }
    }

    return {
      resetPassword,
      password,
      loading,
      code,
    };
  },
};
</script>

<style scoped>

</style>
