<template>
<div class="flex">
  <div v-for="(i, f) in ranks[rank].fullStars" :key="i + f + 'full'">
    <img src="/img/ui/star-full.svg"/>
  </div>
  <div v-for="(i, f) in ranks[rank].halfStars" :key="i + f + 'half'">
    <img src="/img/ui/star-half.svg"/>
  </div>
  <div v-for="(i, f) in ranks[rank].noStars" :key="i + f + 'none'">
    <img src="/img/ui/star-none.svg"/>
  </div>
</div>
</template>

<script>

export default {
  name: 'Rank',
  props: {
    rank: {
      type: String,
      required: true,
    },
  },
  setup() {
    const ranks = {
      local: {
        fullStars: 0,
        halfStars: 1,
        noStars: 3,
      },
      district: {
        fullStars: 1,
        halfStars: 0,
        noStars: 3,
      },
      city: {
        fullStars: 1,
        halfStars: 1,
        noStars: 2,
      },
      county: {
        fullStars: 2,
        halfStars: 0,
        noStars: 2,
      },
      country: {
        fullStars: 2,
        halfStars: 1,
        noStars: 1,
      },
      continental: {
        fullStars: 3,
        halfStars: 0,
        noStars: 1,
      },
      global: {
        fullStars: 3,
        halfStars: 1,
        noStars: 0,
      },
      neo: {
        fullStars: 4,
        halfStars: 0,
        noStars: 0,
      },
    };
    return {
      ranks,
    };
  },
};
</script>

<style scoped>

</style>
