<template>
  <div class="p-4">
    <div class="relative items-center justify-between">
      <div class="shadow-box p-3 relative">
        <div class="rounded-lg overflow-hidden border-[#C8EBE052] border-2">
          <div class="bg-[#3333330F] w-full p-3 h-[76px] flex justify-between">
            <div class="flex items-center bg-[#FFFFFFA3] rounded-md p-[5px] gap-1 h-[26px]">
              <img src="../../assets/stadium.svg" class="w-4 h-4"/>
              <div class="text-[11px] font-semibold text-neobit-black">Daily {{ dailyPlayed }}<span class="opacity-50">/2</span></div>
            </div>
            <div class="flex items-center bg-[#FFFFFFA3] rounded-md p-[5px] gap-1 h-[26px]">
              <img src="../../assets/clock.svg" class="w-4 h-4"/>
              <span class="text-[11px] font-semibold text-neobit-black">{{ timeLeft }}{{timeType}} left</span>
            </div>
          </div>
          <div class="-mt-16 z-10 relative flex flex-col items-center px-3 pb-3">
            <img src="/img/ui/friendly_match.svg" class="w-32 h-32 rounded-full"/>
            <div class="mt-3 text-lg font-bold text-neobit-black">Friendly Match</div>
            <button
              @click="$router.push({ name: 'SearchOpponent' })"
              class="mt-3 px-4 w-full py-2.5 bg-neobit-green font-bold text-[15px] text-white rounded-xl"
              v-if="dailyPlayed < 2"
            >
              SELECT OPPONENT
            </button>
            <div
              class="mt-3 px-4 w-full py-2.5 bg-gray-500 font-bold text-[15px] text-white rounded-xl text-center"
              v-if="dailyPlayed >= 2"
            >
              WAIT {{ timeLeft }}{{timeType}}
            </div>
            <button
              @click="$router.push({ name: 'LiveMatch', params: { type: 'practice', id: 1 } })"
              v-if="nowLive"
              class="bg-[#E80054] w-full mt-3 rounded-xl px-4 py-2.5 flex items-center justify-between text-[15px] font-bold tracking-wide text-white"
            >
              NOW LIVE - {{ formatCounter }}
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="shadow-box overflow-hidden w-full tracking-wide mt-4">
        <div class="px-4 py-2 bg-neobit-greenlight flex items-center justify-between text-neobit-green">
          <span class="text-[13px] font-bold">RECENT FRIENDLY MATCHES</span>
          <button class="text-[11px] font-semibold">VIEW ALL</button>
        </div>
        <div class="p-2 flex flex-col gap-3">
          <div class="bg-[#F8F8F8] rounded-lg p-2 tracking-wide" v-for="match in store.state.userStore.clubMatches?.filter((match) => match.type === 'practice').slice(0, 5)" :key="match.id">
            <div class="border-[#C8EBE052] border-2 rounded-lg px-1 py-1 flex items-center justify-center"
                 @click="$router.push({ name: 'MatchView', params: { match: match.id } })"
            >
              <span class="text-xs font-semibold w-[35%] text-right text-[10px] text-neobit-black">{{ store.state.userStore.clubNames[match.home] }}</span>
              <club-logo :club-id="match.home" class="w-5 h-5 ml-1"></club-logo>
              <span class="mx-2 font-bold text-center w-12 text-[10px] text-neobit-black">{{ match.home_score }} - {{ match.away_score }}</span>
              <club-logo :club-id="match.away" class="w-5 h-5 mr-1"></club-logo>
              <span class="text-xs font-semibold w-[35%] text-[10px] text-neobit-black">{{ store.state.userStore.clubNames[match.away] }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  computed,
  ref,
  onBeforeMount,
  onBeforeUnmount,
  watch,
} from 'vue';
import store from '@/store';
import ClubLogo from '@/apps/globalComponents/components/clubLogo.vue';
import backendAPI from '@/os/APIs/backendAPI';
import { format } from 'date-fns';

export default {
  name: 'friendly-match',
  components: {
    ClubLogo,
  },
  setup() {
    const dailyPlayed = ref(0);
    const nowLive = computed(() => {
      if (!store.state.userStore.liveMatches.practice) return false;
      const liveData = store.state.userStore.liveMatches.practice[1];
      if (!liveData) return false;
      if (Object.keys(liveData).length === 0) return false;
      const fItem = Object.keys(liveData).find((item) => liveData[item].home === store.state.userStore.club.id);
      if (fItem) return true;
      return false;
    });
    let liveCounter = null;
    const counter = ref(0);

    function calcLiveCounter() {
      counter.value += 1;
      if (counter.value >= 5400) {
        clearInterval(liveCounter);
      }
    }

    const formatCounter = computed(() => {
      const ss = counter.value % 60;
      let mm = parseInt(counter.value / 60, 10);
      const hh = parseInt(mm / 60, 10);
      mm %= 60;
      return `${hh > 9 ? hh : `0${hh}`}:${mm > 9 ? mm : `0${mm}`}:${ss > 9 ? ss : `0${ss}`}`;
    });

    function checkCounter() {
      if (!nowLive.value) {
        clearInterval(liveCounter);
        return;
      }
      clearInterval(liveCounter);
      const liveData = store.state.userStore.liveMatches.practice[1];
      const match = Object.values(liveData).find((item) => item.home === store.state.userStore.club.id);
      const endTime = match.completed;
      counter.value = parseInt(new Date().getTime() / 1000, 10) - (endTime - 60 * 90);
      liveCounter = setInterval(() => calcLiveCounter(), 1000);
    }

    const curDate = new Date();
    curDate.setHours(24, 0, 0, 0);
    const hoursLeft = format(curDate - new Date(), 'HH');
    const minLeft = format(curDate - new Date(), 'mm');
    const timeType = hoursLeft > 0 ? 'h' : 'min';
    const timeLeft = hoursLeft > 0 ? hoursLeft : minLeft;

    async function getData() {
      if (!store.state.userStore.clubMatches) {
        await store.dispatch('userStore/getClubMatches', { clubId: store.state.userStore.club.id, season: false });
      }
      const data = await backendAPI.clubFriendlyPlayed(store.state.userStore.club.id);
      if (data) {
        dailyPlayed.value = data;
      }
    }

    watch(nowLive, () => {
      checkCounter();
    });

    onBeforeMount(() => {
      checkCounter();
    });

    onBeforeUnmount(() => {
      clearInterval(liveCounter);
    });

    getData();

    return {
      store,
      timeLeft,
      timeType,
      nowLive,
      formatCounter,
      dailyPlayed,
    };
  },
};
</script>

<style scoped>
.shadow-box {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.06);
  border-radius: 8px;
}
</style>
