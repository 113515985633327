<template>
  <BigHead
    :accessory="accessory"
    :body="body"
    :clothing="clothing"
    :clothingColor="clothingColor"
    :eyebrows="eyebrows"
    :eyes="eyes"
    :facialHair="facialHair"
    :facialHairColor="facialHairColor"
    graphic="none"
    :hair="hair"
    :hairColor="hairColor"
    :hat="hat"
    :hatColor="hatColor"
    :lashes="lashes"
    :lipColor="lipColor"
    :mouth="mouth"
    :shapeColor="{ base: '#ffffff', shadow: '#ffffff' }"
    :shape="shape"
    :skinTone="skinTone"
  />
</template>

<script>
import { BigHead } from 'fixed-vue-bigheads';

export default {
  name: 'player-avatar',
  props: {
    shape: {
      default: 'circle',
    },
    avatarData: {
      default: {
        accessory: 'shades',
        body: 'chest',
        clothing: 'tankTop',
        clothingColor: 'black',
        eyebrows: 'angry',
        eyes: 'wink',
        facialHair: 'mediumBeard',
        faceMask: 'faceMask',
        facialHairColor: 'black',
        graphic: 'vue',
        hair: 'short',
        hairColor: 'black',
        hat: 'none',
        hatColor: 'green',
        lashes: 'false',
        lipColor: 'purple',
        mouth: 'open',
        shape: 'circle',
        shapeColor: 'blue',
        skinTone: 'brown',
      },
    },
  },
  components: {
    BigHead,
  },
  setup(props) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const {
      accessory,
      body,
      clothing,
      clothingColor,
      eyebrows,
      eyes,
      facialHair,
      facialHairColor,
      hair,
      hairColor,
      hat,
      hatColor,
      lashes,
      lipColor,
      faceMask,
      mouth,
      skinTone,
      shapeColor,
    } = props.avatarData;

    return {
      accessory,
      body,
      clothing,
      clothingColor,
      eyebrows,
      eyes,
      facialHair,
      facialHairColor,
      faceMask,
      hair,
      hairColor,
      hat,
      hatColor,
      lashes,
      lipColor,
      mouth,
      shapeColor,
      skinTone,
    };
  },
};
</script>

<style scoped>

</style>
