import MainOverview from './views/MainOverview.vue';

const routes = [
  {
    path: '/overview',
    name: 'OverviewMain',
    component: MainOverview,
    meta: { title: 'Dashboard - Neo FC' },
  },
];

export default routes;
