<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import store from '@/store';
import { onBeforeMount } from 'vue';

export default {
  name: 'cup-app',
  setup() {
    onBeforeMount(async () => {
      await store.dispatch('userStore/getCups');
    });

    return {
    };
  },
};
</script>

<style scoped>
</style>
