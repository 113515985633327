<template>
  <div
    v-if="osAPI.state.activeModal.value === modalName"
    class="elementToFadeInAndOut fixed h-full inset-0 simpleModal flex justify-center md:items-center shadow-card"
    style="z-index: 1000;">
    <div
      class="modal md:mx-auto text-white relative rounded-2xl z-10"
      :class="[
        bg === 'rgba(0,0,0,0)' ? '' : 'shadow-lg',
        height === '100%' ? '' : 'mt-12 md:mt-24 ',
        width === '100%' ? '' : 'mx-1'
      ]"
      :style="'height: ' + height + '; width: ' + width + '; background: ' + bg"
    >
      <slot></slot>
    </div>
    <div v-if="!noClickOutside" @click="osAPI.triggerModal()" class="absolute inset-0 w-full h-full"></div>
  </div>
</template>

<script>
import osAPI from '@/os/APIs/osAPI';

export default {
  name: 'simple-modal',
  props: [
    'height',
    'width',
    'modalName',
    'bg',
    'relative',
    'noClickOutside',
  ],
  setup() {
    const popOut = 'hover:text-flm-pink transition duration-500 ease-in-out transform hover:scale-110';

    return {
      popOut,
      osAPI,
    };
  },
};
</script>

<style scoped>

.modal {
  border-radius: 20px;
  height: fit-content;
  background: #1F1A22;
}

.simpleModal {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
}

.create-meme-modal .modal {
  margin-bottom: 0px !important;
}
</style>
