<template>
  <app-interface>
    <div class="h-full">
      <router-view></router-view>
    </div>
  </app-interface>
</template>

<script>
import AppInterface from '@/os/components/templates/AppInterface.vue';

export default {
  name: 'my-club',
  components: {
    AppInterface,
  },
};
</script>
