import BigNumber from 'bignumber.js';

export function getInteger(number) {
  return new BigNumber(number).integerValue(BigNumber.ROUND_FLOOR);
}

export function getFractional(number, decimals = 8) {
  return new BigNumber(number)
    .dp(decimals)
    .toFixed(decimals)
    .split('.')[1];
}

export function getAutomaticDecimals(number, minDecimals = 0, sensitivity = 2) {
  if (number === 0) return minDecimals;
  const decimalsNeeded = Math.ceil(Math.log10(Math.abs(number)) * -1) + sensitivity;
  return Math.max(decimalsNeeded, minDecimals);
}

export function getNumberWithUnitSuffix(number) {
  const unitLookup = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'K' },
    { value: 1E6, symbol: 'M' },
    { value: 1E9, symbol: 'B' },
    { value: 1E12, symbol: 'T' },
  ];
  let i;
  for (i = unitLookup.length - 1; i > 0; i -= 1) {
    if (number >= unitLookup[i].value) break;
  }
  return {
    number: number / unitLookup[i].value,
    suffix: unitLookup[i].symbol,
  };
}

export function amountToBigInt(amount, decimals) {
  return amount * (10 ** decimals);
}

export function bigIntToAmount(amount, decimals) {
  return amount / (10 ** decimals);
}

function sleep(ms) {
  return new Promise((r) => setTimeout(r, ms));
}

export default {
  getInteger,
  getFractional,
  getAutomaticDecimals,
  getNumberWithUnitSuffix,
  amountToBigInt,
  bigIntToAmount,
  sleep,
};
