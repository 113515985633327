import { ref } from 'vue';

// LineUp Filters
const itemPlayerTypeSelected = ref('goalkeepers');
const itemConsumableTypeSelected = ref('skills');
const stadiumConsumableTypeSelected = ref('capacity');
const coreLineupFilters = {
  minAge: 1,
  minSkills: 1,
  minLuck: 1,
  minMultiplier: 1,
  minSalary: 0.0001,
  minWeather: 1,
  maxAge: 100,
  maxSkills: 100,
  maxLuck: 100,
  maxMultiplier: 100,
  maxWeather: 100,
  maxSalary: 10,
};
const baseLineupFilters = ref({
  minAge: 1,
  minSkills: 1,
  minLuck: 1,
  minMultiplier: 1,
  minSalary: 0.0001,
  minWeather: 1,
  maxAge: 100,
  maxSkills: 100,
  maxLuck: 100,
  maxMultiplier: 100,
  maxWeather: 100,
  maxSalary: 10,
});
const rankTypes = ref([]);

// Market filters
const marketPlayerTypeSelected = ref('players');
const coreMarketFilters = {
  minPrice: 0.0001,
  minAge: 15,
  minSkills: 1,
  minLuck: 1,
  minMultiplier: 1,
  minSalary: 0.0001,
  minWeather: 1,
  maxPrice: 1000,
  maxAge: 35,
  maxSkills: 100,
  maxLuck: 100,
  maxMultiplier: 100,
  maxWeather: 100,
  maxSalary: 10,
};
const baseMarketFilters = ref({
  minPrice: 0.0001,
  minAge: 15,
  minSkills: 1,
  minLuck: 1,
  minMultiplier: 1,
  minSalary: 0.0001,
  minWeather: 1,
  maxPrice: 1000,
  maxAge: 35,
  maxSkills: 100,
  maxLuck: 100,
  maxMultiplier: 100,
  maxWeather: 100,
  maxSalary: 10,
});
const buy = ref(true);
const filterSelected = ref('lowPrice');
const playerTypes = ref([]);

// Tournament
const tournamentTypeSelected = ref('league');
const friendlyMatchOpponent = ref(null);

export default {
  itemConsumableTypeSelected,
  itemPlayerTypeSelected,
  stadiumConsumableTypeSelected,
  coreLineupFilters,
  baseLineupFilters,
  rankTypes,
  marketPlayerTypeSelected,
  coreMarketFilters,
  baseMarketFilters,
  buy,
  filterSelected,
  playerTypes,
  tournamentTypeSelected,
  friendlyMatchOpponent,
};
