<template>
  <app-interface>
    <div class="tracking-wide">
      <div class="z-20 top-0 sticky flex text-[13px] font-bold bg-[#C8EBE0] text-neobit-green items-center justify-between">
        <div class="text-center w-1/3 cursor-pointer"
            @click="switchItemMenu('league')"
            :class="tournamentTypeSelected === 'league' ? 'bg-[#00AF92] text-white p-2' : ''">
          LEAGUE
        </div>
        <div class="text-center w-1/3 cursor-pointer"
            @click="switchItemMenu('cup')"
            :class="tournamentTypeSelected === 'cup' ? 'bg-[#00AF92] text-white p-2' : ''">
          CUP
        </div>
        <div class="text-center w-1/3 cursor-pointer"
            @click="switchItemMenu('friendly')"
            :class="tournamentTypeSelected === 'friendly' ? 'bg-[#00AF92] text-white p-2' : ''">
          FRIENDLY
        </div>
      </div>
      <div class="">
        <router-view></router-view>
      </div>
    </div>
  </app-interface>
</template>

<script>

import { ref, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AppInterface from '@/os/components/templates/AppInterface.vue';
import globalStore from '@/os/utils/global';

export default {
  name: 'neo-tournament',
  components: {
    AppInterface,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const tournamentTypeSelected = ref('league');
    const path = computed(() => route.path);

    if (path.value.startsWith('/ranking')) {
      tournamentTypeSelected.value = 'league';
    }
    if (path.value.startsWith('/tournament/friendly')) {
      tournamentTypeSelected.value = 'friendly';
    }
    if (path.value.startsWith('/tournament/cup')) {
      tournamentTypeSelected.value = 'cup';
    }

    function switchItemMenu(itemType) {
      tournamentTypeSelected.value = itemType;
      if (itemType === 'league') router.push({ name: 'NeoLeague' });
      if (itemType === 'friendly') router.push({ name: 'FriendlyMatch' });
      if (itemType === 'cup') router.push({ name: 'NeoCup' });
    }

    watch(path, () => {
      if (path.value.startsWith('/ranking')) {
        tournamentTypeSelected.value = 'league';
      }
      if (path.value.startsWith('/tournament/friendly')) {
        tournamentTypeSelected.value = 'friendly';
      }
      if (path.value.startsWith('/tournament/cup')) {
        tournamentTypeSelected.value = 'cup';
      }
    });

    return {
      globalStore,
      switchItemMenu,
      tournamentTypeSelected,
    };
  },
};
</script>
