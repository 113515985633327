<template>
  <div class="relative bg-white">

    <!-- Player Card -->
    <div v-if="item.item_type !== 'consumable'" class="w-full text-black flex justify-center items-center relative">
      <div class="w-full relative flex cursor-pointer">
        <div
          @click="openModal"
          class="flex justify-center items-center relative bg-white overflow-hidden w-[50px] h-[60px]"
          :class="[thisPlayer.rank]"
        >
          <game-avatar
            v-if="item.item_type === 'player' || item.item_type === 'manager'"
            :avatar-data="item.avatar"
            :shape="'none'"
            class="absolute h-[70px] -mt-2"
          />
          <stadium-avatar
            v-if="item.item_type === 'stadium'"
            :stadium-rank="item.rank"
            class="h-14 -mt-2"
          />
        </div>

        <div class="flex flex-col flex-grow">
          <div class="flex pl-2 border-b">
            <div
              @click="openModal"
              class="flex-grow font-semibold text-[15px] flex items-center"
            >
              <div class="mr-1 flex items-center">
                <div
                  v-if="item.item_type === 'player' || item.item_type === 'manager'"
                >
                  {{ name }}
                </div>
                <div
                  v-if="item.item_type === 'stadium'"
                  class="text-xs"
                >
                  {{ item.name }}
                </div>
              </div>
              <div class="flex text-sm text-black text-opacity-75">
                <span>(</span>
                <div>
                  <sexy-number
                    :number="item.age"
                    :decimals="0"
                    class="text-center w-full"
                  />
                </div>
                <span>)</span>
              </div>
            </div>

            <div
              @click="openModal"
              class="flex items-center border-l px-2 gap-2"
            >
              <div
                v-if="myPlayer && item.contract_to_season === store.state.userStore.currentSeason"
                class="h-4 w-4"
              >
                <img class="z-10" :src="`/img/ui/expire.svg`">
              </div>
              <div
                v-if="item.on_loan_from_club !== '' && item.on_loan_from_club !== store.state.userStore.club.id"
                class="h-4 w-4"
              >
                <img class="z-10" :src="`/img/ui/on-loan.svg`">
              </div>
              <div>
                <img class="z-10" :src="`/img/cards/icons/${item.position}.svg`">
              </div>
            </div>

            <div
              v-click-outside="hideLineup"
              class="flex justify-center items-center border-l w-[100px]"
            >
              <div
                class="cursor-pointer flex items-center"
                @click="myPlayer ? toggleLineup() : ''"
                v-show="item.inLineup"
              >
                <div class="text-[12px] font-bold px-2 py-0.5 text-neobit-black capitalize">
                  {{ lineupDict[thisPlayer.inLineup] }}
                </div>
                <div v-if="myPlayer">
                  <img src="/img/ui/downward-arrow.svg" class="w-4"/>
                </div>
              </div>
              <div
                class="cursor-pointer flex items-center"
                @click="myPlayer ? toggleLineup() : ''"
                v-show="!thisPlayer.inLineup && !thisPlayer.onSale && !thisPlayer.onLoan && showSalary"
              >
                <div class="text-[12px] font-bold px-2 py-0.5 text-neobit-black">Lineup</div>
                <div v-if="myPlayer"><img src="/img/ui/downward-arrow.svg" class="w-4"/></div>
              </div>
              <div
                class="flex items-center bg-gray-200 w-full justify-center"
                v-show="thisPlayer.onSale && !market"
              >
                <div class="flex justify-center items-center font-number text-[12px] font-bold px-2 py-0.5 text-neobit-black">
                  <span class="mr-1">For sale</span>
                </div>
              </div>
              <div
                class="flex items-center bg-gray-200 w-full justify-center"
                v-show="thisPlayer.onSale && market"
              >
                <div class="flex justify-center items-center font-number text-[12px] font-bold px-2 py-0.5 text-neobit-black">
                  <sexy-number
                    :number="thisPlayer.onSalePrice / (10 ** 8)"
                    :decimals="4"
                    class="text-[13px] font-bold"
                  />
                  <img src="/img/tokens/transparent/bNEO.svg" class="w-5 h-5"/>
                </div>
              </div>
              <div
                class="flex items-center bg-gray-200 w-full justify-center"
                v-show="thisPlayer.onLoan && !market"
              >
                <div class="flex justify-center items-center font-number text-[12px] font-bold px-2 py-0.5 text-neobit-black">
                  <span class="mr-1">For loan</span>
                </div>
              </div>
              <div
                class="flex items-center bg-gray-200 w-full justify-center"
                v-show="thisPlayer.onLoan && market"
              >
                <div class="flex justify-center items-center font-number text-[12px] font-bold px-2 py-0.5 text-neobit-black">
                  <sexy-number
                    :number="(thisPlayer.onLoanPrice + item.season_cost) / (10 ** 8)"
                    :decimals="4"
                    class="text-[13px] font-bold"
                  />
                  <img src="/img/tokens/transparent/bNEO.svg" class="w-5 h-5"/>
                </div>
              </div>
              <div
                v-show="showLineup"
                class="absolute top-[24px] right-0 w-[200px] bg-white z-20 shadow-2xl rounded-l-lg"
              >
                <div class="p-1 border-b border-gray-300">
                  <div class="text-xs">Lineup:</div>
                  <div class="cursor-pointer hover:bg-gray-300" @click.stop="addToLineup('goalkeeper')" v-show="thisPlayer.position === 'goalkeeper'">GK</div>
                  <div class="cursor-pointer hover:bg-gray-300" @click.stop="addToLineup('defender1')" v-show="thisPlayer.position === 'defender'">DEF 1</div>
                  <div class="cursor-pointer hover:bg-gray-300" @click.stop="addToLineup('defender2')" v-show="thisPlayer.position === 'defender'">DEF 2</div>
                  <div class="cursor-pointer hover:bg-gray-300" @click.stop="addToLineup('defender3')" v-show="thisPlayer.position === 'defender'">DEF 3</div>
                  <div class="cursor-pointer hover:bg-gray-300" @click.stop="addToLineup('defender4')" v-show="thisPlayer.position === 'defender'">DEF 4</div>
                  <div class="cursor-pointer hover:bg-gray-300" @click.stop="addToLineup('midfielder1')" v-show="thisPlayer.position === 'midfielder'">MID 1</div>
                  <div class="cursor-pointer hover:bg-gray-300" @click.stop="addToLineup('midfielder2')" v-show="thisPlayer.position === 'midfielder'">MID 2</div>
                  <div class="cursor-pointer hover:bg-gray-300" @click.stop="addToLineup('midfielder3')" v-show="thisPlayer.position === 'midfielder'">MID 3</div>
                  <div class="cursor-pointer hover:bg-gray-300" @click.stop="addToLineup('attacker1')" v-show="thisPlayer.position === 'attacker'">ATT 1</div>
                  <div class="cursor-pointer hover:bg-gray-300" @click.stop="addToLineup('attacker2')" v-show="thisPlayer.position === 'attacker'">ATT 2</div>
                  <div class="cursor-pointer hover:bg-gray-300" @click.stop="addToLineup('attacker3')" v-show="thisPlayer.position === 'attacker'">ATT 3</div>
                  <div class="cursor-pointer hover:bg-gray-300" @click.stop="addToLineup('manager')" v-show="thisPlayer.position === 'manager'">Manager</div>
                  <div class="cursor-pointer hover:bg-gray-300" @click.stop="addToLineup('stadium')" v-show="thisPlayer.position === 'stadium'">Stadium</div>
                </div>
                <div class="p-1 border-t border-black">
                  <div class="text-xs">Actions:</div>
                  <div class="items-center gap-2" v-if="userId === item.owner_id && !item.on_sale && !item.on_loan">
                    <div
                      @click.stop="toggleOption('sell')"
                      class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center"
                    >
                      <img src="/img/ui/sell.svg" title="SELL" class="h-4 mr-2"/>
                      <div>Sell</div>
                    </div>
                    <div
                      @click.stop="toggleOption('lend')"
                      class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center"
                    >
                      <img src="/img/ui/lend.svg" title="LEND" class="h-4 mr-2"/>
                      <div>Lend Out</div>
                    </div>
                    <div
                      v-if="store.state.userStore.clubIds.length > 1"
                      @click.stop="toggleOption('transfer')"
                      class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center"
                    >
                      <img src="/img/ui/lend.svg" title="TRANSFER" class="h-4 mr-2"/>
                      <div>Transfer</div>
                    </div>
                    <div
                      @click.stop="toggleOption('ItemUseConsumable')"
                      class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center"
                    >
                      <img src="/img/ui/use.svg" title="USE" class="h-4 mr-2"/>
                      <div>Use Consumable</div>
                    </div>
                    <div
                      @click.stop="toggleOption('burn')"
                      class="p-1.5 bg-white flex hover:bg-green-200 items-center cursor-pointer"
                    >
                      <img src="/img/ui/terminate.svg" title="TERMINATE" class="h-4 mr-2"/>
                      <div>Terminate</div>
                    </div>
                  </div>
                  <div class="items-center gap-2" v-if="userId === item.owner_id && (item.on_sale || item.on_loan)">
                    <div class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center" @click.stop="toggleOption('sell')" v-if="item.on_sale">
                      <img src="/img/ui/edit.svg" title="EDIT SALE" class="h-4 mr-2"/>
                      <div>Edit Sale</div>
                    </div>
                    <div class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center" @click.stop="toggleOption('cancel')" v-if="item.on_sale">
                      <img src="/img/ui/cancel.svg" title="CANCEL SALE" class="h-4 mr-2"/>
                      <div>Cancel Sale</div>
                    </div>
                    <div class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center" @click.stop="toggleOption('lend')" v-if="item.on_loan">
                      <img src="/img/ui/edit.svg" title="EDIT SALE" class="h-4 mr-2"/>
                      <div>Edit Loan</div>
                    </div>
                    <div class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center" @click.stop="toggleOption('cancel')" v-if="item.on_loan">
                      <img src="/img/ui/cancel.svg" title="CANCEL SALE" class="h-4 mr-2"/>
                      <div>Cancel Loan</div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!-- Person Info -->
          <div
            @click="openModal"
            v-if="thisPlayer.itemType === 'player' || thisPlayer.itemType === 'manager'"
            class="flex flex-grow pl-2 items-center"
          >
            <div class="w-[64px] -mt-1">
              <div class="text-[9px] text-[#33333380] font-semibold">Rank</div>
              <div class="capitalize text-[13px] font-bold">
                <rank :rank="thisPlayer.rank"/>
              </div>
            </div>
            <div class="w-[45px]">
              <div class="text-[9px] text-[#33333380] font-semibold">Skills</div>
              <div>
                <sexy-number
                  :number="(thisPlayer.skills.base + thisPlayer.skills.inc + thisPlayer.skills.dec) / 10"
                  :decimals="1"
                  class="text-[13px] font-bold -mt-1"
                />
              </div>
            </div>
            <div class="w-[45px]">
              <div class="text-[9px] text-[#33333380] font-semibold">Luck</div>
              <div>
                <sexy-number
                  :number="(thisPlayer.luck.base + thisPlayer.luck.inc + thisPlayer.luck.dec) / 10"
                  :decimals="1"
                  class="text-[13px] font-bold -mt-1"
                />
              </div>
            </div>
            <div class="w-[45px]">
              <div class="text-[9px] text-[#33333380] font-semibold">Multip.</div>
              <div>
                <sexy-number
                  :number="(thisPlayer.multiplier.base + thisPlayer.multiplier.inc + thisPlayer.multiplier.dec) / 10"
                  :decimals="1"
                  class="text-[13px] font-bold -mt-1"
                />
              </div>
            </div>
            <div class="w-[45px]">
              <div class="text-[9px] text-[#33333380] font-semibold">Aggr.</div>
              <div>
                <sexy-number
                  :number="(thisPlayer.aggression.base + thisPlayer.aggression.inc + thisPlayer.aggression.dec) / 10"
                  :decimals="1"
                  class="text-[13px] font-bold -mt-1"
                />
              </div>
            </div>
            <div class="w-[45px]" v-if="showSalary">
              <div class="text-[9px] text-[#33333380] font-semibold">Salary</div>
              <div>
                <sexy-number
                  :number="thisPlayer.seasonCost / 100000000"
                  :decimals="4"
                  class="text-[13px] font-bold -mt-1"
                />
              </div>
            </div>
          </div>

          <!-- Stadium Info -->
          <div
            v-if="thisPlayer.itemType === 'stadium'"
            class="flex flex-grow pl-2 items-center"
          >
            <div class="w-[64px] -mt-1">
              <div class="text-[9px] text-[#33333380] font-semibold">Rank</div>
              <div class="capitalize text-[13px] font-bold">
                <rank :rank="thisPlayer.rank"/>
              </div>
            </div>
            <div class="w-[50px]">
              <div class="text-[9px] text-[#33333380] font-semibold">Weather</div>
              <div>
                <sexy-number
                  :number="(thisPlayer.weather.base + thisPlayer.weather.inc + thisPlayer.weather.dec) / 10"
                  :decimals="1"
                  class="text-[13px] font-bold -mt-1"
                />
              </div>
            </div>
            <div class="w-[40px]">
              <div class="text-[9px] text-[#33333380] font-semibold">Luck</div>
              <div>
                <sexy-number
                  :number="(thisPlayer.luck.base + thisPlayer.luck.inc + thisPlayer.luck.dec) / 10"
                  :decimals="1"
                  class="text-[13px] font-bold -mt-1"
                />
              </div>
            </div>
            <div class="w-[52px]">
              <div class="text-[9px] text-[#33333380] font-semibold">Capacity</div>
              <div>
                <sexy-number
                  :number="(thisPlayer.capacity)"
                  :decimals="0"
                  class="text-[13px] font-bold -mt-1"
                />
              </div>
            </div>
            <div class="w-[40px]" v-if="showSalary">
              <div class="text-[9px] text-[#33333380] font-semibold">Salary</div>
              <div>
                <sexy-number
                  :number="thisPlayer.seasonCost / 100000000"
                  :decimals="4"
                  class="text-[13px] font-bold -mt-1"
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- Consumable Card -->
    <div v-if="item.item_type === 'consumable'" class="w-full text-black flex justify-center items-center relative">
      <div class="w-full relative flex cursor-pointer">
        <div
          class="flex justify-center items-center relative bg-white overflow-hidden w-[60px] h-[60px]"
          :class="[item.rank]"
          @click="openModal"
        >
          <consumable-avatar class="h-[40px]" :consumable="item"></consumable-avatar>
        </div>

        <div class="flex flex-col flex-grow">
          <div class="flex pl-2 border-b">
            <div
              class="flex-grow font-semibold text-[15px] flex items-center"
              @click="openModal"
            >
              <div class="mr-1 flex items-center">
                <div class="text-xs capitalize" v-if="!isSponsor && !isStonks">{{ item.for_item }} {{ item.for_skill }}</div>
                <div class="text-xs capitalize" v-if="isSponsor || isStonks">{{ sponsor }} Sponsorship</div>
              </div>
            </div>
            <div
              v-click-outside="hideLineup"
              class="flex justify-center items-center border-l w-[100px]"
            >
              <div
                class="cursor-pointer flex items-center"
                @click="myPlayer ? toggleLineup() : ''"
                v-show="!item.on_sale"
              >
                <div class="text-[12px] font-bold px-2 py-0.5 text-neobit-black">Action</div>
                <div v-if="myPlayer"><img src="/img/ui/downward-arrow.svg" class="w-4"/></div>
              </div>
              <div
                class="flex items-center bg-gray-200 w-full justify-center"
                v-show="item.on_sale && !market"
              >
                <div @click="myPlayer ? toggleLineup() : ''" class="flex justify-center items-center font-number text-[12px] font-bold px-2 py-0.5 text-neobit-black">
                  <span class="mr-1">For sale</span><img src="/img/ui/downward-arrow.svg" class="w-4"/>
                </div>
              </div>
              <div
                class="flex items-center bg-gray-200 w-full justify-center"
                v-show="item.on_sale && market"
              >
                <div class="flex justify-center items-center font-number text-[12px] font-bold px-2 py-0.5 text-neobit-black">
                  <sexy-number
                    :number="item.on_sale_price / (10 ** 8)"
                    :decimals="4"
                    class="text-[13px] font-bold"
                  />
                  <img src="/img/tokens/transparent/bNEO.svg" class="w-5 h-5"/>
                </div>
              </div>
              <div
                v-show="showLineup"
                class="absolute top-[24px] right-0 w-[200px] bg-white z-20 shadow-2xl rounded-l-lg"
              >
                <div class="p-1">
                  <div class="text-xs">Actions:</div>
                  <div class="items-center gap-2" v-if="userId === item.owner_id && !item.on_sale && !item.on_loan">
                    <div v-if="!isSponsor && !isStonks"
                      @click.stop="toggleOption('useConsumable')"
                      class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center"
                    >
                      <img src="/img/ui/use.svg" title="USE" class="h-4 mr-2"/>
                      <div>Use</div>
                    </div>
                    <div v-if="isSponsor || isStonks"
                         @click.stop="toggleOption('UseStonks')"
                         class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center"
                    >
                      <img src="/img/ui/use.svg" title="USE" class="h-4 mr-2"/>
                      <div>Use</div>
                    </div>
                    <div
                      @click.stop="toggleOption('sell')"
                      class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center"
                    >
                      <img src="/img/ui/sell.svg" title="SELL" class="h-4 mr-2"/>
                      <div>Sell</div>
                    </div>
                    <div
                      v-if="store.state.userStore.clubIds.length > 1"
                      @click.stop="toggleOption('transfer')"
                      class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center"
                    >
                      <img src="/img/ui/lend.svg" title="TRANSFER" class="h-4 mr-2"/>
                      <div>Transfer</div>
                    </div>
                  </div>
                  <div class="items-center gap-2" v-if="userId === item.owner_id && item.on_sale">
                    <div class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center" @click.stop="toggleOption('sell')" v-if="item.on_sale">
                      <img src="/img/ui/edit.svg" title="EDIT SALE" class="h-4 mr-2"/>
                      <div>Edit Sale</div>
                    </div>
                    <div class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center" @click.stop="toggleOption('cancel')" v-if="item.on_sale">
                      <img src="/img/ui/cancel.svg" title="CANCEL SALE" class="h-4 mr-2"/>
                      <div>Cancel Sale</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Consumable Info -->
          <div
            @click="openModal"
            class="flex flex-grow pl-2 items-center"
          >
            <div class="w-[64px] -mt-1">
              <div class="text-[9px] text-[#33333380] font-semibold">Rank</div>
              <div class="capitalize text-[13px] font-bold">
                <rank :rank="item.rank"/>
              </div>
            </div>
            <div class="w-[66px]">
              <div class="text-[9px] text-[#33333380] font-semibold">For</div>
              <div class="capitalize text-[13px] font-bold -mt-1">
                {{ item.for_item }}
              </div>
            </div>
            <div class="w-[44px]" v-if="!isSponsor && !isStonks">
              <div class="text-[9px] text-[#33333380] font-semibold">Gain</div>
              <div class="capitalize text-[13px] font-bold -mt-1">
                {{ getGain(item) }}
              </div>
            </div>
            <div class="w-[140px]" v-if="isSponsor || isStonks">
              <div class="text-[9px] text-[#33333380] font-semibold">Tokens</div>
              <div class="capitalize text-[13px] font-bold -mt-1">
                <div class="flex items-center text-[13px] font-bold -mt-1">
                  <sexy-number
                    :number="item.gain"
                    :decimals="tokens[sponsor].displayDecimals"
                    class="mr-1"
                  ></sexy-number>
                  <div class="font-number">{{sponsor}}</div>
                </div>
              </div>
            </div>
            <div class="w-[76px]" v-if="!isSponsor && !isStonks">
              <div class="text-[9px] text-[#33333380] font-semibold">TRAIT</div>
              <div class="capitalize text-[13px] font-bold -mt-1">
                {{ item.for_skill }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- On Loan Card -->
    <div
      v-if="thisPlayer.onLoanFrom === playerClubId"
      @click="openModal"
      class="absolute inset-0 bg-white bg-opacity-90 z-10 flex justify-center items-center"
    >
      <div class="flex text-center text-sm flex-col items-center">
        <div class="mb-1">
          <div class="font-semibold text-[15px] flex">
            <div class="mr-1.5 flex items-center">
              <div class="mr-1"><img class="z-10" :src="`/img/cards/icons/${thisPlayer.position}.svg`"></div>
              <div>{{ thisPlayer.name }}</div>
            </div>
            (
            <div>
              <sexy-number
                :number="thisPlayer.age"
                :decimals="0"
                class="text-center w-full"
              />

            </div>
            <div class="">)</div>
          </div>
        </div>
        <div class="flex">
          On Loan at
          <club-logo :club-id="thisPlayer.clubId" class="w-5 h-5 mx-1"/>
          {{ store.state.userStore.clubNames[thisPlayer.clubId] }}
        </div>
      </div>
    </div>

  </div>
  <div
    v-if="['burn', 'sell', 'extend', 'edit', 'cancel', 'buy', 'lend', 'loan', 'transfer', 'useConsumable', 'ItemUseConsumable', 'UseStonks'].includes(itemOption)"
    class="fixed flex justify-center items-center inset-0 bg-black bg-opacity-40 text-black z-50"
  >
    <div>
      <terminate-contract :item="item" v-show="itemOption === 'burn'" @close="toggleOption(null)" @burn="burn"></terminate-contract>
      <sell-item :item="item" v-show="itemOption === 'sell' || itemOption === 'edit'" @sell="sell" @close="toggleOption(null)"></sell-item>
      <cancel-sale :item="item" v-show="itemOption === 'cancel'" @close="toggleOption(null)" @cancel="cancel()"></cancel-sale>
      <buy-item :item="item" v-show="itemOption === 'buy'" @close="toggleOption(null)" @cancel="cancel()" @buy="buy"></buy-item>
      <loan-item :item="item" v-show="itemOption === 'loan'" @close="toggleOption(null)" @cancel="cancel()" @loan="loan"></loan-item>
      <lend-item :item="item" v-show="itemOption === 'lend'" @close="toggleOption(null)" @cancel="cancel()" @lend="lend"></lend-item>
      <transfer-item :item="item" v-show="itemOption === 'transfer'" @close="toggleOption(null)" @cancel="cancel()" @transfer="transfer"></transfer-item>
      <use-consumable :item="item" v-show="itemOption === 'useConsumable'" @close="toggleOption(null)" @cancel="cancel()" @use="useConsumable"></use-consumable>
      <item-use-consumable :item="item" v-show="itemOption === 'ItemUseConsumable'" @close="toggleOption(null)" @cancel="cancel()" @use="itemUseConsumable"></item-use-consumable>
      <use-stonks :item="item" v-show="itemOption === 'UseStonks'" @close="toggleOption(null)" @cancel="cancel()" @useStonks="useConsumable"></use-stonks>
    </div>
  </div>
  <spinner v-if="loading"></spinner>
</template>

<script>
import GameAvatar from '@/apps/globalComponents/components/PlayerAvatar.vue';
import { mapPlayerData } from '@/apps/globalComponents/models/items';
import { computed, ref } from 'vue';
import backendAPI from '@/os/APIs/backendAPI';
import StadiumAvatar from '@/apps/globalComponents/components/StadiumAvatar.vue';
import Spinner from '@/os/components/Spinner.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import ClubLogo from '@/apps/globalComponents/components/clubLogo.vue';
import Rank from '@/apps/globalComponents/components/Rank.vue';
import TerminateContract from '@/apps/globalComponents/components/itemsModal/TerminateContract.vue';
import SellItem from '@/apps/globalComponents/components/itemsModal/SellItem.vue';
import CancelSale from '@/apps/globalComponents/components/itemsModal/CancelSale.vue';
import BuyItem from '@/apps/globalComponents/components/itemsModal/BuyItem.vue';
import LoanItem from '@/apps/globalComponents/components/itemsModal/LendItem.vue';
import LendItem from '@/apps/globalComponents/components/itemsModal/LoanItem.vue';
import TransferItem from '@/apps/globalComponents/components/itemsModal/TransferItem.vue';
import BigNumber from 'bignumber.js';
import ConsumableAvatar from '@/apps/globalComponents/components/ConsumableAvatar.vue';
import UseConsumable from '@/apps/globalComponents/components/itemsModal/UseConsumable.vue';
import UseStonks from '@/apps/globalComponents/components/itemsModal/UseTokens.vue';
import ItemUseConsumable from '@/apps/globalComponents/components/itemsModal/ItemUseConsumable.vue';
import tokens from '@/os/coreData/tokens';

export default {
  name: 'playerCard',
  components: {
    ItemUseConsumable,
    UseStonks,
    UseConsumable,
    ConsumableAvatar,
    TransferItem,
    LoanItem,
    BuyItem,
    LendItem,
    CancelSale,
    SellItem,
    TerminateContract,
    Rank,
    ClubLogo,
    Spinner,
    StadiumAvatar,
    GameAvatar,
  },
  props: {
    player: {
      type: Object,
      required: true,
    },
    isLineup: {
      type: Boolean,
      default: true,
    },
    market: {
      type: Boolean,
      default: false,
    },
    clubId: {
      type: String,
      default: '',
    },
  },
  emits: ['itemChange'],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const showMenu = ref(false);
    const showLineup = ref(false);
    const loading = ref(false);
    const thisPlayer = computed(() => mapPlayerData(props.player));
    const item = computed(() => props.player);
    const showSalary = computed(() => props.isLineup);
    const openCardPlayer = computed(() => store.state.osStore.modalData);
    const name = computed(() => thisPlayer.value.name.split(' ')[1]);
    const myPlayer = thisPlayer.value.clubId === store.state.userStore.club.id;
    const itemOption = ref(null);
    const extendPeriod = ref(1);
    const userId = computed(() => store.state.userStore.userId);
    const playerClubId = computed(() => (props.clubId === '' ? store.state.userStore.club.id : props.clubId));
    const isSponsor = computed(() => props.player.for_skill.startsWith('sponsor') && props.player.for_skill.replace('sponsor-', '') !== 'STONKS');
    const isStonks = computed(() => props.player.for_skill.startsWith('sponsor') && props.player.for_skill.replace('sponsor-', '') === 'STONKS');
    const sponsor = computed(() => props.player.for_skill.replace('sponsor-', ''));
    const lineupDict = {
      goalkeeper: 'GK',
      defender1: 'DEF 1',
      defender2: 'DEF 2',
      defender3: 'DEF 3',
      defender4: 'DEF 4',
      midfielder1: 'MID 1',
      midfielder2: 'MID 2',
      midfielder3: 'MID 3',
      attacker1: 'ATT 1',
      attacker2: 'ATT 2',
      attacker3: 'ATT 3',
      manager: 'MANAGER',
      stadium: 'STADIUM',
    };

    function toggleLineup() {
      showLineup.value = !showLineup.value;
    }

    function hideLineup() {
      showLineup.value = false;
    }

    async function addToLineup(position) {
      loading.value = true;

      try {
        const clubId = store.state.userStore.club.id;
        await backendAPI.clubEditLineup(thisPlayer.value.itemType, thisPlayer.value.id, position, clubId);
        showLineup.value = false;
        thisPlayer.value.inLineup = position;
        window.toast.success('Added to lineup');
        await store.dispatch('userStore/getUserInfo');
        await store.dispatch('userStore/getUserClub', { clubId });
        await store.dispatch('userStore/getClubItems', { clubId });
        loading.value = false;
        emit('itemChange', thisPlayer.value);
      } catch (err) {
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    function toggleOption(optionValue) {
      if (itemOption.value === optionValue) {
        itemOption.value = null;
        return;
      }

      itemOption.value = optionValue;
      showLineup.value = false;
    }

    async function useConsumable(itemId, newName) {
      loading.value = true;
      try {
        await backendAPI.itemUse(item.value.id, itemId, newName);
        window.toast.success('Used successfully');
        const clubId = store.state.userStore.club.id;
        await store.dispatch('userStore/getUserClub', { clubId });
        await store.dispatch('userStore/getClubItems', { clubId });
        if (isStonks.value || isSponsor.value) {
          await store.dispatch('userStore/getUserInfo');
        }
        loading.value = false;
        emit('itemChange', thisPlayer.value);
      } catch (err) {
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function itemUseConsumable(itemId, newName) {
      loading.value = true;
      try {
        await backendAPI.itemUse(itemId, item.value.id, newName);
        window.toast.success('Used successfully');
        const clubId = store.state.userStore.club.id;
        await store.dispatch('userStore/getUserClub', { clubId });
        await store.dispatch('userStore/getClubItems', { clubId });
        loading.value = false;
        emit('itemChange', thisPlayer.value);
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function sell(e) {
      loading.value = true;
      try {
        const price = new BigNumber(e).shiftedBy(8);
        await backendAPI.itemSell(item.value.item_type, item.value.id, price.toNumber());
        window.toast.success('Listed successfully');
        const clubId = store.state.userStore.club.id;
        await store.dispatch('userStore/getUserClub', { clubId });
        await store.dispatch('userStore/getClubItems', { clubId });
        itemOption.value = '';
        item.value.on_sale = true;
        item.value.on_sale_price = e * 100000000;
        loading.value = false;
        emit('itemChange', thisPlayer.value);
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function lend(e) {
      loading.value = true;
      try {
        const price = new BigNumber(e).shiftedBy(8);
        await backendAPI.itemLend(item.value.item_type, item.value.id, price.toNumber());
        window.toast.success('Listed successfully');
        const clubId = store.state.userStore.club.id;
        await store.dispatch('userStore/getUserClub', { clubId });
        await store.dispatch('userStore/getClubItems', { clubId });
        itemOption.value = '';
        item.value.on_loan = true;
        item.value.on_loan_price = (e * 100000000) + item.value.season_cost;
        loading.value = false;
        emit('itemChange', thisPlayer.value);
      } catch (err) {
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function cancel() {
      loading.value = true;
      try {
        await backendAPI.itemCancelMarket(item.value.item_type, item.value.id);
        window.toast.success('Canceled successfully');
        const clubId = store.state.userStore.club.id;
        await store.dispatch('userStore/getUserClub', { clubId });
        await store.dispatch('userStore/getClubItems', { clubId });
        itemOption.value = '';
        item.value.on_sale = false;
        item.value.on_loan = false;
        loading.value = false;
        emit('itemChange', thisPlayer.value);
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function burn() {
      loading.value = true;
      try {
        await backendAPI.itemBurn(item.value.item_type, item.value.id, true);
        window.toast.success('Burned successfully');
        await store.dispatch('userStore/getUserInfo');
        const clubId = store.state.userStore.club.id;
        await store.dispatch('userStore/getClubItems', { clubId });
        itemOption.value = null;
        loading.value = false;
        emit('itemChange', thisPlayer.value);
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function buy() {
      loading.value = true;
      try {
        const clubId = store.state.userStore.club.id;
        await backendAPI.itemBuy(item.value.item_type, item.value.id, clubId);
        window.toast.success('Bought successfully');
        await store.dispatch('userStore/getUserInfo');
        await store.dispatch('userStore/getClubItems', { clubId });
        await store.dispatch('marketplaceStore/getItemsOnSale', { itemType: item.value.item_type });
        itemOption.value = '';
        item.value.contractToSeason += 1;
        item.value.club_id = clubId;
        item.value.on_sale = false;
        loading.value = false;
        emit('itemChange', thisPlayer.value);
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function loan() {
      loading.value = true;
      try {
        const clubId = store.state.userStore.club.id;
        await backendAPI.itemLoan(item.value.item_type, item.value.id, clubId);
        window.toast.success('Rented successfully');
        await store.dispatch('userStore/getUserInfo');
        await store.dispatch('userStore/getClubItems', { clubId });
        await store.dispatch('marketplaceStore/getItemsOnSale', { itemType: item.value.item_type });
        itemOption.value = '';
        item.value.contractToSeason += 1;
        item.value.club_id = clubId;
        item.value.on_loan = false;
        loading.value = false;
        emit('itemChange', thisPlayer.value);
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function transfer(e) {
      loading.value = true;
      try {
        await backendAPI.itemTransfer(item.value.item_type, item.value.id, e);
        window.toast.success('Transferred successfully');
        const clubId = store.state.userStore.club.id;
        await store.dispatch('userStore/getUserClub', { clubId });
        await store.dispatch('userStore/getClubItems', { clubId });
        itemOption.value = '';
        item.value.club_id = e;
        loading.value = false;
        emit('itemChange', thisPlayer.value);
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function extend() {
      loading.value = true;
      try {
        await backendAPI.itemExtend(item.value.item_type, item.value.id, extendPeriod.value, true);
        window.toast.success('Extended successfully');
        const clubId = store.state.userStore.club.id;
        await store.dispatch('userStore/getUserInfo');
        await store.dispatch('userStore/getClubItems', { clubId });
        itemOption.value = '';
        item.value.contract_to_season += Number(extendPeriod.value);
        loading.value = false;
        emit('itemChange', thisPlayer.value);
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    function getGain(thisItem) {
      if (thisItem.for_skill === 'rename') return '-';
      if (thisItem.for_item === 'stadium') return thisItem.gain;
      if (thisItem.for_skill === 'stonks') return thisItem.gain;
      if (thisItem.for_skill === 'age') return thisItem.gain;
      return thisItem.gain / 10;
    }

    function openModal() {
      router.push({ name: 'ItemView', params: { itemId: item.value.id } });
    }

    function tokenImage(token) {
      return `/img/tokens/circle/${token}.svg`;
    }

    return {
      store,
      item,
      userId,
      sell,
      lend,
      cancel,
      burn,
      buy,
      getGain,
      loan,
      useConsumable,
      itemUseConsumable,
      transfer,
      extend,
      thisPlayer,
      openCardPlayer,
      openModal,
      myPlayer,
      showMenu,
      toggleLineup,
      hideLineup,
      name,
      addToLineup,
      lineupDict,
      showLineup,
      loading,
      showSalary,
      toggleOption,
      itemOption,
      playerClubId,
      sponsor,
      isSponsor,
      isStonks,
      tokens,
      tokenImage,
    };
  },
};
</script>

<style scoped>

</style>
