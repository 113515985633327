import FriendlyMatch from './practice/views/FriendlyMatch.vue';
import SearchOpponent from './practice/views/SearchOpponent.vue';
import RankingApp from './ranking/App.vue';
import RankingAppRouter from './ranking/router';
import CupApp from './cup/App.vue';
import CupAppRouter from './cup/router';

const routes = [
  {
    path: '/tournament/league',
    name: 'NeoLeague',
    component: RankingApp,
    redirect: { name: 'TableMain' },
    meta: { title: 'League - Neo FC' },
    children: RankingAppRouter,
  },
  {
    path: '/tournament/cup',
    name: 'NeoCup',
    component: CupApp,
    redirect: { name: 'CupOverview' },
    children: CupAppRouter,
  },
  {
    path: '/tournament/friendly',
    name: 'FriendlyMatch',
    component: FriendlyMatch,
    meta: { title: 'Friendly Match - Neo FC' },
  },
  {
    path: '/tournament/friendly/search',
    name: 'SearchOpponent',
    component: SearchOpponent,
    meta: { title: 'Search Opponent - Neo FC' },
  },
];

export default routes;
