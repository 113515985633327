<template>
  <div>
    <div class="mt-[34px] mb-[30px]" @click="test()">
      <div
        id=""
        class="card"
        :class="result"
      >
        <div class="card one">
          <img
            src="/img/cards/player/local.png"
          />
        </div>
        <div class="card two">
          <img
            src="/img/cards/player/continentalBack.png"
          />
        </div>
        <div class="card three">
          <img
            src="/img/cards/player/city.png"
          />
        </div>
        <div class="card four">
          <img
            src="/img/cards/player/cityBack.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const result = ref('tails');

    function test() {
      if (result.value === 'heads') {
        result.value = '';
      } else {
        result.value = 'heads';
      }
    }

    return {
      result,
      test,
    };
  },
};
</script>

<style scoped>

.card {
  position: relative;
  width: 240px;
  height: 373px;
  transform-style: preserve-3d;
}

.card div {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  animation: flip 3s ease-out infinite;
  opacity: 0;
}

.card .one {
}

.card .two {
  animation-delay: 1s;
}

.card .three {
  animation-delay: 2s;
}

.card .four {
  animation-delay: 3s;
}

@keyframes flip {
  0% {
    transform-style: preserve-3d;
    transform: rotateY(-90deg);
    opacity: 1;
  }
  33.33% {
    transform-style: preserve-3d;
    transform: rotateY(90deg);
    opacity: 1;
  }
  33.34% {
    transform-style: preserve-3d;
    opacity: 0;
  }
  100% {
    transform-style: preserve-3d;
    opacity: 0;
  }
}

</style>
