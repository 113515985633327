<template>
  <div class="relative p-2 bg-white rounded-2xl mx-4 w-full">
    <div class="text-[18px] font-bold mt-1 text-neobit-green uppercase text-center mb-2">
      SIGN UP FOR SEASON {{ season + 1 }}
    </div>
    <div class="text-center mb-2 bg-gray-200 rounded-2xl p-4">
      <div class="text-sm font-bold">
        SIGNUP COST:
      </div>
      <div class="flex justify-center items-center">
        <div class=" text-3xl">
          <sexy-number
            :number="5"
            :decimals="2"
            class="mr-1"
          />
        </div>
        <div class="bg-white rounded-full mt-1">
          <img
            alt="bNEO"
            title="bNEO"
            src="/img/tokens/transparent/bNEO.svg"
            class="w-8 h-8"
          />
        </div>
      </div>
    </div>
    <button
      @click="$emit('signup')"
      class="confirm flex items-center justify-center text-[15px] mt-4 w-full"
    >
      CONFIRM
    </button>
    <button
      @click="$emit('close')"
      class="cancel flex items-center justify-center text-[15px] mt-3 w-full"
    >
      CANCEL
    </button>
  </div>
</template>

<script>
export default {
  name: 'SignUpForSeason',
  props: {
    season: {
      type: Number,
    },
  },
};
</script>

<style scoped>
.input {
  border: 2px solid #C8EBE0;
}

.confirm {
  background: #00AF92;
  border-radius: 12px;
  height: 46px;
  color: #FFFFFF;
}

.cancel {
  background: #C8EBE0;
  border-radius: 12px;
  height: 46px;
  color: #00AF92;
}
</style>
