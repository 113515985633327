<template>
  <app-interface>
    <div class="h-full">
      <router-view></router-view>
    </div>
  </app-interface>
  <mint-modal></mint-modal>
</template>

<script>
import AppInterface from '@/os/components/templates/AppInterface.vue';
import MintModal from '@/apps/recruit/modals/mintModal.vue';

export default {
  name: 'mint-main-app',
  components: { MintModal, AppInterface },
  setup() {
    return {};
  },
};
</script>
