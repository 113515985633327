<template>
  <img :src="`/img/cards/consumable/${getName(consumable)}.svg`"/>
</template>

<script>

export default {
  name: 'ConsumableAvatar',
  props: {
    consumable: {},
  },
  setup() {
    function getName(item) {
      if (item.for_skill === 'age') return `${item.for_item}-${item.for_skill}${-item.gain}`;
      if (item.for_skill === 'rename') return `${item.for_item}-${item.for_skill}`;
      return item.for_skill;
    }
    return {
      getName,
    };
  },
};
</script>

<style scoped>

</style>
