<template>
  <img :src="`/img/cards/stadiums/${stadiumRank}-stadium.svg`"/>
</template>

<script>

export default {
  name: 'stadium-avatar',
  props: {
    stadiumRank: {},
  },
};
</script>

<style scoped>

</style>
