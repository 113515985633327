<template>
  <div class="bg-[#ECECEC]">
    <div class="bg-white info-box py-2 pr-1">
      <div class="flex pb-1.5 px-2 -mt-1 border-b border-gray-500">
        <div class="flex">
          <div class="px-1.5 py-0.5 rounded-2xl font-semibold text-[12px]" @click="toggleMenu('prizes')"
               :class="toggle === 'prizes' ? 'bg-gray-300 text-neobit-green border-none' : 'text-gray-600 bg-gray-100 bg-opacity-70 shadow-inner'"
          >
            Prizes
          </div>
          <div class="ml-2 px-1.5 py-0.5 rounded-2xl font-semibold text-[12px]" @click="toggleMenu('form')"
               :class="toggle === 'form' ? 'bg-gray-300 text-neobit-green border-none' : 'text-gray-600 bg-gray-100 bg-opacity-70 shadow-inner'"
          >
            Form
          </div>
        </div>
      </div>
      <div class="flex text-[11px] font-semibold text-neobit-black text-opacity-50 items-center text-center mt-1">
        <div class="w-[6px]"></div>
        <div class="w-[18px] text-left">#</div>
        <div class="w-[150px] text-left">Team</div>
        <div class="w-6 mr-1 text-left"></div>
        <div v-if="toggle === ''" class="flex w-6/12">
          <div class="w-1/6">PL</div>
          <div class="w-1/6">W</div>
          <div class="w-1/6">D</div>
          <div class="w-1/6">L</div>
          <div class="w-[50px]">+/-</div>
          <div class="w-1/6">Dif</div>
        </div>
        <div v-if="toggle === 'prizes'" class="w-6/12">
          <div>Current Prize</div>
        </div>
        <div v-if="toggle !== 'form'" class="w-1/12">P</div>
        <div v-if="toggle === 'form'" class="w-7/12 flex justify-end mr-2">Last Matches</div>
      </div>
      <div v-for="(team, index) in sortedTeams" :key="team.club_id" class="flex text-xs items-center text-neobit-black text-center border-b border-gray-100"
           :class="[team.club_id === store.state.userStore.club.id ? 'bg-green-100 bg-opacity-50' : '' ]">
        <div :class="[
                    index === 0 ? 'first': 'w-[6px]',
                    index === 1 ? 'second': 'w-[6px]',
                    index === 2 && Number(leagueId) === 1 ? 'third': 'w-[6px]',
                    index === 16 ? 'last': 'w-[6px]',
                    index === 17 ? 'last': 'w-[6px]',
                    index === 18 ? 'last': 'w-[6px]',
                    index === 19 ? 'last': 'w-[6px]'
                    ]"
        ></div>
        <div class="py-1 text-left w-[18px] ml-1">{{ index + 1 }}</div>
        <div class="flex items-center cursor-pointer" @click="$router.push({ name: 'ClubView', params: { clubId: team.club_id } })">
          <club-logo :club-id="team.club_id" class="w-5 h-5"></club-logo>
          <div class="w-[155px] ml-1 text-left text-[11px] font-semibold">{{ store.state.userStore.clubNames[team.club_id] }}</div>
        </div>
        <div v-show="toggle === ''" class="flex w-6/12">
          <div class="w-1/6">{{ store.state.userStore.currentRound - 1 }}</div>
          <div class="w-1/6">{{ team.home_wins + team.away_wins }}</div>
          <div class="w-1/6">{{ team.home_draws + team.away_draws }}</div>
          <div class="w-1/6">{{ team.home_loss + team.away_loss }}</div>
          <div class="w-[50px]">{{ team.goals_for }}-{{ team.goals_against }}</div>
          <div class="w-1/6">{{ team.goals_for - team.goals_against }}</div>
        </div>
        <div v-show="toggle === 'prizes'" class="w-6/12 flex items-center justify-center">
          <img src="/img/tokens/transparent/bNEO.svg" class="w-5 h-5"/>
          <sexy-number
            :number="thisLeaguePrice * tableShare[(index + 1)]"
            :decimals="4"
            class="text-[11px] text-neobit-black font-semibold"
          />
        </div>
        <div v-show="toggle !== 'form'" class="w-1/12">
          <div class="px-2 py-0.5 bg-neobit-greenlight rounded-md text-[11px] font-semibold">{{ team.points }}</div>
        </div>
        <div v-show="toggle === 'form'" class="w-7/12 flex justify-end mr-2">
          <div v-for="match in team.matches.slice(0, 5).reverse()" :key="match.id" class="flex">
            <div class="mx-0.5 relative rounded text-white justify-center font-bold flex items-center"
                 :class="[
                                match.points === 3 ? 'win' : '',
                                match.points === 1 ? 'draw' : '',
                                match.points === 0 ? 'lose' : '',
                            ]">
              <div v-show="match.points === 3">W</div>
              <div v-show="match.points === 0">L</div>
              <div v-show="match.points === 1">D</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { tableShare } from '@/os/utils/config';
import ClubLogo from '@/apps/globalComponents/components/clubLogo.vue';

export default {
  components: { ClubLogo },
  props: {
    small: {
      default: true,
      type: Boolean,
    },
  },
  name: 'league-table',
  setup() {
    const route = useRoute();
    const leagueId = computed(() => route.params.league ?? 1);
    const toggle = ref('');
    const thisLeaguePrice = computed(() => store.state.userStore.leaguePrizes[leagueId.value] / 100000000);
    const teams = computed(() => store.state.userStore.leagues[leagueId.value]);

    function toggleMenu(menu) {
      if (toggle.value === menu) {
        toggle.value = '';
        return;
      }
      toggle.value = menu;
    }

    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    const sortedTeams = computed(() => {
      if (teams.value?.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return teams.value.sort((b, a) => a.points - b.points || (a.goals_for - a.goals_against) - (b.goals_for - b.goals_against));
      }
      return [];
    });

    return {
      store,
      toggle,
      toggleMenu,
      thisLeaguePrice,
      tableShare,
      leagueId,
      sortedTeams,
    };
  },
};
</script>

<style scoped>
.win {
  background-color: #0e8f14;
  height: 18px;
  width: 18px;
}

.lose {
  background-color: #f91111;
  height: 18px;
  width: 18px;
}

.draw {
  background-color: #6f6d6d;
  height: 18px;
  width: 18px;
}

.last {
  width: 18px;
  height: 2px;
}

.first {
  background-color: #C8B06D;
  height: 22px;
  width: 4px;
  margin-right: 2px;
  border-radius: 0px 4px 4px 0px;
}

.second {
  background-color: #B4BACA;
  height: 22px;
  width: 4px;
  margin-right: 2px;
  border-radius: 0px 4px 4px 0px;
}

.third {
  background-color: #966938;
  height: 22px;
  width: 4px;
  margin-right: 2px;
  border-radius: 0px 4px 4px 0px;
}

.last {
  background-color: #db162d;
  height: 22px;
  width: 4px;
  margin-right: 2px;
  border-radius: 0px 4px 4px 0px;
}

.info-box {
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.06);
}
</style>
