export default ({
  namespaced: true,
  state: () => ({
    activeModal: false,
    modalData: null,
  }),
  mutations: {
    setActiveModal(state, modal) {
      state.activeModal = modal === '' ? false : modal;
    },
    setModalData(state, data) {
      state.modalData = data;
    },
  },
});
