const mainnetTokens = {
  // ============ Neo Assets ============ //
  bNEO: {
    symbol: 'bNEO',
    decimals: 8,
    displayDecimals: 4,
    hash: '0x48c40d4666f93408be1bef038b6722404d9a4c2a',
    chain: 'NEO',
    deposit: true,
    withdraw: true,
    minAmount: 10,
  },
  NEO: {
    symbol: 'NEO',
    decimals: 0,
    displayDecimals: 8,
    hash: '0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5',
    chain: 'NEO',
    deposit: true,
    withdraw: false,
    minAmount: 10,
  },
  BITS: {
    symbol: 'BITS',
    decimals: 0,
    displayDecimals: 0,
    hash: '0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5',
    chain: 'NEO',
    withdraw: false,
    deposit: false,
    minAmount: 10,
  },
  CANDY: {
    symbol: 'CANDY',
    decimals: 9,
    displayDecimals: 0,
    hash: '0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5',
    chain: 'NEO',
    deposit: false,
    withdraw: true,
    minAmount: 10000,
  },
  FLM: {
    symbol: 'FLM',
    decimals: 8,
    displayDecimals: 0,
    hash: '0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5',
    chain: 'NEO',
    deposit: false,
    withdraw: true,
    minAmount: 10000,
  },
  DOGER: {
    symbol: 'DOGER',
    decimals: 8,
    displayDecimals: 0,
    hash: '0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5',
    chain: 'NEO',
    deposit: false,
    withdraw: true,
    minAmount: 1000,
  },
  STONKS: {
    symbol: 'STONKS',
    decimals: 0,
    displayDecimals: 0,
    hash: '0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5',
    chain: 'NEO',
    deposit: false,
    withdraw: false,
    minAmount: 1000,
  },
};

export default {
  ...mainnetTokens,
};
