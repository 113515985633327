<template>
  <div v-if="loading">
    <spinner></spinner>
  </div>
  <onboarding-template>
    <div class="bg-white shadow-md p-4 flex flex-col items-center justify-center font-montserrat rounded-[20px] w-full">
      <div class="text-lg font-bold text-neobit-green text-center">Complete Your Account</div>
      <div class="mt-1 text-neobit-black font-medium text-center text-[15px]">
        Neo Soccer requires an invite and a club name. Please enter you invite code and club name bellow.
      </div>
      <div class="mt-4 w-full flex items-center">
        <div class="font-bold py-2 flex-grow">
          Invite Code:
        </div>
        <div
          v-if="codeChecked"
          class="bg-green-200 rounded py-0.5 px-2 text-center text-sm"
        >
          Code is valid
        </div>
      </div>
      <div class="flex space-x-2">
        <input
          v-for="(n, index) in 6"
          :key="index"
          ref="inputs"
          @input.prevent.stop="onInputAtIndex($event.target.value, index)"
          @keydown.delete.stop="onDeleteAtIndex($event.target.value, index)"
          :disabled="codeChecked"
          type="text"
          autocomplete="off"
          class="border rounded w-10 h-10 text-center font-bold outline-green-300"
        >
      </div>

      <div class="text-sm w-full mt-2">
        Don't have an invitation code? <br>
        <a
          href="https://discord.gg/a5R97QJnS6"
          target="_blank"
          class="underline"
        >
          Join our Discord server to get one
        </a>
      </div>

      <div class="mt-4 w-full flex items-center">
        <div class="font-bold py-2 flex-grow">
          Team Name:
        </div>
        <div
          v-if="clubNameChecked === true"
          class="bg-green-200 rounded py-0.5 px-2 text-center text-sm"
        >
          Team name valid
        </div>
        <div
          v-if="clubNameChecked !== true"
          class="bg-red-200 rounded py-0.5 px-2 text-center text-sm"
        >
          {{ clubNameChecked }}
        </div>
      </div>

      <input
        v-model="clubName"
        type="text"
        class="border rounded-lg outline-green-300 w-full mb-4 px-4 py-3"
      >

      <button
        @click="completeAccount"
        :disabled="clubNameChecked !== true || !codeChecked"
        class="w-full bg-neobit-green rounded-lg text-white p-3 disabled:bg-gray-400"
      >
        Continue
      </button>
    </div>
  </onboarding-template>
</template>

<script>
/* eslint-disable prefer-destructuring */
import store from '@/store';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import backendAPI from '@/os/APIs/backendAPI';
import Spinner from '@/os/components/Spinner.vue';
import OnboardingTemplate from '@/os/components/templates/OnboardingTemplate.vue';

export default {
  name: 'EnterInviteCode',
  components: {
    Spinner,
    OnboardingTemplate,
  },
  setup() {
    const router = useRouter();
    const loading = ref(false);
    const codeChecked = ref(false);
    const inputs = ref();
    const clubName = ref('');
    const clubNameChecked = computed(() => {
      if (clubName.value.length < 3) return 'Min. 3 characters';
      if (clubName.value.length > 14) return 'Max 14 characters';
      if (clubName.value.includes('#')) return 'Cannot include #';
      return true;
    });
    let code = '';

    async function checkCode() {
      loading.value = true;
      try {
        await backendAPI.clubCheckCode(code);
        await store.dispatch('userStore/getUserInfo');
        codeChecked.value = true;
        loading.value = false;
      } catch (err) {
        console.error(err.response.data.detail);
        window.toast.error('Wrong Code');
        loading.value = false;
        document.getElementById('n1')
          .focus();
        throw err;
      }
    }

    function onInputAtIndex(value, index) {
      const newCode = value.toUpperCase().slice(0, 6 - index);
      code = code.substr(0, index) + newCode;

      inputs.value[0].value = code[0] || '';
      inputs.value[1].value = code[1] || '';
      inputs.value[2].value = code[2] || '';
      inputs.value[3].value = code[3] || '';
      inputs.value[4].value = code[4] || '';
      inputs.value[5].value = code[5] || '';

      if (code.length === 6) {
        checkCode();
        return;
      }

      if (index + newCode.length < 6) {
        inputs.value[index + newCode.length].focus();
      } else {
        inputs.value[index].blur();
      }
    }

    function onDeleteAtIndex(value, index) {
      if (value !== '') return;
      if (index > 0) inputs.value[index - 1].focus();
      inputs.value[index].value = '';
    }

    async function completeAccount() {
      loading.value = true;

      try {
        await backendAPI.clubCreate(clubName.value, code);
        await store.dispatch('userStore/getUserInfo');
        await store.dispatch('userStore/getUserClub', { clubId: false });
        loading.value = false;
        router.push({ name: 'OverviewMain' });
      } catch (err) {
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    return {
      store,
      loading,
      codeChecked,
      onInputAtIndex,
      onDeleteAtIndex,
      inputs,
      clubName,
      clubNameChecked,
      completeAccount,
    };
  },
};
</script>
