<template>
  <stadium-avatar :stadium-rank="item.rank" class="h-[280px] -mt-48"></stadium-avatar>
  <div class="mt-1 text-[26px] font-extrabold text-neobit-black">{{ item.name }}</div>

  <div class="flex items-center flex-wrap w-full px-4 mt-6 gap-3">
    <div class="flex-1 rounded-md px-1.5 py-[5px] flex flex-col justify-center bg-neobit-greenlight items-center">
      <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">POSITION</span>
      <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase">{{ item.position }}</span>
    </div>
    <div class="flex-1 rounded-md px-1.5 py-[5px] flex flex-col justify-center bg-neobit-greenlight items-center">
      <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">SALARY</span>
      <sexy-number
        :number="item.season_cost / 100000000"
        :decimals="4"
        class="text-neobit-black text-sm font-semibold leading-[14px]"
      />
    </div>
    <div class="flex-1 rounded-md px-1.5 py-[5px] flex flex-col justify-center bg-neobit-greenlight items-center">
      <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">RANK</span>
      <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase"><rank :rank="item.rank" /></span>
    </div>
  </div>
  <div class="flex items-center flex-wrap w-full px-4 mt-3 gap-3">
    <div class="flex-1 rounded-md px-1.5 py-[5px] flex flex-col justify-center bg-neobit-greenlight bg-opacity-30 border-2 border-neobit-greenlight border-opacity-40 items-center">
      <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">CAPACITY</span>
      <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase">{{ item.capacity }}</span>
    </div>
    <div class="flex-1 rounded-md px-1.5 py-[5px] flex flex-col justify-center bg-neobit-greenlight bg-opacity-30 border-2 border-neobit-greenlight border-opacity-40 items-center">
      <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">LUCK</span>
      <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase">{{ (item.luck?.base + item.luck?.inc + item.luck?.dec) / 10 }}</span>
    </div>
  </div>
  <div class="flex items-center flex-wrap w-full px-4 mt-3 gap-3">
    <div class="flex-1 rounded-md px-1.5 py-[5px] flex flex-col justify-center bg-neobit-greenlight bg-opacity-30 border-2 border-neobit-greenlight border-opacity-40 items-center">
      <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">WEATHER</span>
      <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase">{{ (item.weather?.base + item.weather?.inc + item.weather?.dec) / 10 }}</span>
    </div>
    <div class="flex-1 rounded-md px-1.5 py-[5px] flex flex-col justify-center bg-neobit-greenlight bg-opacity-30 border-2 border-neobit-greenlight border-opacity-40 items-center">
      <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">AGE</span>
      <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase">{{ item.age }}</span>
    </div>
  </div>
</template>

<script>
import StadiumAvatar from '@/apps/globalComponents/components/StadiumAvatar.vue';
import Rank from '@/apps/globalComponents/components/Rank.vue';

export default {
  name: 'StadiumModalComponent',
  components: { StadiumAvatar, Rank },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
