<template>
  <div class="relative p-2 flex flex-col bg-white rounded-2xl mx-4 w-96">
    <div class="flex items-center mb-2">
      <div v-if="!item.on_sale" class="flex flex-grow text-[18px] font-bold mt-1 text-neobit-green uppercase">Use Consumable</div>
      <div class="" @click="$emit('close')">
        <img :src="`/img/ui/cross.svg`" width="26"/>
      </div>
    </div>
    <div>
      <div>
        <div class="text-[12px] mb-2">
          <item-search-card :player="item"></item-search-card>
        </div>
      </div>
      <div class="relative flex-grow flex items-end selectdiv mb-2" v-if="item.item_type !== 'stadium'">
        <select
          v-model="globalStore.itemConsumableTypeSelected.value"
          class="w-full h-10 dropdown text-neobit-green font-bold outline-none"
        >
          <option value="skills">Skills</option>
          <option value="luck">Luck</option>
          <option value="multiplier">Multiplier</option>
          <option value="aggression">Aggression</option>
          <option value="age">Age</option>
          <option value="rename">Rename</option>
        </select>
      </div>
      <div class="relative flex-grow flex items-end selectdiv mb-2" v-if="item.item_type === 'stadium'">
        <select
          v-model="globalStore.stadiumConsumableTypeSelected.value"
          class="w-full h-10 dropdown text-neobit-green font-bold outline-none"
        >
          <option value="capacity">Capacity</option>
          <option value="age">Age</option>
          <option value="rename">Rename</option>
        </select>
      </div>

      <div class="max-h-[200px] min-h-[200px] overflow-y-scroll nice-scroll py-1">
        <div v-for="item in filteredItems" :key="item.id" :value="item" class="p-1 relative">
          <item-search-card :player="item"></item-search-card>
          <div
            @click="selectedItem = item" class="absolute cursor-pointer inset-0 z-10 p-1"
            :class="[selectedItem.id === item.id ? 'bg-green-700 bg-opacity-30' : '']"
          >
            <div v-if="selectedItem.id === item.id" class="flex h-full items-center justify-center text-white font-bold text-2xl">SELECTED</div>
          </div>
        </div>
      </div>
      <div v-if="selectedItem.for_skill === 'rename'">
        <div  class="mt-4 flex justify-center font-semibold">
          What do you want to name {{item.name}}:
        </div>
        <input
          @input="validateInput"
          class="block w-full pl-2 py-3 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500
        focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
        dark:focus:border-blue-500"
          v-model="newName"
          :placeholder="`New Name...`" />
        <p class="text-red-500 text-xs italic" v-if="!validName">Please enter a valid name</p>
      </div>
    </div>
    <div class="cursor-pointer confirm flex items-center justify-center text-[15px] mt-4" @click="$emit('use', selectedItem.id, newName)">CONFIRM</div>
    <div class="cursor-pointer cancel flex items-center justify-center text-[15px] mt-3" @click="$emit('close')">CANCEL</div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import store from '@/store';
import ItemSearchCard from '@/apps/globalComponents/components/ItemSearchCard.vue';
import globalStore from '@/os/utils/global';

export default {
  name: 'ItemUseConsumable',
  components: { ItemSearchCard },
  props: {
    item: {
      type: Object,
    },
  },
  setup(props) {
    const itemId = ref(null);
    const itemType = ref(null);
    const newName = ref('');
    const searchQuery = ref('');
    const selectedItem = ref(false);
    const validName = ref(false);
    const items = computed(() => store.state.userStore.consumables.filter((i) => i.for_item === props.item.item_type));

    const ranks = {
      local: 1,
      district: 2,
      city: 3,
      county: 4,
      country: 5,
      continental: 6,
      global: 7,
      neo: 8,
    };
    function itemSort(a, b) {
      return ranks[b.rank] > ranks[a.rank] ? 1 : -1;
    }

    itemType.value = globalStore.itemConsumableTypeSelected.value;
    if (props.item.item_type === 'stadium') {
      itemType.value = globalStore.stadiumConsumableTypeSelected.value;
    }

    watch(globalStore.stadiumConsumableTypeSelected, () => {
      itemType.value = globalStore.itemConsumableTypeSelected.value;
      if (props.item.item_type === 'stadium') {
        itemType.value = globalStore.stadiumConsumableTypeSelected.value;
      }
    });

    watch(globalStore.itemConsumableTypeSelected, () => {
      itemType.value = globalStore.itemConsumableTypeSelected.value;
      if (props.item.item_type === 'stadium') {
        itemType.value = globalStore.stadiumConsumableTypeSelected.value;
      }
    });

    const filteredItems = computed(() => items.value.filter((i) => i.for_skill === itemType.value).sort(itemSort));

    function getGain(thisItem) {
      if (thisItem.for_skill === 'rename') return '-';
      if (thisItem.for_item === 'stadium') return thisItem.gain;
      if (thisItem.for_skill === 'age') return thisItem.gain;
      if (thisItem.for_skill === 'stonks') return thisItem.gain;
      return thisItem.gain / 10;
    }

    function isName(name) {
      if (typeof name !== 'string') return false;
      if (name.split(' ').length < 2) return false;
      if (name.split(' ').length > 3) return false;
      if (name.length < 6) return false;
      if (name.length > 24) return false;
      if (!/^[a-zA-Z ]+$/.test(name)) return false;
      return true;
    }

    function validateInput() {
      validName.value = isName(newName.value);
    }

    return {
      globalStore,
      newName,
      itemId,
      items,
      filteredItems,
      searchQuery,
      selectedItem,
      getGain,
      validateInput,
      validName,
    };
  },
};
</script>

<style scoped>
.input {
  border: 2px solid #C8EBE0;
}

.confirm {
  background: #00AF92;
  border-radius: 12px;
  height: 46px;
  color: #FFFFFF;
}

.cancel {
  background: #C8EBE0;
  border-radius: 12px;
  height: 46px;
  color: #00AF92;
}

.dropdown {
   border: 2px solid #C8EBE0;
   border-radius: 6px;
 }

/*To remove button from IE11, thank you Matt */
select::-ms-expand {
  display: none;
  background: rgba(0, 0, 0, 0.3);
}

.select option {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.selectdiv:after {
  content: '<>';
  font: 17px "Consolas", monospace;
  color: #00AF92;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 12px;
  /*Adjust for position however you want*/

  top: 12px;
  padding: 0 0 2px;
  border-bottom: 1px solid #00AF92;
  /*left line */

  position: absolute;
  pointer-events: none;
}

.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */

  padding: 0px 12px;
  font-size: 16px;
  line-height: 1.75;
  background-color: #ffffff;
  background-image: none;
  -ms-word-break: normal;
  word-break: normal;
}
</style>
