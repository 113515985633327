<template>
  <button class="neo-button rounded-lg">
    <slot/>
  </button>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'sexy-button',
});
</script>

<style scoped>

.regular {
  background: #00AF92;
  color: #FFFFFF;
}

.red-linear {
  background: linear-gradient(87.04deg, #970000 5.38%, #e03900 97.13%);
}

.green-linear {
  background: linear-gradient(87.04deg, #115c00 5.38%, #25b800 97.13%);
}

.pink-border-black-bg {
  border: solid 2px transparent;
  background-image: linear-gradient(black, black),
  linear-gradient(to left, #A50B7A 0%, #D73AE0 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.neo-button:disabled {
  background: #353535;
  color: #1A1A1A;
  cursor: default;
}

</style>
