<template>
  <div class="inline-flex relative">
    <div
      class="outline-none w-full rounded-md cursor-pointer font-poppins"
      @click="toggleTokenSelector()"
    >
      <div v-if="tagValue !== null" class="flex items-center">
        <img
          class="w-6 h-6 border border-neobit-black rounded-full border-opacity-30"
          :src="tokenImage(tagValue.symbol)"
        >
        <div class="flex-grow ml-2 text-neobit-black text-opacity-90 font-semibold text-sm">{{ tagValue.symbol }}</div>
        <img src="/img/icons/arrow-down.svg" class="w-6 h-6"/>
      </div>
      <div v-else class="flex items-center justify-between">
        <span class="text-[15px] font-medium text-neobit-black text-opacity-50 tracking-wide">Select Token...</span>
        <img src="/img/icons/arrow-down.svg" class="w-6 h-6"/>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="selectorIsVisible"
        class="rounded-lg shadow-lg absolute right-0 my-1 z-10 left-0 overflow-auto nice-scroll border border-neobit-green"
        style="max-height: 182px;"
        :style="selectorStyle"
        ref="selector"
        v-click-outside="hideSelector"
      >
        <div
          v-for="(item, key) in tokenList"
          :key="key"
          @click="selectOpt(item)"
          class="flex items-center border-b border-neobit-green border-opacity-30 last:border-b-0 px-2 py-2 cursor-pointer transition duration-100 bg-[#effaf4]"
        >
            <img
              :src="tokenImage(item.symbol)"
              class="w-6 h-6 border border-neobit-black rounded-full border-opacity-30"
              :alt="item.symbol"
            >
            <div class="text-sm flex-grow ml-2 text-neobit-black text-opacity-90 font-semibold">
              {{ item.symbol }}
            </div>
            <div v-if="depositOnly" class="text-xs text-right w-[100px]"><span class="font-number text-sm font-bold">{{ item.minAmount }}</span> Minimum</div>
          </div>
        </div>
    </transition>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import tokens from '@/os/coreData/tokens';

export default {
  name: 'TokenSelect',
  props: {
    modelValue: {},
    placeholderValue: {},
    withdrawOnly: {
      type: Boolean,
      default: false,
    },
    depositOnly: {
      type: Boolean,
      default: false,
    },
    defaultToken: {
      type: String,
      default: 'bNEO',
    },
  },
  emits: ['update:token'],
  setup(props, { emit }) {
    const selectorIsVisible = ref(false);
    const tagValue = ref(null);

    function toggleTokenSelector(show = null) {
      selectorIsVisible.value = (show === null) ? !selectorIsVisible.value : show;
    }

    function tokenImage(token) {
      return `/img/tokens/circle/${token}.svg`;
    }

    function hideSelector() {
      selectorIsVisible.value = false;
    }

    function emitTag() {
      emit('update:token', tagValue.value);
      selectorIsVisible.value = false;
    }

    function selectOpt(item) {
      tagValue.value = item;
      emitTag();
    }

    const tokenList = computed(() => {
      if (props.withdrawOnly) {
        return Object.values(tokens).filter((t) => t.withdraw === true);
      }
      if (props.depositOnly) {
        return Object.values(tokens).filter((t) => t.deposit === true);
      }
      return tokens;
    });

    if (props.defaultToken !== {}) {
      tagValue.value = tokens[props.defaultToken];
    }

    const selector = ref();
    const selectorStyle = ref({ top: '100%' });

    return {
      tokenList,
      selectorIsVisible,
      toggleTokenSelector,
      hideSelector,
      selectOpt,
      selector,
      selectorStyle,
      tagValue,
      emitTag,
      tokenImage,
    };
  },
};

</script>

<style
  lang="scss"
  scoped
>

.token-menu-item:hover {
  background: #473ECB;
}

.token-menu-item:first-child {
  border-radius: 0.5rem 0.5rem 0 0;
}

.token-menu-item:last-child {
  border-radius: 0 0 0.5rem 0.5rem;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 100ms;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.nice-scroll {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #3D3D44;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #808087;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #808087;
  }
}

.bg-selectbox {
  background: rgba(17, 17, 17, 0.64);
}
</style>
