<template>
  <div v-if="loading">
    <spinner></spinner>
  </div>
  <div
    v-if="step === 1"
    class="bg-white shadow-md p-4 flex flex-col items-center justify-center font-montserrat rounded-[20px] w-full"
  >
    <div class="text-lg font-bold text-neobit-green text-center">
      REGISTER
    </div>
    <div class="mt-1 text-neobit-black font-medium text-center text-[15px]">
      Create your NeoBits account.
    </div>
    <input
      v-model="username"
      placeholder="Username"
      name="displayname"
      class="border-2 border-neobit-greenlight rounded-md bg-transparent outline-none mt-4 w-full px-3 py-2.5"
    />
    <input
      v-model="email"
      placeholder="E-mail"
      name="email"
      class="border-2 border-neobit-greenlight rounded-md bg-transparent outline-none mt-4 w-full px-3 py-2.5"
    />
    <input
      v-model="password"
      placeholder="Password"
      type="password"
      name="password"
      class="border-2 border-neobit-greenlight rounded-md bg-transparent outline-none mt-3 w-full px-3 py-2.5"
    />
    <button
      @click="register"
      class="bg-neobit-green rounded-xl py-3 text-center w-full text-white text-[15px] font-bold mt-6"
    >
      REGISTER ACCOUNT
    </button>
    <button
      @click="$router.push({ name: 'Onboarding' })"
      class="bg-neobit-greenlight rounded-xl py-2.5 text-center w-full text-neobit-green text-[15px] font-bold mt-4"
    >
      BACK
    </button>
  </div>
  <div
    v-if="step === 2"
    class="bg-white shadow-md p-4 flex flex-col items-center justify-center font-montserrat rounded-[20px] w-full"
  >
    <div class="text-lg font-bold text-neobit-green text-center">
      CHECK YOUR EMAIL
    </div>
    <div class="mt-1 text-neobit-black font-medium text-center mb-4 text-[15px]">
      You need to verify your email before you can login
    </div>
    <img src="/img/ui/activate.png">
  </div>
</template>

<script>
import { ref } from 'vue';
import backendAPI from '@/os/APIs/backendAPI';
import store from '@/store';
import Spinner from '@/os/components/Spinner.vue';

export default {
  name: 'register-user',
  components: { Spinner },
  setup() {
    const loading = ref(false);
    const step = ref(1);
    const email = ref('');
    const username = ref('');
    const password = ref('');

    async function register() {
      loading.value = true;
      try {
        const data = await backendAPI.userNew(username.value, email.value, password.value);
        store.commit('userStore/setApiKey', data.api_key);
        await store.dispatch('userStore/getGameInfo');
        await store.dispatch('userStore/getClubGetNames');
        await store.dispatch('userStore/getUserInfo');
        window.toast.success('Registered successfully');
        step.value = 2;
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        throw err;
      } finally {
        loading.value = false;
      }
    }

    return {
      email,
      loading,
      username,
      password,
      step,
      register,
    };
  },
};
</script>

<style scoped>

</style>
