<template>
  <div class="flex-1 bg-white md:rounded-2xl flex p-2">
    <div class="flex-1 overflow-auto nice-scroll pr-0">
      <div class="overflow-hidden md:min-h-[670px] px-4 pt-8 pb-4 flex flex-col items-center justify-center text-center">
        <img src="../assets/guide_join.png" class="mt-4 w-[65%]" />
        <div class="mt-12 font-bold text-2xl">Join a Season</div>
        <div class="mt-4">
          Once a full team is lined up, you must join a season to play. Joining a season gives you a
          <span class="font-bold opacity-90">chance to win prizes,</span>
          so you have to pay to join.
        </div>
        <img src="../assets/ranking.png" class="my-4" />
        <div class="mt-2">
          If you are a new player, you will be placed in the last league when the season starts. The following season your will league will be determent by how well you performed the previous season. The higher the league you play in, the
          <span class="font-bold opacity-90">the bigger the prizes</span>.
        </div>
      </div>
      <div class="p-6">
        <button
          @click="goToNextStep()"
          class="text-white bg-[#00AF92] w-full p-4 font-bold rounded-lg"
        >
          NEXT
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  name: 'IntroStep7',
  setup() {
    const router = useRouter();

    function goToNextStep() {
      router.push({ name: 'IntroStep8' });
    }

    return {
      goToNextStep,
    };
  },
};
</script>
