<template>
  <div class="bg-white shadow-md p-4 flex flex-col items-center justify-center font-montserrat rounded-[20px]">
    <img
      src="/img/ui/ball.svg"
      width="128"
      height="128"
      class="mb-6 mt-4"
    />
    <div class="text-lg font-bold text-neobit-green text-center">
      Join NEO-SOCCER Today!
    </div>
    <div class="mt-1 text-neobit-black font-medium text-center text-[15px]">
      Create and manage your own team and become champion!
    </div>
    <button
      @click="$router.push({ name: 'Register' })"
      class="bg-neobit-green rounded-xl py-3 text-center w-full text-white text-[15px] font-bold mt-8"
    >
      REGISTER NOW
    </button>
    <button
      @click="$router.push({ name: 'Login' })"
      class="bg-neobit-greenlight rounded-xl py-2.5 text-center w-full text-neobit-green text-[15px] font-bold mt-4"
    >
      LOGIN
    </button>
  </div>
</template>

<script>

export default {
  name: 'frontpage-landing-page',
};
</script>

<style scoped>
</style>
