import userStore from '@/os/stores/userStore';
import { createStore } from 'vuex';
import osStore from '@/os/stores/osStore';
import marketplaceStore from '@/apps/market/marketplaceStore';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    osStore,
    userStore,
    marketplaceStore,
  },
});
