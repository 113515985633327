<template>
  <app-interface :backButton="true">
    <div class="mt-40 mx-auto" v-if="!ready">
      <simple-spinner></simple-spinner>
    </div>
    <div v-if="ready" class="overflow-y-scroll h-full no-scroll text-white relative font-montserrat">
      <div>
        <div class="flex items-center justify-center p-4" :class="[item.rank]">
          <div class="flex items-center gap-2 rounded-lg w-full bg-white shadow-xl">
            <game-avatar
              v-if="item.item_type === 'player' || item.item_type === 'manager'"
              :avatar-data="item.avatar"
              class="h-20 -mt-4"
            />
            <img
              v-if="item.item_type === 'stadium'"
              :src="`/img/cards/stadiums/${item.rank}-stadium.svg`"
              class="h-20 m-2"
            />
            <consumable-avatar
              v-if="item.item_type === 'consumable'"
              class="h-20 m-2"
              :consumable="item">
            </consumable-avatar>
            <div class="flex flex-col flex-1 pr-4">
              <div class="flex mt-2">
                <div v-if="item.item_type !== 'consumable'" class="font-bold text-neobit-black tracking-wide flex-grow">
                  {{ item.name }} ({{ item.age }})
                </div>
                <div v-if="item.item_type === 'consumable'" class="font-bold text-neobit-black tracking-wide flex-grow capitalize">
                  <div v-if="!isStonks && !isSponsor">{{ item.for_item }} {{ item.for_skill }}</div>
                  <div v-if="isStonks || isSponsor">{{ sponsor }} Sponsorship</div>
                </div>
                <div class="relative" v-if="item.item_type !== 'consumable'">
                  <img src="/img/ui/downward-arrow.svg" @click="openMenu" class="w-4" v-if="store.state.userStore.clubIds.includes(item.club_id) && item.on_loan_from_club === ''"/>
                  <div v-if="menuOpen">
                    <div v-click-outside="closeMenu" class="absolute top-[24px] right-0 w-[180px] bg-white text-neobit-black z-20 shadow-2xl rounded-l-lg">
                      <div class="items-center gap-2" v-if="userId === item.owner_id && !item.on_sale && !item.on_loan">
                        <div class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center" @click="itemOption = 'ItemUseConsumable'">
                          <img src="/img/ui/use.svg" title="USE" class="h-4 mr-2"/>
                          <div>Use Consumable</div>
                        </div>
                        <div class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center" @click="itemOption = 'sell'">
                          <img src="/img/ui/sell.svg" title="SELL" class="h-4 mr-2"/>
                          <div>Sell</div>
                        </div>
                        <div class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center" @click="itemOption = 'lend'">
                          <img src="/img/ui/lend.svg" title="LEND" class="h-4 mr-2"/>
                          <div>Lend Out</div>
                        </div>
                        <div v-if="store.state.userStore.clubIds.length > 1" class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center" @click="itemOption = 'transfer'">
                          <img src="/img/ui/lend.svg" title="TRANSFER" class="h-4 mr-2"/>
                          <div>Transfer</div>
                        </div>
                        <div class="p-1.5 bg-white flex hover:bg-green-200 items-center cursor-pointer" @click="openBurn">
                          <img src="/img/ui/terminate.svg" title="TERMINATE" class="h-4 mr-2"/>
                          <div>Terminate</div>
                        </div>
                      </div>
                      <div class="items-center gap-2" v-if="userId === item.owner_id && (item.on_sale || item.on_loan)">
                        <div class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center" @click="itemOption = 'sell'" v-if="item.on_sale">
                          <img src="/img/ui/edit.svg" title="EDIT SALE" class="h-4 mr-2"/>
                          <div>Edit Sale</div>
                        </div>
                        <div class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center" @click="itemOption = 'cancel'" v-if="item.on_sale">
                          <img src="/img/ui/cancel.svg" title="CANCEL SALE" class="h-4 mr-2"/>
                          <div>Cancel Sale</div>
                        </div>
                        <div class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center" @click="itemOption = 'lend'" v-if="item.on_loan">
                          <img src="/img/ui/edit.svg" title="EDIT SALE" class="h-4 mr-2"/>
                          <div>Edit Loan</div>
                        </div>
                        <div class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center" @click="itemOption = 'cancel'" v-if="item.on_loan">
                          <img src="/img/ui/cancel.svg" title="CANCEL SALE" class="h-4 mr-2"/>
                          <div>Cancel Loan</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="relative" v-if="item.item_type === 'consumable'">
                  <img src="/img/ui/downward-arrow.svg" @click="openMenu" class="w-4" v-if="store.state.userStore.clubIds.includes(item.club_id)"/>
                  <div v-if="menuOpen">
                    <div v-click-outside="closeMenu" class="absolute top-[24px] right-0 w-[180px] bg-white text-neobit-black z-20 shadow-2xl rounded-l-lg">
                      <div class="items-center gap-2" v-if="userId === item.owner_id && !item.on_sale && !item.on_loan">
                        <div
                          v-if="!isSponsor && !isStonks"
                          class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center"
                          @click="itemOption = 'useConsumable'"
                        >
                          <img src="/img/ui/use.svg" title="USE" class="h-4 mr-2"/>
                          <div>Use Consumable</div>
                        </div>
                        <div
                          v-if="isSponsor || isStonks"
                          class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center"
                          @click="itemOption = 'UseStonks'"
                        >
                          <img src="/img/ui/use.svg" title="USE" class="h-4 mr-2"/>
                          <div>Use Consumable</div>
                        </div>
                        <div class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center" @click="itemOption = 'sell'">
                          <img src="/img/ui/sell.svg" title="SELL" class="h-4 mr-2"/>
                          <div>Sell</div>
                        </div>
                        <div v-if="store.state.userStore.clubIds.length > 1" class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center" @click="itemOption = 'transfer'">
                          <img src="/img/ui/lend.svg" title="TRANSFER" class="h-4 mr-2"/>
                          <div>Transfer</div>
                        </div>
                      </div>
                      <div class="items-center gap-2" v-if="userId === item.owner_id && (item.on_sale || item.on_loan)">
                        <div class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center" @click="itemOption = 'sell'" v-if="item.on_sale">
                          <img src="/img/ui/edit.svg" title="EDIT SALE" class="h-4 mr-2"/>
                          <div>Edit Sale</div>
                        </div>
                        <div class="p-1.5 bg-white flex hover:bg-green-200 cursor-pointer items-center" @click="itemOption = 'cancel'" v-if="item.on_sale">
                          <img src="/img/ui/cancel.svg" title="CANCEL SALE" class="h-4 mr-2"/>
                          <div>Cancel Sale</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex items-end justify-between mb-2" v-if="item.item_type !== 'consumable'">
                <div>
                  <div class="text-[9px] leading-3 text-neobit-black opacity-50 font-semibold flex">
                    <div>Current Club:</div>
                    <div v-if="item.on_loan_from_club !== ''" class="ml-2">(On Loan)</div>
                  </div>
                  <div class="text-[13px] leading-4 text-neobit-black font-bold tracking-wide flex items-center cursor-pointer" @click="$router.push({ name: 'ClubView', params: { clubId: item.club_id } })">
                    <div>
                      <club-logo :club-id="item.club_id" class="w-5 h-5 mr-1"></club-logo>
                    </div>
                    <div>{{ store.state.userStore.clubNames[item.club_id] }}</div>
                  </div>
                </div>
                <div class="flex flex-col items-end">
                  <div class="text-[9px] leading-3 text-neobit-black opacity-50 font-semibold">Matches</div>
                  <div class="text-[13px] leading-4 text-neobit-black font-bold tracking-wide">
                    {{ stats.length }}
                  </div>
                </div>
              </div>
              <div class="flex items-end justify-between mb-2" v-if="item.item_type === 'consumable'">
                <div>
                  <div class="text-[9px] leading-3 text-neobit-black opacity-50 font-semibold flex">
                    <div>Current Club:</div>
                  </div>
                  <div class="text-[13px] leading-4 text-neobit-black font-bold tracking-wide flex items-center cursor-pointer" @click="$router.push({ name: 'ClubView', params: { clubId: item.club_id } })">
                    <div>
                      <club-logo :club-id="item.club_id" class="w-5 h-5 mr-1"></club-logo>
                    </div>
                    <div>{{ store.state.userStore.clubNames[item.club_id] }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center" v-if="item.item_type !== 'consumable'">
          <button @click="tabView = 'Profile'" class="py-2 flex-1 text-center text-[13px] font-bold tracking-wide" :class="tabView === 'Profile' ? 'bg-[#00AF92] text-white' : 'bg-[#C8EBE0] text-neobit-green'">
            PROFILE
          </button>
          <button @click="getMatches" class="py-2 flex-1 text-center text-[13px] font-bold tracking-wide" :class="tabView === 'Matches' ? 'bg-[#00AF92] text-white' : 'bg-[#C8EBE0] text-neobit-green'">
            MATCHES
          </button>
          <button
            v-if="item.item_type !== 'stadium'"
            @click="getStats"
            class="py-2 flex-1 text-center text-[13px] font-bold tracking-wide"
            :class="tabView === 'Stats' ? 'bg-[#00AF92] text-white' : 'bg-[#C8EBE0] text-neobit-green'"
          >
            STATISTICS
          </button>
          <button
            @click="getCareer"
            class="py-2 flex-1 text-center text-[13px] font-bold tracking-wide"
            :class="tabView === 'Career' ? 'bg-[#00AF92] text-white' : 'bg-[#C8EBE0] text-neobit-green'"
          >
            CAREER
          </button>
        </div>
      </div>
      <div class="p-2 bg-gray-100 relative">
        <spinner v-if="loading"></spinner>
        <div v-else class="nice-scroll overflow-auto">
          <div v-if="item.on_sale || item.on_loan" class="p-1 rounded-lg mb-1 shadow-lg text-white font-bold flex justify-center w-full items-center"
               :class="[userId === item.owner_id ? 'bg-red-500' : 'bg-green-500']">
            <div v-if="item.on_sale && userId === item.owner_id">This {{ item.item_type }} is listed for sale</div>
            <div v-if="item.on_loan && userId === item.owner_id">This {{ item.item_type }} is listed for loan</div>
            <div v-if="item.on_loan && userId !== item.owner_id" class="flex items-center cursor-pointer" @click="itemOption = 'loan'">
              <div>Sign on loan for</div>
              <sexy-number
                :number="(item.on_loan_price + item.season_cost) / (10 ** 8)"
                :decimals="4"
                class="ml-2"
              />
              <img src="/img/tokens/transparent/bNEO.svg" class="w-5 h-5"/>
            </div>
            <div v-if="item.on_sale && userId !== item.owner_id" class="flex items-center cursor-pointer" @click="itemOption = 'buy'">
              <div>Buy for</div>
              <sexy-number
                :number="item.on_sale_price / (10 ** 8)"
                :decimals="4"
                class="ml-2"
              />
              <img src="/img/tokens/transparent/bNEO.svg" class="w-5 h-5"/>
            </div>

          </div>
          <div v-if="item.item_type !== 'consumable'">
            <div v-if="tabView === 'Profile'">
              <div class="info-box p-4">
                <div class="flex items-center gap-3 tracking-wide">
                  <div class="flex-1 flex flex-col items-center gap-1 bg-neobit-greenlight rounded-md pt-1.5 pb-2 px-3" v-if="item.item_type !== 'stadium'">
                    <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">POSITION</span>
                    <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase">{{ item.position }}</span>
                  </div>
                  <div class="flex-1 flex flex-col items-center gap-1 bg-neobit-greenlight rounded-md pt-1.5 pb-2 px-3" v-if="item.item_type === 'stadium'">
                    <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">CAPACITY</span>
                    <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase">{{ item.capacity }}</span>
                  </div>
                  <div class="flex-1 flex flex-col items-center gap-1 bg-neobit-greenlight rounded-md pt-1.5 pb-2 px-3">
                    <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">SALARY</span>
                    <sexy-number
                      :number="item.season_cost / 100000000"
                      :decimals="4"
                      class="text-neobit-black text-sm font-semibold leading-[14px]"
                    />
                  </div>
                  <div class="flex-1 flex flex-col items-center gap-1 bg-neobit-greenlight rounded-md pt-1.5 pb-2 px-3">
                    <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">RANK</span>
                    <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase">{{ item.rank }}</span>
                  </div>
                </div>
                <div class="flex items-center gap-3 tracking-wide mt-3">
                  <div class="flex-1 flex flex-col items-center gap-1 bg-neobit-greenlight bg-opacity-50 rounded-md pt-1.5 pb-2 px-3">
                    <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">AGE</span>
                    <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase">{{ item.age }}</span>
                  </div>
                  <div class="flex-1 flex flex-col items-center gap-1 bg-neobit-greenlight bg-opacity-50 rounded-md pt-1.5 pb-2 px-3" v-if="item.item_type === 'stadium'">
                    <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">MIN WEATHER</span>
                    <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase">{{ Math.ceil(item.weather.base / 10) }}</span>
                  </div>
                  <div class="flex-1 flex flex-col items-center gap-1 bg-neobit-greenlight bg-opacity-50 rounded-md pt-1.5 pb-2 px-3">
                    <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">COUNTRY</span>
                    <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase text-center">{{ item.nationality }}</span>
                  </div>
                </div>
              </div>
              <div class="info-box p-3 mt-2">
                <div class="flex items-center gap-3 tracking-wide">
                  <div class="flex-1 bg-neobit-greenlight bg-opacity-50 pt-[5px] relative rounded-md">
                    <div class="text-[9px] text-neobit-black text-opacity-70 font-semibold text-center">LUCK - BASE: {{ (item.luck?.base / 10).toFixed(1) }}</div>
                    <div class="text-sm font-semibold text-neobit-black flex items-center justify-center">
                      {{ (item.luck?.base + item.luck?.inc + item.luck?.dec) / 10 }}
                      <span class="ml-1 text-xs font-semibold" :class="item.luck?.inc > -item.luck?.dec ? 'text-[#23D962]' : 'text-[#F23030]'" v-if="item.luck?.inc + item.luck?.dec !== 0">
                                            {{ item.luck?.inc > -item.luck?.dec ? '+' : '' }}{{ (item.luck?.inc + item.luck?.dec) / 10 }}
                                        </span>
                    </div>
                    <div class="skillBox h-1.5 rounded-b-3xl m-0.5"></div>
                    <img src="/img/icons/item/indicator.svg" class="absolute bottom-0.5 skillIndicator" :style="getSkillIndicatorStyle(item.luck)"/>
                  </div>
                  <div class="flex-1 bg-neobit-greenlight bg-opacity-50 pt-[5px] relative rounded-md" v-if="item.item_type !== 'stadium'">
                    <div class="text-[9px] text-neobit-black text-opacity-70 font-semibold text-center">MULTIPLIER - BASE: {{ (item.multiplier?.base / 10).toFixed(1) }}</div>
                    <div class="text-sm font-semibold text-neobit-black flex items-center justify-center">
                      {{ (item.multiplier?.base + item.multiplier?.inc + item.multiplier?.dec) / 10 }}
                      <span class="ml-1 text-xs font-semibold" :class="item.multiplier?.inc > -item.multiplier?.dec ? 'text-[#23D962]' : 'text-[#F23030]'" v-if="item.multiplier?.inc + item.multiplier?.dec !== 0">
                                            {{ item.multiplier?.inc > -item.multiplier?.dec ? '+' : '' }}{{ (item.multiplier?.inc + item.multiplier?.dec) / 10 }}
                                        </span>
                    </div>
                    <div class="skillBox h-1.5 rounded-b-3xl m-0.5"></div>
                    <img src="/img/icons/item/indicator.svg" class="absolute bottom-0.5 skillIndicator" :style="getSkillIndicatorStyle(item.multiplier)"/>
                  </div>
                </div>
                <div class="flex items-center gap-3 tracking-wide mt-3" v-if="item.item_type !== 'stadium'">
                  <div class="flex-1 bg-neobit-greenlight bg-opacity-50 pt-[5px] relative rounded-md">
                    <div class="text-[9px] text-neobit-black text-opacity-70 font-semibold text-center">SKILLS - BASE: {{ (item.skills?.base / 10).toFixed(1) }}</div>
                    <div class="text-sm font-semibold text-neobit-black flex items-center justify-center">
                      {{ (item.skills?.base + item.skills?.inc + item.skills?.dec) / 10 }}
                      <span class="ml-1 text-xs font-semibold" :class="item.skills?.inc > -item.skills?.dec ? 'text-[#23D962]' : 'text-[#F23030]'" v-if="item.skills?.inc + item.skills?.dec !== 0">
                                            {{ item.skills?.inc > -item.skills?.dec ? '+' : '' }}{{ (item.skills?.inc + item.skills?.dec) / 10 }}
                                        </span>
                    </div>
                    <div class="skillBox h-1.5 rounded-b-3xl m-0.5"></div>
                    <img src="/img/icons/item/indicator.svg" class="absolute bottom-0.5 skillIndicator" :style="getSkillIndicatorStyle(item.skills)"/>
                  </div>
                  <div class="flex-1 bg-neobit-greenlight bg-opacity-50 pt-[5px] relative rounded-md">
                    <div class="text-[9px] text-neobit-black text-opacity-70 font-semibold text-center">AGRESSION - BASE: {{ (item.aggression?.base / 10).toFixed(1) }}</div>
                    <div class="text-sm font-semibold text-neobit-black flex items-center justify-center">
                      {{ (item.aggression?.base + item.aggression?.inc + item.aggression?.dec) / 10 }}
                      <span class="ml-1 text-xs font-semibold" :class="item.aggression?.inc > -item.aggression?.dec ? 'text-[#F23030]' : 'text-[#23D962]'" v-if="item.aggression?.inc + item.aggression?.dec !== 0">
                                            {{ item.aggression?.inc > -item.aggression?.dec ? '+' : '' }}{{ (item.aggression?.inc + item.aggression?.dec) / 10 }}
                                        </span>
                    </div>
                    <div class="revSkillBox h-1.5 rounded-b-3xl m-0.5"></div>
                    <img src="/img/icons/item/indicator.svg" class="absolute bottom-0.5 skillIndicator" :style="getSkillIndicatorStyle(item.aggression)"/>
                  </div>
                </div>
              </div>
              <div class="info-box p-3 mt-2" v-if="userId === item.owner_id && !item.on_sale">
                <div v-if="currentSeason >= item.contract_to_season" class="rounded-md bg-red-600 px-3 py-1.5 text-white mb-2 text-[11px]">
                  You need to extend the contract for the coming seasons.
                </div>
                <div class="flex items-center gap-3 tracking-wide">
                  <div class="flex-1 flex flex-col items-center gap-1 bg-neobit-greenlight bg-opacity-50 rounded-md pt-1.5 pb-2 px-3">
                    <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">Current Season</span>
                    <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase">{{ currentSeason }}</span>
                  </div>
                  <div class="flex-1 flex flex-col items-center gap-1 bg-neobit-greenlight bg-opacity-50 rounded-md pt-1.5 pb-2 px-3">
                    <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">Contract to season</span>
                    <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase">{{ item.contract_to_season }}</span>
                  </div>
                </div>
                <div class="rounded-md border-2 border-neobit-greenlight border-opacity-50 mt-2 pl-4 flex items-center">
                  <input class="outline-none bg-transparent flex-1 my-1.5 text-xs" v-model="extendPeriod" placeholder="Enter contract period to extend"/>
                  <button class="flex items-center gap-1 bg-neobit-greenlight bg-opacity-80 px-3 py-1.5 h-full" @click="extend()">
                    <img src="/img/ui/extend.svg" title="EXTEND" class="cursor-pointer h-4"/>
                    <span class="font-semibold text-sm text-neobit-black">Extend</span>
                  </button>
                </div>
                <div v-if="item.item_type !== 'consumable'" class="mt-1 ml-3 text-xs text-neobit-black text-opacity-80 font-semibold flex items-center">
                  Cost:
                  <sexy-number
                    :number="item.season_cost * extendPeriod / 100000000"
                    :decimals="4"
                    class="ml-1"
                  />
                </div>
              </div>
            </div>
            <div v-if="tabView === 'Matches'" class="flex flex-col">
              <div class="info-box cursor-pointer mb-3 px-4 py-3 tracking-wide" v-for="(_match, index) in matches" :key="index" @click="$router.push({ name: 'MatchView', params: { match: _match.id } })">
                <div class="flex items-center justify-between">
                  <span class="text-neobit-black text-[13px] font-semibold capitalize" v-if="_match.type !== 'league'">{{ _match.type }}</span>
                  <span class="text-neobit-black text-[13px] font-semibold capitalize" v-if="_match.type === 'league'">{{ _match.type }} {{ _match.league }}</span>
                  <span class="text-neobit-black text-opacity-50 text-[13px] font-semibold">
                                    Round {{ _match.round }} - {{ formatDate(_match.time) }}
                                </span>
                </div>
                <div class="flex items-center justify-center mt-3 text-neobit-black w-full py-3 rounded-lg border-2 border-[#C8EBE052]">
                  <span class="text-[11px] font-semibold w-[35%] text-right">{{ _match.home_name }}</span>
                  <club-logo :club-id="_match.home_id" class="w-5 h-5 ml-1"></club-logo>
                  <span class="mx-2 font-bold text-sm text-center w-12">{{ _match.home_score }} - {{ _match.away_score }}</span>
                  <club-logo :club-id="_match.away_id" class="w-5 h-5 mr-1"></club-logo>
                  <span class="text-[11px] font-semibold w-[35%]">{{ _match.away_name }}</span>
                </div>
                <div class="mt-2 flex items-center justify-between px-2" v-if="item.item_type === 'stadium'">
                  <div class="text-xs font-semibold text-neobit-black" v-if="_match.type === 'league'">
                    Tickets Sold: <span class="font-medium">{{ _match.attendees }} of {{ _match.capacity }}</span>
                  </div>
                  <div class="text-xs font-semibold text-neobit-black flex items-center" v-if="_match.type === 'league'">
                    Income:
                    <img src="/img/tokens/transparent/bNEO.svg" class="w-5 h-5"/>
                    <sexy-number
                      :number="_match.match_day_income / 100000000"
                      :decimals="6"
                      class="font-bold font-rubik text-[11px]"
                    />
                  </div>
                </div>
                <div class="mt-2 gap-2 flex" v-else>
                  <div class="flex-1 flex flex-col gap-1.5">
                    <div v-for="(_event, _evIndex) of _match.homeEvents" :key="_evIndex" class="flex items-center bg-[#D7C8EB52] p-1.5 rounded-md">
                      <img src="/img/ui/ball.svg" title="Goal" class="h-4 mr-1" v-if="_event.event === 'goal'"/>
                      <img src="/img/ui/yellow-card.svg" title="Yellow Cards" class="h-4 mr-1" v-if="_event.event === 'yellow_card'"/>
                      <img src="/img/ui/red-card.svg" title="Red Cards" class="h-4 mr-1" v-if="_event.event === 'red_card'"/>
                      <img src="/img/ui/yellow-red-card.svg" title="Second Yellow" class="h-4 mr-1" v-if="_event.event === 'second_yellow'"/>
                      <span class="text-[11px] font-semibold text-neobit-black flex-grow">{{ _event.name }}</span>
                      <span class="text-xs font-semibold text-neobit-black">{{ _event.min }}'</span>
                    </div>
                    <div v-if="_match.club_id === _match.home_id">
                      <div class="flex items-center bg-[#e6ffcc] p-1.5 rounded-md" v-if="_match.level_up_amount > 0 && _match.level_up !== 'aggression'">
                        <img src="/img/ui/level-up.svg" title="Second Yellow" class="h-4 mr-1"/>
                        <span class="text-xs font-semibold text-neobit-black">+{{ _match.level_up_amount / 10 }} {{ _match.level_up }}</span>
                      </div>
                      <div class="flex items-center bg-[#fcbbbb] p-1.5 rounded-md" v-if="_match.level_up_amount < 0 && _match.level_up !== 'aggression'">
                        <img src="/img/ui/level-down.svg" title="Second Yellow" class="h-4 mr-1"/>
                        <span class="text-xs font-semibold text-neobit-black">{{ _match.level_up_amount / 10 }} {{ _match.level_up }}</span>
                      </div>
                      <div class="flex items-center bg-[#e6ffcc] p-1.5 rounded-md" v-if="_match.level_up_amount < 0 && _match.level_up === 'aggression'">
                        <img src="/img/ui/level-down.svg" title="Second Yellow" class="h-4 mr-1"/>
                        <span class="text-xs font-semibold text-neobit-black">+{{ _match.level_up_amount / 10 }} {{ _match.level_up }}</span>
                      </div>
                      <div class="flex items-center bg-[#fcbbbb] p-1.5 rounded-md" v-if="_match.level_up_amount > 0 && _match.level_up === 'aggression'">
                        <img src="/img/ui/level-up.svg" title="Second Yellow" class="h-4 mr-1"/>
                        <span class="text-xs font-semibold text-neobit-black">{{ _match.level_up_amount / 10 }} {{ _match.level_up }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="flex-1 flex flex-col gap-1.5">
                    <div v-for="(_event, _evIndex) of _match.awayEvents" :key="_evIndex" class="flex items-center justify-between bg-[#C8EBE052] p-1.5 rounded-md">
                      <img src="/img/ui/ball.svg" title="Goal" class="h-4 mr-1" v-if="_event.event === 'goal'"/>
                      <img src="/img/ui/yellow-card.svg" title="Yellow Cards" class="h-4 mr-1" v-if="_event.event === 'yellow_card'"/>
                      <img src="/img/ui/red-card.svg" title="Red Cards" class="h-4 mr-1" v-if="_event.event === 'red_card'"/>
                      <img src="/img/ui/yellow-red-card.svg" title="Second Yellow" class="h-4 mr-1" v-if="_event.event === 'second_yellow'"/>
                      <span class="text-[11px] font-semibold text-neobit-black flex-grow">{{ _event.name }}</span>
                      <span class="text-xs font-semibold text-neobit-black">{{ _event.min }}'</span>
                    </div>
                    <div v-if="_match.club_id === _match.away_id">
                      <div class="flex items-center bg-[#e6ffcc] p-1.5 rounded-md" v-if="_match.level_up_amount > 0 && _match.level_up !== 'aggression'">
                        <img src="/img/ui/level-up.svg" title="Second Yellow" class="h-4 mr-1"/>
                        <span class="text-xs font-semibold text-neobit-black">+{{ _match.level_up_amount / 10 }} {{ _match.level_up }}</span>
                      </div>
                      <div class="flex items-center bg-[#fcbbbb] p-1.5 rounded-md" v-if="_match.level_up_amount < 0 && _match.level_up !== 'aggression'">
                        <img src="/img/ui/level-down.svg" title="Second Yellow" class="h-4 mr-1"/>
                        <span class="text-xs font-semibold text-neobit-black">{{ _match.level_up_amount / 10 }} {{ _match.level_up }}</span>
                      </div>
                      <div class="flex items-center bg-[#e6ffcc] p-1.5 rounded-md" v-if="_match.level_up_amount < 0 && _match.level_up === 'aggression'">
                        <img src="/img/ui/level-down.svg" title="Second Yellow" class="h-4 mr-1"/>
                        <span class="text-xs font-semibold text-neobit-black">+{{ _match.level_up_amount / 10 }} {{ _match.level_up }}</span>
                      </div>
                      <div class="flex items-center bg-[#fcbbbb] p-1.5 rounded-md" v-if="_match.level_up_amount > 0 && _match.level_up === 'aggression'">
                        <img src="/img/ui/level-up.svg" title="Second Yellow" class="h-4 mr-1"/>
                        <span class="text-xs font-semibold text-neobit-black">{{ _match.level_up_amount / 10 }} {{ _match.level_up }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="tabView === 'Stats'" class="flex flex-col">
              <div class="flex items-center justify-between info-box px-4 py-3">
                <span class="text-[13px] text-neobit-black text-opacity-50 font-semibold tracking-wide">Legend</span>
                <div class="flex items-center gap-6">
                  <img src="/img/icons/item/matches.svg" title="Match" class="w-6 h-6"/>
                  <img src="/img/icons/item/goal.svg" title="Goal" class="w-6 h-6"/>
                  <img src="/img/ui/yellow-card.svg" title="Yellow Cards" class="w-4 h-4"/>
                  <img src="/img/ui/red-card.svg" title="Red Cards" class="w-4 h-4"/>
                  <img src="/img/icons/item/rating.svg" title="Rating" class="w-6 h-6"/>
                </div>
              </div>
              <template v-for="(_league, index) in leagueStats" :key="index">
                <div class="info-box px-4 py-3 mt-4 tracking-wide" v-if="_league">
                  <div class="text-[13px] text-neobit-black font-semibold mb-3">League {{ index }}</div>
                  <template v-for="(_season, sIndex) in _league" :key="sIndex">
                    <div class="py-3 border-t border-neobit-green border-opacity-10 flex items-center justify-between" v-if="_season">
                      <span class="text-[11px] font-semibold text-neobit-black text-opacity-50">Season {{ _season.season }}</span>
                      <div class="gap-4 flex items-center">
                        <div class="bg-neobit-greenlight bg-opacity-30 px-2 py-0.5 rounded-md text-neobit-black text-[11px] font-semibold">
                          {{ _season.match }}
                        </div>
                        <div class="bg-neobit-greenlight bg-opacity-30 px-2 py-0.5 rounded-md text-neobit-black text-[11px] font-semibold">
                          {{ _season.goal }}
                        </div>
                        <div class="bg-neobit-greenlight bg-opacity-30 px-2 py-0.5 rounded-md text-neobit-black text-[11px] font-semibold">
                          {{ _season.yellow }}
                        </div>
                        <div class="bg-neobit-greenlight bg-opacity-30 px-2 py-0.5 rounded-md text-neobit-black text-[11px] font-semibold">
                          {{ _season.red }}
                        </div>
                        <div class="bg-neobit-greenlight px-2 py-0.5 rounded-md text-neobit-black text-[11px] font-semibold">
                          {{ _season.rating.toFixed(1) }}
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
            <div v-if="tabView === 'Career'" class="flex flex-col">
              <div class="flex items-center justify-between info-box px-4 py-3 mb-3">
                <span class="text-[13px] text-neobit-black text-opacity-50 font-semibold tracking-wide">Legend</span>
                <div class="flex items-center gap-6">
                  <img src="/img/icons/item/matches.svg" title="Match" class="w-6 h-6"/>
                  <img v-if="item.item_type !== 'stadium'" src="/img/icons/item/goal.svg" title="Goal" class="w-6 h-6"/>
                  <img v-if="item.item_type === 'stadium'" src="/img/icons/item/goal.svg" title="Goal" class="w-6 h-6"/>
                </div>
              </div>
              <template v-for="(_career, index) in careerHistory" :key="index">
                <div class="info-box p-3 pr-4 tracking-wide flex items-center gap-3">
                  <div class="flex items-center flex-grow">
                    <club-logo :club-id="_career.clubId" class="w-8 h-8 mr-2"></club-logo>
                    <div class="flex flex-col gap-0.5 flex-grow">
                      <span class="text-[13px] text-neobit-black font-semibold leading-[14px]">{{ _career.clubName }}</span>
                      <div class="text-[11px] text-neobit-black text-opacity-50 font-semibold leading-[14px] flex">{{ _career.from }} - {{ _career.to }}
                        <div class="capitalize ml-2 rounded-lg py-[1px] px-1" v-if="_career.type" :class="careerStyle(_career.type)">{{ _career.type }}</div>
                      </div>
                    </div>
                    <div class="text-[10px] leading-3 font-medium uppercase"></div>
                    <div class="flex flex-col items-center ml-1 rounded-xl px-2 py-1 bg-neobit-greenlight" v-if="_career.price > 0">
                      <div v-if="_career.price > 0" class="flex items-center" title="Price">
                        <img src="/img/tokens/transparent/bNEO.svg" class="w-4 h-4"/>
                        <sexy-number
                          :number="_career.price / 100000000"
                          :decimals="4"
                          class="mr-1 text-[10px]"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="flex items-center">
                    <div class="px-1.5 py-0.5 bg-neobit-greenlight bg-opacity-30 text-[11px] text-neobit-black font-semibold rounded-md">
                      {{ _career.matches }}
                    </div>
                    <div v-if="item.item_type !== 'stadium'" class="px-1.5 py-0.5 bg-neobit-greenlight bg-opacity-30 text-[11px] text-neobit-black font-semibold rounded-md ml-7">
                      {{ _career.goals }}
                    </div>
                    <div v-if="item.item_type === 'stadium'" class="px-1.5 py-0.5 bg-neobit-greenlight bg-opacity-30 text-[11px] text-neobit-black font-semibold rounded-md ml-7">
                      <div class="flex items-center" title="Price">
                        <img src="/img/tokens/transparent/bNEO.svg" class="w-4 h-4"/>
                        <sexy-number
                          :number="_career.income / 100000000"
                          :decimals="4"
                          class="mr-1 text-[10px]"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="index !== (careerHistory.length - 1)" class="ml-8 h-3 border-l-2 border-[#999999]"></div>
              </template>
            </div>
          </div>
          <div v-if="item.item_type === 'consumable'">
            <div v-if="tabView === 'Profile'">
              <div class="info-box p-4">
                <div class="flex items-center gap-3 tracking-wide">
                  <div class="flex-1 flex flex-col items-center gap-1 bg-neobit-greenlight rounded-md pt-1.5 pb-2 px-3">
                    <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">RANK</span>
                    <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase">{{ item.rank }}</span>
                  </div>
                  <div class="flex-1 flex flex-col items-center gap-1 bg-neobit-greenlight rounded-md pt-1.5 pb-2 px-3">
                    <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">FOR</span>
                    <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase">{{ item.for_item }}</span>
                  </div>
                </div>
                <div class="flex items-center gap-3 tracking-wide mt-3">
                  <div v-if="!isSponsor && !isStonks" class="flex-1 flex flex-col items-center gap-1 bg-neobit-greenlight bg-opacity-50 rounded-md pt-1.5 pb-2 px-3">
                    <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">TRAIT</span>
                    <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase">{{ item.for_skill }}</span>
                  </div>
                  <div v-if="!isSponsor && !isStonks" class="flex-1 flex flex-col items-center gap-1 bg-neobit-greenlight bg-opacity-50 rounded-md pt-1.5 pb-2 px-3">
                    <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">GAIN</span>
                    <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase text-center">{{ getGain(item) }}</span>
                  </div>
                  <div v-if="isSponsor || isStonks" class="flex-1 flex flex-col items-center gap-1 bg-neobit-greenlight bg-opacity-50 rounded-md pt-1.5 pb-2 px-3">
                    <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">TOKENS</span>
                    <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase text-center"><div class="flex items-center">
        <sexy-number
          :number="item.gain"
          :decimals="tokens[sponsor].displayDecimals"
          class="text-neobit-black text-sm leading-[18px] font-semibold"
        ></sexy-number>
        <img
          :src="tokenImage(sponsor)"
          class="w-5 h-5 ml-1 border border-neobit-black rounded-full border-opacity-30"
          :alt="sponsor"
        >
      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="['burn', 'sell', 'extend', 'edit', 'cancel', 'buy', 'lend', 'loan', 'transfer', 'useConsumable', 'ItemUseConsumable', 'UseStonks'].includes(itemOption)"
      class="fixed flex items-center justify-center inset-0 bg-black bg-opacity-40 text-black z-50"
    >
      <div>
        <terminate-contract :item="item" v-show="itemOption === 'burn'" :pay-back="payBack" @close="itemOption = ''" @burn="burn"/>
        <sell-item :item="item" v-show="itemOption === 'sell' || itemOption === 'edit'" @sell="sell" @close="itemOption = ''"/>
        <cancel-sale :item="item" v-show="itemOption === 'cancel'" @close="itemOption = ''" @cancel="cancel()"/>
        <buy-item :item="item" v-show="itemOption === 'buy'" @close="itemOption = ''" @cancel="cancel()" @buy="buy"/>
        <loan-item :item="item" v-show="itemOption === 'loan'" @close="itemOption = ''" @cancel="cancel()" @loan="loan"/>
        <lend-item :item="item" v-show="itemOption === 'lend'" @close="itemOption = ''" @cancel="cancel()" @lend="lend"/>
        <transfer-item :item="item" v-show="itemOption === 'transfer'" @close="itemOption = ''" @cancel="cancel()" @transfer="transfer"/>
        <use-consumable :item="item" v-show="itemOption === 'useConsumable'" @close="itemOption = ''" @cancel="cancel()" @use="useConsumable"/>
        <item-use-consumable :item="item" v-show="itemOption === 'ItemUseConsumable'" @close="itemOption = ''" @cancel="cancel()" @use="itemUseConsumable"/>
        <use-stonks :item="item" v-show="itemOption === 'UseStonks'" @close="itemOption = ''" @cancel="cancel()" @useStonks="useConsumable"/>
      </div>
    </div>
  </app-interface>
</template>

<script>
/* eslint-disable no-underscore-dangle, no-await-in-loop, array-callback-return */
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AppInterface from '@/os/components/templates/AppInterface.vue';
import backendAPI from '@/os/APIs/backendAPI';
import osAPI from '@/os/APIs/osAPI';
import store from '@/store';
import GameAvatar from '@/apps/globalComponents/components/PlayerAvatar.vue';
import Spinner from '@/os/components/Spinner.vue';
import TerminateContract from '@/apps/globalComponents/components/itemsModal/TerminateContract.vue';
import SellItem from '@/apps/globalComponents/components/itemsModal/SellItem.vue';
import CancelSale from '@/apps/globalComponents/components/itemsModal/CancelSale.vue';
import BuyItem from '@/apps/globalComponents/components/itemsModal/BuyItem.vue';
import { weatherTypes } from '@/os/utils/config';
import ClubLogo from '@/apps/globalComponents/components/clubLogo.vue';
import LoanItem from '@/apps/globalComponents/components/itemsModal/LendItem.vue';
import LendItem from '@/apps/globalComponents/components/itemsModal/LoanItem.vue';
import TransferItem from '@/apps/globalComponents/components/itemsModal/TransferItem.vue';
import UseConsumable from '@/apps/globalComponents/components/itemsModal/UseConsumable.vue';
import ItemUseConsumable from '@/apps/globalComponents/components/itemsModal/ItemUseConsumable.vue';
import ConsumableAvatar from '@/apps/globalComponents/components/ConsumableAvatar.vue';
import SimpleSpinner from '@/os/components/SimpleSpinner.vue';
import BigNumber from 'bignumber.js';
import UseStonks from '@/apps/globalComponents/components/itemsModal/UseTokens.vue';
import tokens from '@/os/coreData/tokens';

export default {
  name: 'ItemView',
  components: {
    UseStonks,
    ItemUseConsumable,
    UseConsumable,
    SimpleSpinner,
    TransferItem,
    LendItem,
    LoanItem,
    ClubLogo,
    AppInterface,
    Spinner,
    GameAvatar,
    BuyItem,
    CancelSale,
    SellItem,
    TerminateContract,
    ConsumableAvatar,
  },
  setup() {
    const payBack = ref(0);
    const userId = computed(() => store.state.userStore.userId);
    const currentSeason = computed(() => store.state.userStore.currentSeason);
    const itemOption = ref('');
    const ready = ref(false);
    const loading = ref(false);
    const route = useRoute();
    const item = ref(null);
    const stats = ref(null);
    const leagueStats = ref(null);
    const matches = ref(null);
    const transfers = ref(null);
    const menuOpen = ref(false);
    const careerHistory = ref(null);
    const sortDirection = ref(1);
    const extendPeriod = ref(1);
    const tabView = ref('Profile');
    const router = useRouter();
    const params = computed(() => route.params);
    const isSponsor = computed(() => item.value.for_skill.startsWith('sponsor') && item.value.for_skill.replace('sponsor-', '') !== 'STONKS');
    const isStonks = computed(() => item.value.for_skill.startsWith('sponsor') && item.value.for_skill.replace('sponsor-', '') === 'STONKS');
    const sponsor = computed(() => item.value.for_skill.replace('sponsor-', ''));

    async function getItem() {
      loading.value = true;
      item.value = await backendAPI.itemGet(params.value.itemId);
      await store.dispatch('userStore/getUserInfo');
      // await store.dispatch('userStore/getUserClub', { clubId: item.value.club_id });
      stats.value = await backendAPI.itemGetStats(params.value.itemId);
      transfers.value = await backendAPI.itemGetTransfers(params.value.itemId);
      ready.value = true;
      loading.value = false;
    }

    function formatDate(_time) {
      const matchDate = new Date(_time * 1000);
      return `${matchDate.getDate()}/${(matchDate.getMonth() + 1)}/${matchDate.getFullYear()}`;
    }

    function openMenu() {
      menuOpen.value = !menuOpen.value;
    }

    function closeMenu() {
      menuOpen.value = false;
    }

    function getSkillIndicatorStyle(skill) {
      const total = 20 + skill.base + skill.inc + skill.dec;
      return `left: ${total / 10}%`;
    }

    function sortMatches() {
      matches.value = matches.value.reverse();
      sortDirection.value = -sortDirection.value;
    }

    async function getMatches() {
      tabView.value = 'Matches';
      if (matches.value === null) {
        loading.value = true;
        matches.value = [];
        const tmpMatches = stats.value.reverse().slice(0, 50);
        if (Object.keys(store.state.userStore.clubNames).length < 1) {
          await store.dispatch('userStore/getClubGetNames');
        }
        const matchIds = [];
        tmpMatches.map((_tMatch) => matchIds.push(_tMatch.match_id));
        const itemMatches = await backendAPI.itemGetMatches(matchIds);
        for (let idx = 0; idx < matchIds.length; idx += 1) {
          const _stat = tmpMatches[idx];
          const _match = itemMatches.find((_iMatch) => _iMatch.id === _stat.match_id);
          // eslint-disable-next-line
          let matchEvents = [];
          if (typeof _match !== 'undefined') {
            if (Object.keys(_match).includes('events')) {
              matchEvents = _match.events.sort((a, b) => (a.min > b.min ? 1 : -1));
            }
            matches.value.push({
              rating: _stat.rating ?? 0,
              club_id: _stat.club_id,
              home_score: _match.home_score,
              away_score: _match.away_score,
              type: _match.type,
              home_name: store.state.userStore.clubNames[_match.home],
              away_name: store.state.userStore.clubNames[_match.away],
              home_id: _match.home,
              away_id: _match.away,
              level_up: _stat.level_up,
              level_up_amount: _stat.level_up_amount,
              homeEvents: matchEvents.filter((_ev) => Object.values(_match.home_lineup).includes(_ev.item) && _ev.item === params.value.itemId),
              awayEvents: matchEvents.filter((_ev) => Object.values(_match.away_lineup).includes(_ev.item) && _ev.item === params.value.itemId),
              id: _match.id,
              league: _match.league,
              round: _stat.round,
              time: _match.time ?? 0,
              attendees: _stat.attendees,
              capacity: _stat.capacity,
              match_day_income: _stat.match_day_income,
            });
          }
        }
        loading.value = false;
      }
    }

    async function getStats() {
      tabView.value = 'Stats';
      if (leagueStats.value === null) {
        loading.value = true;
        leagueStats.value = [];
        // eslint-disable-next-line
        stats.value.map((_stat) => {
          const _league = _stat.league ?? 1;
          const _season = _stat.season ?? 1;
          if (!leagueStats.value[_league]) leagueStats.value[_league] = [];
          if (!leagueStats.value[_league][_season]) {
            leagueStats.value[_league][_season] = {
              match: 0,
              goal: 0,
              yellow: 0,
              red: 0,
              rating: 0,
              season: _season,
            };
          }

          const curStatObj = leagueStats.value[_league][_season];
          curStatObj.goal += _stat.events.filter((_ev) => _ev.event === 'goal').length;
          curStatObj.yellow += _stat.events.filter((_ev) => _ev.event === 'yellow_card').length + _stat.events.filter((_ev) => _ev.event === 'second_yellow').length * 2;
          curStatObj.red += _stat.events.filter((_ev) => _ev.event === 'red_card' || _ev.event === 'second_yellow').length;
          curStatObj.rating = (curStatObj.rating * curStatObj.match + (_stat.rating ?? 0)) / (curStatObj.match + 1);
          curStatObj.match += 1;
          leagueStats.value[_league][_season] = curStatObj;
        });
        loading.value = false;
      }
    }

    function careerStyle(_type) {
      if (_type === 'buy') return 'bg-pink-200';
      return 'bg-neobit-greenlight';
    }

    async function getCareer() {
      tabView.value = 'Career';
      if (careerHistory.value === null) {
        loading.value = true;
        careerHistory.value = [];
        const transferLength = transfers.value.length;
        const lastTransfer = transferLength > 0 ? transfers.value[transferLength - 1] : null;
        const tHistory = [...transfers.value, {
          from: item.value.club_id,
          round: lastTransfer ? lastTransfer.round : 1,
          season: lastTransfer ? lastTransfer.season : 1,
        }];
        tHistory.map((_trans, _tIndex) => {
          const from = _tIndex > 0 ? tHistory[_tIndex - 1] : null;
          let cGoals = 0;
          let cIncome = 0;
          const transMatches = stats.value.filter((_stat) => _stat.club_id === _trans.from);
          if (item.value.item_type !== 'stadium') {
            transMatches.map((_stat) => {
              cGoals += _stat.events.filter((_ev) => _ev.event === 'goal').length;
            });
          }
          if (item.value.item_type === 'stadium') {
            transMatches.map((_stat) => {
              if (!Number.isNaN(_stat.league) || !Number.isNaN(_stat.round)) {
                cIncome += _stat.match_day_income;
              }
            });
          }

          careerHistory.value.push({
            clubId: _trans.from,
            clubName: store.state.userStore.clubNames[_trans.from],
            matches: transMatches.length,
            goals: cGoals,
            income: cIncome,
            from: from ? `R${from.round}/S${from.season}` : 'R1/S1',
            to: _tIndex === (tHistory.length - 1) ? 'Now' : `R${_trans.round}/S${_trans.season}`,
            price: from?.price,
            type: from?.type === 'loan-return' ? 'return' : from?.type,
          });
        });
        careerHistory.value = careerHistory.value.reverse();
        loading.value = false;
      }
    }

    getItem();

    watch(params, () => {
      if (typeof params.value.itemId !== 'undefined') {
        getItem();
      }
    });

    watch(itemOption, () => {
      if (itemOption.value !== '') {
        menuOpen.value = false;
      }
    });

    function getRatingClass(_ratingNum) {
      if (_ratingNum >= 9.0) return 'bg-[#1A237E] text-white';
      if (_ratingNum >= 5.0) return 'bg-[#00AF92] text-white';
      return '';
    }

    function openBurn() {
      itemOption.value = 'burn';
    }

    async function useConsumable(itemId, newName) {
      loading.value = true;
      try {
        await backendAPI.itemUse(item.value.id, itemId, newName);
        window.toast.success('Used successfully');
        const clubId = store.state.userStore.club.id;
        await store.dispatch('userStore/getUserClub', { clubId });
        await store.dispatch('userStore/getClubItems', { clubId });
        if (isSponsor.value || isStonks.value) {
          await store.dispatch('userStore/getUserInfo');
        }
        loading.value = false;
        router.back();
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function itemUseConsumable(itemId, newName) {
      loading.value = true;
      try {
        await backendAPI.itemUse(itemId, item.value.id, newName);
        window.toast.success('Used successfully');
        const clubId = store.state.userStore.club.id;
        await store.dispatch('userStore/getUserClub', { clubId });
        await store.dispatch('userStore/getClubItems', { clubId });
        await getItem();
        loading.value = false;
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function sell(e) {
      loading.value = true;
      try {
        const price = new BigNumber(e).shiftedBy(8);
        await backendAPI.itemSell(item.value.item_type, item.value.id, price.toNumber());
        window.toast.success('Listed successfully');
        const clubId = store.state.userStore.club.id;
        await store.dispatch('userStore/getUserClub', { clubId });
        await store.dispatch('userStore/getClubItems', { clubId });
        itemOption.value = '';
        item.value.on_sale = true;
        item.value.on_sale_price = e * 100000000;
        loading.value = false;
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function lend(e) {
      loading.value = true;
      try {
        const price = new BigNumber(e).shiftedBy(8);
        await backendAPI.itemLend(item.value.item_type, item.value.id, price.toNumber());
        window.toast.success('Listed successfully');
        const clubId = store.state.userStore.club.id;
        await store.dispatch('userStore/getUserClub', { clubId });
        await store.dispatch('userStore/getClubItems', { clubId });
        itemOption.value = '';
        item.value.on_loan = true;
        item.value.on_loan_price = (e * 100000000) + item.value.season_cost;
        loading.value = false;
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function cancel() {
      loading.value = true;
      try {
        await backendAPI.itemCancelMarket(item.value.item_type, item.value.id);
        window.toast.success('Canceled successfully');
        const clubId = store.state.userStore.club.id;
        await store.dispatch('userStore/getUserClub', { clubId });
        await store.dispatch('userStore/getClubItems', { clubId });
        itemOption.value = '';
        item.value.on_sale = false;
        item.value.on_loan = false;
        loading.value = false;
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function burn() {
      loading.value = true;
      try {
        await backendAPI.itemBurn(item.value.item_type, item.value.id, true);
        window.toast.success('Burned successfully');
        await store.dispatch('userStore/getUserInfo');
        const clubId = store.state.userStore.club.id;
        await store.dispatch('userStore/getClubItems', { clubId });
        itemOption.value = '';
        loading.value = false;
        router.back();
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function buy() {
      loading.value = true;
      try {
        const clubId = store.state.userStore.club.id;
        await backendAPI.itemBuy(item.value.item_type, item.value.id, clubId);
        window.toast.success('Bought successfully');
        await store.dispatch('userStore/getUserInfo');
        await store.dispatch('userStore/getClubItems', { clubId });
        await store.dispatch('marketplaceStore/getItemsOnSale', { itemType: item.value.item_type });
        itemOption.value = '';
        item.value.contractToSeason += 1;
        item.value.club_id = clubId;
        item.value.on_sale = false;
        loading.value = false;
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function loan() {
      loading.value = true;
      try {
        const clubId = store.state.userStore.club.id;
        await backendAPI.itemLoan(item.value.item_type, item.value.id, clubId);
        window.toast.success('Rented successfully');
        await store.dispatch('userStore/getUserInfo');
        await store.dispatch('userStore/getClubItems', { clubId });
        await store.dispatch('marketplaceStore/getItemsOnSale', { itemType: item.value.item_type });
        itemOption.value = '';
        item.value.contractToSeason += 1;
        item.value.club_id = clubId;
        item.value.on_loan = false;
        loading.value = false;
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function transfer(e) {
      loading.value = true;
      try {
        await backendAPI.itemTransfer(item.value.item_type, item.value.id, e);
        window.toast.success('Transferred successfully');
        const clubId = store.state.userStore.club.id;
        await store.dispatch('userStore/getUserClub', { clubId });
        await store.dispatch('userStore/getClubItems', { clubId });
        itemOption.value = '';
        item.value.club_id = e;
        loading.value = false;
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function extend() {
      loading.value = true;
      try {
        await backendAPI.itemExtend(item.value.item_type, item.value.id, extendPeriod.value, true);
        window.toast.success('Extended successfully');
        const clubId = store.state.userStore.club.id;
        await store.dispatch('userStore/getUserInfo');
        await store.dispatch('userStore/getClubItems', { clubId });
        itemOption.value = '';
        item.value.contract_to_season += Number(extendPeriod.value);
        loading.value = false;
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    function getGain(thisItem) {
      if (thisItem.for_skill === 'rename') return '-';
      if (thisItem.for_item === 'stadium') return thisItem.gain;
      if (thisItem.for_skill === 'age') return thisItem.gain;
      if (thisItem.for_skill === 'stonks') return thisItem.gain;
      return thisItem.gain / 10;
    }

    function tokenImage(token) {
      return `/img/tokens/circle/${token}.svg`;
    }

    return {
      tokens,
      osAPI,
      item,
      userId,
      payBack,
      itemOption,
      loading,
      ready,
      tabView,
      sortDirection,
      matches,
      stats,
      store,
      leagueStats,
      careerHistory,
      formatDate,
      sortMatches,
      getMatches,
      getStats,
      careerStyle,
      getCareer,
      getRatingClass,
      transfer,
      getSkillIndicatorStyle,
      burn,
      useConsumable,
      itemUseConsumable,
      extendPeriod,
      extend,
      buy,
      sell,
      lend,
      loan,
      cancel,
      openBurn,
      weatherTypes,
      currentSeason,
      menuOpen,
      openMenu,
      closeMenu,
      getGain,
      isSponsor,
      isStonks,
      sponsor,
      tokenImage,
    };
  },
};
</script>

<style scoped>
.bg-pink-linear {
  background: linear-gradient(134.69deg, #c63bc9 0%, #642ffa 100%), rgba(255, 255, 255, 0.06);
}

.modal-title {
  letter-spacing: 0.02em;
  background: linear-gradient(134.69deg, #c63bc9 0%, #642ffa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  mix-blend-mode: normal;
}

.border-button {
  border: solid 3px transparent;
  background-image: linear-gradient(#1f1a22, #1f1a22), linear-gradient(to left, #642ffa 0%, #c63bc9 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.info-box {
  border-radius: 8px;
  background: white;
  color: black;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.06);
}

.match-btn {
  background: #FFFFFF;
  border: 2px solid #C8EBE0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
}

@media (min-width: 768px) {
  .match-container {
    height: 400.5px;
  }
}

.skillBox {
  background: linear-gradient(90deg, #F23030 0%, #ff8600 10.00%, #3bcd4c 25.00%, #3bcd4c 60.52%, #22DDA2 100%);
}

.revSkillBox {
  background: linear-gradient(90deg, #22DDA2 0%, #3bcd4c 10.00%, #3bcd4c 25.00%, #ff8600 60.52%, #F23030 100%);
}

.skillIndicator {
  transform: translate(-50%, 0);
}

.nice-scroll::-webkit-scrollbar {
  width: 7px;
}

.nice-scroll::-webkit-scrollbar-track {
  background: #00000000;
  border-radius: 5px;
}

.nice-scroll::-webkit-scrollbar-thumb {
  background: #80808766;
  border-radius: 5px;
}

.nice-scroll::-webkit-scrollbar-thumb:hover {
  background: #808087;
  cursor: pointer;
}
</style>
