<template>
  <simple-modal
    modalName="item-modal"
    :bg="modalBg"
    :noClickOutside="true"
  >
    <div class="flex flex-col justify-center min-w-[320px]">
      <div class="relative flex flex-col items-center justify-center bg-white rounded-[20px] pb-4">
        <div class="absolute top-3 right-3 text-black z-10" @click="closeModal()">
          <img :src="`/img/ui/cross.svg`" width="26"/>
        </div>
        <div :class="item.rank" class="w-full h-[190px]" style="border-radius: 20px 20px 0px 0px;"></div>
        <stadium-modal-component v-if="item.item_type === 'stadium'" :item="item"></stadium-modal-component>
        <manager-modal-component v-if="item.item_type === 'manager'" :item="item"></manager-modal-component>
        <player-modal-component v-if="item.item_type === 'player'" :item="item"></player-modal-component>
        <consumable-modal-component v-if="item.item_type === 'consumable'" :item="item"></consumable-modal-component>
      </div>
      <div
        v-if="(userId === item.owner_id) && item.item_type !== 'consumable' && !item.on_sale"
        class="bg-white h-16 mt-6 rounded-2xl flex items-center justify-around text-black px-2"
      >
        <div @click="openBurn()" class="bg-white hover:bg-green-200 hover:rounded-2xl cursor-pointer w-1/2 flex-col justify-center text-center">
          <div class="flex justify-center">
            <img :src="`/img/ui/terminate.svg`" class="h-5"/>
          </div>
          <div class="text-[#C72424] text-[11px] font-bold items-center">
            <div>TERMINATE</div>
            <div>
              <sexy-number
                :number="burnAmount"
                :decimals="4"
                class="font-bold text-[11px] justify-center"
              />
            </div>
          </div>
        </div>
        <div
          class="p-1.5 bg-white hover:bg-green-200 hover:rounded-2xl cursor-pointer w-1/2 flex-col justify-center text-center"
          @click="osAPI.triggerModal('mint-modal', item.item_type);"
        >
          <div>
            <div class="flex justify-center">
              <img src="/img/ui/sell.svg" title="SELL" class="h-5"/>
            </div>
            <div>Recruit one more</div>
          </div>
        </div>
      </div>
      <div
        v-if="userId === item.owner_id && item.item_type === 'consumable'"
        class="bg-white h-16 mt-6 rounded-2xl text-black"
      >
        <div
          v-if="userId === item.owner_id && !item.on_sale"
          class="text-center cursor-pointer flex items-center justify-around px-2 mt-1"
        >
          <div
            v-if="item.for_skill !== 'stonks' && !item.for_skill.startsWith('sponsor-')"
            class="p-1.5 bg-white hover:bg-green-200 hover:rounded-2xl cursor-pointer w-1/3 flex-col justify-center"
            @click="itemOption = 'useConsumable'"
          >
            <div class="flex justify-center">
              <img src="/img/ui/use.svg" title="USE" class="h-5"/>
            </div>
            <div>Use</div>
          </div>
          <div
            v-if="item.for_skill === 'stonks' || item.for_skill.startsWith('sponsor-')"
            class="p-1.5 bg-white hover:bg-green-200 hover:rounded-2xl cursor-pointer w-1/3 flex-col justify-center"
            @click="itemOption = 'UseStonks'"
          >
            <div>
              <div class="flex justify-center">
                <img src="/img/ui/use.svg" title="USE" class="h-5"/>
              </div>

              <div>Use</div>
            </div>

          </div>
          <div
            class="p-1.5 bg-white hover:bg-green-200 hover:rounded-2xl cursor-pointer w-1/3 flex-col justify-center"
            @click="itemOption = 'sell'"
          >
            <div>
              <div class="flex justify-center">
                <img src="/img/ui/sell.svg" title="SELL" class="h-5"/>
              </div>
              <div>Sell</div>
            </div>

          </div>
          <div
            class="p-1.5 bg-white hover:bg-green-200 hover:rounded-2xl cursor-pointer w-1/3 flex-col justify-center"
            @click="osAPI.triggerModal('mint-modal', item.item_type);"
          >
            <div>
              <div class="flex justify-center">
                <img src="/img/ui/mint-again.svg" title="One More" class="h-5"/>
              </div>
              <div>One more</div>
            </div>

          </div>
        </div>
      </div>
      <div v-if="['burn', 'sell', 'UseStonks', 'useConsumable'].includes(itemOption)"
           class="fixed flex items-center justify-center inset-0 bg-black bg-opacity-40 text-black z-50">
        <terminate-contract :item="item" v-show="itemOption === 'burn'" :pay-back="payBack" @close="itemOption = ''" @burn="burn"/>
        <sell-item :item="item" v-show="itemOption === 'sell'" @sell="sell" @close="itemOption = ''"/>
        <use-consumable :item="item" v-show="itemOption === 'useConsumable'" @close="itemOption = ''" @cancel="itemOption = ''" @use="useConsumable"/>
        <use-stonks :item="item" v-show="itemOption === 'UseStonks'" @close="itemOption = ''" @cancel="itemOption = ''" @useStonks="useConsumable"/>
      </div>
    </div>
  </simple-modal>
  <spinner class="absolute" v-if="loading"></spinner>
</template>

<script>
import SimpleModal from '@/os/components/modals/SimpleModal.vue';
import backendAPI from '@/os/APIs/backendAPI';
import osAPI from '@/os/APIs/osAPI';
import store from '@/store';
import { computed, ref } from 'vue';
import Spinner from '@/os/components/Spinner.vue';
import TerminateContract from '@/apps/globalComponents/components/itemsModal/TerminateContract.vue';
import { Canvg } from 'canvg';
import StadiumModalComponent from '@/apps/globalComponents/modals/components/StadiumModalComponent.vue';
import ManagerModalComponent from '@/apps/globalComponents/modals/components/ManagerModalComponent.vue';
import PlayerModalComponent from '@/apps/globalComponents/modals/components/PlayerModalComponent.vue';
import ConsumableModalComponent from '@/apps/globalComponents/modals/components/ConsumableModalComponent.vue';
import SellItem from '@/apps/globalComponents/components/itemsModal/SellItem.vue';
import UseStonks from '@/apps/globalComponents/components/itemsModal/UseTokens.vue';
import BigNumber from 'bignumber.js';
import UseConsumable from '@/apps/globalComponents/components/itemsModal/UseConsumable.vue';

export default {
  name: 'item-modal',
  components: {
    UseConsumable,
    UseStonks,
    SellItem,
    ConsumableModalComponent,
    PlayerModalComponent,
    ManagerModalComponent,
    StadiumModalComponent,
    Spinner,
    TerminateContract,
    SimpleModal,
  },
  setup() {
    const item = computed(() => store.state.osStore.modalData);
    const modalBg = 'rgba(0,0,0,0)';
    const payBack = ref(0);
    const userId = computed(() => store.state.userStore.userId);
    const itemOption = ref('');
    const loading = ref(false);

    const burnAmount = computed(() => (item.value.base_burn_amount / item.value.age) / 100000000);

    function openBurn() {
      payBack.value = burnAmount.value * 0.96;
      itemOption.value = 'burn';
    }

    async function sell(e) {
      loading.value = true;
      try {
        const price = new BigNumber(e).shiftedBy(8);
        await backendAPI.itemSell(item.value.item_type, item.value.id, price.toNumber());
        window.toast.success('Listed successfully');
        const clubId = store.state.userStore.club.id;
        await store.dispatch('userStore/getUserClub', { clubId });
        await store.dispatch('userStore/getClubItems', { clubId });
        itemOption.value = '';
        item.value.on_sale = true;
        item.value.on_sale_price = e * 100000000;
        loading.value = false;
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function useConsumable(itemId, newName) {
      loading.value = true;
      try {
        await backendAPI.itemUse(item.value.id, itemId, newName);
        window.toast.success('Used successfully');
        const clubId = store.state.userStore.club.id;
        await store.dispatch('userStore/getUserClub', { clubId });
        await store.dispatch('userStore/getClubItems', { clubId });
        if (item.value.for_item === 'user') {
          await store.dispatch('userStore/getUserInfo');
        }
        loading.value = false;
        itemOption.value = '';
        osAPI.triggerModal('');
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function burn() {
      loading.value = true;
      try {
        await backendAPI.itemBurn(item.value.item_type, item.value.id, true);
        window.toast.success('Burned successfully');
        await store.dispatch('userStore/getUserInfo');
        const clubId = store.state.userStore.club.id;
        await store.dispatch('userStore/getClubItems', { clubId });
        itemOption.value = '';
        loading.value = false;
        osAPI.triggerModal('');
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function download() {
      if (item.value.item_type === 'player' || item.value.item_type === 'manager') {
        const svgCode = document.getElementById('avatar');
        const canvas = document.createElement('canvas');
        canvas.width = 400;
        canvas.height = 400;
        const ctx = canvas.getContext('2d');
        const v = await Canvg.fromString(ctx, svgCode.outerHTML);
        v.start();
        v.stop();

        const img = canvas.toDataURL('image/png');
        const base64img = img.replace('data:image/png;base64,', '');
        await backendAPI.itemMinted(item.value.id, base64img);
      }
      if (item.value.item_type === 'stadium') {
        await backendAPI.itemMinted(item.value.id, null);
      }
    }

    function closeModal() {
      download(item);
      osAPI.triggerModal('');
    }

    return {
      osAPI,
      item,
      burn,
      burnAmount,
      modalBg,
      closeModal,
      userId,
      payBack,
      itemOption,
      openBurn,
      loading,
      useConsumable,
      sell,
    };
  },
};
</script>

<style scoped>
.bg-pink-linear {
  background: linear-gradient(134.69deg, #c63bc9 0%, #642ffa 100%), rgba(255, 255, 255, 0.06);
}

.modal-title {
  letter-spacing: 0.02em;
  background: linear-gradient(134.69deg, #c63bc9 0%, #642ffa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  mix-blend-mode: normal;
}

.border-button {
  border: solid 3px transparent;
  background-image: linear-gradient(#1f1a22, #1f1a22), linear-gradient(to left, #642ffa 0%, #c63bc9 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.nice-scroll::-webkit-scrollbar {
  width: 7px;
}

.nice-scroll::-webkit-scrollbar-track {
  background: #00000000;
  border-radius: 5px;
}

.nice-scroll::-webkit-scrollbar-thumb {
  background: #80808766;
  border-radius: 5px;
}

.nice-scroll::-webkit-scrollbar-thumb:hover {
  background: #808087;
  cursor: pointer;
}
</style>
