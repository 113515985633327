<template>
  <app-interface>
    <div class="p-4">
      <div class="box">
        <div class="flex items-center justify-between">
          <span class="text-lg text-neobit-green font-bold">INVITE FRIENDS</span>
          <span class="text-[13px] font-semibold text-neobit-black">Available: {{ store.state.userStore.invites.length }}</span>
        </div>
        <div class="my-3 mx-9 border-t border-neobit-green border-opacity-10"></div>
        <div class="text-sm font-medium text-neobit-black tracking-wide">
          You will receive one unique invitation code each time you successfully complete a NEO-SOCCER season.
        </div>
        <div class="border-2 border-neobit-black border-opacity-10 rounded-lg bg-neobit-black bg-opacity-5 px-4 py-3 mt-3" v-if="store.state.userStore.invites.length === 0">
          <div class="text-[13px] leading-[14px] font-semibold text-neobit-black">No invitation codes available</div>
          <div class="text-[11px] leading-[14px] font-semibold text-neobit-black text-opacity-50 mt-1">Sometimes you get one sometimes you don't...</div>
        </div>
        <div v-else class="gap-3">
          <div
            class="border-2 border-neobit-greenlight rounded-lg bg-neobit-greenlight bg-opacity-30 px-4 py-3 mt-3 flex items-center justify-between"
            v-for="(invite, index) of store.state.userStore.invites"
            :key="index"
          >
            <div>
              <div class="text-[13px] leading-[14px] font-semibold text-neobit-black">
                Invite Code: <span class="font-bold">{{ invite.code }}</span>
              </div>
              <div class="text-[11px] leading-[14px] font-semibold text-neobit-black text-opacity-50 mt-1">This code as not been redeemed yet</div>
            </div>
            <img src="/img/icons/inviteCopy.svg" class="w-6 h-6 cursor-pointer" @click="copyCode(invite.code)"/>
          </div>
        </div>
        <button @click="$router.back()" class="mt-4 bg-neobit-greenlight rounded-xl text-neobit-green py-2.5 w-full text-[15px] font-bold">CANCEL</button>
      </div>
    </div>
  </app-interface>
</template>

<script>
import AppInterface from '@/os/components/templates/AppInterface.vue';
import store from '@/store';

export default {
  name: 'InviteCode',
  components: {
    AppInterface,
  },
  setup() {
    function copyCode(code) {
      navigator.clipboard.writeText(code);
      window.toast.success('Copied');
    }

    return {
      copyCode,
      store,
    };
  },
};
</script>

<style scoped>
.box {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.06);
  border-radius: 8px;
  padding: 12px 18px;
}
</style>
