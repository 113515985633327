import Register from '@/apps/user/views/Register.vue';
import Activate from '@/apps/user/views/Activate.vue';
import Login from '@/apps/user/views/Login.vue';
import Forgot from '@/apps/user/views/Forgot.vue';
import Reset from '@/apps/user/views/Reset.vue';
import EnterInviteCode from '@/apps/user/views/EnterInviteCode.vue';

const routes = [
  {
    path: 'login',
    name: 'Login',
    component: Login,
    meta: { title: 'Login - Neo FC' },
  },
  {
    path: 'register',
    name: 'Register',
    component: Register,
    meta: { title: 'Register - Neo FC' },
  },
  {
    path: 'activate',
    name: 'Activate',
    component: Activate,
    meta: { title: 'Activate Account - Neo FC' },
  },
  {
    path: 'forgot',
    name: 'Forgot',
    component: Forgot,
    meta: { title: 'Forgot Password - Neo FC' },
  },
  {
    path: 'reset',
    name: 'Reset',
    component: Reset,
    meta: { title: 'Reset Password - Neo FC' },
  },
  {
    path: 'enter-invite-code',
    name: 'EnterInviteCode',
    component: EnterInviteCode,
    meta: { title: 'Enter Invite Code - Neo FC' },
  },
];

export default routes;
