<template>
  <div class="circular relative">
    <div class="circle">
      <i class="fas fa-redo absolute inner"></i>
      <div class="bar left">
        <div
          class="progress"
          :style="leftStyle"
        />
      </div>
      <div class="bar right">
        <div
          class="progress"
          :style="rightStyle"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { computed } from 'vue';

export default {
  props: {
    progress: {
      default: 25,
    },
  },
  setup(props) {
    const capedProgress = computed(() => Math.max(Math.min(props.progress, 100), 0));
    const leftProgress = computed(() => Math.min(capedProgress.value, 50) / 50.0);
    const leftStyle = computed(() => `transform: rotate(${(leftProgress.value * 180).toFixed(2)}deg)`);
    const rightProgress = computed(() => Math.max((capedProgress.value - 50), 0) / 50.0);
    const rightStyle = computed(() => `transform: rotate(${(rightProgress.value * 180).toFixed(2)}deg)`);

    return {
      leftStyle,
      rightStyle,
      rightProgress,
      leftProgress,
    };
  },
};

</script>

<style scoped>
.circular {
  height: 18px;
  width: 18px;
}

.inner {
  transform: translate(50%, -50%);
  top: 50%;
  right: 50%;
}

.circular .bar {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #C8EBE0;
  -webkit-border-radius: 100%;
  clip: rect(0px, 18px, 18px, 9px);
}

.circle .bar .progress {
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-border-radius: 100%;
  clip: rect(0px, 9px, 18px, 0px);
  background: #00AF92;
  transition: transform 50ms linear;
}

.circle .right {
  transform: rotate(180deg);
  z-index: 3;
}
</style>
