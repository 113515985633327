<template>
  <app-interface>
    <div class="p-4">
      <div class="box overflow-hidden mt-4">
        <div class="w-full bg-neobit-green px-4 py-1.5 text-[13px] font-bold text-white">
          STONKS
        </div>
        <div class="p-3">
          <div class="border border-neobit-green border-opacity-20 rounded-lg overflow-hidden">
            <div class="w-full bg-neobit-green bg-opacity-40 p-3">
              <div class="bg-white bg-opacity-60 rounded-md py-2 text-center items-center justify-center text-[15px] font-semibold text-neobit-green flex">
                <div>Holding: <b>{{ store.state.userStore.tokens.STONKS }} of {{ store.state.userStore.totalStonks }}</b></div>
                <div><img src="/img/tokens/transparent/STONKS.svg" class="w-5 h-5"/></div>
              </div>
            </div>
            <div class="p-4">
              <div class="w-full flex items-center justify-between bg-[#C8EBE052] py-1.5 px-2.5">
                <span class="text-[11px] text-neobit-black font-semibold">Current Dividend per Stonk</span>
                <div class="flex items-center gap-0.5">
                  <img src="/img/tokens/transparent/bNEO.svg" class="w-4 h-4"/>
                  <sexy-number
                    :number="getDividend()"
                    :decimals="4"
                    class="text-neobit-black text-[11px] font-semibold"
                  />
                </div>
              </div>
              <div class="w-full flex items-center justify-between bg-[#C8EBE052] py-1.5 px-2.5 mt-2">
                <span class="text-[11px] text-neobit-black font-semibold">Your Current Dividend</span>
                <div class="flex items-center gap-0.5">
                  <img src="/img/tokens/transparent/bNEO.svg" class="w-4 h-4"/>
                  <sexy-number
                    :number="getDividend() * store.state.userStore.tokens.STONKS"
                    :decimals="4"
                    class="text-neobit-black text-[11px] font-semibold"
                  />
                </div>
              </div>
              <div class="w-full flex items-center justify-between bg-[#C8EBE052] py-1.5 px-2.5 mt-2">
                <span class="text-[11px] text-neobit-black font-semibold">Total Dividend for all Stonk</span>
                <div class="flex items-center gap-0.5">
                  <img src="/img/tokens/transparent/bNEO.svg" class="w-4 h-4"/>
                  <sexy-number
                    :number="store.state.userStore.seasonFees / 10 ** 8"
                    :decimals="4"
                    class="text-neobit-black text-[11px] font-semibold"
                  />
                </div>
              </div>
              <div class="w-full flex items-center justify-between bg-[#C8EBE052] py-1.5 px-2.5 mt-2">
                <span class="text-[11px] text-neobit-black font-semibold">Dividend Payout</span>
                <span class="text-[11px] text-neobit-black font-semibold">
                  After season {{ store.state.userStore.currentSeason }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-interface>
</template>

<script>
import AppInterface from '@/os/components/templates/AppInterface.vue';
import store from '@/store';

export default {
  name: 'MyStonks',
  components: {
    AppInterface,
  },
  setup() {
    function getDividend() {
      return (store.state.userStore.seasonFees / 10 ** 8) / store.state.userStore.totalStonks;
    }
    return {
      store,
      getDividend,

    };
  },
};
</script>

<style scoped>
.box {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.06);
  border-radius: 8px;
}
</style>
