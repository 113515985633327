<template>
  <div class="relative bg-white">

    <!-- Player Card -->
    <div v-if="item.item_type !== 'consumable'" class="w-full text-black flex justify-center items-center relative bg-gray-100">
      <div class="w-full relative flex cursor-pointer">
        <div
          class="flex justify-center items-center relative bg-white overflow-hidden w-[50px] h-[60px]"
          :class="[thisPlayer.rank]"
        >
          <game-avatar
            v-if="item.item_type === 'player' || item.item_type === 'manager'"
            :avatar-data="item.avatar"
            :shape="'none'"
            class="absolute h-[70px] -mt-2"
          />
          <stadium-avatar
            v-if="item.item_type === 'stadium'"
            :stadium-rank="item.rank"
            class="h-14 -mt-2"
          />
        </div>

        <div class="flex flex-col flex-grow">
          <div class="flex pl-2 border-b">
            <div
              @click="openModal"
              class="flex-grow font-semibold text-[15px] flex items-center"
            >
              <div class="mr-1 flex items-center">
                <div>
                  {{ item.name }}
                </div>
              </div>
              <div class="flex text-sm text-black text-opacity-75">
                <span>(</span>
                <div>
                  <sexy-number
                    :number="item.age"
                    :decimals="0"
                    class="text-center w-full"
                  />
                </div>
                <span>)</span>
              </div>
            </div>
          </div>
          <!-- Person Info -->
          <div
            v-if="thisPlayer.itemType === 'player' || thisPlayer.itemType === 'manager'"
            class="flex flex-grow pl-2 items-center"
          >
            <div class="w-[64px] -mt-1">
              <div class="text-[9px] text-[#33333380] font-semibold">Rank</div>
              <div class="capitalize text-[13px] font-bold">
                <rank :rank="thisPlayer.rank"/>
              </div>
            </div>
            <div class="w-[45px]">
              <div class="text-[9px] text-[#33333380] font-semibold">Skills</div>
              <div>
                <sexy-number
                  :number="(thisPlayer.skills.base + thisPlayer.skills.inc + thisPlayer.skills.dec) / 10"
                  :decimals="1"
                  class="text-[13px] font-bold -mt-1"
                />
              </div>
            </div>
            <div class="w-[45px]">
              <div class="text-[9px] text-[#33333380] font-semibold">Luck</div>
              <div>
                <sexy-number
                  :number="(thisPlayer.luck.base + thisPlayer.luck.inc + thisPlayer.luck.dec) / 10"
                  :decimals="1"
                  class="text-[13px] font-bold -mt-1"
                />
              </div>
            </div>
            <div class="w-[45px]">
              <div class="text-[9px] text-[#33333380] font-semibold">Multip.</div>
              <div>
                <sexy-number
                  :number="(thisPlayer.multiplier.base + thisPlayer.multiplier.inc + thisPlayer.multiplier.dec) / 10"
                  :decimals="1"
                  class="text-[13px] font-bold -mt-1"
                />
              </div>
            </div>
            <div class="w-[45px]">
              <div class="text-[9px] text-[#33333380] font-semibold">Aggr.</div>
              <div>
                <sexy-number
                  :number="(thisPlayer.aggression.base + thisPlayer.aggression.inc + thisPlayer.aggression.dec) / 10"
                  :decimals="1"
                  class="text-[13px] font-bold -mt-1"
                />
              </div>
            </div>
          </div>

          <!-- Stadium Info -->
          <div
            v-if="thisPlayer.itemType === 'stadium'"
            class="flex flex-grow pl-2 items-center"
          >
            <div class="w-[64px] -mt-1">
              <div class="text-[9px] text-[#33333380] font-semibold">Rank</div>
              <div class="capitalize text-[13px] font-bold">
                <rank :rank="thisPlayer.rank"/>
              </div>
            </div>
            <div class="w-[50px]">
              <div class="text-[9px] text-[#33333380] font-semibold">Weather</div>
              <div>
                <sexy-number
                  :number="(thisPlayer.weather.base + thisPlayer.weather.inc + thisPlayer.weather.dec) / 10"
                  :decimals="1"
                  class="text-[13px] font-bold -mt-1"
                />
              </div>
            </div>
            <div class="w-[40px]">
              <div class="text-[9px] text-[#33333380] font-semibold">Luck</div>
              <div>
                <sexy-number
                  :number="(thisPlayer.luck.base + thisPlayer.luck.inc + thisPlayer.luck.dec) / 10"
                  :decimals="1"
                  class="text-[13px] font-bold -mt-1"
                />
              </div>
            </div>
            <div class="w-[52px]">
              <div class="text-[9px] text-[#33333380] font-semibold">Capacity</div>
              <div>
                <sexy-number
                  :number="(thisPlayer.capacity)"
                  :decimals="0"
                  class="text-[13px] font-bold -mt-1"
                />
              </div>
            </div>
            <div class="w-[40px]" v-if="showSalary">
              <div class="text-[9px] text-[#33333380] font-semibold">Salary</div>
              <div>
                <sexy-number
                  :number="thisPlayer.seasonCost / 100000000"
                  :decimals="4"
                  class="text-[13px] font-bold -mt-1"
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- Consumable Card -->
    <div v-if="item.item_type === 'consumable'" class="w-full text-black flex justify-center items-center relative">
      <div class="w-full relative flex cursor-pointer">
        <div
          class="flex justify-center items-center relative bg-white overflow-hidden w-[60px] h-[60px]"
          :class="[item.rank]"
        >
          <consumable-avatar class="h-[36px]" :consumable="item"></consumable-avatar>
        </div>

        <div class="flex flex-col flex-grow">
          <div class="flex pl-2 border-b">
            <div
              class="flex-grow font-semibold text-[15px] flex items-center"
            >
              <div class="mr-1 flex items-center">
                <div class="text-xs capitalize">{{ item.for_item }} {{ item.for_skill }}</div>
              </div>
            </div>
          </div>

          <!-- Consumable Info -->
          <div
            class="flex flex-grow pl-2 items-center"
          >
            <div class="w-[64px] -mt-1">
              <div class="text-[9px] text-[#33333380] font-semibold">Rank</div>
              <div class="capitalize text-[13px] font-bold">
                <rank :rank="item.rank"/>
              </div>
            </div>
            <div class="w-[70px]">
              <div class="text-[9px] text-[#33333380] font-semibold">For</div>
              <div class="capitalize text-[13px] font-bold -mt-1">
                {{ item.for_item }}
              </div>
            </div>
            <div class="w-[50px]">
              <div class="text-[9px] text-[#33333380] font-semibold">Gain</div>
              <div class="capitalize text-[13px] font-bold -mt-1">
                {{ getGain(item) }}
              </div>
            </div>
            <div class="w-[52px]">
              <div class="text-[9px] text-[#33333380] font-semibold">TRAIT</div>
              <div class="capitalize text-[13px] font-bold -mt-1">
                {{ item.for_skill }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- On Loan Card -->
      <div
        v-if="item.on_loan_from_club !== '' && item.item_type !== 'consumable'"
        class="absolute inset-0 bg-white bg-opacity-90 z-10 flex justify-center items-center"
      >
        <div class="flex text-center text-sm flex-col items-center">
          <div class="mb-1">
            <div class="font-semibold text-[15px] flex">
              <div class="mr-1.5 flex items-center">
                <div class="mr-1"><img class="z-10" :src="`/img/cards/icons/${item.position}.svg`"></div>
                <div>{{ item.name }}</div>
              </div>
              (
              <div>
                <sexy-number
                  :number="item.age"
                  :decimals="0"
                  class="text-center w-full"
                />

              </div>
              <div class="">)</div>
            </div>
          </div>
          <div class="flex">
            On Loan at
            <club-logo :club-id="item.club_id" class="w-5 h-5 mx-1"/>
            {{ store.state.userStore.clubNames[item.club_id] }}
          </div>
        </div>
      </div>
    </div>

  <spinner v-if="loading"></spinner>
</template>

<script>
import GameAvatar from '@/apps/globalComponents/components/PlayerAvatar.vue';
import ConsumableAvatar from '@/apps/globalComponents/components/ConsumableAvatar.vue';
import { mapPlayerData } from '@/apps/globalComponents/models/items';
import { computed, ref } from 'vue';
import StadiumAvatar from '@/apps/globalComponents/components/StadiumAvatar.vue';
import Spinner from '@/os/components/Spinner.vue';
import { useStore } from 'vuex';
import ClubLogo from '@/apps/globalComponents/components/clubLogo.vue';
import Rank from '@/apps/globalComponents/components/Rank.vue';

export default {
  name: 'ItemSearchCard',
  components: {
    Rank,
    ClubLogo,
    Spinner,
    StadiumAvatar,
    GameAvatar,
    ConsumableAvatar,
  },
  props: {
    player: {
      type: Object,
      required: true,
    },
  },
  emits: ['itemChange'],
  setup(props) {
    const store = useStore();
    const loading = ref(false);
    const thisPlayer = computed(() => mapPlayerData(props.player));
    const item = computed(() => props.player);
    const name = computed(() => thisPlayer.value.name.split(' ')[1]);
    const myPlayer = thisPlayer.value.clubId === store.state.userStore.club.id;
    const itemOption = ref(null);
    const userId = computed(() => store.state.userStore.userId);

    function getGain(thisItem) {
      if (thisItem.for_skill === 'rename') return '-';
      if (thisItem.for_item === 'stadium') return thisItem.gain;
      if (thisItem.for_skill === 'age') return thisItem.gain;
      if (thisItem.for_skill === 'stonks') return thisItem.gain;
      return thisItem.gain / 10;
    }

    return {
      store,
      item,
      userId,
      getGain,
      thisPlayer,
      myPlayer,
      name,
      loading,
      itemOption,
    };
  },
};
</script>

<style scoped>

</style>
