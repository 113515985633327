<template>
  <div class="mx-4 bg-white px-4 py-3 mt-4 rounded-lg shadow relative">
    <div class="z-10 absolute inset-0 w-full h-full rounded-lg blur-back flex flex-col items-center justify-center" v-if="uploading">
      <simple-spinner></simple-spinner>
      <span class="font-bold text-xl mt-5 text-neobit-black tracking-wide">Uploading</span>
      <span class="text-sm font-medium tracking-wide text-neobit-black mt-1">Please wait...</span>
    </div>
    <div class="text-lg font-bold text-neobit-green tracking-wide">CHANGE CLUB BADGE</div>
    <div class="mx-9 border-t border-neobit-green border-opacity-10 my-3"></div>
    <div class="text-[15px] font-medium text-neobit-black tracking-wide">Select a photo from your gallery:</div>
    <div @click="onClickUpload" class="border-2 border-neobit-greenlight bg-neobit-greenlight bg-opacity-25 mt-3 rounded-md px-4 py-2.5 flex items-center justify-between">
      <span class="text-[15px] font-bold text-neobit-green bg-transparent outline-none">
          {{ fileName ?? 'Upload photo'  }}
      </span>
      <img src="/img/icons/upload.svg" class="w-6 h-6"/>
    </div>
    <input type="file" id="uploadBadge" class="hidden" @change="pickFile"/>
    <div class="text-[15px] font-medium text-neobit-black tracking-wide mt-6">Club badge preview:</div>
    <div class="mt-3 border-2 border-neobit-greenlight border-opacity-30 rounded-lg py-5 w-full flex justify-center">
      <img v-if="!base64Img" :src="originalImg === 'regular' ? 'img/ui/football-club.svg' : `https://neosoccer.b-cdn.net/badges/${originalImg}`" class="h-[120px]"/>
      <img v-else :src="base64Img" class="h-[120px]"/>
    </div>
    <div class="text-center mt-2 text-[15px] font-medium text-neobit-black opacity-50">Max 50kb (PNG, JPG)</div>
    <div class="text-center mt-4 text-[15px] font-medium text-neobit-black opacity-50">Fee: 0.01 bNEO</div>
    <button
      @click="save"
      class="mt-6 rounded-xl py-2.5 text-white text-[15px] font-bold tracking-wide w-full"
      :disabled="!fileName"
      :class="fileName ? 'bg-neobit-green' : 'bg-gray-600'"
    >
      SAVE
    </button>
    <button @click="$emit('close')" class="mt-4 rounded-xl bg-neobit-greenlight py-2.5 text-neobit-green text-[15px] font-bold tracking-wide w-full">CANCEL</button>
  </div>
</template>

<script>
/* eslint-disable */
import SimpleModal from '@/os/components/modals/SimpleModal.vue';
import backendAPI from '@/os/APIs/backendAPI';
import { computed, ref } from 'vue';
import osAPI from '@/os/APIs/osAPI';
import store from '@/store';
import SimpleSpinner from '@/os/components/SimpleSpinner.vue';

export default {
  name: 'upload-modal',
  components: {
    SimpleModal,
    SimpleSpinner,
  },
  props: {},
  emits: ['close'],
  setup(props, { emit }) {
    const modalBg = 'rgba(0,0,0,0)';
    const clubId = computed(() => store.state.userStore.club.id);
    const fileName = ref(undefined);
    const originalImg = computed(() => store.state.userStore.club.badge);
    const base64Img = ref(null);
    const uploading = ref(false);

    function closeModal() {
      osAPI.triggerModal('');
    }

    function onClickUpload() {
      document.getElementById('uploadBadge').click();
    }

    function pickFile() {
      const badgeImages = document.getElementById('uploadBadge').files;

      if (badgeImages.length === 0) {
        fileName.value = '';
        return;
      }

      fileName.value = badgeImages[0].name;
      const reader = new FileReader();
      reader.readAsBinaryString(badgeImages[0]);

      reader.onload = () => {
        base64Img.value = `data:image/jpeg;base64,${btoa(reader.result)}`;
      };

      reader.onerror = (error) => {
        window.toast.error(error.message);
        throw error;
      };
    }

    async function save() {
      uploading.value = true;
      try {
        const name = await backendAPI.clubEditBadge(clubId.value, document.getElementById('uploadBadge').files[0]);
        window.toast.success('Upload Success');
        store.commit('userStore/setNewBadge', name)
        emit('close');
      } catch (error) {
        console.log(error, 'Upload Error');
        window.toast.error('Upload Error');
      }
      uploading.value = false;
    }

    return {
      modalBg,
      closeModal,
      onClickUpload,
      pickFile,
      fileName,
      originalImg,
      base64Img,
      save,
      uploading,
    };
  },
};
</script>

<style scoped>
.blur-back {
  background: rgba(255, 255, 255, 0.92);
  backdrop-filter: blur(4px);
}

.nice-scroll::-webkit-scrollbar {
  width: 7px;
}

.nice-scroll::-webkit-scrollbar-track {
  background: #00000000;
  border-radius: 5px;
}

.nice-scroll::-webkit-scrollbar-thumb {
  background: #80808766;
  border-radius: 5px;
}

.nice-scroll::-webkit-scrollbar-thumb:hover {
  background: #808087;
  cursor: pointer;
}
</style>
