<template>
  <div class="flex-1 bg-white md:rounded-2xl flex p-2">
    <div class="flex-1 overflow-auto nice-scroll pr-0">
      <div class="overflow-hidden md:min-h-[670px] px-4 pt-8 pb-4 flex flex-col items-center justify-center text-center">
        <img src="../assets/guide_stadium.png" class="w-[80%]" />
        <div class="mt-8 font-bold text-2xl">Buy a Stadium</div>
        <div class="mt-4">
          Every club needs a stadium they can call home. It is a home for the club to play and for the fans to cheer on their favorites!
        </div>
        <div class="mt-4">
          For every match you play at home, you get
          <span class="font-bold opacity-90">match-day income</span>.
          <br />
          The stadium's
          <span class="font-bold opacity-90">capacity</span>
          determents how much match-day income you get.
        </div>
      </div>
      <div class="p-6">
        <button
          @click="goToNextStep()"
          class="text-white bg-[#00AF92] w-full p-4 font-bold rounded-lg"
        >
          NEXT
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  name: 'IntroStep5',
  setup() {
    const router = useRouter();

    function goToNextStep() {
      router.push({ name: 'IntroStep6' });
    }

    return {
      goToNextStep,
    };
  },
};
</script>
