<template>
  <div class="flex-1 bg-white md:rounded-2xl flex p-2">
    <div class="flex-1 overflow-auto nice-scroll pr-0">
      <div class="overflow-hidden md:min-h-[670px] px-4 pt-8 pb-2 flex flex-col items-center justify-center text-center">
        <img src="../assets/guide_buy_team.png" class="mt-4 w-[90%]" />
        <div class="mt-4 font-bold text-2xl">Buy a Team</div>
        <div class="mt-4">
          The first step of your journey to becoming a NeoSoccer champion is first to get a team.
          To get a team, you need to buy players, a manager, and a stadium, which we call <span class="font-semibold text-green-700">items</span>.
        </div>
        <div class="mt-4">
          You can buy new <span class="font-semibold text-green-700">items</span> by visiting the recruit page, then clicking “Buy” on the type of item you want.
        </div>
        <img src="../assets/navbar.png" class="my-3" />
        <div class="mt-4">
          Every time you buy an item, you get an item with random stats that determent's how good it is. If you get a card with stats you don’t like; you can
          <span class="font-bold opacity-90"> terminate it to get back some of what you paid.</span>
        </div>
        <div class="mt-4">
          A club consists of:
        </div>
        <span class="font-bold opacity-90">11 Players</span>
        <span class="font-bold opacity-90">1 Manager</span>
        <span class="font-bold opacity-90">1 Stadium</span>
        <div class="mt-4">
          You will need a full club to be able to play.
        </div>
        <div class="mt-4">
          Next, we will explore how players, managers, and stadiums work.
        </div>
      </div>
      <div class="p-6">
        <button
          @click="goToNextStep()"
          class="text-white bg-[#00AF92] w-full p-4 font-bold rounded-lg"
        >
          NEXT
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  name: 'IntroStep2',
  setup() {
    const router = useRouter();

    function goToNextStep() {
      router.push({ name: 'IntroStep3' });
    }

    return {
      goToNextStep,
    };
  },
};
</script>
