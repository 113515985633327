import { createRouter, createWebHistory } from 'vue-router';
import MarketApp from '@/apps/market/App.vue';
import MarketAppRouter from '@/apps/market/router';
import TournamentApp from '@/apps/tournament/App.vue';
import TournamentAppRouter from '@/apps/tournament/router';
import RecruitApp from '@/apps/recruit/App.vue';
import RecruitAppRouter from '@/apps/recruit/router';
import MyClubApp from '@/apps/lineup/App.vue';
import MyClubRouter from '@/apps/lineup/router';
import UserApp from '@/apps/user/App.vue';
import UserAppRouter from '@/apps/user/router';
import SearchApp from '@/apps/search/App.vue';
import SearchAppRouter from '@/apps/search/router';
import LandingpageApp from '@/apps/landingPage/Home.vue';
import OnboardingApp from '@/apps/onboarding/App.vue';
import IntroApp from '@/apps/intro/App.vue';
import IntroAppRouter from '@/apps/intro/router';
import OverviewApp from '@/apps/overview/App.vue';
import OverviewAppRouter from '@/apps/overview/router';
import ViewMatch from '@/apps/globalComponents/views/MatchView.vue';
import ItemView from '@/apps/globalComponents/views/ItemView.vue';
import InviteCode from '@/apps/user/invite/InviteCode.vue';
import MyBits from '@/apps/user/bits/MyBits.vue';
import MyStonks from '@/apps/user/stonks/MyStonks.vue';
import Wallet from '@/apps/user/wallet/Wallet.vue';
import Clubs from '@/apps/user/clubs/Clubs.vue';
import ClubView from '@/apps/globalComponents/views/ClubView.vue';
import ClubItemView from '@/apps/globalComponents/views/ClubItemView.vue';
import LiveMatch from '@/apps/globalComponents/views/LiveMatch.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: LandingpageApp,
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: OnboardingApp,
  },
  {
    path: '/intro',
    name: 'Intro',
    component: IntroApp,
    redirect: { name: 'IntroStep1' },
    children: IntroAppRouter,
  },
  {
    path: '/user',
    name: 'User',
    component: UserApp,
    redirect: { name: 'Login' },
    children: UserAppRouter,
  },
  {
    path: '/search',
    name: 'Search',
    component: SearchApp,
    redirect: { name: 'SearchClubs' },
    children: SearchAppRouter,
  },
  {
    path: '/overview',
    name: 'Overview',
    component: OverviewApp,
    redirect: { name: 'OverviewMain' },
    children: OverviewAppRouter,
  },
  {
    path: '/lineup',
    name: 'Lineup',
    component: MyClubApp,
    redirect: { name: 'LineupMain' },
    children: MyClubRouter,
  },
  {
    path: '/recruit',
    name: 'Recruit',
    component: RecruitApp,
    redirect: { name: 'RecruitMain' },
    children: RecruitAppRouter,
  },
  {
    path: '/market',
    name: 'Market',
    component: MarketApp,
    redirect: { name: 'MarketMain' },
    children: MarketAppRouter,
  },
  {
    path: '/tournament',
    name: 'Tournament',
    component: TournamentApp,
    redirect: { name: 'NeoLeague' },
    children: TournamentAppRouter,
  },
  {
    path: '/match/:match?',
    name: 'MatchView',
    component: ViewMatch,
  },
  {
    path: '/item/:itemId?',
    name: 'ItemView',
    component: ItemView,
  },
  {
    path: '/club/:clubId',
    name: 'ClubView',
    component: ClubView,
  },
  {
    path: '/club/items/:clubId',
    name: 'ClubItemView',
    component: ClubItemView,
  },
  {
    path: '/inviteCode',
    name: 'InviteCode',
    component: InviteCode,
  },
  {
    path: '/myBits',
    name: 'MyBits',
    component: MyBits,
  },
  {
    path: '/myStonks',
    name: 'MyStonks',
    component: MyStonks,
  },
  {
    path: '/my-clubs',
    name: 'MyClubs',
    component: Clubs,
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: Wallet,
  },
  {
    path: '/live/:type/:id',
    name: 'LiveMatch',
    component: LiveMatch,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
