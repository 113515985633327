<template>
  <app-interface :backButton="true">
    <div class="bg-white px-6 pb-4">
      <div class="border-[#E80054] border-2 rounded-lg flex items-center justify-between px-3 py-2">
        <league-name v-if="type === 'league'" :league-id="id"/>
        <div v-else-if="type === 'practice'" class="flex items-center">
          <img src="/img/ui/friendly_match.svg" class="w-8 h-8 rounded-full"/>
          <div class="ml-2 font-semibold">Friendly Match</div>
        </div>
        <div v-else-if="type === 'cup'" class="flex items-center">
          <img :src="`/img/ui/cup/${cupData.theme}.svg`" class="w-8 h-8 rounded-full"/>
          <div class="ml-2 font-semibold">{{ cupData.cup_name }}</div>
        </div>
        <div class="bg-[#E80054] px-1.5 text-[11px] font-bold text-white rounded-xl">
          {{ formatCounter }}
        </div>
      </div>
    </div>
    <div class="" v-if="liveData">
      <div class="flex items-center text-[13px] font-bold flex-grow text-neobit-green bg-[#C8EBE0]">
        <div class="text-center w-1/2 cursor-pointer py-2"
             @click="switchItemMenu('live-score')"
             :class="tab === 'live-score' ? 'bg-[#00AF92] text-white' : ''">
          LIVE SCORES
        </div>
        <div class="text-center w-1/2 cursor-pointer py-2"
             @click="switchItemMenu('event-feed')"
             :class="tab === 'event-feed' ? 'bg-[#00AF92] text-white' : ''">
          EVENT FEED
        </div>
      </div>
      <div class="text-neobit-black">
        <div v-if="tab === 'live-score'" class="flex flex-col">
          <template
            v-for="(item, index) in Object.keys(liveData)"
            :key="index"
          >
            <div v-if="liveData[item].home === store.state.userStore.club.id">
              <div
                class="flex items-center justify-between border-b border-[#3333330F] text-[13px] font-semibold pl-4 pr-6 py-2.5 cursor-pointer"
                :class="openMatch === item ? 'bg-neobit-green text-white' : 'bg-white text-neobit-black'"
                @click="openMatch === item ? openMatch = 0 : openMatch = item;"
              >
                <div class="flex flex-col gap-2">
                  <div class="flex items-center">
                    <span>{{ liveData[item].home_score }}</span>
                    <club-logo :club-id="liveData[item].home" class="w-6 h-6 ml-5"/>
                    <span class="ml-2">{{ store.state.userStore.clubNames[liveData[item].home] }}</span>
                  </div>
                  <div class="flex items-center">
                    <span>{{ liveData[item].away_score }}</span>
                    <club-logo :club-id="liveData[item].away" class="w-6 h-6 ml-5"/>
                    <span class="ml-2">{{ store.state.userStore.clubNames[liveData[item].away] }}</span>
                  </div>
                </div>
                <i class="fas fa-chevron-up text-white font-bold text-base" v-if="openMatch === item"></i>
                <i class="fas fa-chevron-down text-neobit-green font-bold text-base" v-else></i>
              </div>
              <div v-if="openMatch === item" class="p-3">
                <div class="bg-white px-2.5 py-1.5 flex items-center justify-between rounded-md">
                  <span class="text-[11px] font-semibold">Stadium</span>
                  <div class="text-[11px] font-semibold" v-if="!liveData[item].stadium">
                    In the streets 😯
                  </div>
                  <div class="text-[11px] font-semibold" v-if="liveData[item].stadium">
                    {{ liveData[item].stadium.name }}
                    <span class="opacity-50 ml-0.5">(Capacity: {{ liveData[item].stadium.capacity.toLocaleString() }})</span>
                  </div>
                </div>
                <div class="flex gap-2 mt-2">
                  <div class="bg-white text-[11px] font-semibold px-2.5 py-1.5 flex items-center justify-between rounded-md flex-1">
                    Attendees:
                    <span>{{ liveData[item].attendees.toLocaleString() }}</span>
                  </div>
                  <div class="bg-white text-[11px] font-semibold px-2.5 py-1.5 flex items-center justify-between rounded-md flex-1">
                    Weather:
                    <span>{{ weatherTypes[Math.ceil(liveData[item].weather * 10)] }}</span>
                  </div>
                </div>

                <div v-if="liveData[item].events.length > 0">
                  <div class="m-3 border-b border-neobit-black border-opacity-5"></div>
                  <div class="flex gap-2">
                    <div class="flex-1 flex flex-col gap-2">
                      <div
                        v-for="(event, eIndex) of liveData[item].events.filter((fEvent) => fEvent.club_id === liveData[item].home)"
                        :key="eIndex"
                        class="bg-[#E2CBFF] py-1.5 px-2 rounded-md flex items-center text-[11px] font-semibold"
                      >
                        <div v-if="event.event !== 'penalty'">
                          <event-img :type="event.event"/>
                          <span class="ml-2 flex-grow">{{ event.name }}</span>
                          <span>{{ event.min }}'</span>
                        </div>
                        <div v-if="event.event === 'penalty'">
                          <event-img :type="`${event.event}${event.result}`"/>
                          <span class="ml-2 flex-grow">{{ event.name }}</span>
                          <span>{{ event.penalty_nr }}'</span>
                        </div>

                      </div>
                    </div>
                    <div class="flex-1 flex flex-col gap-2">
                      <div
                        v-for="(event, eIndex) of liveData[item].events.filter((fEvent) => fEvent.club_id === liveData[item].away)"
                        :key="eIndex"
                        class="bg-neobit-greenlight py-1.5 px-2 rounded-md flex items-center text-[11px] font-semibold"
                      >
                        <div v-if="event.event !== 'penalty'">
                          <event-img :type="event.event"/>
                          <span class="ml-2 flex-grow">{{ event.name }}</span>
                          <span>{{ event.min }}'</span>
                        </div>
                        <div v-if="event.event === 'penalty'">
                          <event-img :type="`${event.event}${event.result}`"/>
                          <span class="ml-2 flex-grow">{{ event.name }}</span>
                          <span>{{ event.penalty_nr }}'</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template
            v-for="(item, index) in Object.keys(liveData)"
            :key="index"
          >
            <div v-if="liveData[item].home !== store.state.userStore.club.id">
              <div
                class="flex items-center justify-between border-b border-[#3333330F] text-[13px] font-semibold pl-4 pr-6 py-2.5 cursor-pointer"
                :class="openMatch === item ? 'bg-neobit-green text-white' : 'bg-white text-neobit-black'"
                @click="openMatch === item ? openMatch = 0 : openMatch = item;"
              >
                <div class="flex flex-col gap-2">
                  <div class="flex items-center">
                    <span>{{ liveData[item].home_score }}</span>
                    <club-logo :club-id="liveData[item].home" class="w-6 h-6 ml-5"/>
                    <span class="ml-2">{{ store.state.userStore.clubNames[liveData[item].home] }}</span>
                  </div>
                  <div class="flex items-center">
                    <span>{{ liveData[item].away_score }}</span>
                    <club-logo :club-id="liveData[item].away" class="w-6 h-6 ml-5"/>
                    <span class="ml-2">{{ store.state.userStore.clubNames[liveData[item].away] }}</span>
                  </div>
                </div>
                <i class="fas fa-chevron-up text-white font-bold text-base" v-if="openMatch === item"></i>
                <i class="fas fa-chevron-down text-neobit-green font-bold text-base" v-else></i>
              </div>
              <div v-if="openMatch === item" class="p-3">
                <div class="bg-white px-2.5 py-1.5 flex items-center justify-between rounded-md">
                  <span class="text-[11px] font-semibold">Stadium</span>
                  <div class="text-[11px] font-semibold">
                    {{ liveData[item].stadium.name }}
                    <span class="opacity-50 ml-0.5">(Capacity: {{ liveData[item].stadium.capacity.toLocaleString() }})</span>
                  </div>
                </div>
                <div class="flex gap-2 mt-2">
                  <div class="bg-white text-[11px] font-semibold px-2.5 py-1.5 flex items-center justify-between rounded-md flex-1">
                    Attendees:
                    <span>{{ liveData[item].attendees.toLocaleString() }}</span>
                  </div>
                  <div class="bg-white text-[11px] font-semibold px-2.5 py-1.5 flex items-center justify-between rounded-md flex-1">
                    Weather:
                    <span>{{ weatherTypes[Math.ceil(liveData[item].weather * 10)] }}</span>
                  </div>
                </div>

                <div v-if="liveData[item].events.length > 0">
                  <div class="m-3 border-b border-neobit-black border-opacity-5"></div>
                  <div class="flex gap-2">
                    <div class="flex-1 flex flex-col gap-2">
                      <div
                        v-for="(event, eIndex) of liveData[item].events.filter((fEvent) => fEvent.club_id === liveData[item].home)"
                        :key="eIndex"
                        class="bg-[#E2CBFF] py-1.5 px-2 rounded-md flex items-center text-[11px] font-semibold"
                      >
                        <event-img :type="event.event"/>
                        <span class="ml-2 flex-grow">{{ event.name }}</span>
                        <span>{{ event.min }}'</span>
                      </div>
                    </div>
                    <div class="flex-1 flex flex-col gap-2">
                      <div
                        v-for="(event, eIndex) of liveData[item].events.filter((fEvent) => fEvent.club_id === liveData[item].away)"
                        :key="eIndex"
                        class="bg-neobit-greenlight py-1.5 px-2 rounded-md flex items-center text-[11px] font-semibold"
                      >
                        <event-img :type="event.event"/>
                        <span class="ml-2 flex-grow">{{ event.name }}</span>
                        <span>{{ event.min }}'</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div v-if="tab === 'event-feed'" class="flex flex-col gap-3 p-3">
          <div
            v-for="(event, index) of allEvents"
            :key="index"
            class="shadow-box flex items-center p-2"
          >
            <club-logo :club-id="event.club_id" class="w-8 h-8"/>
            <div class="flex flex-col flex-grow ml-3">
              <div class="text-[11px] font-semibold opacity-50">
                {{ store.state.userStore.clubNames[event.clubId] }}
              </div>
              <div class="mt-0.5 text-[13px] font-semibold">
                {{ event.min }}' {{ eventDict[event.event] }} {{ event.name }}
              </div>
            </div>
            <event-img :type="event.event" :size="'big'"/>
          </div>
        </div>
      </div>
    </div>
  </app-interface>
</template>

<script>
/* eslint-disable array-callback-return */
import {
  computed,
  ref,
  onBeforeMount,
  onBeforeUnmount,
  watch,
} from 'vue';
import store from '@/store';
import { useRoute } from 'vue-router';
import AppInterface from '@/os/components/templates/AppInterface.vue';
import LeagueName from '@/apps/globalComponents/components/leagueName.vue';
import ClubLogo from '@/apps/globalComponents/components/clubLogo.vue';
import EventImg from '@/apps/globalComponents/components/eventImg.vue';
import backendAPI from '@/os/APIs/backendAPI';
import { weatherTypes } from '@/os/utils/config';

export default {
  name: 'LiveMatch',
  components: {
    LeagueName,
    AppInterface,
    ClubLogo,
    EventImg,
  },
  setup() {
    const route = useRoute();
    const tab = ref('live-score');
    const type = computed(() => route.params.type ?? 'league');
    const id = computed(() => route.params.id ?? 1);
    const liveData = computed(() => {
      if (!store.state.userStore.liveMatches[type.value]) return false;
      if (!store.state.userStore.liveMatches[type.value][id.value]) return false;
      if (Object.keys(store.state.userStore.liveMatches[type.value][id.value]).length === 0) return false;
      return store.state.userStore.liveMatches[type.value][id.value];
    });

    const eventDict = {
      yellow_card: 'Yellow Card to',
      second_yellow: 'Second Yellow Card to',
      red_card: 'Red Card to',
      goal: 'Goal by',
    };

    const cupData = ref({});
    const allEvents = ref([]);
    const openMatch = ref(0);

    let liveCounter = null;
    const counter = ref(0);

    function calcLiveCounter() {
      counter.value += 1;
      if (counter.value >= 5400) {
        clearInterval(liveCounter);
      }
    }

    async function getStadiumInfo(stadiumId) {
      const stadium = await backendAPI.itemGet(stadiumId);
      return stadium;
    }

    const formatCounter = computed(() => {
      const ss = counter.value % 60;
      let mm = parseInt(counter.value / 60, 10);
      const hh = parseInt(mm / 60, 10);
      mm %= 60;
      return `${hh > 9 ? hh : `0${hh}`}:${mm > 9 ? mm : `0${mm}`}:${ss > 9 ? ss : `0${ss}`}`;
    });

    async function checkCounter() {
      if (liveData.value === false) {
        clearInterval(liveCounter);
        return;
      }
      // filteredData.value = [];
      allEvents.value = [];
      Object.keys(liveData.value).map((item) => {
        if (item !== 'info') {
          liveData.value[item].events.map((event) => {
            allEvents.value.push({
              ...event,
              clubId: liveData.value[item][event.club],
            });
          });
        }
      });
      // for (let idx = 0; idx < Object.keys(liveData.value).length; idx += 1) {
      //   const item = Object.keys(liveData.value)[idx];
      //   if (item !== 'info') {
      //     // // eslint-disable-next-line
      //     // const stadium = await getStadiumInfo(liveData.value[item].stadium);
      //     // filteredData.value.push({
      //     //   ...liveData.value[item],
      //     //   stadium,
      //     //   weather: weatherTypes[Math.ceil(liveData.value[item].weather * 10)],
      //     // });
      //     allEvents.value = [
      //       ...allEvents.value,
      //       ...liveData.value[item].events,
      //     ];
      //   }
      // }
      // eslint-disable-next-line
      allEvents.value.sort((a, b) => a.min < b.min ? 1 : -1);
      let endTime = Object.values(store.state.userStore.liveMatches[type.value][id.value])[0].completed;
      if (type.value === 'cup') {
        cupData.value = store.state.userStore.cups.find((item) => item.id === id.value);
        if (cupData.value) endTime = (Number(cupData.value.rounds[`round${cupData.value.current_round}`].completed));
      }
      counter.value = parseInt(new Date().getTime() / 1000, 10) - (endTime - 60 * 90);
      clearInterval(liveCounter);
      liveCounter = setInterval(() => calcLiveCounter(), 1000);
    }

    onBeforeMount(() => {
      checkCounter();
    });

    watch(liveData, () => {
      checkCounter();
    });

    onBeforeUnmount(() => {
      clearInterval(liveCounter);
    });

    function switchItemMenu(newTab) {
      tab.value = newTab;
    }

    return {
      store,
      openMatch,
      tab,
      type,
      id,
      liveData,
      eventDict,
      switchItemMenu,
      getStadiumInfo,
      formatCounter,
      allEvents,
      cupData,
      weatherTypes,
    };
  },
};
</script>

<style scoped>
.dropdown {
  border: 2px solid #C8EBE0;
  border-radius: 6px;
}

.selectdiv {
  margin-top: 10px;
}

/*To remove button from IE11, thank you Matt */
select::-ms-expand {
  display: none;
  background: rgba(0, 0, 0, 0.3);
}

.select option {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.selectdiv:after {
  content: '<>';
  font: 17px "Consolas", monospace;
  color: #00AF92;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 16px;
  /*Adjust for position however you want*/

  top: 12px;
  padding: 0 0 2px;
  border-bottom: 1px solid #00AF92;
  /*left line */

  position: absolute;
  pointer-events: none;
}

.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */

  margin: 5px 0px;
  padding: 0px 12px;
  font-size: 16px;
  line-height: 1.75;
  background-color: #ffffff;
  background-image: none;
  -ms-word-break: normal;
  word-break: normal;
}

.shadow-box {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.06);
  border-radius: 8px;
}
</style>
