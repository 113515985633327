import Recruit from './views/MintApp.vue';

const routes = [
  {
    path: '/recruit',
    name: 'RecruitMain',
    component: Recruit,
    meta: { title: 'Recruit - Neo FC' },
  },
];

export default routes;
