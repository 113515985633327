<template>
  <div v-if="noPlayer" class="flex flex-col justify-center items-center pt-4">
    <div class="w-14 h-14 rounded-full relative">
    </div>
  </div>
  <div v-if="!noPlayer" class="flex flex-col justify-center items-center pt-6" @click="$router.push({ name: 'ItemView', params: { itemId: player.item_id } });">
    <div class="w-12 h-12 rounded-full relative" :class="bg ? 'bg-gray-100' : 'bg-white'">
      <player-avatar :avatar-data="player.avatar"></player-avatar>
      <div class="absolute -top-2 -right-2 text-white z-10 -py-[3px] bg-green-500 rounded-lg flex items-center"
           @click="showToolTip = !showToolTip"
           :class="[ratingBg(player.rating), player.gained_skill ? 'w-[36px]' : 'w-[30px]']">
        <div class="w-full flex">
          <sexy-number
            v-if="player.rating <= 9.9"
            :number="player.rating"
            :decimals="1"
            class="font-bold mx-auto font-rubik text-[13px]"
          />
          <sexy-number
            v-if="player.rating > 9.9"
            :number="player.rating"
            :decimals="0"
            class="font-bold mx-auto font-rubik text-[13px]"
          />
        </div>
      </div>
      <div class="absolute top-6 right-9 flex">
        <div v-if="player.level_up_amount" class="w-6 h-6 shadow-lg bg-white rounded-full flex items-center justify-center">
          <img src="/img/ui/level-up.svg" width="24" v-if="player.level_up_amount > 0"/>
          <img src="/img/ui/level-down.svg" width="24" v-if="player.level_up_amount < 0"/>
        </div>
      </div>
      <div class="absolute -top-1 right-9 flex">
        <div v-if="getCard(player)" class="w-6 h-6 shadow-lg bg-white rounded-full flex items-center justify-center">
          <img :src="`/img/ui/${getCard(player)}.svg`" width="12"/>
        </div>
      </div>
      <div v-if="getGoals(player) > 0" class="w-full flex justify-center items-center absolute bottom-0 left-9">
        <div v-for="goal in getGoals(player)" :key="goal + 'goal' + player.id" class="bg-white w-[20px] h-[20px] -ml-2 rounded-full flex items-center justify-center">
          <img src="/img/ui/ball.svg" width="16"/>
        </div>
      </div>
    </div>
    <div class="text-[11px] pt-2 text-center font-semibold" :class="bg ? 'text-neobit-black' : 'text-white'">{{ name }}</div>
  </div>
</template>

<script>
import PlayerAvatar from '@/apps/globalComponents/components/PlayerAvatar.vue';
import { computed, ref } from 'vue';

const skillTypes = {
  gain_luck: 'Luck +',
  gain_skills: 'Skills +',
  gain_multiplier: 'Multiplier +',
};

export default {
  name: 'pitchPlayer',
  components: { PlayerAvatar },
  props: {
    player: {
      type: Object,
    },
    bg: {
      type: Boolean,
    },
  },
  setup(props) {
    const noPlayer = ref(false);
    const showToolTip = ref(false);
    const playerCard = ref(false);

    if (typeof props.player === 'undefined') {
      noPlayer.value = true;
    }

    const name = computed(() => props.player.name.split(' ')[1]);

    function getCard(player) {
      let worstCard = false;
      player.events.forEach((e) => {
        if (!e.event.startsWith('goal')) {
          if (e.event === 'yellow_card') {
            worstCard = 'yellow-card';
          }
          if (e.event !== 'yellow_card') {
            if (e.event === 'red_card') {
              worstCard = 'red-card';
            } else {
              worstCard = 'yellow-red-card';
            }
          }
        }
      });
      return worstCard;
    }

    function getGoals(player) {
      let goals = 0;
      player.events.forEach((e) => {
        if (e.event === 'goal') {
          goals += 1;
        }
      });
      return goals;
    }

    function ratingBg(rating) {
      if (rating < 5) {
        return 'bad';
      }
      if (rating < 7) {
        return 'novice';
      }
      if (rating <= 9.9) {
        return 'good';
      }
      return 'gold';
    }

    return {
      ratingBg,
      showToolTip,
      noPlayer,
      skillTypes,
      name,
      getCard,
      getGoals,
      playerCard,
    };
  },
};
</script>

<style scoped>

.bad {
  background-color: #ff0000;
}

.novice {
  background-color: #FFA41B;
}

.good {
  background-color: #1cc828;
}

.gold {
  background-color: #d611dc;
}

</style>
