<template>
  <div class="p-4">
    <div class="relative bg-[#C8EBE0] items-center justify-between">
      <div class="shadow-box p-3 relative">
        <div class="h-[40px] bg-white rounded-lg w-full pl-2 flex items-center bg-[#3333330F] dropdown" @click="dropdownOpen = !dropdownOpen">
          <league-name class="font-semibold text-neobit-green flex-grow" :league-id="selectedLeague"></league-name>
          <img class="mr-2" src="/img/icons/arrow-down.svg" width="30">
          <div v-show="dropdownOpen" class="absolute top-[49px] border-r-[2px] border-l-[2px] shadow-lg border-b-[2px] border-[#C8EBE0] bg-gray-100 right-[12px] left-[12px] z-20 rounded-b-lg">
            <div v-for="i in numberOfLeagues" :key="i" @click="selectedLeague = i">
              <div v-if="selectedLeague !== i" class="py-1 px-2 hover:bg-green-100 cursor-pointer font-semibold text-neobit-green">
                <league-name :league-id="i" @click="switchLeague(i)"></league-name>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3 rounded-lg overflow-hidden border-[#C8EBE052] border-2">
          <div class="bg-[#3333330F] w-full p-3 h-[76px] flex justify-between">
            <div class="flex items-center rounded-md p-[5px] gap-1 h-[26px]">
              <div class="flex items-center p-[5px] bg-[#FFFFFFA3] gap-1" v-if="leagueId === userLeague">
                <img src="../assets/check.svg" class="w-4 h-4"/>
                <span class="text-[11px] font-semibold text-neobit-black">Joined</span>
              </div>
            </div>
            <div class="flex items-center bg-[#FFFFFFA3] rounded-md p-[5px] gap-1 h-[26px]">
              <img src="../assets/clock.svg" class="w-4 h-4"/>
              <span class="text-[11px] font-semibold text-neobit-black">{{ 38 - (store.state.userStore.currentRound - 1) }}d left</span>
            </div>
          </div>
          <div class="-mt-16 z-10 relative flex flex-col items-center px-3 pb-3">
            <img :src="leagueInfo[selectedLeague].logo" class="w-32 h-32 p-4"/>
            <div class="mt-3 text-lg font-bold text-neobit-black">{{ leagueInfo[selectedLeague].name }}</div>
          </div>
        </div>
        <div class="mt-2 flex items-center gap-2">
          <div class="flex-1 bg-[#C8EBE052] rounded-md flex items-center justify-between px-2.5 py-1.5">
            <div class="text-[11px] font-semibold text-neobit-black">Round</div>
            <div class="text-[11px] font-semibold text-neobit-black">
              {{ store.state.userStore.currentRound - 1 }}<span class="opacity-50">/38</span>
            </div>
          </div>
          <div class="flex-1 bg-[#C8EBE052] rounded-md flex items-center justify-between px-2.5 py-1.5">
            <div class="text-[11px] font-semibold text-neobit-black">Prize Pool</div>
            <div class="text-[11px] font-semibold text-neobit-black flex items-center gap-1.5">
              <img src="/img/tokens/transparent/bNEO.png" class="w-5 h-5"/>
              <sexy-number
                :number="leaguePrize"
                :decimals="4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 rounded-lg overflow-hidden">
      <div class="flex items-center text-[13px] font-bold flex-grow text-neobit-green bg-[#C8EBE0]">
        <div class="text-center w-1/2 cursor-pointer"
             @click="switchItemMenu('table')"
             :class="itemTypeSelected === 'table' ? 'bg-[#00AF92] text-white p-2' : ''">
          Table
        </div>
        <div class="text-center w-1/2 cursor-pointer flex items-center justify-center"
             @click="switchItemMenu('matches')"
             :class="itemTypeSelected === 'matches' ? 'bg-[#00AF92] text-white p-2' : ''">
          Matches
          <div v-if="nowLive" class="bg-[#E80054] rounded-xl px-1.5 ml-2 text-white text-[11px] font-bold">LIVE</div>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import osAPI from '@/os/APIs/osAPI';
import {
  computed,
  ref,
  watch,
} from 'vue';
import store from '@/store';
import { useRoute, useRouter } from 'vue-router';
import LeagueName from '@/apps/globalComponents/components/leagueName.vue';
import leagueInfo from '@/os/coreData/leagues';

export default {
  name: 'ranking-app',
  components: { LeagueName },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const selectedRound = ref(1);
    const clubLeague = computed(() => store.state.userStore.club.league);
    let leagueId = typeof route.params.league !== 'undefined'
      ? Number(route.params.league)
      : clubLeague.value;
    const selectedLeague = ref(leagueId);
    let roundId = route.params.round;
    const itemTypeSelected = ref('');
    const itemPlayerTypeSelected = ref('goalkeepers');
    const dropdownOpen = ref(false);

    const nowLive = computed(() => {
      if (!store.state.userStore.liveMatches.league) return false;
      if (!store.state.userStore.liveMatches.league[leagueId]) return false;
      if (Object.keys(store.state.userStore.liveMatches.league[leagueId]).length === 0) return false;
      return true;
    });
    const path = computed(() => route.path);
    const leagues = computed(() => store.state.userStore.leagues);
    const rounds = computed(() => store.state.userStore.matches);
    const numberOfLeagues = computed(() => store.state.userStore.numberOfLeagues);
    const currentRound = computed(() => store.state.userStore.currentRound);
    const userLeague = computed(() => store.state.userStore.club.league);
    const leaguePrize = computed(() => {
      const allLeaguePrizes = store.state.userStore.leaguePrizes;
      return (allLeaguePrizes[selectedLeague.value] ?? 0) / (10 ** 8);
    });
    const leagueEarning = computed(() => {
      const matchPrize = (store.state.userStore.matchPrize ?? 0) / (10 ** 8);
      const ticketPrize = (store.state.userStore.ticketPrice ?? 0) / (10 ** 8);
      const tmp = (leaguePrize.value + matchPrize * 380 + ticketPrize * 10000 * 380);
      return tmp;
    });

    console.log(clubLeague.value);

    if (typeof leagueId.value === 'undefined' && path.value.startsWith('/ranking/table')) {
      router.push({ path: `/ranking/table/${leagueId}` });
    }

    if (path.value.startsWith('/ranking/table')) {
      itemTypeSelected.value = 'table';
    }
    if (path.value.startsWith('/ranking/round')) {
      itemTypeSelected.value = 'matches';
    }

    watch(path, (newPath) => {
      if (newPath.startsWith('/ranking/table')) {
        itemTypeSelected.value = 'table';
      }
      if (newPath.startsWith('/ranking/round')) {
        itemTypeSelected.value = 'matches';
      }
    });

    watch(
      selectedLeague,
      (newItem) => {
        // TODO: Fetch leages ones instead of all over the place xD
        store.dispatch('userStore/getLeagues', { league: newItem });
        store.dispatch('userStore/getMatches', { league: newItem });
      },
      { immediate: true },
    );

    const sortedList = computed(() => {
      if (leagues.value.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return leagues.value.sort((b, a) => a.points - b.points || (a.goals_for - a.goals_against) - (b.goals_for - b.goals_against));
      }
      return [];
    });

    function switchLeague(league) {
      selectedLeague.value = league;
      router.push({ path: `/ranking/table/${league}` });
    }

    function switchItemMenu(itemType) {
      leagueId = selectedLeague.value;
      if (typeof leagueId === 'undefined') {
        leagueId = userLeague.value;
      }

      if (typeof roundId === 'undefined') {
        roundId = currentRound.value - 1;
      }
      if (itemType === 'table') {
        router.push({ path: `/ranking/table/${leagueId}` });
      }

      if (itemType === 'matches') {
        if (nowLive.value) {
          router.push({ path: `/ranking/round/${leagueId}/${Number(roundId) + 1}` });
        } else {
          router.push({ path: `/ranking/round/${leagueId}/${roundId}` });
        }
      }
    }

    function switchPlayerItemMenu(itemPlayerType) {
      itemPlayerTypeSelected.value = itemPlayerType;
    }

    function toggleDropdown() {
      dropdownOpen.value = !dropdownOpen.value;
    }

    if (typeof roundId !== 'undefined' && Number(roundId) !== selectedRound.value) {
      selectedRound.value = Number(roundId);
    }

    return {
      store,
      osAPI,
      itemTypeSelected,
      switchItemMenu,
      numberOfLeagues,
      selectedLeague,
      userLeague,
      itemPlayerTypeSelected,
      switchPlayerItemMenu,
      dropdownOpen,
      toggleDropdown,
      switchLeague,
      sortedList,
      rounds,
      leagueId,
      roundId,
      leaguePrize,
      leagueInfo,
      leagueEarning,
      nowLive,
    };
  }

  ,
};
</script>

<style scoped>
.dropdown {
  border: 2px solid #C8EBE0;
  border-radius: 6px;
}

.selectdiv {
  margin-top: 10px;
}

/*To remove button from IE11, thank you Matt */
select::-ms-expand {
  display: none;
  background: rgba(0, 0, 0, 0.3);
}

.select option {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.selectdiv:after {
  content: '<>';
  font: 17px "Consolas", monospace;
  color: #00AF92;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 16px;
  /*Adjust for position however you want*/

  top: 12px;
  padding: 0 0 2px;
  border-bottom: 1px solid #00AF92;
  /*left line */

  position: absolute;
  pointer-events: none;
}

.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */

  margin: 5px 0px;
  padding: 0px 12px;
  font-size: 16px;
  line-height: 1.75;
  background-color: #ffffff;
  background-image: none;
  -ms-word-break: normal;
  word-break: normal;
}

.shadow-box {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.06);
  border-radius: 8px;
}
</style>
