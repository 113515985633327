<template>
  <div class="h-full relative">
    <div class="absolute inset-0 w-full h-full" v-if="loading">
      <spinner></spinner>
    </div>
    <div class="h-full bg-[#ECECEC]">
      <div class="flex bg-white items-center justify-center gap-4 border-b-2 border-green-200 px-4 pb-1">
        <div class="relative flex-grow flex items-end selectdiv">
          <select
            v-model="globalStore.itemPlayerTypeSelected.value"
            class="w-full h-10 dropdown text-neobit-green font-bold outline-none"
          >
            <option value="goalkeepers">Goalkeepers</option>
            <option value="defenders">Defenders</option>
            <option value="midfielders">Midfielders</option>
            <option value="attackers">Attackers</option>
            <option value="managers">Managers</option>
            <option value="stadiums">Stadiums</option>
            <option value="consumables">Consumables</option>
            <option disabled>────────────────────</option>
            <option value="all">All</option>
            <option value="inLineup">In Lineup</option>
            <option value="onMarket">On Market</option>
            <option value="contract">Needs Contract</option>
          </select>
        </div>
        <div class="flex items-center w-24">
          <div class="ml-2 text-[13px] text-neobit-green font-bold" @click="showFilters = true">Filter ({{ filterAmount }})</div>
          <div><img src="/img/ui/filter.svg" class="w-4 h-4"/></div>
        </div>
      </div>
      <div class="flex flex-col gap-2 pb-16">
          <div
            v-for="item in sortedItems"
            :key="item.id"
          >
            <item-card
              @itemChange="onItemChange"
              :player="item"
            />
          </div>
      </div>
      <div class="mb-20"></div>
    </div>
  </div>
  <transition name="slide-menu">
    <div v-if="showFilters" class="slideLayer h-full">
      <div class="flex h-full">
        <div @click="showFilters = false" class="w-1/4 h-full"></div>
        <div class="w-3/4 overflow-auto bg-white h-full shadow-2xl p-2 relative">
          <div class="flex items-center pb-4 mx-4 border-b border-neobit-green border-opacity-10">
            <div class="flex-grow mt-4 text-lg text-neobit-green">
              <span class="mr-0.5 font-bold">Filter</span>
              ({{ filterAmount }})
            </div>
            <div class="mt-4 font-bold text-sm text-[#E33A3A]" @click="clearFilters()">RESET FILTER</div>
          </div>
          <div v-if="globalStore.marketPlayerTypeSelected.value === 'players'" class="mt-4 ml-4 text-[14px]">
            <div class="mb-0.5 filterLabel">Position:</div>
            <div class="flex gap-3">
              <!-- eslint-disable max-len -->
              <div @click="toggleRank('local')"
                   class="px-2 py-1.5 rounded-xl flex-1 flex justify-center items-center text-[13px] font-bold"
                   :class="globalStore.rankTypes.value.includes('local') ? 'text-white bg-neobit-green border-2 border-solid border-neobit-green' : 'bg-neobit-greenlight text-neobit-green border-2 border-dashed border-neobit-green'"
              >
                Local
              </div>
              <div @click="toggleRank('district')"
                   class="px-2 py-1.5 rounded-xl flex-1 flex justify-center items-center text-[13px] font-bold"
                   :class="globalStore.rankTypes.value.includes('district') ? 'text-white bg-neobit-green border-2 border-solid border-neobit-green' : 'bg-neobit-greenlight text-neobit-green border-2 border-dashed border-neobit-green'"
              >
                District
              </div>
              <div @click="toggleRank('city')"
                   class="px-2 py-1.5 rounded-xl flex-1 flex justify-center items-center text-[13px] font-bold"
                   :class="globalStore.rankTypes.value.includes('city') ? 'text-white bg-neobit-green border-2 border-solid border-neobit-green' : 'bg-neobit-greenlight text-neobit-green border-2 border-dashed border-neobit-green'"
              >
                City
              </div>
            </div>
            <div class="flex mt-2 gap-3">
              <div @click="toggleRank('county')"
                   class="px-2 py-1.5 rounded-xl flex-1 flex justify-center items-center text-[13px] font-bold"
                   :class="globalStore.rankTypes.value.includes('county') ? 'text-white bg-neobit-green border-2 border-solid border-neobit-green' : 'bg-neobit-greenlight text-neobit-green border-2 border-dashed border-neobit-green'"
              >
                County
              </div>
              <div @click="toggleRank('country')"
                   class="px-2 py-1.5 rounded-xl flex-1 flex justify-center items-center text-[13px] font-bold"
                   :class="globalStore.rankTypes.value.includes('country') ? 'text-white bg-neobit-green border-2 border-solid border-neobit-green' : 'bg-neobit-greenlight text-neobit-green border-2 border-dashed border-neobit-green'"
              >
                Country
              </div>
              <div @click="toggleRank('continental')"
                   class="px-2 py-1.5 rounded-xl flex-1 flex justify-center items-center text-[13px] font-bold"
                   :class="globalStore.rankTypes.value.includes('continental') ? 'text-white bg-neobit-green border-2 border-solid border-neobit-green' : 'bg-neobit-greenlight text-neobit-green border-2 border-dashed border-neobit-green'"
              >
                Continental
              </div>
            </div>
            <div class="flex mt-2 gap-3">
              <div @click="toggleRank('global')"
                   class="px-2 py-1.5 rounded-xl flex-1 flex justify-center items-center text-[13px] font-bold"
                   :class="globalStore.rankTypes.value.includes('global') ? 'text-white bg-neobit-green border-2 border-solid border-neobit-green' : 'bg-neobit-greenlight text-neobit-green border-2 border-dashed border-neobit-green'"
              >
                Global
              </div>
              <div @click="toggleRank('neo')"
                   class="px-2 py-1.5 rounded-xl flex-1 flex justify-center items-center text-[13px] font-bold"
                   :class="globalStore.rankTypes.value.includes('neo') ? 'text-white bg-neobit-green border-2 border-solid border-neobit-green' : 'bg-neobit-greenlight text-neobit-green border-2 border-dashed border-neobit-green'"
              >
                Neo
              </div>
            </div>
          </div>
          <div class="mt-5 ml-4">
            <div class="filterLabel">Age:</div>
            <div class="flex gap-3">
              <input typeof="number" v-model="globalStore.baseLineupFilters.value.minAge"
                     class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
              <input typeof="number" v-model="globalStore.baseLineupFilters.value.maxAge"
                     class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
            </div>
          </div>
          <div class="mt-2 ml-4">
            <div class="filterLabel">Season cost:</div>
            <div class="flex gap-3">
              <input typeof="number" v-model="globalStore.baseLineupFilters.value.minSalary"
                     class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
              <input typeof="number" v-model="globalStore.baseLineupFilters.value.maxSalary"
                     class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
            </div>
          </div>
          <div class="mt-2 ml-4">
            <div class="filterLabel">Luck:</div>
            <div class="flex gap-3">
              <input typeof="number" v-model="globalStore.baseLineupFilters.value.minLuck"
                     class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
              <input typeof="number" v-model="globalStore.baseLineupFilters.value.maxLuck"
                     class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
            </div>
          </div>
          <div class="mt-2 ml-4">
            <div class="filterLabel">Skills:</div>
            <div class="flex gap-3">
              <input typeof="number" v-model="globalStore.baseLineupFilters.value.minSkills"
                     class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
              <input typeof="number" v-model="globalStore.baseLineupFilters.value.maxSkills"
                     class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
            </div>
          </div>
          <div class="mt-2 ml-4">
            <div class="filterLabel">Multiplier:</div>
            <div class="flex gap-3">
              <input typeof="number" v-model="globalStore.baseLineupFilters.value.minMultiplier"
                     class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
              <input typeof="number" v-model="globalStore.baseLineupFilters.value.maxMultiplier"
                     class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
            </div>
          </div>
          <div class="mt-2 ml-4">
            <div class="filterLabel">Weather:</div>
            <div class="flex gap-3">
              <input typeof="number" v-model="globalStore.baseLineupFilters.value.minWeather"
                     class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
              <input typeof="number" v-model="globalStore.baseLineupFilters.value.maxWeather"
                     class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import store from '@/store';
import osAPI from '@/os/APIs/osAPI';
import ItemCard from '@/apps/globalComponents/components/ItemCard.vue';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import globalStore from '@/os/utils/global';
import backendAPI from '@/os/APIs/backendAPI';
import Spinner from '@/os/components/Spinner.vue';

export default {
  name: 'my-players',
  components: { ItemCard, Spinner },
  setup() {
    const dropdownOpen = ref(false);
    const loading = ref(false);
    const route = useRoute();
    const clubId = computed(() => route.params.clubId);
    const showFilters = ref(false);
    const lineupObj = ref(null);
    const sortedItems = ref(null);
    const activeObjects = ref([]);
    const myClub = computed(() => store.state.userStore.club.id);

    const managers = computed(() => lineupObj.value?.managers.sort((a, b) => (b.inLineup ? 1 : -1)));
    const stadiums = computed(() => lineupObj.value?.stadiums.sort((a, b) => (b.inLineup ? 1 : -1)));
    const consumables = computed(() => lineupObj.value?.consumables);

    const filterAmount = computed(() => {
      let amount = 0;
      Object.keys(globalStore.baseLineupFilters.value).forEach((key) => {
        if (globalStore.baseLineupFilters.value[key] !== globalStore.coreLineupFilters[key]) {
          amount += 1;
        }
      });
      amount += globalStore.rankTypes.value.length;
      return amount;
    });

    function toggleRank(tRank) {
      if (!globalStore.rankTypes.value?.includes(tRank)) {
        globalStore.rankTypes.value.push(tRank);
        return;
      }
      if (globalStore.rankTypes.value?.includes(tRank)) {
        const index = globalStore.rankTypes.value.indexOf(tRank);
        if (index > -1) {
          globalStore.rankTypes.value.splice(index, 1);
        }
      }
    }

    function playerSort(a, b) {
      // Sort based on lineup if both have lineup.
      if (a.inLineup && b.inLineup) return a.inLineup.localeCompare(b.inLineup);
      // Sort players with lineup above.
      if (a.inLineup || b.inLineup) return b.inLineup ? 1 : -1;
      // Finally sort based on rank
      return a.rank > b.rank ? 1 : -1;
    }
    function getItems() {
      if (globalStore.itemPlayerTypeSelected.value === 'managers') return managers.value.filter((item) => item.on_loan_from_club !== myClub.value);
      if (globalStore.itemPlayerTypeSelected.value === 'stadiums') return stadiums.value.filter((item) => item.on_loan_from_club !== myClub.value);
      if (globalStore.itemPlayerTypeSelected.value === 'consumables') return consumables.value;
      if (!lineupObj.value) return [];
      let allItems = lineupObj.value?.players;
      allItems = [
        ...allItems?.goalkeepers?.sort(playerSort),
        ...allItems?.defenders?.sort(playerSort),
        ...allItems?.midfielders?.sort(playerSort),
        ...allItems?.attackers?.sort(playerSort),
        ...managers.value,
        ...stadiums.value,
      ];
      if (globalStore.itemPlayerTypeSelected.value === 'all') {
        return allItems;
      }
      if (globalStore.itemPlayerTypeSelected.value === 'inLineup') {
        return allItems.filter((item) => item.inLineup !== undefined);
      }
      if (globalStore.itemPlayerTypeSelected.value === 'contract') {
        return allItems.filter((item) => item.contract_to_season <= store.state.userStore.currentSeason);
      }
      if (globalStore.itemPlayerTypeSelected.value === 'onMarket') {
        return allItems.filter((item) => item.on_loan_from_club === store.state.userStore.club.id || item.on_sale !== false || item.on_loan !== false);
      }
      return lineupObj.value?.players[globalStore.itemPlayerTypeSelected.value].filter((item) => item.on_loan_from_club !== myClub.value).sort(playerSort);
    }

    function getSortedItems() {
      loading.value = true;
      const items = getItems();
      let tmp;
      if (globalStore.itemPlayerTypeSelected.value !== 'consumables') {
        tmp = items.filter((o) => (
          o.age >= globalStore.baseLineupFilters.value.minAge && o.age <= globalStore.baseLineupFilters.value.maxAge
          && o.season_cost >= (globalStore.baseLineupFilters.value.minSalary * 100000000) && o.season_cost <= (globalStore.baseLineupFilters.value.maxSalary * 100000000)
          && ((o.luck?.base + o.luck?.inc) - o.luck?.dec) >= (globalStore.baseLineupFilters.value.minLuck * 10) && ((o.luck?.base + o.luck?.inc) - o.luck?.dec) <= (globalStore.baseLineupFilters.value.maxLuck * 10)
          && (o.position !== 'stadium' || (o.weather?.base >= (globalStore.baseLineupFilters.value.minWeather * 10) && o.weather?.base <= (globalStore.baseLineupFilters.value.maxWeather * 10)))
        ));
      }
      if (globalStore.itemPlayerTypeSelected.value === 'consumables') {
        tmp = items;
      }

      if (globalStore.rankTypes.value.length > 0) {
        tmp = tmp.filter((o) => (
          globalStore.rankTypes.value?.includes(o.rank)
        ));
      }
      sortedItems.value = tmp;
      loading.value = false;
    }

    function clearFilters() {
      globalStore.rankTypes.value = [];
      globalStore.baseLineupFilters.value = {
        ...globalStore.coreLineupFilters,
      };
      getSortedItems();
    }

    async function getData() {
      loading.value = true;
      await store.dispatch('userStore/getUserInfo');
      await store.dispatch('userStore/getUserClub', { clubId: store.state.userStore.club.id });
      const clubGetId = (route.params.clubId !== undefined && route.params.clubId !== '') ? route.params.clubId : store.state.userStore.club.id;
      const data = await backendAPI.itemClubGetAll(clubGetId);

      const lineupData = {
        players: {
          goalkeepers: [],
          defenders: [],
          midfielders: [],
          attackers: [],
        },
        managers: [],
        stadiums: [],
        consumables: [],
      };

      const clubData = await backendAPI.clubGet(clubGetId);
      const inLineup = {};

      Object.keys(clubData).forEach((key) => {
        inLineup[clubData[key]] = key;
      });

      data.forEach((item) => {
        const localItem = item;
        if (Object.keys(inLineup).includes(localItem.id)) {
          localItem.inLineup = inLineup[localItem.id];
        }
        if (item.item_type !== 'player') {
          lineupData[`${item.item_type}s`].push(localItem);
        } else {
          lineupData[`${item.item_type}s`][`${item.position}s`].push(localItem);
        }
      });

      lineupObj.value = lineupData;
      getSortedItems();
      loading.value = false;
    }

    watch(clubId, () => {
      getData();
    });

    watch(globalStore.itemPlayerTypeSelected, () => {
      getSortedItems();
    });

    getData();

    function switchPlayerItemMenu(itemPlayerType) {
      globalStore.itemPlayerTypeSelected.value = itemPlayerType;
    }

    function toggleDropdown() {
      dropdownOpen.value = !dropdownOpen.value;
    }

    function onItemChange() {
      getData();
    }

    return {
      activeObjects,
      store,
      osAPI,
      globalStore,
      filterAmount,
      clearFilters,
      switchPlayerItemMenu,
      dropdownOpen,
      toggleDropdown,
      onItemChange,
      clubId,
      loading,
      sortedItems,
      showFilters,
      toggleRank,
    };
  },
};
</script>
<style scoped>

.dropdown {
  border: 2px solid #C8EBE0;
  border-radius: 6px;
}

/*To remove button from IE11, thank you Matt */
select::-ms-expand {
  display: none;
  background: rgba(0, 0, 0, 0.3);
}

.select option {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.selectdiv:after {
  content: '<>';
  font: 17px "Consolas", monospace;
  color: #00AF92;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 12px;
  /*Adjust for position however you want*/

  top: 12px;
  padding: 0 0 2px;
  border-bottom: 1px solid #00AF92;
  /*left line */

  position: absolute;
  pointer-events: none;
}

.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */

  padding: 0px 12px;
  font-size: 16px;
  line-height: 1.75;
  background-color: #ffffff;
  background-image: none;
  -ms-word-break: normal;
  word-break: normal;
}

.slideLayer {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
}

@media (min-width: 768px) {
  .slideLayer {
    position: absolute;
  }
}

.slide-menu-enter-active {
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  transform: translate(275px, 0);
  -webkit-transform: translate(275px, 0);
}

.slide-menu-leave-active {
  transform: translate(275px, 0);
  -webkit-transform: translate(275px, 0);
}

.filterLabel {
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #333333;
}
</style>
