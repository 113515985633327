<template>
  <app-interface :backButton="true">
    <div class="h-full relative">
      <div class="absolute inset-0 w-full h-full" v-if="loading">
        <spinner></spinner>
      </div>
      <div class="h-full bg-[#ECECEC] pt-4 px-2">
        <div class="flex flex-col gap-2 rounded-xl overflow-hidden pb-4">
          <transition-group name="flip-list">
            <div
              v-for="item in items"
              :key="item.id"
            >
              <item-card
                :player="item"
                :clubId="clubId"
              />
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </app-interface>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import store from '@/store';
import AppInterface from '@/os/components/templates/AppInterface.vue';
import ItemCard from '@/apps/globalComponents/components/ItemCard.vue';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import backendAPI from '@/os/APIs/backendAPI';
import Spinner from '@/os/components/Spinner.vue';

export default {
  name: 'ClubItemView',
  components: {
    ItemCard,
    AppInterface,
    Spinner,
  },
  setup() {
    const loading = ref(false);
    const route = useRoute();
    const clubId = computed(() => route.params.clubId);
    const lineupObj = ref(null);
    const managers = computed(() => lineupObj.value?.managers.sort((a, b) => (b.inLineup ? 1 : -1)));
    const stadiums = computed(() => lineupObj.value?.stadiums.sort((a, b) => (b.inLineup ? 1 : -1)));

    function playerSort(a, b) {
      // Sort based on lineup if both have lineup.
      if (a.inLineup && b.inLineup) return a.inLineup.localeCompare(b.inLineup);
      // Sort players with lineup above.
      if (a.inLineup || b.inLineup) return b.inLineup ? 1 : -1;
      // Finally sort based on name
      return a.name > b.name ? 1 : -1;
    }

    const items = computed(() => {
      if (!lineupObj.value) return [];
      let allItems = lineupObj.value?.players;
      allItems = [
        ...allItems?.goalkeepers?.sort(playerSort),
        ...allItems?.defenders?.sort(playerSort),
        ...allItems?.midfielders?.sort(playerSort),
        ...allItems?.attackers?.sort(playerSort),
        ...managers.value?.sort(playerSort),
        ...stadiums.value?.sort(playerSort),
      ];
      return allItems;
    });

    async function getData() {
      loading.value = true;
      await store.dispatch('userStore/getUserInfo');
      await store.dispatch('userStore/getUserClub', { clubId: store.state.userStore.club.id });
      const clubGetId = route.params.clubId;
      const data = await backendAPI.itemClubGetAll(clubGetId);

      const lineupData = {
        players: {
          goalkeepers: [],
          defenders: [],
          midfielders: [],
          attackers: [],
        },
        managers: [],
        stadiums: [],
      };

      const clubData = await backendAPI.clubGet(clubGetId);
      const inLineup = {};

      Object.keys(clubData).forEach((key) => {
        inLineup[clubData[key]] = key;
      });

      data.forEach((item) => {
        const localItem = item;
        if (Object.keys(inLineup).includes(localItem.id)) {
          localItem.inLineup = inLineup[localItem.id];
        }
        if (item.item_type !== 'player') {
          lineupData[`${item.item_type}s`].push(localItem);
        } else {
          lineupData[`${item.item_type}s`][`${item.position}s`].push(localItem);
        }
      });

      lineupObj.value = lineupData;

      loading.value = false;
    }

    watch(clubId, () => {
      getData();
    });

    getData();

    return {
      items,
      loading,
      clubId,
    };
  },
};
</script>

<style scoped>

</style>
