<template>
  <app-interface>
    <div class="flex">
      <div class="text-center flex-1 text-[13px] font-bold py-1.5 cursor-pointer active:opacity-75" @click="mode='wallet'" :class="getNavTabStyle('wallet')">
        WALLET
      </div>
      <div class="text-center flex-1 text-[13px] font-bold py-1.5 cursor-pointer active:opacity-75" @click="mode='deposit'" :class="getNavTabStyle('deposit')">
        DEPOSIT
      </div>
      <div class="text-center flex-1 text-[13px] font-bold py-1.5 cursor-pointer active:opacity-75" @click="mode='withdraw'" :class="getNavTabStyle('withdraw')">
        WITHDRAW
      </div>
    </div>
    <div v-if="mode === 'wallet'" class="p-4">
      <div class="box">
        <div class="flex items-center justify-between">
          <span class="text-lg text-neobit-green font-bold">YOUR ASSETS</span>
        </div>
        <div class="my-3 mx-9 border-t border-neobit-green border-opacity-20"></div>
        <div v-if="store.state.userStore.balance > 0" class="flex items-center">
          <div class="flex items-center w-1/3">
            <img
              :src="tokenImage('bNEO')"
              class="w-6 h-6 border border-neobit-black rounded-full border-opacity-30"
              :alt="bNEO"
            >
            <div class="ml-1">bNEO</div>
          </div>
          <div class="w-1/3 flex justify-end">
            <sexy-number
              :number="store.state.userStore.balance / (10 ** 8)"
              :decimals="4"
              class="text-[15px] font-medium"
            />
          </div>
          <div class="w-1/3 flex justify-end cursor-pointer" @click="openWithdraw(tokens.bNEO)">
            <div class="text-sm bg-neobit-greenlight rounded-lg p-1.5 text-neobit-green">WITHDRAW</div>
          </div>
        </div>

        <!-- If token is deposited and can be converted -->
        <div v-if="Object.keys(store.state.userStore.deposits).length > 0">
          <div v-for="(token, chains) in store.state.userStore.deposits" :key="chains">
            <div v-for="(v, k) in token" :key="k" class="flex items-center mt-2">
              <div class="flex items-center w-1/3">
                <img
                  :src="tokenImage(k)"
                  class="w-6 h-6 border border-neobit-black rounded-full border-opacity-30"
                  :alt="k"
                >
                <div class="ml-1">{{ k }}</div>
              </div>
              <div class="w-1/3 flex justify-end">
                <sexy-number
                  :number="v / (10 ** tokens[k].decimals)"
                  :decimals="4"
                  class="text-[15px] font-medium"
                />
              </div>
              <div class="w-1/3 flex justify-end cursor-pointer" @click="openConvert(tokens[k])">
                <div class="text-sm bg-neobit-greenlight rounded-lg p-1.5 text-neobit-green">CONVERT</div>
              </div>
            </div>
          </div>
        </div>

        <!-- If token is won and can be withdrawn -->
        <div v-if="Object.keys(store.state.userStore.tokens).length > 0">
          <div v-for="(v, k) in store.state.userStore.tokens" :key="k" class="mt-2">
            <div v-if="v > 0 && !['bNEO', 'BITS', 'STONKS'].includes(k)" class="flex items-center ">
              <div class="flex items-center w-1/3">
                <img
                  :src="tokenImage(k)"
                  class="w-6 h-6 border border-neobit-black rounded-full border-opacity-30"
                  :alt="k"
                >
                <div class="ml-1">{{ k }}</div>
              </div>
              <div class="w-1/3 flex justify-end">
                <sexy-number
                  :number="v / (10 ** tokens[k].decimals)"
                  :decimals="tokens[k].displayDecimals"
                  class="text-[15px] font-medium"
                />
              </div>
              <div class="w-1/3 flex justify-end cursor-pointer" @click="openWithdraw(tokens[k])">
                <div class="text-sm bg-neobit-greenlight rounded-lg p-1.5 text-neobit-green">WITHDRAW</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="Object.keys(store.state.userStore.deposits).length < 1 && store.state.userStore.balance < 0 && Object.keys(store.state.userStore.tokens).length < 0">
          You have no assets...
        </div>
      </div>
    </div>
    <div v-if="mode === 'deposit'" class="p-4">
      <!-- Select Deposit Token -->
      <div class="box" v-if="depositStep === 1">
        <div class="flex items-center justify-between">
          <span class="text-lg text-neobit-green font-bold">SELECT DEPOSIT TOKEN</span>
          <span class="text-[13px] font-semibold text-neobit-black">1/3</span>
        </div>
        <div class="my-3 mx-9 border-t border-neobit-green border-opacity-20"></div>
        <div class="text-[15px] font-medium text-neobit-black">Select a token you’d like to deposit:</div>
        <token-select
          class="w-full rounded border-2 border-neobit-greenlight bg-neobit-greenlight bg-opacity-25 pl-3.5 pr-2 py-2 mt-3"
          v-model:token="depositToken"
          :deposit-only="true"
        />
        <button
          class="mt-6 rounded-xl py-2.5 text-center text-[15px] font-bold text-white w-full"
          :class="depositToken ? 'bg-neobit-green' : 'bg-neobit-black bg-opacity-25'"
          @click="getDepositAddress"
          :disabled="!depositToken"
        >
          NEXT STEP
        </button>
        <!-- <button @click="reset()" class="mt-4 bg-neobit-greenlight rounded-xl text-neobit-green py-2.5 w-full text-[15px] font-bold">CANCEL</button> -->
      </div>
      <!-- Deposit address -->
      <div class="box relative" v-else-if="depositStep > 1 && depositStep < 6">
        <!-- Validating -->
        <div
          v-if="depositStep === 3"
          class="absolute inset-0 w-full h-full backblur bg-white bg-opacity-90 rounded-lg flex flex-col items-center justify-center"
        >
          <simple-spinner></simple-spinner>
          <div class="mt-5 text-xl font-bold text-neobit-black">WORKING</div>
          <div class="mt-1 text-sm text-neobit-black font-medium opacity-50">Validating deposit...</div>
          <div class="w-full px-6">
            <button
              class="mt-6 rounded-xl py-2.5 text-center text-[15px] font-bold bg-neobit-green text-white w-full"
              @click="setDepositStep(1)"
            >
              CANCEL
            </button>
          </div>
        </div>
        <!-- Error -->
        <div class="absolute inset-0 w-full h-full backblur bg-white bg-opacity-90 rounded-lg flex flex-col items-center justify-between pt-16 pb-5 px-4" v-if="depositStep === 4">
          <div class="flex flex-col items-center">
            <img src="/img/icons/error.svg" class="w-[104px] h-[104px]"/>
            <div class="mt-5 text-xl font-bold text-neobit-black">NO DEPOSIT FOUND</div>
            <div class="mt-1 text-sm text-neobit-black font-medium opacity-50">Please try again...</div>
          </div>
          <button
            class="mt-6 rounded-xl py-2.5 text-center text-[15px] font-bold bg-neobit-green text-white w-full"
            @click="validateDeposit"
          >
            TRY AGAIN
          </button>
        </div>
        <!-- Success -->
        <div
          v-if="depositStep === 5"
          class="absolute inset-0 w-full h-full backblur bg-white bg-opacity-90 rounded-lg flex flex-col items-center justify-center pb-5 px-4 pt-4"
        >
          <div class="flex flex-col items-center w-full">
            <img src="/img/icons/success.svg" class="w-[104px] h-[104px]"/>
            <div class="mt-5 text-xl font-bold text-neobit-black">SUCCESSFUL</div>
            <div class="mt-1 text-sm text-neobit-black font-medium opacity-50">Deposit validated!</div>
            <button
              class="mt-6 rounded-xl py-2.5 text-center text-[15px] font-bold bg-neobit-green text-white w-full"
              @click="setDepositStep(1)"
            >
              CONTINUE
            </button>
          </div>
        </div>
        <div class="my-3 mx-9 border-t border-neobit-green border-opacity-20"></div>
        <div class="text-[15px] font-medium leading-[22px] text-neobit-black">1: Send the amount of {{ depositToken.symbol }} you’d like to deposit to following deposit address:</div>
        <div class="text-red-500 text-xs">Minimum {{ depositToken.minAmount }} {{ depositToken.symbol }}</div>
        <div class="text-xs mt-2">Your personal {{ depositToken.chain }} wallet:</div>
        <div class="rounded-[5px] w-full bg-neobit-greenlight bg-opacity-25 border border-neobit-greenlight px-3 py-2 flex items-center">
          <span class="text-[15px] font-medium text-neobit-black w-[90%] break-words">{{ depositAddress }}</span>
          <img src="/img/icons/copy-green.svg" class="w-6 h-6" @click="copyAddress"/>
        </div>
        <div class="text-[15px] font-medium leading-[22px] text-neobit-black mt-6">2: After successfully sending your {{ depositToken.symbol }}, please validate the deposit to proceed.</div>
        <div class="text-[10px]">You can always come back to this step and the tokens sent to the address above is credited to your account</div>
        <button
          class="mt-3 rounded-xl py-2.5 text-center text-[15px] font-bold text-white w-full bg-neobit-green"
          @click="validateDeposit"
        >
          VALIDATE DEPOSIT
        </button>
        <button class="mt-4 bg-neobit-greenlight rounded-xl text-neobit-green py-2.5 w-full text-[15px] font-bold" @click="depositStep -= 1">BACK</button>
      </div>
      <!-- Convert to bneo -->
      <div class="box relative" v-else-if="depositStep >= 6">
        <!-- Validating -->
        <div class="z-10 absolute inset-0 w-full h-full backblur bg-white bg-opacity-90 rounded-lg flex flex-col items-center pt-16" v-if="depositStep === 7">
          <simple-spinner></simple-spinner>
          <div class="mt-5 text-xl font-bold text-neobit-black">WORKING</div>
          <div class="mt-1 text-sm text-neobit-black font-medium opacity-50">Sending tokens...</div>
        </div>
        <!-- Error -->
        <div class="z-10 absolute inset-0 w-full h-full backblur bg-white bg-opacity-90 rounded-lg flex flex-col items-center justify-between pt-16 pb-5 px-4" v-if="depositStep === 8">
          <div class="flex flex-col items-center">
            <img src="/img/icons/error.svg" class="w-[104px] h-[104px]"/>
            <div class="mt-5 text-xl font-bold text-neobit-black">TRANSACTION FAILED</div>
            <div class="mt-1 text-sm text-neobit-black font-medium opacity-50">Please try again...</div>
          </div>
          <button
            class="mt-6 rounded-xl py-2.5 text-center text-[15px] font-bold bg-neobit-green text-white w-full"
            @click="convert"
          >
            TRY AGAIN
          </button>
        </div>
        <!-- Success -->
        <div class="z-10 absolute inset-0 w-full h-full backblur bg-white bg-opacity-90 rounded-lg flex flex-col items-center justify-between pt-16 pb-5 px-4" v-if="depositStep === 9">
          <div class="flex flex-col items-center">
            <img src="/img/icons/success.svg" class="w-[104px] h-[104px]"/>
            <div class="mt-5 text-xl font-bold text-neobit-black">SUCCESSFUL</div>
            <div class="mt-1 text-sm text-neobit-black font-medium opacity-50">Tokens sent!</div>
          </div>
          <button
            class="mt-6 rounded-xl py-2.5 text-center text-[15px] font-bold bg-neobit-green text-white w-full"
            @click="reset()"
          >
            DONE
          </button>
        </div>
        <div class="flex items-center justify-between">
          <span class="text-lg text-neobit-green font-bold">SEND TO NEOSOCCER</span>
          <span class="text-[13px] font-semibold text-neobit-black">3/3</span>
        </div>
        <div v-if="loadingQuote">
          <div class="flex justify-center mt-6">Getting new quote</div>
          <simple-spinner></simple-spinner>
        </div>
        <div v-if="!loadingQuote">
          <div class="my-3 mx-9 border-t border-neobit-green border-opacity-20"></div>
          <div class="text-[15px] font-medium text-neobit-black">Convert from:</div>
          <div class="border-2 border-neobit-greenlight bg-neobit-greenlight bg-opacity-25 rounded-[5px] flex px-3.5 mt-3">
            <div class="flex-grow border-r-2 border-neobit-greenlight py-2.5">
              <sexy-number
                :number="depositAmount / (10 ** depositToken.decimals)"
                :decimals="8"
                class="text-[15px] font-medium"
              />
            </div>
            <div class="w-[115px] flex items-center pl-2.5 py-2.5">
              <img :src="tokenImage(depositToken.symbol)" class="w-6 h-6"/>
              <span class="ml-2 text-[15px] font-medium text-neobit-black">{{ depositToken.symbol }}</span>
            </div>
          </div>
          <div class="text-sm font-medium text-neobit-black mt-6">Convert to this quoted amount, it is added to your game balance once when you click the button below</div>
          <div class="border-2 border-neobit-greenlight bg-neobit-greenlight bg-opacity-25 rounded-[5px] flex px-3.5 mt-3">
            <div class="flex-grow border-r-2 border-neobit-greenlight py-2.5 flex items-center justify-between pr-2.5">
              <sexy-number
                :number="parseFloat(quoteAmount.toFixed(4))"
                :decimals="4"
                class="text-[15px] font-medium"
              />
              <circular-progress-bar :progress="quoteProgress" @click="quoteProgress=0; getQuotes()"/>
            </div>
            <div class="w-[115px] flex items-center pl-2.5 py-2.5">
              <img :src="tokenImage('bNEO')" class="w-6 h-6"/>
              <span class="ml-2 text-[15px] font-medium text-neobit-black">bNEO</span>
            </div>
          </div>
          <button
            class="mt-6 rounded-xl py-2.5 text-center text-[15px] font-bold text-white w-full bg-neobit-green"
            @click="convert"
          >
            CONVERT AND ADD TO BALANCE
          </button>
        </div>
      </div>
    </div>
    <div v-if="mode === 'withdraw'" class="p-4">
      <!-- Choose withdraw amount -->
      <div class="box" v-if="withdrawStep === 1">
        <div class="flex items-center justify-between">
          <span class="text-lg text-neobit-green font-bold">CHOOSE WITHDRAW AMOUNT</span>
          <span class="text-[13px] font-semibold text-neobit-black">1/3</span>
        </div>
        <div class="text-[13px]">Withdrawals can take up to <span class="text-red-600 font-bold">48h</span></div>
        <div class="text-[13px]">Withdrawals has a <span class="text-red-600">0,1 bNEO fee</span></div>
        <div class="my-3 mx-9 border-t border-neobit-green border-opacity-20"></div>
        <div class="text-[15px] font-medium text-neobit-black">Choose how much you’d like to withdraw:</div>
        <div class="border-2 border-neobit-greenlight bg-neobit-greenlight bg-opacity-25 rounded-[5px] flex mt-3">
          <div class="flex-1 border-r-2 border-neobit-greenlight py-2.5 flex items-center justify-between pr-3.5 pl-3.5">
            <input class="bg-transparent w-[120px] outline-none text-neobit-black text-[15px] font-medium" placeholder="0" v-model="withdrawAmount"/>
            <button class="text-[13px] font-medium text-neobit-green" @click="setMax">MAX</button>
          </div>
          <token-select
            class="w-[160px] pl-1 pr-1 flex items-center"
            v-model:token="withdrawToken"
            :withdrawOnly="true"
            :defaultToken="withdrawToken.symbol"
          />
        </div>
        <p class="text-red-500 text-[14px] mt-1 font-bold" v-if="store.state.userStore.balance < 10000000">You don't have enough bNEO to cover fees</p>
        <button
          class="mt-6 rounded-xl py-2.5 text-center text-[15px] font-bold text-white w-full"
          :class="Number(withdrawAmount) > 0 && store.state.userStore.balance > 10000000 ? 'bg-neobit-green' : 'bg-neobit-black bg-opacity-25'"
          :disabled="Number(withdrawAmount) === 0 && store.state.userStore.balance < 10000000"
          @click="withdrawStep += 1"
        >
          NEXT STEP
        </button>
      </div>
      <div class="box" v-else-if="withdrawStep === 2">
        <div class="flex items-center justify-between">
          <span class="text-lg text-neobit-green font-bold">SET WITHDRAW ADDRESS</span>
          <span class="text-[13px] font-semibold text-neobit-black">2/3</span>
        </div>
        <div class="my-3 mx-9 border-t border-neobit-green border-opacity-20"></div>
        <div class="text-[15px] font-medium text-neobit-black">Enter your Neo N3 address to which you’d like to withdraw {{ withdrawAmount }} {{ withdrawToken.symbol }}:</div>
        <div class="text-red-600 mt-2">DO NOT USE AN EXCHANGE ADDRESS</div>
        <div class="border-2 border-neobit-greenlight bg-neobit-greenlight bg-opacity-25 rounded-[5px] flex px-3.5 mt-3 py-2">
          <input placeholder="Enter your Neo N3 address..." class="text-[15px] w-full font-medium text-neobit-black bg-transparent outline-none" v-model="withdrawAddress"/>
        </div>
        <div v-if="withdrawAddressValidation" class="mt-3 text-[#E33A3A] text-[15px] font-medium">
          {{ withdrawAddressValidation }}
        </div>
        <button
          class="mt-6 rounded-xl py-2.5 text-center text-[15px] font-bold text-white w-full"
          :class="withdrawAddress ? 'bg-neobit-green' : 'bg-neobit-black bg-opacity-25'"
          :disabled="!withdrawAddress"
          @click="validateAddress"
        >
          NEXT STEP
        </button>
        <button @click="withdrawStep -= 1" class="mt-4 bg-neobit-greenlight rounded-xl text-neobit-green py-2.5 w-full text-[15px] font-bold">BACK</button>
      </div>
      <div class="box relative" v-else-if="withdrawStep > 2">
        <!-- Validating -->
        <div class="absolute inset-0 w-full h-full backblur bg-white bg-opacity-90 rounded-lg flex flex-col items-center pt-16" v-if="withdrawStep === 4">
          <simple-spinner></simple-spinner>
          <div class="mt-5 text-xl font-bold text-neobit-black">WORKING</div>
          <div class="mt-1 text-sm text-neobit-black font-medium opacity-50">Sending withdraw request</div>
        </div>
        <!-- Error -->
        <div class="absolute inset-0 w-full h-full backblur bg-white bg-opacity-90 rounded-lg flex flex-col items-center justify-between pt-16 pb-5 px-4" v-if="withdrawStep === 5">
          <div class="flex flex-col items-center">
            <img src="/img/icons/error.svg" class="w-[104px] h-[104px]"/>
            <div class="mt-5 text-xl font-bold text-neobit-black">TRANSACTION FAILED</div>
            <div class="mt-1 text-sm text-neobit-black font-medium opacity-50">Please try again...</div>
          </div>
          <button
            class="mt-6 rounded-xl py-2.5 text-center text-[15px] font-bold bg-neobit-green text-white w-full"
            @click="confirmWithdrawal"
          >
            TRY AGAIN
          </button>
        </div>
        <!-- Success -->
        <div class="absolute inset-0 w-full h-full backblur bg-white bg-opacity-90 rounded-lg flex flex-col items-center justify-between pt-16 pb-5 px-4" v-if="withdrawStep === 6">
          <div class="flex flex-col items-center">
            <img src="/img/icons/success.svg" class="w-[104px] h-[104px]"/>
            <div class="mt-5 text-xl font-bold text-neobit-black">SUCCESSFUL</div>
            <div class="mt-1 text-sm text-neobit-black font-medium opacity-50">Withdraw request sent!</div>
          </div>
          <button
            class="mt-6 rounded-xl py-2.5 text-center text-[15px] font-bold bg-neobit-green text-white w-full"
            @click="reset()"
          >
            DONE
          </button>
        </div>
        <div class="flex items-center justify-between">
          <span class="text-lg text-neobit-green font-bold">CONFIRM YOUR WITHDRAW</span>
          <span class="text-[13px] font-semibold text-neobit-black">3/3</span>
        </div>
        <div class="my-3 mx-9 border-t border-neobit-green border-opacity-20"></div>
        <div class="text-[15px] font-medium text-neobit-black">Please carefully verify your withdraw details before proceeding!</div>
        <div class="mt-6 text-[15px] font-bold text-neobit-black">Withdraw Amount:</div>
        <div class="text-[15px] font-medium text-neobit-black">
          {{ withdrawAmount }} {{ withdrawToken.symbol }}
        </div>
        <div class="mt-6 text-[15px] font-bold text-neobit-black">Withdraw Address:</div>
        <div class="text-[15px] font-medium text-neobit-black">
          {{ withdrawAddress }}
        </div>
        <button
          class="mt-6 rounded-xl py-2.5 text-center text-[15px] font-bold text-white w-full bg-neobit-green"
          @click="confirmWithdrawal"
        >
          CONFIRM AND WITHDRAW
        </button>
        <button @click="withdrawStep -= 1" class="mt-4 bg-neobit-greenlight rounded-xl text-neobit-green py-2.5 w-full text-[15px] font-bold">BACK</button>
      </div>
    </div>
  </app-interface>
</template>

<script>
import {
  computed,
  ref,
  watch,
} from 'vue';
import AppInterface from '@/os/components/templates/AppInterface.vue';
import backendAPI from '@/os/APIs/backendAPI';
import store from '@/store';
import SimpleSpinner from '@/os/components/SimpleSpinner.vue';
import CircularProgressBar from '@/os/components/CircularProgressBar.vue';
import utilsAPI from '@/os/APIs/utilsAPI';
import tokens from '@/os/coreData/tokens';
import BigNumber from 'bignumber.js';

export default {
  name: 'Wallet',
  components: {
    AppInterface,
    SimpleSpinner,
    CircularProgressBar,
  },
  setup() {
    const mode = ref('wallet');
    const depositStep = ref(1);
    const withdrawStep = ref(1);
    const depositToken = ref(null);
    const withdrawToken = ref({ symbol: 'bNEO', decimals: 8 });
    const balance = computed(() => store.state.userStore.balance);
    const userTokens = computed(() => store.state.userStore.tokens);
    const deposits = computed(() => store.state.userStore.deposits);
    const depositAddress = ref('Not set yet');
    const withdrawAddress = ref(null);
    const withdrawAddressValidation = ref(null);
    const depositAmount = ref(0);
    const withdrawAmount = ref(null);
    const quoteAmount = ref(0);
    const quoteProgress = ref(0);
    const loadingQuote = ref(false);
    let quoteTimer = null;
    const currentDepositStep = computed(() => depositStep.value);

    function copyAddress() {
      navigator.clipboard.writeText(depositAddress.value);
      window.toast.success('Copied');
    }

    function reset() {
      depositStep.value = 1;
      withdrawStep.value = 1;
      clearInterval(quoteTimer);
    }

    function getNavTabStyle(tabMode) {
      if (mode.value === tabMode) return 'bg-neobit-green text-white';
      return 'bg-neobit-greenlight text-neobit-green';
    }

    function setDepositStep(step) {
      depositStep.value = step;
      store.dispatch('userStore/getUserInfo');
    }

    async function getDepositAddress() {
      depositAddress.value = await backendAPI.userGetWallet(depositToken.value.chain);
      depositStep.value += 1;
    }

    function setMax() {
      withdrawAmount.value = parseFloat((userTokens.value[withdrawToken.value.symbol] / (10 ** withdrawToken.value.decimals)).toFixed(4));
      if (withdrawToken.value.symbol === 'bNEO') {
        withdrawAmount.value = parseFloat(((balance.value - 10000000) / 100000000).toFixed(4));
      }
      if (withdrawAmount.value < 0) {
        withdrawAmount.value = 0;
      }
    }

    async function validateDeposit() {
      depositStep.value = 3;
      let completed = false;
      let tries = 0;

      while (!completed && tries < 10) {
        try {
          // eslint-disable-next-line no-await-in-loop
          await backendAPI.userCheckDeposit(depositToken.value.chain);
          completed = true;
          depositStep.value = 5;
          depositAmount.value = deposits.value[depositToken.value.chain][depositToken.value.symbol];
          // eslint-disable-next-line no-await-in-loop
          await store.dispatch('userStore/getUserInfo');
        } catch {
          console.log('No deposit found, checking next block');
          tries += 1;
        }
        if (tries < 10 && currentDepositStep.value === 3) {
          // eslint-disable-next-line no-await-in-loop
          await utilsAPI.sleep(15000);
        }
      }

      if (!completed) {
        depositStep.value = 4;
        console.log('Validate Deposit Error');
      }

      await store.dispatch('userStore/getUserInfo');
    }

    function tokenImage(token) {
      return `/img/tokens/circle/${token}.svg`;
    }

    async function getQuotes() {
      loadingQuote.value = true;
      try {
        const quoteRes = await backendAPI.userQuote(depositToken.value.chain, depositToken.value.symbol, depositAmount.value);
        quoteAmount.value = parseFloat(quoteRes);
        // Update Quote Amount here
      } catch (error) {
        console.log(error, 'Quote Error');
      }
      loadingQuote.value = false;
    }

    function incQuoteProgress() {
      quoteProgress.value += 1;
      if (quoteProgress.value >= 100) {
        quoteProgress.value = 0;
        getQuotes();
      }
    }

    async function openConvert(token = false) {
      if (token) {
        depositToken.value = token;
      }
      depositAmount.value = store.state.userStore.deposits[token.chain][token.symbol];
      depositStep.value = 6;
      mode.value = 'deposit';
      await getQuotes();
      if (quoteTimer) clearInterval(quoteTimer);
      quoteTimer = setInterval(() => incQuoteProgress(), 1000);
    }

    async function convert() {
      clearInterval(quoteTimer);
      depositStep.value = 7;
      try {
        await backendAPI.userConvert(depositToken.value.chain, depositToken.value.symbol, depositAmount.value * (10 ** depositToken.value.decimals), parseFloat(quoteAmount.value.toFixed(4)) * (10 ** 8));
        depositStep.value = 9;
      } catch (error) {
        depositStep.value = 8;
        console.log(error, 'Convert Error');
      }
    }

    function validateAddress() {
      if (withdrawAddress.value[0] !== 'N') {
        withdrawAddressValidation.value = 'Incorrect address format, a Neo N3 address always starts with the letter N.';
        return;
      }
      if (withdrawAddress.value.length !== 34) {
        withdrawAddressValidation.value = 'Incorrect address format, a Neo N3 address always contains 34 characters.';
        return;
      }
      withdrawStep.value += 1;
    }

    async function openWithdraw(token) {
      withdrawToken.value = token;
      mode.value = 'withdraw';
    }

    async function confirmWithdrawal() {
      withdrawStep.value = 4;
      try {
        const amount = new BigNumber(withdrawAmount.value).shiftedBy(withdrawToken.value.decimals).toNumber();
        await backendAPI.userWithdraw(amount, withdrawAddress.value, withdrawToken.value.symbol);
        withdrawStep.value = 6;
        await store.dispatch('userStore/getUserInfo');
        withdrawAmount.value = 0;
        withdrawToken.value = 'bNEO';
        mode.value = 'wallet';
      } catch (error) {
        withdrawStep.value = 5;
        console.log(error, 'Withdraw Error');
      }
    }

    watch(withdrawToken, () => {
      withdrawAmount.value = 0;
    });

    return {
      mode,
      store,
      depositStep,
      withdrawStep,
      depositToken,
      balance,
      depositAddress,
      copyAddress,
      tokens,
      withdrawAddress,
      withdrawAddressValidation,
      getNavTabStyle,
      getDepositAddress,
      reset,
      validateDeposit,
      tokenImage,
      setDepositStep,
      quoteAmount,
      depositAmount,
      withdrawAmount,
      quoteProgress,
      loadingQuote,
      openWithdraw,
      openConvert,
      convert,
      getQuotes,
      validateAddress,
      confirmWithdrawal,
      setMax,
      withdrawToken,
    };
  },
};
</script>

<style scoped>
.box {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.06);
  border-radius: 8px;
  padding: 12px 18px;
}

.backblur {
  backdrop-filter: blur(8px);
}
</style>
