<template>
  <simple-modal
    modalName="mint-modal"
    :bg="modalBg"
    width="100%"
    height="100%"
  >
    <div class="absolute inset-0 flex justify-center items-center">
      <div class="absolute top-3 right-3 text-black z-10" @click="closeModal()">
        <img :src="`/img/ui/cross-white.svg`" width="26"/>
      </div>
      <div>
        <div class="flex items-center font-semibold text-2xl justify-center mx-auto w-[240px] mt-16">
          <div v-show="!buy" class="flex capitalize">Buy {{itemType}}</div>
          <div v-show="buy" class="h-7"></div>
        </div>
        <div>

        </div>
        <div class="flex justify-center w-full -mt-4">
          <flip-image class="h-[340px] w-[240px]"></flip-image>
        </div>
        <div @click="mint()"
             v-if="!buy"
             class="border mt-36 text-black flex items-center justify-center mx-auto bg-white border-gray-500 rounded-full p-4 mt-4 w-52 flex cursor-pointer">
          <div class="flex-grow">Confirm</div>
          <div>
            <sexy-number
              v-if="itemType !== 'consumable'"
              :number="price"
              :decimals="2"
              class="text-center w-full"
            />
            <sexy-number
              v-if="itemType === 'consumable'"
              :number="price"
              :decimals="0"
              class="text-center w-full"
            />
          </div>
          <img v-if="itemType !== 'consumable'" src="/img/tokens/transparent/bNEO.svg" class="w-6 h-6"/>
          <img v-if="itemType === 'consumable'" src="/img/tokens/transparent/BITS.svg" class="w-6 h-6"/>
        </div>
      </div>
    </div>
  </simple-modal>
</template>

<script>
import SimpleModal from '@/os/components/modals/SimpleModal.vue';
import backendAPI from '@/os/APIs/backendAPI';
import store from '@/store';
import { computed, ref } from 'vue';
import FlipImage from '@/apps/globalComponents/components/FlipImage.vue';
import osAPI from '@/os/APIs/osAPI';

export default {
  name: 'mint-modal',
  components: { FlipImage, SimpleModal },
  setup() {
    const modalBg = 'rgba(0,0,0,0)';
    const buy = ref(false);
    const itemType = computed(() => store.state.osStore.modalData);
    const playerPrice = computed(() => store.state.userStore.playerPrice);
    const managerPrice = computed(() => store.state.userStore.managerPrice);
    const stadiumPrice = computed(() => store.state.userStore.stadiumPrice);
    const consumablePrice = 10;
    const price = computed(() => {
      if (itemType.value === 'player') {
        return playerPrice.value;
      }
      if (itemType.value === 'manager') {
        return managerPrice.value;
      }
      if (itemType.value === 'consumable') {
        return consumablePrice;
      }
      return stadiumPrice.value;
    });

    function closeModal() {
      osAPI.triggerModal('');
    }

    async function mint() {
      buy.value = true;
      try {
        const clubId = store.state.userStore.club.id;
        const data = await backendAPI.itemMint(itemType.value, clubId);
        window.toast.success('Minted successfully');
        await store.dispatch('userStore/getUserInfo');
        await store.dispatch('userStore/getClubItems', { clubId });
        osAPI.triggerModal('item-modal', data);
        buy.value = false;
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        buy.value = false;
        throw err;
      }
    }

    return {
      itemType,
      modalBg,
      mint,
      closeModal,
      price,
      buy,
    };
  },
};
</script>

<style scoped>
.bg-pink-linear {
  background: linear-gradient(134.69deg, #c63bc9 0%, #642ffa 100%), rgba(255, 255, 255, 0.06);
}

.modal-title {
  letter-spacing: 0.02em;
  background: linear-gradient(134.69deg, #c63bc9 0%, #642ffa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  mix-blend-mode: normal;
}

.border-button {
  border: solid 3px transparent;
  background-image: linear-gradient(#1f1a22, #1f1a22), linear-gradient(to left, #642ffa 0%, #c63bc9 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.nice-scroll::-webkit-scrollbar {
  width: 7px;
}

.nice-scroll::-webkit-scrollbar-track {
  background: #00000000;
  border-radius: 5px;
}

.nice-scroll::-webkit-scrollbar-thumb {
  background: #80808766;
  border-radius: 5px;
}

.nice-scroll::-webkit-scrollbar-thumb:hover {
  background: #808087;
  cursor: pointer;
}
</style>
