<template>
  <router-view v-if="path === '/'"/>
  <div
    v-else
    class="flex-1 flex bg-[#00AF92] bg-football-field"
  >
    <div
      class="flex-1 flex md:items-center md:justify-center"
    >
      <div class="flex-1 md:flex-none md:w-[400px] md:h-[800px] md:rounded-2xl shadow-phone overflow-hidden">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import store from '@/store';

export default {
  name: 'App',
  setup() {
    const route = useRoute();
    const path = computed(() => route.path);
    let getLiveInstance = null;

    async function getLiveData() {
      await store.dispatch('userStore/getLiveMatches');
    }

    onBeforeMount(() => {
      getLiveData();
      getLiveInstance = setInterval(() => getLiveData(), 1000 * 60);
    });

    onBeforeUnmount(() => {
      clearInterval(getLiveInstance);
    });

    return {
      path,
    };
  },
};
</script>

<style>
body {
  background-color: #000;
  overflow-y: scroll;
}

.bg-football-field {
  background-image: url('/img/ui/neo-soccer-bg.svg');
  background-size: 230%;
  background-position: center center;
}

.shadow-phone {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1), 4px 4px 200px rgba(0, 0, 0, 0.25);
}

</style>
