<template>
  <div class="mx-4 bg-white px-4 py-3 mt-4 rounded-lg shadow relative">
    <div class="z-10 absolute inset-0 w-full h-full rounded-lg blur-back flex flex-col items-center justify-center" v-if="loading">
      <simple-spinner></simple-spinner>
      <span class="font-bold text-xl mt-5 text-neobit-black tracking-wide">PREPARING MATCH</span>
      <span class="text-sm font-medium tracking-wide text-neobit-black mt-1">Players are warming up...</span>
    </div>
    <div class="text-lg font-bold text-neobit-green tracking-wide">PLAY FRIENDLY MATCH</div>
    <div class="mx-4 border-t border-neobit-green border-opacity-10 my-3"></div>
    <div class="text-[15px] font-medium text-neobit-black">Selected Opponent:</div>
    <div class="mt-2 border-neobit-greenlight border-2 rounded-lg p-2 flex items-center gap-2">
      <club-logo :club-id="globalStore.friendlyMatchOpponent.id" class="w-[42px] h-[42px]" />
      <span class="text-[17px] font-semibold text-neobit-black">{{ globalStore.friendlyMatchOpponent.name }}</span>
    </div>
    <div class="text-[15px] font-medium text-neobit-black mt-4">Match Cost:</div>
    <div class="mt-2 border-neobit-greenlight border-2 rounded-lg p-2 flex items-center justify-between">
      <sexy-number
        :number="0.2"
        :decimals="1"
        class="text-[15px] font-medium text-neobit-black"
      />
      <div class="flex items-center gap-1">
        <img src="/img/tokens/transparent/bNEO.svg" class="w-8 h-8" />
        <span class="text-[15px] font-medium text-neobit-black">bNEO</span>
      </div>
    </div>
    <button class="mt-6 rounded-xl bg-neobit-green w-full py-2.5 text-white text-[15px] font-bold" @click="confirm()">
      CONFIRM AND PLAY
    </button>
    <button @click="$emit('close')" class="mt-4 rounded-xl bg-neobit-greenlight py-2.5 text-neobit-green text-[15px] font-bold tracking-wide w-full">CANCEL</button>
  </div>
</template>

<script>

import { ref } from 'vue';
import globalStore from '@/os/utils/global';
import ClubLogo from '@/apps/globalComponents/components/clubLogo.vue';
import SimpleSpinner from '@/os/components/SimpleSpinner.vue';
import store from '@/store';
import backendAPI from '@/os/APIs/backendAPI';

export default {
  name: 'play-modal',
  components: {
    ClubLogo,
    SimpleSpinner,
  },
  setup(props, { emit }) {
    const loading = ref(false);

    async function confirm() {
      loading.value = true;
      try {
        await backendAPI.clubPlayFriendly(store.state.userStore.club.id, globalStore.friendlyMatchOpponent.id);
        window.toast.success('Success');
        await store.dispatch('userStore/getUserInfo');
        emit('close');
      } catch (error) {
        console.log(error, 'Practice match error');
        window.toast.error('Failed to play friendly match');
      }
      loading.value = false;
    }

    return {
      loading,
      globalStore,
      confirm,
    };
  },
};
</script>

<style scoped>
.blur-back {
  background: rgba(255, 255, 255, 0.82);
  backdrop-filter: blur(6px);
}

.nice-scroll::-webkit-scrollbar {
  width: 7px;
}

.nice-scroll::-webkit-scrollbar-track {
  background: #00000000;
  border-radius: 5px;
}

.nice-scroll::-webkit-scrollbar-thumb {
  background: #80808766;
  border-radius: 5px;
}

.nice-scroll::-webkit-scrollbar-thumb:hover {
  background: #808087;
  cursor: pointer;
}
</style>
