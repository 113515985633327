<template>
  <div class="relative p-2 flex flex-col bg-white rounded-2xl mx-4">
    <div class="flex items-center mb-2">
      <div v-if="!isSponsor" class="flex flex-grow text-[18px] font-bold mt-1 text-neobit-green uppercase">STONKS</div>
      <div v-if="isSponsor" class="flex flex-grow text-[18px] font-bold mt-1 text-neobit-green uppercase">SPONSOR</div>
      <div class="" @click="$emit('close')">
        <img :src="`/img/ui/cross.svg`" width="26"/>
      </div>
    </div>
    <div>
      <div v-if="!isSponsor" class="text-[12px]">You’re about to add {{ item.gain }} Stonks to your account</div>
      <div v-if="isSponsor" class="text-[12px] w-64 text-center">
        <div>You’re about to add</div>
        <div class="text-[15px]">{{ item.gain }} {{ sponsor }}</div>
        <div>to your account</div>
      </div>
    </div>
    <div class="confirm flex items-center justify-center text-[15px] mt-4" @click="$emit('useStonks', 0, '')">CONFIRM</div>
    <div class="cancel flex items-center justify-center text-[15px] mt-3" @click="$emit('close')">CANCEL</div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'UseStonks',
  props: {
    item: {
      type: Object,
    },
  },
  setup(props) {
    const isSponsor = computed(() => props.item.for_skill.startsWith('sponsor'));
    const sponsor = computed(() => props.item.for_skill.replace('sponsor-', ''));
    return {
      isSponsor,
      sponsor,
    };
  },
};
</script>

<style scoped>
.input {
  border: 2px solid #C8EBE0;
}

.confirm {
  background: #00AF92;
  border-radius: 12px;
  height: 46px;
  color: #FFFFFF;
}

.cancel {
  background: #C8EBE0;
  border-radius: 12px;
  height: 46px;
  color: #00AF92;
}
</style>
