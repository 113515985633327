<template>
  <div class="flex items-center">
    <div class="mr-1">
      <img :src="leagues[leagueId].logo" class="w-5 h-5" />
    </div>
    <div class="text-[15px] font-bold text-neobit-black">{{ leagues[leagueId].name }}</div>
  </div>
</template>

<script>
import leagues from '@/os/coreData/leagues';

export default {
  name: 'leagueName',
  props: {
    leagueId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      leagues,
    };
  },
};
</script>

<style scoped>

</style>
