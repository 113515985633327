<template>
  <spinner v-if="loading"></spinner>
  <div
    v-if="!loading"
    class="flex flex-col bg-[#ECECEC] h-full md:rounded-2xl"
  >
    <div class="flex-grow relative m-2 mb-0">
      <div class="px-2 py-2 absolute inset-0 overflow-auto nice-scroll">
        <div class="info-box w-full tracking-wide">
          <div class="px-4 py-2 bg-neobit-greenlight text-[13px] font-bold text-neobit-green">
            CLUB INFO
          </div>
          <div class="p-3">
            <div class="w-full flex flex-col p-3 border-2 relative border-neobit-greenlight border-opacity-30 items-center">
              <club-logo :club-id="clubData.id" class="w-16 h-16 mt-4"></club-logo>
              <span class="text-[20px] font-semibold text-neobit-black flex-grow mt-4">{{ clubData.name }}</span>
            </div>
            <div class="mt-2 flex items-center flex-wrap gap-2 tracking-wide">
              <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
                <span class="">Matches</span>
                <span>{{ clubStats?.total_matches }}</span>
              </div>
              <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
                <span class="">W/L Ratio</span>
                <span>{{ (clubStats?.won / clubStats?.total_matches * 100).toFixed(2) }}%</span>
              </div>
              <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
                <span class="">Players</span>
                <span>{{ clubStats?.players }}</span>
              </div>
              <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
                <span class="">Avg. Player Age</span>
                <span>{{ clubStats?.average_player_age }}</span>
              </div>
              <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
                <span class="">Managers</span>
                <span>{{ clubStats?.managers }}</span>
              </div>
              <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
                <span class="">Stadiums</span>
                <span>{{ clubStats?.stadiums }}</span>
              </div>
              <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
                <span class="">Season cost</span>
                <div class="flex items-center">
                  <img src="/img/tokens/transparent/bNEO.svg" class="w-5 h-5"/>
                  <span v-if="!clubData.owner_id.startsWith('ghost')">{{ (clubStats?.upkeep / (10 ** 8)).toFixed(4) }}</span>
                  <span v-if="clubData.owner_id.startsWith('ghost')">-</span>
                </div>
              </div>
              <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
                <span class="">Revenue</span>
                <div class="flex items-center">
                  <img src="/img/tokens/transparent/bNEO.svg" class="w-5 h-5"/>
                  <span v-if="!clubData.owner_id.startsWith('ghost')">{{ ((clubStats?.total_match_income + clubStats?.total_ticket_income + clubStats?.prize) / (10 ** 8)).toFixed(4) }}</span>
                  <span v-if="clubData.owner_id.startsWith('ghost')">-</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-box w-full tracking-wide mt-3">
          <div class="px-4 py-2 bg-neobit-greenlight flex items-center justify-between text-neobit-green">
            <span class="text-[13px] font-bold">ACTIVE LINEUP</span>
            <button class="text-[11px] font-semibold" @click="$router.push({ name: 'ClubItemView', params: { clubId: clubId } })">VIEW ALL</button>
          </div>
          <div class="p-3">
            <div
              v-if="clubData.goalkeeper?.length > 0"
              class="bg-[#F8F8F8] pt-3 px-2 pb-4 rounded-lg"
            >
              <div class="mb-3 text-[13px] px-2 font-semibold">Goalkeeper</div>
              <item-card :player="getItemInfo(clubData.goalkeeper)" :isLineup="false"/>
            </div>
            <div
              class="bg-[#F8F8F8] pt-3 px-2 pb-4 rounded-lg flex flex-col gap-3 mt-2"
            >
              <div class="text-[13px] px-2 font-semibold">Defenders</div>
              <item-card v-if="clubData.defender1?.length > 0" :player="getItemInfo(clubData.defender1)" :isLineup="false"/>
              <item-card v-if="clubData.defender2?.length > 0" :player="getItemInfo(clubData.defender2)" :isLineup="false"/>
              <item-card v-if="clubData.defender3?.length > 0" :player="getItemInfo(clubData.defender3)" :isLineup="false"/>
              <item-card v-if="clubData.defender4?.length > 0" :player="getItemInfo(clubData.defender4)" :isLineup="false"/>
            </div>
            <div
              class="bg-[#F8F8F8] pt-3 px-2 pb-4 rounded-lg flex flex-col gap-3 mt-2"
            >
              <div class="text-[13px] px-2 font-semibold">Midfielders</div>
              <item-card v-if="clubData.midfielder1?.length > 0" :player="getItemInfo(clubData.midfielder1)" :isLineup="false"/>
              <item-card v-if="clubData.midfielder2?.length > 0" :player="getItemInfo(clubData.midfielder2)" :isLineup="false"/>
              <item-card v-if="clubData.midfielder3?.length > 0" :player="getItemInfo(clubData.midfielder3)" :isLineup="false"/>
            </div>
            <div
              class="bg-[#F8F8F8] pt-3 px-2 pb-4 rounded-lg flex flex-col gap-3 mt-2"
            >
              <div class="text-[13px] px-2 font-semibold">Attackers</div>
              <item-card v-if="clubData.attacker1?.length > 0" :player="getItemInfo(clubData.attacker1)" :isLineup="false"/>
              <item-card v-if="clubData.attacker2?.length > 0" :player="getItemInfo(clubData.attacker2)" :isLineup="false"/>
              <item-card v-if="clubData.attacker3?.length > 0" :player="getItemInfo(clubData.attacker3)" :isLineup="false"/>
            </div>
            <div
              v-if="clubData.manager?.length > 0"
              class="bg-[#F8F8F8] pt-3 px-2 pb-4 rounded-lg mt-2"
            >
              <div class="mb-3 text-[13px] px-2 font-semibold">Manager</div>
              <item-card :player="getItemInfo(clubData.manager)" :isLineup="false"/>
            </div>
            <div
              v-if="clubData.stadium?.length > 0"
              class="bg-[#F8F8F8] pt-3 px-2 pb-4 rounded-lg mt-2"
            >
              <div class="mb-3 text-[13px] px-2 font-semibold">Stadium</div>
              <item-card :player="getItemInfo(clubData.stadium)" :isLineup="false"/>
            </div>
          </div>
        </div>
        <div class="info-box w-full tracking-wide mt-3">
          <div class="px-4 py-2 bg-neobit-greenlight flex items-center justify-between text-neobit-green">
            <span class="text-[13px] font-bold">RECENT MATCHES</span>
          </div>
          <div class="p-2">
            <div class="bg-[#F8F8F8] rounded-lg px-1 py-0.5 tracking-wide" v-for="match in clubMatches?.slice(0, 5)" :key="match.id">
              <div class="mt-2 border-[#C8EBE052] border-2 rounded-lg px-1 py-1 flex items-center justify-center"
                   @click="$router.push({ name: 'MatchView', params: { match: match.id } })"
              >
                <span class="text-xs font-semibold w-[35%] text-right text-[10px] text-neobit-black">{{ store.state.userStore.clubNames[match.home] }}</span>
                <club-logo :club-id="match.home" class="w-5 h-5 ml-1"></club-logo>
                <span class="mx-2 font-bold text-center w-12 text-[10px] text-neobit-black">{{ match.home_score }} - {{ match.away_score }}</span>
                <club-logo :club-id="match.away" class="w-5 h-5 mr-1"></club-logo>
                <span class="text-xs font-semibold w-[35%] text-[10px] text-neobit-black">{{ store.state.userStore.clubNames[match.away] }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-[#ECECEC] md:rounded-b-2xl p-4 border-t-2 border-gray-300">
      <button
        @click="goBack"
        class="flex justify-center items-center w-full bg-green-400 rounded p-4"
      >
        <div class="mr-2">
          <img src="/img/ui/back-arrow.svg" width="15">
        </div>
        <div>BACK</div>
      </button>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import osAPI from '@/os/APIs/osAPI';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Spinner from '@/os/components/Spinner.vue';
import { tableShare } from '@/os/utils/config';
import backendAPI from '@/os/APIs/backendAPI';
import ClubLogo from '@/apps/globalComponents/components/clubLogo.vue';
import ItemCard from '@/apps/globalComponents/components/ItemCard.vue';

export default {
  name: 'club-overview',
  components: {
    ClubLogo,
    Spinner,
    ItemCard,
  },
  setup() {
    const loading = ref(false);
    const router = useRouter();
    const uploadOpen = ref(false);
    const route = useRoute();
    const clubData = ref({});
    const leagues = ref([]);
    const leagueId = computed(() => clubData.value?.league);
    const thisLeaguePrice = computed(() => store.state.userStore.leaguePrizes[leagueId.value] / 100000000);
    const clubStats = ref([]);
    const clubMatches = ref([]);
    const clubId = computed(() => route.params.clubId);
    const clubItems = ref([]);

    async function getData() {
      loading.value = true;
      if (Object.keys(store.state.userStore.clubNames).length < 1) {
        await store.dispatch('userStore/getClubGetNames');
      }
      clubData.value = await backendAPI.clubGet(clubId.value);
      clubStats.value = await backendAPI.clubGetStats(clubId.value);
      const data = await backendAPI.leagues(store.state.userStore.currentSeason, clubData.value.league);
      clubItems.value = await backendAPI.itemClubGetAll(clubId.value);
      leagues.value = data.league;
      clubMatches.value = await backendAPI.clubGetMatches(clubId.value, store.state.userStore.currentSeason);
      clubMatches.value = clubMatches.value.sort((b, a) => a.time - b.time);
      loading.value = false;
    }

    function getItemInfo(id) {
      const item = clubItems.value.find((club) => club.id === id);
      return item;
    }

    getData();

    function goBack() {
      const history = window.history.length;
      if (history > 2) {
        router.back();
      } else {
        router.push({ name: 'Overview' });
      }
    }

    return {
      store,
      loading,
      tableShare,
      thisLeaguePrice,
      goBack,
      clubStats,
      osAPI,
      uploadOpen,
      clubData,
      clubMatches,
      leagues,
      getItemInfo,
      clubId,
    };
  },
};
</script>

<style scoped>
.info-box {
  border-radius: 8px;
  overflow: hidden;
  background: white;
  color: black;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.06);
}

.overinfo-box {
  width: calc(50% - 4px);
}

.ranking-table th {
  font-size: 13px;
  font-weight: 600;
  color: #33333380;
  text-align: left;
}

.ranking-table td {
  font-size: 11px;
  font-weight: 600;
  color: #333333;
  padding: 4px 0px;
}

.w-fit {
  width: fit-content;
}
</style>
