import { createApp } from 'vue';
import VueToast from 'vue-toast-notification';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import 'vue-toast-notification/dist/theme-sugar.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@/os/style/index.css';
import '@/os/style/animations.css';

import BaseModal from '@/os/components/modals/BaseModal.vue';
import SimpleModal from '@/os/components/modals/SimpleModal.vue';
import SexyTooltip from '@/os/components/basic/SexyTooltip.vue';
import SexyNumber from '@/os/components/basic/SexyNumber.vue';
import SexyUsername from '@/os/components/basic/SexyUsername.vue';
import SexyButton from '@/os/components/basic/SexyButton.vue';
import TokenSelect from '@/os/components/basic/TokenSelect.vue';

import clickOutsideDirective from '@/os/directives/clickOutside';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueToast, { position: 'top' });

app.directive('click-outside', clickOutsideDirective);

app.component('base-modal', BaseModal);
app.component('simple-modal', SimpleModal);
app.component('sexy-tooltip', SexyTooltip);
app.component('sexy-number', SexyNumber);
app.component('sexy-username', SexyUsername);
app.component('sexy-button', SexyButton);
app.component('token-select', TokenSelect);

const vm = app.mount('#app');
window.toast = vm.$toast;
