<template>
  <div class="flex flex-col gap-3 bg-white p-3" v-if="rounds">
    <button
      @click="$router.push({ name: 'LiveMatch', params: { type: 'league', id: leagueId } })"
      v-if="nowLive"
      class="mb-3 bg-[#E80054] rounded-xl px-4 py-2.5 flex items-center justify-between text-[15px] font-bold tracking-wide text-white"
    >
      NOW LIVE - {{ formatCounter }}
      <i class="fas fa-chevron-right"></i>
    </button>
    <div class="px-2 py-3 bg-[#3333330F] rounded-lg">
      <div class="flex justify-center items-center text-center">
        <div @click="changeRound(-1)" class="cursor-pointer mr-2"><img src="/img/icons/arrow-right.svg" class="w-6 h-6 transform rotate-180"></div>
        <div class="font-semibold text-neobit-black">Round {{ currentRound }}</div>
        <div @click="changeRound(1)" class="cursor-pointer ml-2"><img src="/img/icons/arrow-right.svg" class="w-6 h-6"></div>
      </div>
      <div v-for="match in rounds[currentRound]" :key="match.id">
        <div class="mt-2 border-neobit-greenlight bg-white border-opacity-30 border-2 rounded-lg py-1 px-1" v-if="match.type === 'league'">

          <!--If the match is not been played or are not live-->

          <div
            v-if="!Object.keys(liveMatches).includes(match.id) && !Object.keys(match).includes('home_score')"
            class="py-1">
            <div class="flex flex-grow items-center justify-between text-[11px]">
              <div class="w-1/2 text-right mr-1 text-[11px] font-semibold text-neobit-black"
                   :class="[match.home === store.state.userStore.club.id ? 'text-green-600' : '']"
              >
                {{ store.state.userStore.clubNames[match.home] }}
              </div>
              <club-logo :club-id="match.home" class="w-5 h-5"></club-logo>
              <div class="w-[45px] text-center font-bold text-[11px]">
                <div v-show="!Object.keys(match).includes('home_score') && !Object.keys(liveMatches).includes(match.id)"
                     class="text-[8px]">
                  18:00<br><span class="text-[7px]">UTC</span>
                </div>
              </div>
              <club-logo :club-id="match.away" class="w-5 h-5"></club-logo>
              <div class="w-1/2 ml-1 text-[11px] font-semibold text-neobit-black"
                   :class="[match.away === store.state.userStore.club.id ? 'text-green-600' : '']"
              >
                {{ store.state.userStore.clubNames[match.away] }}
              </div>
            </div>
          </div>

          <!--If the match is live-->
          <router-link
            v-if="Object.keys(liveMatches).includes(match.id)"
            :to="{ name: 'LiveMatch', params: { type: 'league', id: leagueId } }"
            class="py-1 border-b border-gray-300">
            <div class="flex flex-grow items-center justify-between text-[11px]">
              <div class="w-1/2 text-right mr-1 text-[11px] font-semibold text-neobit-black"
                   :class="[match.home === store.state.userStore.club.id ? 'text-green-600' : '']"
              >
                {{ store.state.userStore.clubNames[match.home] }}
              </div>
              <club-logo :club-id="match.home" class="w-5 h-5"></club-logo>
              <div class="w-[45px] text-center font-bold text-[11px]">
                <div class="text-red-600 animate-pulse"
                     v-if="Object.keys(liveMatches).includes(match.id)"
                     @click="$router.push({ name: 'LiveMatch', params: { type: 'league', id: leagueId } })"
                >
                  {{ liveMatches[match.id].home_score }} : {{ liveMatches[match.id].away_score }}
                </div>
              </div>
              <club-logo :club-id="match.away" class="w-5 h-5"></club-logo>
              <div class="w-1/2 ml-1 text-[11px] font-semibold text-neobit-black"
                   :class="[match.away === store.state.userStore.club.id ? 'text-green-600' : '']"
              >
                {{ store.state.userStore.clubNames[match.away] }}
              </div>
            </div>
          </router-link>

          <!--If the match is completed-->
          <router-link
            v-if="!Object.keys(liveMatches).includes(match.id) && Object.keys(match).includes('home_score')"
            :to="{ name: 'MatchView', params: { match: match.id } }"
            class="py-1 border-b border-gray-300">
            <div class="flex flex-grow items-center justify-between text-[11px]">
              <div class="w-1/2 text-right mr-1 text-[11px] font-semibold text-neobit-black"
                   :class="[match.home === store.state.userStore.club.id ? 'text-green-600' : '']"
              >
                {{ store.state.userStore.clubNames[match.home] }}
              </div>
              <club-logo :club-id="match.home" class="w-5 h-5"></club-logo>
              <div class="w-[45px] text-center font-bold text-[11px]">
                <div v-show="!Object.keys(match).includes('home_score') && !Object.keys(liveMatches).includes(match.id)"
                     class="text-[8px]">
                  18:00<br><span class="text-[9px]">UTC</span>
                </div>
                <div class="text-red-600 animate-pulse"
                     v-if="Object.keys(liveMatches).includes(match.id)"
                     @click="$router.push({ name: 'LiveMatch', params: { type: 'league', id: leagueId } })"
                >
                  {{ liveMatches[match.id].home_score }} : {{ liveMatches[match.id].away_score }}
                </div>
                <div v-show="Object.keys(match).includes('home_score') && store.state.userStore.roundPlaying === match.round">-</div>
                <div v-show="store.state.userStore.roundPlaying !== match.round && Object.keys(match).includes('home_score')">
                  {{ match.home_score }} : {{ match.away_score }}
                </div>
              </div>
              <club-logo :club-id="match.away" class="w-5 h-5"></club-logo>
              <div class="w-1/2 ml-1 text-[11px] font-semibold text-neobit-black"
                   :class="[match.away === store.state.userStore.club.id ? 'text-green-600' : '']"
              >
                {{ store.state.userStore.clubNames[match.away] }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { useRoute, useRouter } from 'vue-router';
import {
  computed,
  watch,
  ref,
  onBeforeMount,
  onBeforeUnmount,
} from 'vue';
import ClubLogo from '@/apps/globalComponents/components/clubLogo.vue';

export default {
  name: 'round',
  components: { ClubLogo },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const roundId = computed(() => route.params.round);
    const currentRound = ref(roundId.value);
    const leagueId = computed(() => route.params.league);
    const rounds = computed(() => store.state.userStore.matches[leagueId.value]);
    const nowLive = computed(() => {
      if (!store.state.userStore.liveMatches.league) return false;
      if (!store.state.userStore.liveMatches.league[leagueId.value]) return false;
      if (Object.keys(store.state.userStore.liveMatches.league[leagueId.value]).length === 0) return false;
      return true;
    });
    const liveMatches = computed(() => {
      if (!Object.keys(store.state.userStore.liveMatches.length > 0)) {
        return [];
      }
      if (!Object.keys(store.state.userStore.liveMatches).includes('league')) {
        return [];
      }
      if (!Object.keys(store.state.userStore.liveMatches.league).includes(leagueId.value)) {
        return [];
      }
      return store.state.userStore.liveMatches?.league[leagueId.value];
    });
    let liveCounter = null;
    const counter = ref(0);

    function changeRound(amount) {
      currentRound.value = String(Number(currentRound.value) + amount);
      router.push({ path: `/ranking/round/${leagueId.value}/${currentRound.value}` });
    }

    function calcLiveCounter() {
      counter.value += 1;
      if (counter.value >= 5400) {
        clearInterval(liveCounter);
      }
    }

    const formatCounter = computed(() => {
      const ss = counter.value % 60;
      let mm = parseInt(counter.value / 60, 10);
      const hh = parseInt(mm / 60, 10);
      mm %= 60;
      return `${hh > 9 ? hh : `0${hh}`}:${mm > 9 ? mm : `0${mm}`}:${ss > 9 ? ss : `0${ss}`}`;
    });

    function checkCounter() {
      if (!nowLive.value) {
        clearInterval(liveCounter);
        return;
      }
      clearInterval(liveCounter);
      const endTime = Object.values(store.state.userStore.liveMatches.league[leagueId.value])[0].completed;
      counter.value = parseInt(new Date().getTime() / 1000, 10) - (endTime - 60 * 90);
      liveCounter = setInterval(() => calcLiveCounter(), 1000);
    }

    watch(nowLive, () => {
      checkCounter();
    });

    onBeforeMount(() => {
      checkCounter();
    });

    onBeforeUnmount(() => {
      clearInterval(liveCounter);
    });

    if (Number(roundId.value) === 0) {
      router.push({ path: `/ranking/round/${leagueId.value}/1` });
    }

    return {
      store,
      rounds,
      leagueId,
      roundId,
      counter,
      nowLive,
      liveMatches,
      formatCounter,
      currentRound,
      changeRound,
    };
  },
};
</script>

<style scoped>

.left {
  background-color: rgb(33, 22, 22);
  -webkit-mask: url(/img/ui/arrow-right.svg) no-repeat center;
  mask: url(/img/ui/arrow-right.svg) no-repeat center;
  transform: rotate(180deg);
}

.right {
  background-color: rgb(33, 22, 22);
  -webkit-mask: url(/img/ui/arrow-right.svg) no-repeat center;
  mask: url(/img/ui/arrow-right.svg) no-repeat center;
}

</style>
