<template>
  <div id="Home" class="frontContainer relative h-full">
    <onboarding-template>
      <router-view></router-view>
    </onboarding-template>
  </div>
</template>

<script>
import OnboardingTemplate from '@/os/components/templates/OnboardingTemplate.vue';
/* eslint-disable */
export default {
  name: 'user-home',
  components: {
    OnboardingTemplate,
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>

.frontContainer {
  font-family: 'Rubik', sans-serif;
}

</style>
