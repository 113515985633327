<template>
  <div class="flex-1 bg-white md:rounded-2xl flex p-2">
    <div class="flex-1 overflow-auto nice-scroll pr-0">
      <div class="overflow-hidden md:min-h-[670px] px-4 pt-8 pb-4 flex flex-col items-center justify-center">
        <img src="../assets/guide_trophy.png" class="w-[70%]" />
        <div class="mt-8 font-bold text-2xl">Become a Champion!</div>
        <span class="mt-4 text-center">In NeoSoccer, you can climb to the top with your football club and become the world's new champion.</span>
        <span class="mt-4 text-center">But becoming a champion is never easy, so let us show you how to play the game so you can start your journey.</span>
      </div>
      <div class="p-6">
        <button
          @click="goToNextStep()"
          class="text-white bg-[#00AF92] w-full p-4 font-bold rounded-lg"
        >
          START JOURNEY
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  name: 'IntroStep1',
  setup() {
    const router = useRouter();

    function goToNextStep() {
      router.push({ name: 'IntroStep2' });
    }

    return {
      goToNextStep,
    };
  },
};
</script>
