<template>
  <app-interface>
    <div class="p-4">
      <div class="box overflow-hidden">
        <div class="w-full bg-neobit-green px-4 py-1.5 text-[13px] font-bold text-white">
          HODL bNEO IN YOUR WALLET, EARN BITS!
        </div>
        <div class="p-3">
          <div class="border border-neobit-green border-opacity-20 rounded-lg overflow-hidden">
            <div class="w-full bg-neobit-green bg-opacity-40 p-3">
              <div class="bg-white bg-opacity-60 rounded-md py-2 text-center items-center justify-center text-[15px] font-semibold text-neobit-green flex">
                <div>Unclaimed:</div>
                <sexy-number
                :number="unclaimed"
                :decimals="6"
                class="ml-1"
              />
                <img src="/img/tokens/transparent/BITS.svg" class="w-5 h-5"/>
              </div>
            </div>
            </div>
          <div>
            <button
              @click="claimNow"
              class="mt-2 rounded-xl py-2.5 text-center text-[15px] font-bold text-white w-full"
              :class="Number(unclaimed) > 1 ? 'bg-neobit-green' : 'bg-neobit-black bg-opacity-25'"
            >
              {{ claimText }}
            </button>
          </div>
        </div>
      </div>
      <div class="box overflow-hidden mt-4">
        <div class="w-full bg-neobit-greenlight px-4 py-1.5 text-[13px] font-bold text-neobit-green">
          BITS HISTORY
        </div>
        <div class="px-4 py-3">
          <div class="text-neobit-black text-[15px] font-medium">You can earn bits by doing in-game activities or by contributing to NeoSoccer.</div>
          <div class="mt-3 flex flex-col gap-3">
            <div
              class="border-2  bg-opacity-30 rounded-lg py-2.5 px-4 flex items-center justify-between"
              :class="[item.action !== 'mint-consumable' ? 'border-bits-bluelight bg-bits-bluelight' : 'border-neobit-greenlight bg-neobit-greenlight']"
              v-for="(item, i) in history"
              :key="i"
            >
              <div class="flex flex-col gap-1">
                <div class="text-[13px] font-semibold text-neobit-black">
                  {{ getBitType(item.action) }}
                </div>
                <div v-if="item.action !== 'mint-consumable'" class="flex items-center text-[11px] font-semibold text-neobit-black text-opacity-50">
                  <div>{{ timeToDate(Number(item.time + '000')) }} - Reward: {{ item.amount }}</div>
                  <div><img src="/img/tokens/transparent/BITS.svg" class="w-5 h-5"/></div>
                </div>
                <div v-if="item.action === 'mint-consumable'" class="flex items-center text-[11px] font-semibold text-neobit-black text-opacity-50">
                  <div>{{ timeToDate(Number(item.time + '000')) }} - Cost: {{ item.amount - item.amount - item.amount }}</div>
                  <div><img src="/img/tokens/transparent/BITS.svg" class="w-5 h-5"/></div>
                </div>
              </div>
              <img v-if="item.action === 'mint-consumable'" src="/img/ui/check.svg" class="w-6 h-6"/>
              <img v-if="item.action !== 'mint-consumable'" src="/img/ui/check-blue.svg" class="w-6 h-6"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-interface>
</template>

<script>
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue';
import { format } from 'date-fns';
import AppInterface from '@/os/components/templates/AppInterface.vue';
import backendAPI from '@/os/APIs/backendAPI';
import store from '@/store';
import BigNumber from 'bignumber.js';

export default {
  name: 'MyBits',
  components: {
    AppInterface,
  },
  setup() {
    const history = ref([]);
    const unclaimed = ref(0);
    const loading = ref(false);
    const claimText = computed(() => {
      if (unclaimed.value > 1) return 'CLAIM NOW';
      return 'Must be more than 1';
    });

    let interval;

    function timeToDate(time) {
      return `${format(time, 'MMM d, yy')}`;
    }

    function getBitType(type) {
      if (type === 'mint-item') return 'Minted item';
      if (type === 'burn-item') return 'Terminated item contract';
      if (type === 'buy-item') return 'Bought item';
      if (type === 'lend-item') return 'Lent item';
      if (type === 'extend-item-contract') return 'Extended item contract';
      if (type === 'invite') return 'Invited player';
      if (type === 'signup-for-cup') return 'Signed up for cup';
      if (type === 'practice') return 'Played friendly match';
      if (type === 'mint-consumable') return 'Minted Consumable';
      if (type === 'win-match') return 'Won Match';
      if (type === 'draw-match') return 'Draw Match';
      return type;
    }

    async function getBitsHistory() {
      const res = await backendAPI.userGetBitsHistory();
      // eslint-disable-next-line
      history.value = res.sort((a, b) => b.time > a.time ? 1 : -1);
    }

    function getDividend() {
      return (store.state.userStore.seasonFees / 10 ** 8) / store.state.userStore.totalBits;
    }

    getBitsHistory();

    function updateUnclaimed() {
      const base = store.state.userStore.earnedBits;
      const bitsPrSecPrbNEO = new BigNumber(store.state.userStore.bitsPrSeasonPrbNEO).dividedBy((45 * 86400)).toNumber();
      const userBalance = new BigNumber(store.state.userStore.balance).dividedBy(10 ** 8).toNumber();
      const userBitsPrSec = new BigNumber(bitsPrSecPrbNEO).multipliedBy(userBalance).toNumber();
      const growth = userBitsPrSec * (Math.floor(Date.now() / 1000) - store.state.userStore.lasClaimed);
      unclaimed.value = base + growth;
    }

    async function claimNow() {
      loading.value = true;
      try {
        await backendAPI.userClaimBits();
        await store.dispatch('userStore/getUserInfo');
      } catch (error) {
        console.log(error, 'Error Claiming');
      }
      loading.value = false;
    }

    onMounted(async () => {
      interval = setInterval(updateUnclaimed, 1000);
    });

    onBeforeUnmount(() => {
      clearInterval(interval);
    });

    return {
      store,
      history,
      getBitType,
      timeToDate,
      getDividend,
      unclaimed,
      claimText,
      claimNow,
    };
  },
};
</script>

<style scoped>
.box {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.06);
  border-radius: 8px;
}
</style>
