import Overview from './views/Overview.vue';

const routes = [
  {
    path: '/lineup/:clubId?',
    name: 'LineupMain',
    component: Overview,
    meta: { title: 'Lineup - Neo FC' },
  },
];

export default routes;
