<template>
  <app-interface>
    <div class="h-full">
      <router-view></router-view>
    </div>
  </app-interface>
  <mint-modal></mint-modal>
</template>

<script>
import AppInterface from '@/os/components/templates/AppInterface.vue';
import MintModal from '@/apps/recruit/modals/mintModal.vue';
import store from '@/store';
import { onBeforeUnmount } from 'vue';

export default {
  name: 'mint-main-app',
  components: { MintModal, AppInterface },
  setup() {
    let refreshData = null;

    async function fetchGameData() {
      await store.dispatch('userStore/getGameInfo');
    }

    if (refreshData) clearInterval(refreshData);
    refreshData = setInterval(() => fetchGameData(), (1000 * 100));

    onBeforeUnmount(() => {
      clearInterval(refreshData);
    });

    return {};
  },
};
</script>
