<template>
  <div class="relative p-2 flex flex-col bg-white rounded-2xl mx-4 w-96">
    <div class="flex items-center mb-2">
      <div v-if="!item.on_sale" class="flex flex-grow text-[18px] font-bold mt-1 text-neobit-green uppercase">Use Consumable</div>
      <div class="" @click="$emit('close')">
        <img :src="`/img/ui/cross.svg`" width="26"/>
      </div>
    </div>
    <div>
      <div>
        <div class="text-[12px]">You’re about to use: {{ item.for_item }} {{ item.for_skill }} {{ getGain(item) }}</div>
      </div>
      <div class="px-2 mb-1">
        <input
          class="block w-full pl-2 py-3 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500
        focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
        dark:focus:border-blue-500"
          v-model="searchQuery"
          :placeholder="`Search for an ${item.for_item}...`" />
      </div>

      <div class="max-h-[200px] min-h-[200px] overflow-y-scroll nice-scroll py-1">
        <div v-for="item in filteredItems" :key="item.id" :value="item" class="p-1 relative">
          <item-search-card :player="item"></item-search-card>
          <div
            @click="selectedItem = item" class="absolute cursor-pointer inset-0 z-10 p-1"
            :class="[selectedItem.id === item.id ? 'bg-green-700 bg-opacity-30' : '']"
          >
            <div v-if="selectedItem.id === item.id" class="flex h-full items-center justify-center text-white font-bold text-2xl">SELECTED</div>
          </div>
        </div>
      </div>
      <div v-if="item.for_skill !== 'rename' && selectedItem" class="mt-4 flex justify-center font-semibold">
        Give {{selectedItem.name}} {{ getGain(item) }} {{ item.for_skill }}
      </div>
      <div v-if="item.for_skill === 'rename' && selectedItem">
        <div  class="mt-4 flex justify-center font-semibold">
          What do you want to name {{selectedItem.name}}:
        </div>
        <input
          @input="validateInput"
          class="block w-full pl-2 py-3 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500
        focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
        dark:focus:border-blue-500"
          v-model="newName"
          :placeholder="`New Name...`" />
        <p class="text-red-500 text-xs italic" v-if="!validName">Please enter a valid name</p>
      </div>

    </div>
    <div class="cursor-pointer confirm flex items-center justify-center text-[15px] mt-4" @click="$emit('use', selectedItem.id, newName)">CONFIRM</div>
    <div class="cursor-pointer cancel flex items-center justify-center text-[15px] mt-3" @click="$emit('close')">CANCEL</div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import store from '@/store';
import ItemSearchCard from '@/apps/globalComponents/components/ItemSearchCard.vue';

export default {
  name: 'UseConsumable',
  components: { ItemSearchCard },
  props: {
    item: {
      type: Object,
    },
  },
  setup(props) {
    const itemId = ref(null);
    const newName = ref('');
    const validName = ref(false);
    const searchQuery = ref('');
    const selectedItem = ref(false);
    const items = computed(() => [
      ...store.state.userStore.players?.goalkeepers,
      ...store.state.userStore.players?.defenders,
      ...store.state.userStore.players?.midfielders,
      ...store.state.userStore.players?.attackers,
      ...store.state.userStore.managers,
      ...store.state.userStore.stadiums,
    ].filter((i) => i.item_type === props.item.for_item && i.on_loan_from_club === ''));

    const filteredItems = computed(() => items.value.filter((i) => i.name.toLowerCase().includes(searchQuery.value.toLowerCase())));

    function getGain(thisItem) {
      if (thisItem.for_skill === 'rename') return '-';
      if (thisItem.for_item === 'stadium') return thisItem.gain;
      if (thisItem.for_skill === 'age') return thisItem.gain;
      if (thisItem.for_skill === 'stonks') return thisItem.gain;
      return thisItem.gain / 10;
    }

    function isName(name) {
      if (typeof name !== 'string') return false;
      if (name.split(' ').length < 2) return false;
      if (name.split(' ').length > 3) return false;
      if (name.length < 6) return false;
      if (name.length > 24) return false;
      if (!/^[a-zA-Z ]+$/.test(name)) return false;
      return true;
    }

    function validateInput() {
      validName.value = isName(newName.value);
    }

    return {
      newName,
      itemId,
      items,
      filteredItems,
      validateInput,
      searchQuery,
      selectedItem,
      getGain,
      validName,
    };
  },
};
</script>

<style scoped>
.input {
  border: 2px solid #C8EBE0;
}

.confirm {
  background: #00AF92;
  border-radius: 12px;
  height: 46px;
  color: #FFFFFF;
}

.cancel {
  background: #C8EBE0;
  border-radius: 12px;
  height: 46px;
  color: #00AF92;
}
</style>
