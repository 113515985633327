import { computed } from 'vue';
import store from '@/store';

const state = {
  activeModal: computed(() => store.state.osStore.activeModal),
};

function triggerModal(modal = '', data = null) {
  if (data) {
    store.commit('osStore/setModalData', data);
  }
  store.commit('osStore/setActiveModal', modal);
}

export default {
  triggerModal,
  state,
};
