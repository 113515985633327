<template>
  <div class="w-full bg-white px-5 md:px-[220px] flex items-center justify-between h-16 shadow relative">
    <img src="/img/ui/logo.svg" class="h-[18px]"/>
    <div class="hidden md:block">
      <router-link :to="{ name: 'Home' }" class="p-5 bg-[#00E5991F] text-neobit-green text-lg font-bold tracking-wide hover:opacity-90 active:opacity-75">
        HOME
      </router-link>
      <a href="https://discord.gg/a5R97QJnS6" target="_blank" class="p-5 text-neobit-green text-lg font-bold tracking-wide hover:opacity-90 active:opacity-75">
        JOIN DISCORD
      </a>
      <router-link :to="{ name: 'Onboarding' }" class="px-6 py-2.5 text-white text-[15px] bg-neobit-green rounded-xl font-bold tracking-wide hover:opacity-90 active:opacity-75">
        PLAY NOW
      </router-link>
    </div>
    <div class="md:hidden" @click="menuOpen = !menuOpen">
      <img src="../assets/hamburger.svg" width="32" height="32" v-if="!menuOpen"/>
      <img src="../assets/close.svg" width="32" height="32" v-else/>
    </div>
    <div class="absolute flex flex-col top-16 bg-[#ECECEC] w-full p-5 left-0 z-20" v-if="menuOpen">
      <router-link :to="{ name: 'Home' }" class="mb-5 text-neobit-green text-lg font-bold tracking-wide hover:opacity-90 active:opacity-75">
        HOME
      </router-link>
      <div class="mx-3 border border-neobit-green border-opacity-10"></div>
      <a href="https://discord.gg/a5R97QJnS6" target="_blank" class="my-5 text-neobit-green text-lg font-bold tracking-wide hover:opacity-90 active:opacity-75">
        JOIN DISCORD
      </a>
      <div class="mx-3 border border-neobit-green border-opacity-10"></div>
      <router-link :to="{ name: 'Onboarding' }" class="mt-5 py-2.5 text-center text-white text-[15px] bg-neobit-green rounded-xl font-bold tracking-wide hover:opacity-90 active:opacity-75">
        PLAY NOW
      </router-link>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'AppHeader',
  setup() {
    const menuOpen = ref(false);

    return {
      menuOpen,
    };
  },
};
</script>

<style scoped>

</style>
