<template>
  <div v-if="loading" class="absolute inset-0 mx-4 rounded-[20px] overflow-hidden">
    <spinner></spinner>
  </div>
  <div class="bg-white shadow-md p-4 flex flex-col items-center justify-center font-montserrat rounded-[20px] w-full">
    <div class="text-lg font-bold text-neobit-green text-center">LOGIN</div>
    <div class="mt-1 text-neobit-black font-medium text-center text-[15px]">Log in to NeoBits.</div>
    <input
      class="border-2 border-neobit-greenlight rounded-md bg-transparent outline-none mt-4 w-full px-3 py-2.5"
      v-model="email"
      placeholder="Email..."
      type="email"
      @keyup.enter="$event.target.nextElementSibling.focus()"
    />
    <input
      class="border-2 border-neobit-greenlight rounded-md bg-transparent outline-none mt-3 w-full px-3 py-2.5"
      v-model="password"
      placeholder="Password..."
      type="password"
      @keyup.enter="login"
    />
    <div
      class="text-xs mt-1 text-left w-full pl-1"
      @click="$router.push({name: 'Forgot'})"
    >
      Forgot password?
    </div>
    <button
      class="bg-neobit-green rounded-xl py-2.5 text-center w-full text-white text-[15px] font-bold mt-6"
      @click="login"
    >
      LOGIN
    </button>
    <button class="bg-neobit-greenlight rounded-xl py-2.5 text-center w-full text-neobit-green text-[15px] font-bold mt-4" @click="$router.push({ name: 'Onboarding' })">
      CANCEL
    </button>
  </div>
</template>

<script>
import { onBeforeMount, ref } from 'vue';
import backendAPI from '@/os/APIs/backendAPI';
import store from '@/store';
import Spinner from '@/os/components/Spinner.vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'login-user',
  components: { Spinner },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const email = ref('');
    const password = ref('');
    const loading = ref(true);

    async function fetchUserData(apiKey) {
      store.commit('userStore/setApiKey', apiKey);
      await store.dispatch('userStore/getGameInfo');
      await store.dispatch('userStore/getClubGetNames');
      await store.dispatch('userStore/getUserInfo');
      await store.dispatch('userStore/getUserClub', { clubId: false });
      await store.dispatch('userStore/getClubItems', { clubId: false });
      await store.dispatch('userStore/getLeagues', { league: false });
      await store.dispatch('userStore/getMatches', { league: false });
      loading.value = false;
    }

    onBeforeMount(async () => {
      const apiKey = localStorage.getItem('api_key');
      if (apiKey) {
        try {
          await fetchUserData(apiKey);
          if (route.name === 'Login') {
            await router.push({ name: 'Overview' });
          }
        } catch (err) {
          localStorage.removeItem('api_key');
          console.log(err);
          loading.value = false;
        }
      }
      if (!apiKey) {
        localStorage.removeItem('api_key');
        loading.value = false;
      }
    });

    async function login() {
      loading.value = true;
      try {
        const data = await backendAPI.userLogin(email.value, password.value);
        await fetchUserData(data.api_key);
        window.toast.success('Login successfully');
        await router.push({ name: 'Overview' });
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        throw err;
      } finally {
        loading.value = false;
      }
    }

    return {
      email,
      password,
      login,
      loading,
    };
  },
};
</script>

<style scoped>

</style>
