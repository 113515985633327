<template>
  <div class="flex bg-neobit-green flex-col gap-4 bg-detail p-4 pb-12" v-if="cup">
    <div class="box w-full">
      <div class="bg-white rounded-lg p-3 flex flex-col gap-4">
        <cup-item
          :cupData=cup
          :onlyContent="true"
        />
      </div>
    </div>
    <div class="box w-full rounded-lg overflow-hidden">
      <div class="flex items-center text-[13px] font-bold flex-grow text-neobit-green bg-[#C8EBE0]">
        <div class="text-center w-1/2 cursor-pointer"
             @click="itemTypeSelected = 'prize'"
             :class="itemTypeSelected === 'prize' ? 'bg-[#00AF92] text-white p-2' : ''">
          PRIZE LIST
        </div>
        <div class="text-center w-1/2 cursor-pointer flex items-center justify-center"
             @click="itemTypeSelected = 'matches'"
             :class="itemTypeSelected === 'matches' ? 'bg-[#00AF92] text-white p-2' : ''">
          <span v-if="cup.rounds.round1.matches.length > 0">MATCHES</span>
          <span v-if="cup.rounds.round1.matches.length === 0">REGISTERED</span>
          <div v-if="nowLive" class="bg-[#E80054] rounded-xl px-1.5 ml-2 text-white text-[11px] font-bold">LIVE</div>
        </div>
      </div>
      <div class="bg-white pb-2" v-if="itemTypeSelected === 'prize'">
        <div class="flex text-[11px] font-semibold text-neobit-black text-opacity-50 items-center text-center pt-2 pr-2">
          <div class="w-[6px]"></div>
          <div class="w-[18px] text-left">#</div>
          <div class="w-[120px] text-left">Prize Amount</div>
          <div class="w-6/12">Allocation</div>
          <div class="w-1/12" v-if="cup.prize_token === 'balance' || cup.prize_token === 'bits'">Profit</div>
        </div>
        <div v-for="(rank, index) in Object.keys(cup.prize_Structure)" :key="index" class="flex text-xs items-center text-neobit-black text-center border-b border-gray-100">
          <div :class="[
                      index === 0 ? 'first': 'w-[6px]',
                      index === 1 ? 'second': 'w-[6px]',
                      index >= 2 ? 'third': 'w-[6px]',
                      ]"
          ></div>
          <div class="py-1 text-left w-[18px] ml-1">{{ index + 1 }}</div>
          <div v-if="cup.prize_token !== 'balance'" class="flex items-center gap-0.5 w-[120px]">
            <img :src="`/img/tokens/transparent/${helpers.getPriceLogo(cup.prize_token)}.svg`" class="w-4 h-4 mx-1"/>
            <sexy-number
              :number="getPrice(cup, rank)"
              :decimals="helpers.getTokenDisplayDecimals(cup.prize_token)"
            />
          </div>
          <div class="flex items-center justify-center flex-grow">
            {{ parseInt(cup.prize_Structure[rank] * 100) }}%
          </div>
          <div>
            <div
              v-if="cup.prize_token === 'balance' || cup.prize_token === 'bits'"
              class="px-2 py-0.5 bg-neobit-greenlight rounded-md text-[11px] flex font-semibold w-fit whitespace-nowrap mr-2"
            >
              <sexy-number
                :number="getProfit(rank)"
                :decimals="0"
                class="text-[12px]"
              />
              <div>%</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white p-3" v-if="itemTypeSelected === 'matches'">
        <button
          @click="$router.push({ name: 'LiveMatch', params: { type: 'cup', id: cup?.id } })"
          v-if="nowLive"
          class="mb-3 bg-[#E80054] w-full rounded-xl px-4 py-2.5 flex items-center justify-between text-[15px] font-bold tracking-wide text-white"
        >
          NOW LIVE - {{ formatCounter }}
          <i class="fas fa-chevron-right"></i>
        </button>
        <div class="flex flex-col gap-3" v-if="cup.rounds.round1.matches.length > 0">
          <div
            v-for="(rIndex, idx) in Object.keys(cup.rounds)"
            :key="idx"
          >
            <div
              class="bg-[#3333330F] rounded-lg px-1 py-3 text-neobit-black"
            >
              <div class="flex items-center justify-between px-2">
                <div class="flex items-center">
                  <span v-if="(idx + 1) < Object.keys(cup.rounds).length - 1" class="text-[13px] font-semibold">Round {{ idx + 1 }}</span>
                  <span v-if="(idx + 1) === Object.keys(cup.rounds).length - 1" class="text-[13px] font-semibold">Semifinal</span>
                  <span v-if="(idx + 1) === Object.keys(cup.rounds).length" class="text-[13px] font-semibold">Final</span>
                  <div class="w-2 h-2 rounded-full bg-[#E80054] ml-2 animate-pulse" v-if="isRoundLive(cup.rounds[rIndex].matches)"></div>
                </div>
                <span class="text-[11px] font-semibold opacity-50">{{ timeToDate(cup.rounds[rIndex].start_time) }}</span>
              </div>
              <div class="flex flex-col gap-2 mt-3">
                <div
                  v-for="(match, mIndex) in cup.rounds[rIndex].matches"
                  :key="mIndex"
                  class="p-2 flex items-center bg-white rounded-lg"
                >
                  <router-link :to="{ name: 'MatchView', params: { match: match.match_id } }" class="flex items-center w-full">
                    <div class="w-1/2 flex items-center justify-between mr-1"
                         :class="[match.home === store.state.userStore.club.id ? 'text-green-600' : '']"
                    >
                      <img src="../../assets/grey-close.svg" class="w-4 h-4" v-if="match.home_score <= match.away_score"/>
                      <img src="../../assets/check.svg" class="w-4 h-4" v-if="match.home_score > match.away_score"/>
                      <span class="text-[9px] font-semibold text-neobit-black w-full text-right">{{ store.state.userStore.clubNames[match.home] }}</span>
                    </div>
                    <club-logo :club-id="match.home" class="w-5 h-5"></club-logo>
                    <div class="w-[45px] text-center font-bold text-[10px]">
                      <div v-if="isRoundLive(cup.rounds[rIndex].matches)" :class="[nowLive ? 'text-red-600 animate-pulse' : '']">
                        {{ nowLive[match.match_id].home_score }} : {{ nowLive[match.match_id].away_score }}
                      </div>
                      <div v-else>
                        {{ match.home_score }} : {{ match.away_score }}
                      </div>
                    </div>
                    <club-logo :club-id="match.away" class="w-5 h-5"></club-logo>
                    <div class="w-1/2 ml-1 flex items-center justify-between"
                         :class="[match.away === store.state.userStore.club.id ? 'text-green-600' : '']"
                    >
                      <span class="text-[9px] font-semibold text-neobit-black">{{ store.state.userStore.clubNames[match.away] }}</span>
                      <img src="../../assets/grey-close.svg" class="w-4 h-4" v-if="match.away_score < match.home_score"/>
                      <img src="../../assets/check.svg" class="w-4 h-4" v-if="match.away_score >= match.home_score"/>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="cup.rounds.round1.matches.length === 0">
          <div class="text-neobit-black text-[14px]">Matches will be drawn in {{ getLeftDays() }}</div>
          <div class="text-neobit-black text-[13px] mt-2">Registered teams:</div>
          <div v-for="club in cup.signed_up" :key="club" class="flex pb-0.5">
            <club-logo :club-id="club" class="w-5 h-5"></club-logo>
            <span class="text-[12px] text-neobit-black">{{ store.state.userStore.clubNames[club] }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  ref,
  onBeforeMount,
  onBeforeUnmount,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import store from '@/store';
import { format } from 'date-fns';
import ClubLogo from '@/apps/globalComponents/components/clubLogo.vue';
import BigNumber from 'bignumber.js';
import helpers from '@/apps/tournament/cup/helpers';
import CupItem from '../components/CupItem.vue';

export default {
  name: 'CupDetails',
  components: {
    CupItem,
    ClubLogo,
  },
  setup() {
    const route = useRoute();
    const cupId = computed(() => route.params.id ?? 1);
    const cup = computed(() => store.state.userStore.cups.find((item) => item.id === cupId.value));
    const itemTypeSelected = ref('prize');
    const nowLive = computed(() => {
      if (!store.state.userStore.liveMatches.cup || !cup.value) return false;
      if (!store.state.userStore.liveMatches.cup[cup.value.id]) return false;
      if (Object.keys(store.state.userStore.liveMatches.cup[cup.value.id]).length === 0) return false;
      return store.state.userStore.liveMatches.cup[cup.value.id];
    });
    let liveCounter = null;
    const counter = ref(0);

    function calcLiveCounter() {
      counter.value += 1;
      if (counter.value >= 5400) {
        clearInterval(liveCounter);
      }
    }

    const formatCounter = computed(() => {
      const ss = counter.value % 60;
      let mm = parseInt(counter.value / 60, 10);
      const hh = parseInt(mm / 60, 10);
      mm %= 60;
      return `${hh > 9 ? hh : `0${hh}`}:${mm > 9 ? mm : `0${mm}`}:${ss > 9 ? ss : `0${ss}`}`;
    });

    function getProfit(rank) {
      const entry = cup.value.registration_fee;
      const prizePool = cup.value.prize_pool;
      const allocation = cup.value.prize_Structure[rank];
      return (((prizePool * allocation) / entry) * 100) - 100;
    }

    const curTime = parseInt(new Date().getTime() / 1000, 10);

    function getLeftDays() {
      const diffInSec = cup.value.start_time - curTime;
      const days = parseInt(diffInSec / 60 / 60 / 24, 10);
      if (days > 0) return `${days}d`;
      const hours = parseInt(diffInSec / 60 / 60, 10);
      if (hours > 0) return `${hours}h`;
      const min = parseInt(diffInSec / 60, 10);
      return `${min}min`;
    }

    function getPrice(thisCup, rank) {
      return new BigNumber(helpers.getHumanSum(thisCup.prize_pool, thisCup.prize_token)).multipliedBy(thisCup.prize_Structure[rank]).toNumber();
    }

    function checkCounter() {
      if (!nowLive.value) {
        clearInterval(liveCounter);
        return;
      }
      clearInterval(liveCounter);
      counter.value = parseInt(new Date().getTime() / 1000, 10) - (Number(cup.value.rounds[`round${cup.value.current_round}`].start_time));
      liveCounter = setInterval(() => calcLiveCounter(), 1000);
    }

    watch(nowLive, () => {
      checkCounter();
    });

    onBeforeMount(() => {
      checkCounter();
    });

    onBeforeUnmount(() => {
      clearInterval(liveCounter);
    });

    function timeToDate(time) {
      return `${format(time * 1000, 'dd/MM/yyyy, HH:mm')}`;
    }

    function isRoundLive(matches) {
      if (!nowLive.value) return false;
      const liveMatchIds = Object.keys(nowLive.value);
      for (let idx = 0; idx < matches.length; idx += 1) {
        if (liveMatchIds.includes(matches[idx].match_id)) return true;
      }
      return false;
    }

    return {
      cup,
      itemTypeSelected,
      formatCounter,
      nowLive,
      timeToDate,
      getProfit,
      store,
      isRoundLive,
      getLeftDays,
      getPrice,
      helpers,
    };
  },
};
</script>

<style scoped>
.box {
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.06);
}

.w-fit {
  width: fit-content;
}

.bg-detail {
  background: linear-gradient(133.61deg, rgba(0, 229, 153, 0.64) 0%, rgba(0, 175, 146, 0.64) 99.62%), #ECECEC;
  min-height: 100%;
}

.first {
  background-color: #C8B06D;
  height: 22px;
  width: 4px;
  margin-right: 2px;
  border-radius: 0px 4px 4px 0px;
}

.second {
  background-color: #B4BACA;
  height: 22px;
  width: 4px;
  margin-right: 2px;
  border-radius: 0px 4px 4px 0px;
}

.third {
  background-color: #966938;
  height: 22px;
  width: 4px;
  margin-right: 2px;
  border-radius: 0px 4px 4px 0px;
}

.last {
  background-color: #db162d;
  height: 22px;
  width: 4px;
  margin-right: 2px;
  border-radius: 0px 4px 4px 0px;
}
</style>
