import tokens from '@/os/coreData/tokens';
import BigNumber from 'bignumber.js';

function paymentIsNeo(thisCup) {
  if (typeof thisCup.payment_token === 'undefined') {
    return true;
  }
  if (thisCup.payment_token.startsWith('bNEO')) {
    return true;
  }
  return false;
}

function prizeIsNeo(thisCup) {
  if (typeof thisCup.prize_token === 'undefined') {
    return true;
  }
  if (thisCup.prize_token.startsWith('bNEO')) {
    return true;
  }
  return false;
}

function getTokenDisplayDecimals(token) {
  let localToken = token;
  if (typeof token === 'undefined') {
    localToken = 'bNEO';
  }
  return tokens[localToken].displayDecimals;
}

function getTokenDecimals(token) {
  let localToken = token;
  if (typeof token === 'undefined') {
    localToken = 'bNEO';
  }
  return tokens[localToken].decimals;
}

function getPriceLogo(prizeToken) {
  if (typeof prizeToken === 'undefined') {
    return 'bNEO';
  }
  return prizeToken;
}

function getPaymentLogo(paymentToken) {
  if (typeof paymentToken === 'undefined') {
    return 'bNEO';
  }
  return paymentToken;
}

function getHumanSum(amount, token) {
  return new BigNumber(amount).shiftedBy(-getTokenDecimals(token)).toNumber();
}

export default {
  prizeIsNeo,
  paymentIsNeo,
  getTokenDisplayDecimals,
  getTokenDecimals,
  getPriceLogo,
  getPaymentLogo,
  getHumanSum,
};
