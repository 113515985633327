<template>
  <div class="relative p-2 flex flex-col bg-white rounded-2xl mx-4">
    <div class="flex items-center mb-2">
      <div class="flex flex-grow text-[18px] font-bold mt-1 text-neobit-green uppercase">Cancel {{ item.item_type }} Listing</div>
      <div class="" @click="$emit('close')">
        <img :src="`/img/ui/cross.svg`" width="26"/>
      </div>
    </div>
    <div>
      <div class="text-[12px]">You’re about to cancel listing for: <br>{{ item.name }}</div>
    </div>
    <div class="confirm flex items-center justify-center text-[15px] mt-4" @click="$emit('cancel')">Confirm</div>
    <div class="cancel flex items-center justify-center text-[15px] mt-3" @click="$emit('close')">CANCEL</div>
  </div>
</template>

<script>
export default {
  name: 'cancel-sale',
  props: {
    item: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.input {
  border: 2px solid #C8EBE0;
}

.confirm {
  background: #00AF92;
  border-radius: 12px;
  height: 46px;
  color: #FFFFFF;
}

.cancel {
  background: #C8EBE0;
  border-radius: 12px;
  height: 46px;
  color: #00AF92;
}
</style>
