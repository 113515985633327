<template>
  <div class="relative p-2 flex flex-col bg-white rounded-2xl mx-4 min-w-[300px]">
    <div class="flex items-center mb-2">
      <div class="flex flex-grow text-[18px] font-bold mt-1 text-neobit-green uppercase">LOAN {{ item.item_type }}</div>
      <div class="" @click="$emit('close')">
        <img :src="`/img/ui/cross.svg`" width="26"/>
      </div>
    </div>
    <div>
      <div class="text-[12px]">You’re about to loan: {{ item.name }}</div>
      <div class="text-[12px] mt-2">
        The {{ item.item_type }} can play <span class="font-bold text-red-500">{{roundsLeft()}} league games</span><br>
        The player will return
        <span class="font-bold text-red-500" v-if="store.state.userStore.currentRound < 39"> after season {{store.state.userStore.currentSeason}}</span><br>
        <span class="font-bold text-red-500" v-if="store.state.userStore.currentRound > 38"> after season {{store.state.userStore.currentSeason + 1}}</span>
        Current season:
        <span class="font-bold text-red-500" v-if="store.state.userStore.currentRound < 39"> {{store.state.userStore.currentSeason}}</span>
        <span class="font-bold text-red-500" v-if="store.state.userStore.currentRound > 38"> {{store.state.userStore.currentSeason + 1}}</span><br>

        Current round:
        <span class="font-bold text-red-500" v-if="store.state.userStore.currentRound < 39"> {{store.state.userStore.currentRound}}</span>
        <span class="font-bold text-red-500" v-if="store.state.userStore.currentRound > 38"> 0</span>
      </div>
      <div class="mt-2 text-[12px]">
        <div>The player will be lent to:</div>
        <div class="flex">
          <div>
            <club-logo :club-id="store.state.userStore.club.id" class="w-5 h-5 mr-1"></club-logo>
          </div>
          <div class="font-bold">{{ store.state.userStore.clubNames[store.state.userStore.club.id] }}</div>
        </div>
      </div>
      <div class="relative flex items-center justify-center input h-10 mt-4">
        <div class="flex justify-center font-number">
          For:
          <sexy-number
            :number="(item.on_loan_price + item.season_cost) / 100000000"
            :decimals="4"
            class="mx-1"
          />
          bNEO
        </div>

      </div>

    </div>
    <div class="cursor-pointer confirm flex items-center justify-center text-[15px] mt-4" @click="$emit('loan')">CONFIRM</div>
    <div class="cursor-pointer cancel flex items-center justify-center text-[15px] mt-3" @click="$emit('close')">CANCEL</div>
  </div>
</template>

<script>
import store from '@/store';
import ClubLogo from '@/apps/globalComponents/components/clubLogo.vue';

export default {
  name: 'loan-item',
  components: {
    ClubLogo,
  },
  props: {
    item: {
      type: Object,
    },
  },
  setup() {
    function roundsLeft() {
      if (store.state.userStore.currentRound > 38) {
        return 38;
      }
      return 38 - store.state.userStore.currentRound;
    }
    return {
      store,
      roundsLeft,
    };
  },
};
</script>

<style scoped>
.input {
  border: 2px solid #C8EBE0;
}

.confirm {
  background: #00AF92;
  border-radius: 12px;
  height: 46px;
  color: #FFFFFF;
}

.cancel {
  background: #C8EBE0;
  border-radius: 12px;
  height: 46px;
  color: #00AF92;
}
</style>
