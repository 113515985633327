<template>
  <div class="relative p-2 flex flex-col bg-white rounded-2xl mx-4">
    <div class="flex items-center mb-2">
      <div class="flex flex-grow text-[18px] font-bold mt-1 text-neobit-green uppercase">TRANSFER {{ item.item_type }}</div>
      <div class="" @click="$emit('close')">
        <img :src="`/img/ui/cross.svg`" width="26"/>
      </div>
    </div>
    <div>
      <div class="text-[15px]">Select which club to transfer to:</div>
      <div class="text-[12px] text-red-500">Transfer Fee 0.1 bNEO</div>
      <div class="gap-3">
        <div
          v-for="(clubId, index) of store.state.userStore.clubIds"
          :key="index"
          @click="$emit('transfer', clubId)"
        >
          <div v-if="clubId !== item.club_id" class="border-2 border-neobit-greenlight cursor-pointer rounded-lg bg-neobit-greenlight bg-opacity-30 px-4 py-3 mt-3 flex items-center justify-between">
            <div class="text-[13px] leading-[14px] font-semibold text-neobit-black flex items-center">
              <div class="mr-2">
                <club-logo :club-id="clubId" class="w-5 h-5"></club-logo>
              </div>
              <div class="font-bold">{{ store.state.userStore.clubNames[clubId] }}</div>
            </div>
            <img src="/img/icons/arrow-right.svg" class="w-6 h-6 cursor-pointer"/>
          </div>
        </div>
      </div>

    </div>
    <div class="cancel flex items-center justify-center text-[15px] mt-3" @click="$emit('close')">CANCEL</div>
  </div>
</template>

<script>
import store from '@/store';
import ClubLogo from '@/apps/globalComponents/components/clubLogo.vue';

export default {
  name: 'transfer-item',
  components: { ClubLogo },
  props: {
    item: {
      type: Object,
    },
  },
  setup() {
    return {
      store,
    };
  },
};
</script>

<style scoped>
.input {
  border: 2px solid #C8EBE0;
}

.confirm {
  background: #00AF92;
  border-radius: 12px;
  height: 46px;
  color: #FFFFFF;
}

.cancel {
  background: #C8EBE0;
  border-radius: 12px;
  height: 46px;
  color: #00AF92;
}
</style>
