<template>
  <div class="h-[240px] -mt-48">
    <div v-if="!isSponsor && !isStonks" class="h-[200px] w-[200px] bg-white rounded-full p-4 mt-12 flex justify-center items-center">
      <consumable-avatar :consumable="item" class="m-2 h-[120px]"></consumable-avatar>
    </div>
    <div v-if="isSponsor || isStonks" class="h-[220px] w-[220px] rounded-full p-4 mt-10 flex justify-center items-center">
      <consumable-avatar :consumable="item" class="m-2 h-[220px]"></consumable-avatar>
    </div>
  </div>
  <div v-if="!isSponsor && !isStonks" class="mt-1 text-[26px] font-extrabold text-neobit-black capitalize">{{ item.for_item }} {{ item.for_skill }}</div>
  <div v-if="isSponsor" class="mt-1 text-[26px] font-extrabold text-neobit-black capitalize">{{sponsor}} Sponsorship</div>
  <div v-if="isStonks" class="mt-1 text-[26px] font-extrabold text-neobit-black capitalize">{{sponsor}}</div>
  <div class="flex items-center flex-wrap w-full px-4 mt-6 gap-3">
    <div class="flex-1 rounded-md px-1.5 py-[5px] flex flex-col justify-center bg-neobit-greenlight items-center">
      <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">FOR</span>
      <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase">{{ item.for_item }}</span>
    </div>
    <div  v-if="!isSponsor" class="flex-1 rounded-md px-1.5 py-[5px] flex flex-col justify-center bg-neobit-greenlight items-center">
      <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">GAIN</span>
      <div class="text-neobit-black text-sm font-semibold leading-[14px]">{{getGain(item)}}</div>
    </div>
    <div  v-if="isSponsor" class="flex-1 rounded-md px-1.5 py-[5px] flex flex-col justify-center bg-neobit-greenlight items-center">
      <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">SPONSOR</span>
      <div class="text-neobit-black text-sm font-semibold leading-[14px]">{{sponsor}}</div>
    </div>
    <div class="flex-1 rounded-md px-1.5 py-[5px] flex flex-col justify-center bg-neobit-greenlight items-center">
      <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">RANK</span>
      <span class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase"><rank :rank="item.rank" /></span>
    </div>
  </div>
  <div class="flex items-center flex-wrap w-full px-4 mt-3 gap-3">
    <div v-if="!isSponsor" class="flex-1 rounded-md px-1.5 py-[5px] flex flex-col justify-center bg-neobit-greenlight bg-opacity-30 border-2 border-neobit-greenlight border-opacity-40 items-center">
      <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">TRAIT</span>
      <span v-if="sponsor !== 'STONKS'" class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase">{{ item.for_skill }}</span>
      <span v-if="sponsor === 'STONKS'" class="text-neobit-black text-sm leading-[14px] font-semibold first-letter:uppercase">STONKS</span>
    </div>
    <div  v-if="isSponsor" class="flex-1 rounded-md px-1.5 py-[5px] flex flex-col justify-center bg-neobit-greenlight bg-opacity-30 border-2 border-neobit-greenlight border-opacity-40 items-center">
      <span class="text-[9px] leading-[10px] font-semibold text-neobit-black text-opacity-80">TOKENS</span>
      <div class="flex items-center">
        <sexy-number
          :number="item.gain"
          :decimals="tokens[sponsor].displayDecimals"
          class="text-neobit-black text-sm leading-[18px] font-semibold"
        ></sexy-number>
        <img
          :src="tokenImage(sponsor)"
          class="w-5 h-5 ml-1 border border-neobit-black rounded-full border-opacity-30"
          :alt="sponsor"
        >
      </div>
    </div>
  </div>

</template>

<script>
import Rank from '@/apps/globalComponents/components/Rank.vue';
import ConsumableAvatar from '@/apps/globalComponents/components/ConsumableAvatar.vue';
import SexyNumber from '@/os/components/basic/SexyNumber.vue';
import tokens from '@/os/coreData/tokens';
import { computed } from 'vue';

export default {
  name: 'ConsumableModalComponent',
  components: { SexyNumber, ConsumableAvatar, Rank },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isSponsor = computed(() => props.item.for_skill.startsWith('sponsor') && props.item.for_skill.replace('sponsor-', '') !== 'STONKS');
    const isStonks = computed(() => props.item.for_skill.startsWith('sponsor') && props.item.for_skill.replace('sponsor-', '') === 'STONKS');
    const sponsor = computed(() => props.item.for_skill.replace('sponsor-', ''));
    function getGain(item) {
      if (item.for_skill === 'rename') return '-';
      if (item.for_skill === 'sponsor-STONKS') return item.gain;
      if (item.for_item === 'stadium') return item.gain;
      if (item.for_skill === 'age') return item.gain;
      return item.gain / 10;
    }

    function tokenImage(token) {
      return `/img/tokens/circle/${token}.svg`;
    }

    return {
      getGain,
      tokens,
      tokenImage,
      isSponsor,
      isStonks,
      sponsor,
    };
  },
};
</script>

<style scoped>

</style>
