import IntroStep1 from '@/apps/intro/views/IntroStep1.vue';
import IntroStep2 from '@/apps/intro/views/IntroStep2.vue';
import IntroStep3 from '@/apps/intro/views/IntroStep3.vue';
import IntroStep4 from '@/apps/intro/views/IntroStep4.vue';
import IntroStep5 from '@/apps/intro/views/IntroStep5.vue';
import IntroStep6 from '@/apps/intro/views/IntroStep6.vue';
import IntroStep7 from '@/apps/intro/views/IntroStep7.vue';
import IntroStep8 from '@/apps/intro/views/IntroStep8.vue';
import IntroStep9 from '@/apps/intro/views/IntroStep9.vue';
import IntroStep10 from '@/apps/intro/views/IntroStep10.vue';

const routes = [
  {
    path: 'step-1',
    name: 'IntroStep1',
    component: IntroStep1,
    meta: { title: 'Intro Step 1 - Neo FC' },
  },
  {
    path: 'step-2',
    name: 'IntroStep2',
    component: IntroStep2,
    meta: { title: 'Intro Step 2 - Neo FC' },
  },
  {
    path: 'step-3',
    name: 'IntroStep3',
    component: IntroStep3,
    meta: { title: 'Intro Step 3 - Neo FC' },
  },
  {
    path: 'step-4',
    name: 'IntroStep4',
    component: IntroStep4,
    meta: { title: 'Intro Step 4 - Neo FC' },
  },
  {
    path: 'step-5',
    name: 'IntroStep5',
    component: IntroStep5,
    meta: { title: 'Intro Step 5 - Neo FC' },
  },
  {
    path: 'step-6',
    name: 'IntroStep6',
    component: IntroStep6,
    meta: { title: 'Intro Step 6 - Neo FC' },
  },
  {
    path: 'step-7',
    name: 'IntroStep7',
    component: IntroStep7,
    meta: { title: 'Intro Step 7 - Neo FC' },
  },
  {
    path: 'step-8',
    name: 'IntroStep8',
    component: IntroStep8,
    meta: { title: 'Intro Step 8 - Neo FC' },
  },
  {
    path: 'step-9',
    name: 'IntroStep9',
    component: IntroStep9,
    meta: { title: 'Intro Step 9 - Neo FC' },
  },
  {
    path: 'step-10',
    name: 'IntroStep10',
    component: IntroStep10,
    meta: { title: 'Intro Step 10 - Neo FC' },
  },
];

export default routes;
