const clickOutsideDirective = {
  beforeMount(el, binding) {
    // eslint-disable-next-line no-param-reassign
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };

    setTimeout(() => {
      document.body.addEventListener('click', el.clickOutsideEvent);
    }, 200);
  },
  beforeUnmount(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
};

export default clickOutsideDirective;
