/* eslint-disable prefer-destructuring */
import backendAPI from '@/os/APIs/backendAPI';

export default {
  namespaced: true,
  state: () => ({
    itemsForSale: [],
  }),
  mutations: {
    itemsForSale(state, data) {
      state.itemsForSale = data;
    },
  },
  actions: {
    async getItemsOnSale({ commit }, { itemType }) {
      const item = itemType.slice(0, -1);
      const res = await backendAPI.itemGetForSale(item);
      commit('itemsForSale', res);
    },
  },
};
