<template>
  <div class="mx-4 bg-white px-4 py-3 mt-4 rounded-lg shadow relative">
    <div class="z-10 absolute inset-0 w-full h-full rounded-lg blur-back flex flex-col items-center justify-center" v-if="loading">
      <simple-spinner></simple-spinner>
      <span class="font-bold text-xl mt-5 text-neobit-black tracking-wide">Creating Club</span>
      <span class="text-sm font-medium tracking-wide text-neobit-black mt-1">Please wait...</span>
    </div>
    <div class="text-lg font-bold text-neobit-green tracking-wide">CREATE NEW CLUB</div>
    <div class="mx-9 border-t border-neobit-green border-opacity-10 my-3"></div>
    <div class="bg-white p-4 flex flex-col items-center justify-center font-montserrat w-full">
      <div class="flex flex-col items-center">
        <div class="text-lg font-bold text-neobit-green text-center">Name your club</div>
        <div class="flex w-full mt-2">
          <input v-model="clubName" class="border-2 border-neobit-greenlight rounded-md bg-transparent outline-none w-full px-3 py-2.5" type="text" placeholder="Club name..." id="clubName" autofocus>
        </div>
        <div class="text-lg font-bold text-neobit-green text-center mt-3">Invite code</div>
        <div class="flex w-full mt-2">
          <input v-model="inviteCode" class="border-2 border-neobit-greenlight rounded-md bg-transparent outline-none w-full px-3 py-2.5" type="text" placeholder="Invite code...">
        </div>
      </div>
    </div>
    <button @click="makeClub()"
            :class="[(clubName.length < 4 || clubName.length > 14) || inviteCode.length !== 6 ? 'cursor-not-allowed bg-gray-300 text-gray-500' : 'bg-neobit-greenlight cursor-pointer']"
            class="mt-4 rounded-xl  py-2.5 text-neobit-green text-[15px] font-bold tracking-wide w-full">CREATE CLUB (1bNEO fee)
    </button>
    <button @click="$emit('close')" class="mt-4 rounded-xl bg-neobit-greenlight py-2.5 text-neobit-green text-[15px] font-bold tracking-wide w-full">CANCEL</button>
  </div>
</template>

<script>
/* eslint-disable */
import SimpleModal from '@/os/components/modals/SimpleModal.vue';
import backendAPI from '@/os/APIs/backendAPI';
import { computed, ref } from 'vue';
import osAPI from '@/os/APIs/osAPI';
import store from '@/store';
import SimpleSpinner from '@/os/components/SimpleSpinner.vue';

export default {
  name: 'MakeClubModal',
  components: {
    SimpleModal,
    SimpleSpinner,
  },
  props: {},
  emits: ['close'],
  setup(props, { emit }) {
    const modalBg = 'rgba(0,0,0,0)';
    const clubName = ref('');
    const inviteCode = ref('');
    const loading = ref(false);

    async function makeClub() {
      loading.value = true;
      try {
        const result = await backendAPI.clubCreate(clubName.value, inviteCode.value);
        await store.dispatch('userStore/getUserInfo');
        await store.dispatch('userStore/getUserClub', { clubId: result.id });
        await store.dispatch('userStore/getGameInfo');
        await store.dispatch('userStore/getClubGetNames');
        emit('close')
      } catch (err) {
        console.log(err.response.data.detail);
        window.toast.error(err.response.data.detail);
        throw err;
      }
      loading.value = false;
    }


    return {
      modalBg,
      clubName,
      inviteCode,
      loading,
      makeClub,
    };
  },
};
</script>

<style scoped>
.blur-back {
  background: rgba(255, 255, 255, 0.92);
  backdrop-filter: blur(4px);
}

.nice-scroll::-webkit-scrollbar {
  width: 7px;
}

.nice-scroll::-webkit-scrollbar-track {
  background: #00000000;
  border-radius: 5px;
}

.nice-scroll::-webkit-scrollbar-thumb {
  background: #80808766;
  border-radius: 5px;
}

.nice-scroll::-webkit-scrollbar-thumb:hover {
  background: #808087;
  cursor: pointer;
}
</style>
