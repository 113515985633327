/* eslint-disable prefer-destructuring */
import backendAPI from '@/os/APIs/backendAPI';

export default {
  namespaced: true,
  state: () => ({
    apiKey: localStorage.getItem('api_key'),
    userId: null,
    username: '',
    clubIds: [],
    balance: 0,
    deposits: {},
    club: {
      league: 1,
    },
    inLineup: {},
    players: {
      goalkeepers: [],
      defenders: [],
      midfielders: [],
      attackers: [],
    },
    stadiums: [],
    managers: [],
    consumables: [],
    active: false,
    intro: false,
    production: true,
    totalBits: 0,
    seasonFees: 0,
    currentRound: 1,
    currentSeason: 1,
    unclaimedBits: 0,
    earnedBits: 0,
    lasClaimed: 0,
    match_in_play: false,
    offline: false,
    neo_price: 0,
    validTo: 0,
    playerPrice: 2,
    managerPrice: 4,
    stadiumPrice: 6,
    bitsPrSeasonPrbNEO: 0,
    invites: [],
    leagues: {},
    matches: {},
    currentMatch: {},
    leaguePrizes: {},
    ticketPrice: {},
    matchPrize: {},
    clubNames: {},
    clubBadges: {},
    clubBots: [],
    liveMatches: {},
    tokens: {},
    needNewContract: 0,
    cups: [],
  }),
  mutations: {
    setCupData(state, data) {
      state.cups = data;
    },
    setLiveMatches(state, data) {
      state.liveMatches = data;
    },
    setNewBadge(state, data) {
      state.clubBadges[state.userId] = data;
      state.club.badge = data;
    },
    increaseBalance(state, data) {
      state.balance += data;
    },
    decreaseBalance(state, data) {
      state.balance -= data;
    },
    logOut(state) {
      state.userId = null;
      state.apiKey = null;
    },
    setApiKey(state, data) {
      localStorage.setItem('api_key', data);
      state.apiKey = data;
    },
    setGameInfo(state, data) {
      state.currentRound = data.current_round;
      state.currentSeason = data.current_season;
      state.leaguePrizes = data.league_prizes;
      state.ticketPrice = data.ticket_price;
      state.matchPrize = data.match_price;
      state.numberOfLeagues = data.total_leagues;
      state.neo_price = data.neo_price;
      state.validTo = data.next_sync;
      state.playerPrice = data.player_price;
      state.managerPrice = data.manager_price;
      state.stadiumPrice = data.stadium_price;
      state.offlineMessage = data.offline_message;
      state.offline = data.offline;
      state.production = data.production;
      state.totalStonks = data.total_stonks;
      state.seasonFees = data.season_fees;
      state.bitsPrSeasonPrbNEO = data.bits_pr_bneo_pr_season;
    },
    setLeagues(state, data) {
      state.leagues[data.league] = data.result.league;
    },
    setMatch(state, data) {
      state.currentMatch = data;
    },
    setClubStats(state, data) {
      state.clubStats = data;
    },
    setClubMatches(state, data) {
      state.clubMatches = data.sort((b, a) => a.time - b.time);
    },
    setMatches(state, data) {
      const result = {};
      data.result.forEach((match) => {
        if (!Object.keys(result).includes(String(match.round))) {
          result[String(match.round)] = [];
        }
        result[match.round].push(match);
      });
      state.matches[data.league] = result;
    },
    setClubNames(state, data) {
      const result = {};
      const badges = {};
      const bots = [];
      data.forEach((item) => {
        result[item.id] = item.name;
        badges[item.id] = item.badge;
        if (item.owner_id.startsWith('ghost')) {
          bots.push(item.id);
        }
      });
      state.clubNames = result;
      state.clubBadges = badges;
      state.clubBots = bots;
    },
    setUserInfo(state, data) {
      state.username = data.username;
      state.userId = localStorage.getItem('api_key').split('.')[0];
      state.clubIds = data.club_ids;
      state.balance = data.tokens.bNEO;
      state.active = data.app_info.neo_soccer.active;
      state.intro = data.app_info.neo_soccer.intro;
      state.invites = data.invites;
      state.deposits = data.deposits;
      state.tokens = data.tokens;
      state.unclaimedBits = data.unclaimed_bits;
      state.earnedBits = data.earned_bits;
      state.lasClaimed = data.last_claimed;
    },
    setUserClub(state, data) {
      state.club = data;
      state.inLineup = {};
      Object.keys(data).forEach((key) => {
        state.inLineup[data[key]] = key;
      });
    },
    setClubItems(state, data) {
      state.needNewContract = 0;
      state.players = {
        goalkeepers: [],
        defenders: [],
        midfielders: [],
        attackers: [],
      };
      state.managers = [];
      state.stadiums = [];
      state.consumables = [];
      const season = state.currentRound < 39 ? state.currentSeason : state.currentSeason + 1;
      data.forEach((item) => {
        const localItem = item;
        if (item.contract_to_season < season) {
          state.needNewContract += 1;
        }
        if (Object.keys(state.inLineup).includes(localItem.id)) {
          localItem.inLineup = state.inLineup[localItem.id];
        }
        if (item.item_type !== 'player') {
          state[`${item.item_type}s`].push(localItem);
        } else {
          state[`${item.item_type}s`][`${item.position}s`].push(localItem);
        }
      });
    },
  },
  actions: {
    async getCups({ commit }) {
      const res = await backendAPI.getCupData();
      commit('setCupData', res);
    },
    async getLiveMatches({ commit }) {
      const res = await backendAPI.getLiveMatches();
      commit('setLiveMatches', res);
    },
    async getGameInfo({ commit }) {
      const res = await backendAPI.gameInfo();
      commit('setGameInfo', res);
    },
    async getUserInfo({ commit }) {
      const res = await backendAPI.userGet();
      commit('setUserInfo', res);
    },
    async getUserClub({ commit, state }, { clubId }) {
      if (state.clubIds.length === 0 && !clubId) {
        return;
      }
      // eslint-disable-next-line
      const club = clubId ? clubId : state.clubIds[0];
      const res = await backendAPI.clubGet(club);
      commit('setUserClub', res);
    },
    async getClubGetNames({ commit }) {
      const res = await backendAPI.clubGetNames();
      commit('setClubNames', res);
    },
    async getLeagues({ commit, state }, { league }) {
      if (Object.keys(state.leagues).includes(league)) {
        return;
      }
      let l = state.club.league;
      if (league) {
        l = league;
      }
      const result = await backendAPI.leagues(state.currentSeason, l);
      commit('setLeagues', { league, result });
    },
    async getClubStats({ commit, state }, { clubId }) {
      let l = state.club.id;
      if (clubId) {
        l = clubId;
      }
      const res = await backendAPI.clubGetStats(l);
      commit('setClubStats', res);
    },
    async getMatches({ commit, state }, { league }) {
      if (Object.keys(state.matches).includes(league)) {
        return;
      }
      let l = state.club.league;
      if (league) {
        l = league;
      }
      const result = await backendAPI.matches(state.currentSeason, l);
      commit('setMatches', { league, result });
    },
    async getClubMatches({ commit, state }, { clubId, season }) {
      if (state.clubIds.length === 0 && !clubId) {
        return;
      }
      let l = state.club.league;
      if (clubId) {
        l = clubId;
      }
      let s = state.currentSeason;
      if (season) {
        s = season;
      }
      const res = await backendAPI.clubGetMatches(l, s);
      commit('setClubMatches', res);
    },
    async getMatch({ commit }, { match }) {
      if (!match) {
        return;
      }
      const res = await backendAPI.match(match);
      commit('setMatch', res);
    },
    async getUserItems({ commit, state }) {
      const res = await backendAPI.itemUserGetAll(state.userId);
      commit('setUserItems', res);
    },
    async getClubItems({ commit, state }, { clubId }) {
      let club = state.clubIds[0];
      if (clubId) {
        club = clubId;
      }
      const res = await backendAPI.itemClubGetAll(club);
      commit('setClubItems', res);
    },
  },
};
