<template>
  <div class="w-full flex flex-col bg-black relative">
    <app-header></app-header>
    <div class="grassBack relative pb-4 md:pb-20">
      <img src="./assets/mask.png" class="absolute inset-0 w-full h-full"/>
      <div class="flex flex-col items-center relative z-10 overflow-x-hidden">
        <img src="./assets/cards.png" class="md:-mt-12 w-[414px] md:w-[1000px]"/>
        <div class="md:-mt-20 flex gap-1.5 md:gap-4 justify-center items-center w-[200%]">
          <img src="./assets/get_start.svg" class="h-8 md:h-16"/>
          <div class="text-[28px] md:text-[64px] whitespace-nowrap font-bold tracking-wide text-white">LETS GET STARTED</div>
          <img src="./assets/get_start.svg" class="h-8 md:h-16"/>
        </div>
      </div>
    </div>
    <div class="hidden md:block mx-[271px] border-2 border-dashed border-neobit-green opacity-25"></div>
    <div class="md:flex items-center justify-between px-4 md:px-[220px] -mt-4 relative z-20 hidden">
      <div class="flex flex-col items-center">
        <div class="step">1<span class="text-white text-opacity-60">/</span>6</div>
        <div class="text-sm font-bold text-white mt-6">GET REGISTERED</div>
      </div>
      <div class="flex flex-col items-center">
        <div class="step">2<span class="text-white text-opacity-60">/</span>6</div>
        <div class="text-sm font-bold text-white mt-6">CREATE A CLUB</div>
      </div>
      <div class="flex flex-col items-center">
        <div class="step">3<span class="text-white text-opacity-60">/</span>6</div>
        <div class="text-sm font-bold text-white mt-6">DEPOSIT TOKENS</div>
      </div>
      <div class="flex flex-col items-center">
        <div class="step">4<span class="text-white text-opacity-60">/</span>6</div>
        <div class="text-sm font-bold text-white mt-6">MANAGE CLUB</div>
      </div>
      <div class="flex flex-col items-center">
        <div class="step">5<span class="text-white text-opacity-60">/</span>6</div>
        <div class="text-sm font-bold text-white mt-6">CREATE A LINEUP</div>
      </div>
      <div class="flex flex-col items-center">
        <div class="step">6<span class="text-white text-opacity-60">/</span>6</div>
        <div class="text-sm font-bold text-white mt-6">COMPETE & EARN</div>
      </div>
    </div>
    <div class="px-4 mt-12 md:hidden">
      <div class="border-2 border-dashed border-neobit-green opacity-25 mx-20"></div>
      <div class="flex items-center justify-between relative -mt-4">
        <div class="flex flex-col items-center">
          <div class="step">1<span class="text-white text-opacity-60">/</span>6</div>
          <div class="text-sm font-bold text-white mt-5">GET REGISTERED</div>
        </div>
        <div class="flex flex-col items-center">
          <div class="step">2<span class="text-white text-opacity-60">/</span>6</div>
          <div class="text-sm font-bold text-white mt-5">CREATE A CLUB</div>
        </div>
      </div>
      <div class="border-2 border-dashed border-neobit-green opacity-25 mx-20 mt-12"></div>
      <div class="flex items-center justify-between relative -mt-4">
        <div class="flex flex-col items-center">
          <div class="step">3<span class="text-white text-opacity-60">/</span>6</div>
          <div class="text-sm font-bold text-white mt-5">DEPOSIT TOKENS</div>
        </div>
        <div class="flex flex-col items-center">
          <div class="step">4<span class="text-white text-opacity-60">/</span>6</div>
          <div class="text-sm font-bold text-white mt-5">MANAGE CLUB</div>
        </div>
      </div>
      <div class="border-2 border-dashed border-neobit-green opacity-25 mx-20 mt-12"></div>
      <div class="flex items-center justify-between relative -mt-4">
        <div class="flex flex-col items-center">
          <div class="step">5<span class="text-white text-opacity-60">/</span>6</div>
          <div class="text-sm font-bold text-white mt-5">CREATE A LINEUP</div>
        </div>
        <div class="flex flex-col items-center">
          <div class="step">6<span class="text-white text-opacity-60">/</span>6</div>
          <div class="text-sm font-bold text-white mt-5">COMPETE & EARN</div>
        </div>
      </div>
    </div>
    <a href="#steps">
      <img src="./assets/go-down.svg" width="48" height="48" class="absolute top-[90vh] left-1/2 cursor-pointer hover:opacity-90 active:opacity-75" style="transform: translate(-50%, 0);"/>
    </a>
    <div id="steps" class="mt-28"></div>
    <div class="mt-24 md:mt-32 px-3 md:mx-[220px] mb-20 relative">
      <div class="absolute left-20 border-2 border-neobit-green border-dashed border-opacity-25 top-0 h-full"></div>
      <div class="stepBoxBorder z-10 relative">
        <div class="w-full stepBox">
          <img src="./assets/particles.svg" class="absolute inset-0 w-full h-full object-cover"/>
          <div class="step absolute left-9 md:left-12 -top-4">1<span class="text-white text-opacity-60">/</span>6</div>
          <div class="flex flex-col md:flex-row items-center relative z-10 md:pl-12">
            <div class="flex-1 flex flex-col justify-center py-9 px-6">
              <div class="font-bold text-[26px] md:text-4xl text-white">GET REGISTERED</div>
              <div class="font-medium tracking-wide text-white text-opacity-60 mt-4 md:mt-8">
                At this time <span class="font-bold">NEO-SOCCER</span> is in the <span class="font-bold">BETA</span> phase,
                where we are <span class="font-bold">slowly increasing</span> the <span class="font-bold">number of users</span> after each season ends.
                <br/><br/>
                How to register? You’ll need to get yourself an <span class="font-bold">INVITE CODE</span> which are being
                given to- and by our community, so come and <a href="https://discord.gg/a5R97QJnS6" target="_blank" class="font-bold underline">join our Discord</a>!
              </div>
            </div>
            <div class="flex-1 flex justify-center md:-mt-12">
              <img class="h-full" src="./assets/step1.svg"/>
            </div>
          </div>
        </div>
        <button @click="$router.push({ name: 'Onboarding' })"
                class="stepButton mt-6 w-full py-4 text-[26px] font-extrabold tracking-wide text-white rounded-xl hover:opacity-90 active:opacity-75">
          REDEEM INVITE CODE
        </button>
      </div>
      <div class="stepBoxBorder z-10 relative mt-24">
        <div class="w-full stepBox">
          <img src="./assets/particles.svg" class="absolute inset-0 w-full h-full object-cover"/>
          <div class="step absolute left-9 md:left-12 -top-4">2<span class="text-white text-opacity-60">/</span>6</div>
          <div class="flex flex-col md:flex-row items-center relative z-10 md:pl-12">
            <div class="flex-1 flex flex-col justify-center py-9 px-6">
              <div class="font-bold text-[26px] md:text-4xl text-white">CREATE A CLUB</div>
              <div class="font-medium tracking-wide text-white text-opacity-60 mt-4 md:mt-8">
                Now it’s time to <span class="font-bold">get ready to compete</span> against other in the <span class="font-bold">NEO-SOCCER seasons</span>.
                To get started, simply <span class="font-bold">create your very own NEO-SOCCER club</span>!
              </div>
            </div>
            <div class="flex-1 flex justify-center md:-mt-12">
              <img class="h-full" src="./assets/step2.svg"/>
            </div>
          </div>
        </div>
      </div>
      <div class="stepBoxBorder z-10 relative mt-24">
        <div class="w-full stepBox">
          <img src="./assets/particles.svg" class="absolute inset-0 w-full h-full object-cover"/>
          <div class="step absolute left-9 md:left-12 -top-4">3<span class="text-white text-opacity-60">/</span>6</div>
          <div class="flex flex-col md:flex-row items-center relative z-10 md:pl-12">
            <div class="flex-1 flex flex-col justify-center py-9 px-6">
              <div class="font-bold text-[26px] md:text-4xl text-white">DEPOSIT TOKENS</div>
              <div class="font-medium tracking-wide text-white text-opacity-60 mt-4 md:mt-6">
                After you've <span class="font-bold">successfully established</span> your <span class="font-bold"></span>
                NEO-SOCCER club, you'll have to <span class="font-bold">deposit a few tokens</span> to get started <span class="font-bold">building up your club</span>.
              </div>
              <div class="font-medium italic text-white text-opacity-60 mt-8">
                Currently NEO-SOCCER is supporting:
              </div>
              <div class="mt-2.5 bg-white bg-opacity-10 rounded-3xl p-2 flex items-center gap-1 md:gap-2 width-content">
                <div class="currencyBox pr-2 flex items-center">
                  <img src="/img/tokens/circle/FLM.svg" class="w-8 h-8"/>
                  <span class="text-sm font-semibold text-neobit-black">FLM</span>
                </div>
                <div class="currencyBox pr-2 flex items-center">
                  <img src="/img/tokens/circle/GAS.svg" class="w-8 h-8"/>
                  <span class="text-sm font-semibold text-neobit-black">GAS</span>
                </div>
                <div class="currencyBox pr-2 flex items-center">
                  <img src="/img/tokens/circle/NEO.svg" class="w-8 h-8"/>
                  <span class="text-sm font-semibold text-neobit-black">NEO</span>
                </div>
                <div class="currencyBox pr-2 flex items-center">
                  <img src="/img/tokens/circle/bNEO.svg" class="w-8 h-8"/>
                  <span class="text-sm font-semibold text-neobit-black">bNEO</span>
                </div>
              </div>
            </div>
            <div class="flex-1 flex justify-center md:-mt-12">
              <img class="h-full" src="./assets/step3.svg"/>
            </div>
          </div>
        </div>
      </div>
      <div class="stepBoxBorder z-10 relative mt-24">
        <div class="w-full stepBox">
          <img src="./assets/particles.svg" class="absolute inset-0 w-full h-full object-cover"/>
          <div class="step absolute left-9 md:left-12 -top-4">4<span class="text-white text-opacity-60">/</span>6</div>
          <div class="flex flex-col md:flex-row items-center relative z-10 md:pl-12">
            <div class="flex-1 flex flex-col justify-center py-9 px-6">
              <div class="font-bold text-[26px] md:text-4xl text-white">MANAGE CLUB</div>
              <div class="font-medium tracking-wide text-white text-opacity-60 mt-4 md:mt-8">
                Get your hands on the best <span class="font-bold">players, manager</span> and <span class="font-bold">property</span>
                using the <span class="font-bold">market</span> or by <span class="font-bold">minting</span> directly.
                <br/><br/>
                All of the above have their own <span class="font-bold">unique set of attributes</span> such as <span class="font-bold">skill, luck, aggression</span> and many more.
                These can even increase- or decrease over time as they advance through seasons!
              </div>
            </div>
            <div class="flex-1 flex justify-center md:-mt-12">
              <img class="h-full" src="./assets/step4.svg"/>
            </div>
          </div>
        </div>
      </div>
      <div class="stepBoxBorder z-10 relative mt-24">
        <div class="w-full stepBox">
          <img src="./assets/particles.svg" class="absolute inset-0 w-full h-full object-cover"/>
          <div class="step absolute left-9 md:left-12 -top-4">5<span class="text-white text-opacity-60">/</span>6</div>
          <div class="flex flex-col md:flex-row items-center relative z-10 md:pl-12">
            <div class="flex-1 flex flex-col justify-center py-9 px-6">
              <div class="font-bold text-[26px] md:text-4xl text-white">CREATE A LINEUP</div>
              <div class="font-medium tracking-wide text-white text-opacity-60 mt-4 md:mt-8">
                Creating a <span class="font-bold">strong lineup</span> is the <span class="font-bold">key to success</span> when you’re <span class="font-bold">competing in NEO-SOCCER seasons</span>!
                <br/><br/>
                Make sure to keep your <span class="font-bold">chances of winning</span> high by having your <span class="font-bold">lineup up-to-date</span>.
                As all <span class="font-bold">statistics</span> can <span class="font-bold">increase- or decrease</span> as <span class="font-bold">time progresses</span>
                throughout the <span class="font-bold">NEO-SOCCER seasons</span>.
              </div>
            </div>
            <div class="flex-1 flex justify-center md:-mt-12">
              <img class="h-full" src="./assets/step5.svg"/>
            </div>
          </div>
        </div>
      </div>
      <div class="stepBoxBorder z-10 relative mt-24">
        <div class="w-full stepBox">
          <img src="./assets/particles.svg" class="absolute inset-0 w-full h-full object-cover"/>
          <div class="step absolute left-9 md:left-12 -top-4">6<span class="text-white text-opacity-60">/</span>6</div>
          <div class="flex flex-col md:flex-row items-center relative z-10 md:pl-12">
            <div class="flex-1 flex flex-col justify-center py-9 px-6">
              <div class="font-bold text-[26px] md:text-4xl text-white">COMPETE & EARN</div>
              <div class="font-medium tracking-wide text-white text-opacity-60 mt-4 md:mt-8">
                Each <b>NEO-SOCCER seasons</b> has its own <b>prize pool</b> ranging between <b>100-250 bNEO</b>. All prizes will be distributed between the <b>highest ranked</b> clubs at the <b>end of the season</b>.
                <br/><br/>
                <b>In addition</b> to season prize pools, you’ll also <b>earn bNEO from ticket sales</b> for each round you play!
              </div>
            </div>
            <div class="flex-1 flex justify-center md:-mt-12">
              <img class="h-full" src="./assets/step6.svg"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from './components/header.vue';
import AppFooter from './components/footer.vue';

export default {
  name: 'LandingPage',
  components: {
    AppHeader,
    AppFooter,
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
.grassBack {
  background-image: url("/img/ui/grass-top.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.step {
  background: #00AF92;
  border-radius: 32px;
  width: 64px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: white;
}

.stepBoxBorder {
  border-image-source: linear-gradient(113.01deg, rgba(255, 255, 255, 0.35) -16.26%, rgba(255, 255, 255, 0) 40.42%, rgba(0, 175, 146, 0) 66.35%, rgba(0, 229, 153, 0.1) 96.02%, rgba(0, 175, 146, 0.2) 123.27%);
}

.stepBox {
  background: linear-gradient(120.1deg, rgba(255, 255, 255, 0.2304) -22.92%, rgba(255, 255, 255, 0.1152) 76.19%);
  box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 10px;
  border: 2px solid;
}

.stepButton {
  background: radial-gradient(97.32% 97.32% at 41.49% 19.4%, #00E599 0%, #00D897 24.48%, #00C995 51.56%, #00BC94 76.56%, #00AF92 100%), #8E34E5;
  box-shadow: inset 0px -6px 0px rgba(0, 0, 0, 0.2), inset 0px 6px 0px rgba(255, 255, 255, 0.2);
}

.currencyBox {
  background: #FFFFFF;
  border: 2px solid #C8EBE0;
  border-radius: 104px;
}

.width-content {
  width: fit-content;
}
</style>
