<template>
  <div class="relative p-2 flex flex-col bg-white rounded-2xl mx-4">
    <div class="flex items-center mb-2">
      <div class="flex flex-grow text-[18px] font-bold mt-1 text-[#C72424] uppercase">TERMINATE {{ item.itemType }}</div>
      <div class="" @click="$emit('close')">
        <img :src="`/img/ui/cross.svg`" width="26"/>
      </div>
    </div>
    <div>
      <div class="text-[12px]">You’re about to terminate {{ item.name }}'s contract</div>
      <div class="relative flex items-center justify-center input h-10 mt-4">
        <div class="flex justify-center font-number">
          Get
          <sexy-number
            :number="(item.base_burn_amount / item.age) / 100000000"
            :decimals="4"
            class="mx-1"
          />
          bNEO
        </div>

      </div>
      <div class="confirm flex items-center justify-center text-[15px] mt-4" @click="$emit('burn')">CONFIRM</div>
      <div class="cancel flex items-center justify-center text-[15px] mt-3" @click="$emit('close')">CANCEL</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'terminate-contract',
  props: {
    item: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.input {
  border: 2px solid #ebc8c8;
}

.confirm {
  background: #ff0000;
  border-radius: 12px;
  height: 46px;
  color: #FFFFFF;
}

.cancel {
  background: #f0acac;
  border-radius: 12px;
  height: 46px;
  color: #ff0000;
}
</style>
