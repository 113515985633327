<template>
  <div class="w-full">
    <div class="rounded-lg border-2 pb-4" :class="`border-theme-${cupStyleTheme}`">
      <div class="w-full p-3 h-[76px] flex justify-between" :class="`theme-${cupStyleTheme}`">
        <div class="flex items-center bg-[#FFFFFFA3] rounded-md p-[5px] gap-1 h-[26px]">
          <img src="../../assets/check.svg" class="w-4 h-4" v-if="myStatus === 'Joined'" />
          <img src="../../assets/close.svg" class="w-4 h-4" v-if="myStatus === 'Full'" />
          <img src="../../assets/plus.svg" class="w-4 h-4" v-if="myStatus === 'Open'" />
          <span class="text-[11px] font-semibold text-neobit-black">
            {{ myStatus }}
          </span>
        </div>
        <div class="flex items-center bg-[#FFFFFFA3] rounded-md p-[5px] gap-1 h-[26px]" v-if="curTime < cup.start_time">
          <img src="../../assets/clock.svg" class="w-4 h-4" />
          <span class="text-[11px] font-semibold text-neobit-black">{{ getLeftDays() }} left</span>
        </div>
      </div>
      <div class="-mt-16 z-10 relative flex flex-col items-center px-3">
        <img :src="`/img/ui/cup/${cup?.theme}.svg`" class="w-32 h-32" />
        <div class="mt-3 text-lg font-bold text-neobit-black">{{ cup?.cup_name }}</div>
      </div>
      <div class="px-4 flex flex-col items-center">
        <div class="mt-2 mb-1 border border-[#3333331F] px-2.5 py-1 rounded-md flex items-center text-[11px] font-semibold">
          Entry Cost:
          <img :src="`/img/tokens/transparent/${helpers.getPaymentLogo(cup.payment_token)}.svg`" class="w-4 h-4 mx-1"/>
          <sexy-number
            :number="helpers.getHumanSum(cup.registration_fee, cup.payment_token)"
            :decimals="helpers.getTokenDisplayDecimals(cup.payment_token)"
          />
        </div>
        <div v-if="!isOnlyContent" class="w-full">
          <button
            class="w-full mt-3 rounded-xl text-white px-4 py-2.5 text-[15px] font-bold tracking-wide"
            :class="`btn-theme-${cupStyleTheme}`"
            v-if="myStatus === 'Open'"
            @click="openSignup()"
          >
            SIGN UP FOR CUP
          </button>
          <button
            @click="$router.push({ name: 'LiveMatch', params: { type: 'cup', id: cup?.id } })"
            v-if="nowLive"
            class="mt-3 bg-[#E80054] w-full rounded-xl px-4 py-2.5 flex items-center justify-between text-[15px] font-bold tracking-wide text-white"
          >
            NOW LIVE - {{ formatCounter }}
            <i class="fas fa-chevron-right"></i>
          </button>
          <button
            class="w-full my-3 rounded-xl bg-neobit-greenlight text-neobit-green px-4 py-2.5 text-[15px] font-bold tracking-wide"
            @click="$router.push({ name: 'CupDetails', params: { id: cup.id } })"
          >
            VIEW SCHEDULE
          </button>
        </div>
        <div class="border-t border-[#00AF921A] w-full my-3"></div>
        <div class="flex items-center w-full">
          <div class="flex-1 bg-[#C8EBE052] rounded-md flex items-center justify-between px-2.5 py-1.5">
            <div class="text-[11px] font-semibold text-neobit-black">Start Date</div>
            <div class="text-[11px] font-semibold text-neobit-black">
              {{ timeToDate(cup?.start_time) }}
            </div>
          </div>
        </div>
        <div class="flex items-center w-full mt-2">
          <div class="flex-1 bg-[#C8EBE052] rounded-md flex items-center justify-between px-2.5 py-1.5">
            <div class="text-[11px] font-semibold text-neobit-black">End Date</div>
            <div class="text-[11px] font-semibold text-neobit-black">
              {{ timeToDate(cup?.end_time) }}
            </div>
          </div>
        </div>
        <div class="flex items-center w-full gap-2 mt-2">
          <div class="w-1/3 bg-[#C8EBE052] rounded-md flex items-center justify-between px-2.5 py-1.5">
            <div class="text-[11px] font-semibold text-neobit-black">Clubs</div>
            <div class="text-[11px] font-semibold text-neobit-black">
              {{ cup?.signed_up.length }}<span class="opacity-50">/{{ cup?.spots }}</span>
            </div>
          </div>
          <div class="w-2/3 bg-[#C8EBE052] rounded-md flex items-center justify-between px-2.5 py-1.5">
            <div class="text-[11px] font-semibold text-neobit-black">Prize Pool</div>
            <div class="text-[11px] font-semibold text-neobit-black flex items-center gap-0.5">
              <img :src="`/img/tokens/transparent/${helpers.getPriceLogo(cup.prize_token)}.svg`" class="w-4 h-4 mx-1"/>
              <sexy-number
                :number="helpers.getHumanSum(cup?.prize_pool, cup.prize_token)"
                :decimals="helpers.getTokenDisplayDecimals(cup.prize_token)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import {
  computed,
  ref,
  onBeforeMount,
  onBeforeUnmount,
  watch,
} from 'vue';
import { format } from 'date-fns';
import osAPI from '@/os/APIs/osAPI';
import tokens from '@/os/coreData/tokens';
import helpers from '../helpers';

export default {
  name: 'CupItem',
  props: {
    cupData: {
      type: Object,
      required: true,
    },
    onlyContent: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const cup = computed(() => props.cupData);
    const cupStyleTheme = computed(() => cup.value.theme.split('-')[0]);
    const isOnlyContent = computed(() => props.onlyContent);
    const myStatus = computed(() => {
      if (!cup.value) return '';
      if (cup.value.signed_up.includes(store.state.userStore.club.id)) return 'Joined';
      if (cup.value.signed_up.length === cup.value.spots) return 'Full';
      if (Number(cup.value.start_time) > parseInt(new Date().getTime() / 1000, 10)) return 'Open';
      return 'Not Joined';
    });
    const curTime = parseInt(new Date().getTime() / 1000, 10);
    const nowLive = computed(() => {
      if (!store.state.userStore.liveMatches.cup || isOnlyContent.value) return false;
      if (!store.state.userStore.liveMatches.cup[cup.value.id]) return false;
      if (Object.keys(store.state.userStore.liveMatches.cup[cup.value.id]).length === 0) return false;
      return true;
    });
    let liveCounter = null;
    const counter = ref(0);

    function calcLiveCounter() {
      counter.value += 1;
      if (counter.value >= (Number(cup.value.end_time) - Number(cup.value.start_time))) {
        clearInterval(liveCounter);
      }
    }

    const formatCounter = computed(() => {
      const ss = counter.value % 60;
      let mm = parseInt(counter.value / 60, 10);
      const hh = parseInt(mm / 60, 10);
      mm %= 60;
      return `${hh > 9 ? hh : `0${hh}`}:${mm > 9 ? mm : `0${mm}`}:${ss > 9 ? ss : `0${ss}`}`;
    });

    function checkCounter() {
      if (isOnlyContent.value) return;
      if (!nowLive.value) {
        clearInterval(liveCounter);
        return;
      }
      clearInterval(liveCounter);
      counter.value = parseInt(new Date().getTime() / 1000, 10) - (Number(cup.value.rounds[`round${cup.value.current_round}`].start_time));
      liveCounter = setInterval(() => calcLiveCounter(), 1000);
    }

    watch(nowLive, () => {
      checkCounter();
    });

    onBeforeMount(() => {
      checkCounter();
    });

    onBeforeUnmount(() => {
      clearInterval(liveCounter);
    });

    function getLeftDays() {
      const diffInSec = cup.value.start_time - curTime;
      const days = parseInt(diffInSec / 60 / 60 / 24, 10);
      if (days > 0) return `${days}d`;
      const hours = parseInt(diffInSec / 60 / 60, 10);
      if (hours > 0) return `${hours}h`;
      const min = parseInt(diffInSec / 60, 10);
      return `${min}min`;
    }

    function timeToDate(time) {
      return `${format(time * 1000, 'MMMM d, yyyy, HH:mm')}`;
    }

    function openSignup() {
      osAPI.triggerModal('signup-cup', cup.value);
    }

    return {
      cup,
      isOnlyContent,
      myStatus,
      curTime,
      getLeftDays,
      timeToDate,
      formatCounter,
      nowLive,
      openSignup,
      cupStyleTheme,
      helpers,
      tokens,
    };
  },
};
</script>

<style scoped>
.theme-green {
  background: linear-gradient(133.61deg, rgba(0, 229, 153, 0.32) 0%, rgba(0, 175, 146, 0.32) 99.62%), linear-gradient(92.64deg, #F8F8F8 0%, rgba(248, 248, 248, 0) 100%);
  border-radius: 8px 8px 0px 0px;
}

.theme-bits {
  background: linear-gradient(133.61deg, rgb(0, 229, 153) 0%, rgb(0, 175, 146) 99.62%), linear-gradient(92.64deg, #F8F8F8 0%, rgba(248, 248, 248, 0) 100%);
  border-radius: 8px 8px 0px 0px;
}

.theme-pink {
  background: linear-gradient(269.89deg, rgba(214, 40, 152, 0.32) 0.05%, rgba(52, 33, 129, 0.32) 96.36%), linear-gradient(92.64deg, #F8F8F8 0%, rgba(248, 248, 248, 0) 100%);
  border-radius: 8px 8px 0px 0px;
}

.theme-dogerift {
  background: linear-gradient(269.89deg, rgba(141, 83, 55, 0.96) 0.05%, rgba(148, 69, 30, 0.93) 96.36%), linear-gradient(92.64deg, #F8F8F8 0%, rgba(248, 248, 248, 0) 100%);
  border-radius: 8px 8px 0px 0px;
}

.theme-candy {
  background: linear-gradient(269.89deg, rgba(214, 40, 152, 0.32) 0.05%, rgba(52, 33, 129, 0.32) 96.36%), linear-gradient(92.64deg, #F8F8F8 0%, rgba(248, 248, 248, 0) 100%);
  border-radius: 8px 8px 0px 0px;
}

.theme-purple {
  background: linear-gradient(218.88deg, rgba(148, 53, 226, 0.32) 10.9%, rgba(100, 36, 153, 0.32) 89.1%), linear-gradient(92.64deg, #F8F8F8 0%, rgba(248, 248, 248, 0) 100%);
  border-radius: 8px 8px 0px 0px;
}

.border-theme-green {
  border: 1px solid #00af9231;
}

.border-theme-bits {
  border: 1px solid #51bc98;
}

.border-theme-pink {
  border: 1px solid #9435e227;
}

.border-theme-dogerift {
  border: 1px solid #985332;
}

.border-theme-candy {
  border: 1px solid #e2b8d9;
}

.border-theme-purple {
  border: 1px solid #9435e227;
}

.btn-theme-green {
  background-color: #00AF92;
}

.btn-theme-bits {
  background-color: #00AF92FF;
}

.btn-theme-pink {
  background-color: #D62898;
}

.btn-theme-dogerift {
  background-color: #93573b;
}

.btn-theme-candy {
  background-color: #e833a3;
}

.btn-theme-purple {
  background-color: #9435E2;
}
</style>
