import Round from './views/RoundView.vue';
import Table from './views/TableBigView.vue';

const routes = [
  {
    path: '/ranking/table/:league?',
    name: 'TableMain',
    component: Table,
    meta: { title: 'Table - Neo FC' },
  },
  {
    path: '/ranking/round/:league?/:round?',
    name: 'RoundMain',
    component: Round,
    meta: { title: 'Round - Neo FC' },
  },
];

export default routes;
