<template>
  <div class="flex-1 bg-white md:rounded-2xl flex p-2">
    <div class="flex-1 overflow-auto nice-scroll pr-0">
      <div class="overflow-hidden md:min-h-[670px] px-4 pt-8 pb-4 flex flex-col items-center justify-center text-center">
        <img src="../assets/guide_hire_players.png" class="mt-4 w-[70%]" />
        <div class="mt-8 font-bold text-2xl">Hire Players</div>
        <div class="mt-4">
          At the core of every successful football club you find skilled and talented football players. So the first thing you need to get in order is to hire a full team of players.
        </div>
        <div class="mt-4">
          A full team consists of:
        </div>
        <span class="font-bold opacity-90">1 Goalkeeper</span>
        <span class="font-bold opacity-90">4 Defenders</span>
        <span class="font-bold opacity-90">3 Midfielders</span>
        <span class="font-bold opacity-90">3 Attackers</span>
        <div class="mt-4">
          So be sure you buy enough players.
        </div>
        <div class="mt-4">
          All players have parameters like
          <span class="font-bold opacity-90">rank, age, skill, luck, aggression, and a multiplier</span>
          that make them unique. How these stats affect your ability to win will be explained later.
        </div>
      </div>
      <div class="p-6">
        <button
          @click="goToNextStep()"
          class="text-white bg-[#00AF92] w-full p-4 font-bold rounded-lg"
        >
          NEXT
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  name: 'IntroStep3',
  setup() {
    const router = useRouter();

    function goToNextStep() {
      router.push({ name: 'IntroStep4' });
    }

    return {
      goToNextStep,
    };
  },
};
</script>
