<template>
  <div class="h-full bg-[#ECECEC]">
    <div class="z-10 relative flex font-semibold bg-[#C8EBE0] text-neobit-green items-center h-10 justify-between">
      <div class="text-center w-1/3 cursor-pointer"
           @click="switchItemMenu('players')"
           :class="globalStore.marketPlayerTypeSelected.value === 'players' ? 'bg-[#00AF92] text-white p-2' : ''">
        Players
      </div>
      <div class="text-center w-1/3 cursor-pointer"
           @click="switchItemMenu('managers')"
           :class="globalStore.marketPlayerTypeSelected.value === 'managers' ? 'bg-[#00AF92] text-white p-2' : ''">
        Managers
      </div>
      <div class="text-center w-1/3 cursor-pointer"
           @click="switchItemMenu('stadiums')"
           :class="globalStore.marketPlayerTypeSelected.value === 'stadiums' ? 'bg-[#00AF92] text-white p-2' : ''">
        Stadiums
      </div>
      <div class="text-center w-1/3 cursor-pointer"
           @click="switchItemMenu('consumables')"
           :class="globalStore.marketPlayerTypeSelected.value === 'consumables' ? 'bg-[#00AF92] text-white p-2' : ''">
        Other
      </div>
    </div>
    <div class="relative flex px-4 rounded-b-2xl items-center mt-2">
      <div class="selectOne w-1/2 mr-1">
        <select v-model="globalStore.filterSelected.value" class="w-full h-[36px] dropdown text-neobit-green font-bold">
          <option value="lowPrice">Lowest Price</option>
          <option value="highPrice">Highest Price</option>
          <option value="latest">Latest</option>
        </select>
      </div>
      <div class="selectTwo w-1/2" v-if="globalStore.marketPlayerTypeSelected.value === 'consumables'">
        <select
          v-model="globalStore.itemConsumableTypeSelected.value"
          class="w-full h-[36px] dropdown text-neobit-green font-bold"
        >
          <option value="all">All</option>
          <option value="sponsor">Sponsors</option>
          <option value="skills">Skills</option>
          <option value="luck">Luck</option>
          <option value="multiplier">Multiplier</option>
          <option value="aggression">Aggression</option>
          <option value="age">Age</option>
          <option value="rename">Rename</option>
          <option value="capacity">Stadium Capacity</option>
        </select>
      </div>
      <div class="ml-2" v-if="globalStore.marketPlayerTypeSelected.value !== 'consumables'">
        <div v-show="globalStore.buy.value" class="w-24 h-8 rounded-lg bg-gray-100 flex items-end" @click="globalStore.buy.value = !globalStore.buy.value">
          <div class="h-8 w-12 flex justify-center self-end items-center rounded-lg shadow-lg bg-neobit-green text-white">
            Buy
          </div>
          <div class="h-8 w-12 flex justify-center self-end items-center">Loan</div>
        </div>
        <div v-show="!globalStore.buy.value" class="w-24 h-8 rounded-lg bg-gray-100 flex items-end" @click="globalStore.buy.value = !globalStore.buy.value">
          <div class="h-8 w-12 flex justify-center self-end items-center">Buy</div>
          <div class="h-8 w-12 flex justify-center self-end items-center rounded-lg shadow-lg bg-neobit-green text-white">
            Loan
          </div>
        </div>
      </div>
      <div class="flex items-center" v-if="globalStore.marketPlayerTypeSelected.value !== 'consumables'" @click="showFilters = true">
        <div class="ml-2 text-[13px] text-neobit-green font-bold">Filter({{ filterAmount }})</div>
        <div><img src="/img/ui/filter.svg" class="w-4 h-4"/></div>
      </div>

    </div>
    <div class="flex flex-col gap-2 pb-16">
      <div
        v-for="item in sortedItems"
        :key="item.id"
      >
        <item-card :market="true" :player="item" :card-type="'line'"></item-card>
      </div>
    </div>
    <div class="mb-20"></div>
    <transition name="slide-menu">
      <div v-if="showFilters" class="slideLayer h-full">
        <div class="flex h-full">
          <div @click="showFilters = false" class="w-1/4 h-full"></div>
          <div class="w-3/4 overflow-auto bg-white h-full shadow-2xl p-2 relative">
            <div class="flex items-center pb-4 mx-4 border-b border-neobit-green border-opacity-10">
              <div class="flex-grow mt-4 text-lg text-neobit-green">
                <span class="mr-0.5 font-bold">Filter</span>
                ({{ filterAmount }})
              </div>
              <div class="mt-4 font-bold text-sm text-[#E33A3A]" @click="clearFilters()">RESET FILTER</div>
            </div>
            <div v-if="globalStore.marketPlayerTypeSelected.value === 'players'" class="mt-4 ml-4 text-[14px]">
              <div class="mb-0.5 filterLabel">Position:</div>
              <div class="flex gap-3">
                <!-- eslint-disable max-len -->
                <div @click="togglePlayerType('goalkeeper')"
                     class="px-3 py-2.5 rounded-xl flex-1 flex justify-center items-center text-[15px] font-bold"
                     :class="globalStore.playerTypes.value.includes('goalkeeper') ? 'text-white bg-neobit-green border-2 border-solid border-neobit-green' : 'bg-neobit-greenlight text-neobit-green border-2 border-dashed border-neobit-green'"
                >
                  Goalkeepers
                </div>
                <div @click="togglePlayerType('defender')"
                     class="px-3 py-2.5 rounded-xl flex-1 flex justify-center items-center text-[15px] font-bold"
                     :class="globalStore.playerTypes.value.includes('defender') ? 'text-white bg-neobit-green border-2 border-solid border-neobit-green' : 'bg-neobit-greenlight text-neobit-green border-2 border-dashed border-neobit-green'"
                >
                  Defenders
                </div>
              </div>
              <div class="flex mt-2 gap-3">
                <div @click="togglePlayerType('midfielder')"
                     class="px-3 py-2.5 rounded-xl flex-1 flex justify-center items-center text-[15px] font-bold"
                     :class="globalStore.playerTypes.value.includes('midfielder') ? 'text-white bg-neobit-green border-2 border-solid border-neobit-green' : 'bg-neobit-greenlight text-neobit-green border-2 border-dashed border-neobit-green'"
                >
                  Midfielders
                </div>
                <div @click="togglePlayerType('attacker')"
                     class="px-3 py-2.5 rounded-xl flex-1 flex justify-center items-center text-[15px] font-bold"
                     :class="globalStore.playerTypes.value.includes('attacker') ? 'text-white bg-neobit-green border-2 border-solid border-neobit-green' : 'bg-neobit-greenlight text-neobit-green border-2 border-dashed border-neobit-green'"
                >
                  Attackers
                </div>
              </div>
            </div>
            <div class="mt-5 ml-4">
              <div class="filterLabel">Price:</div>
              <div class="flex gap-3">
                <input typeof="number" v-model="globalStore.baseMarketFilters.value.minPrice"
                       class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
                <input typeof="number" v-model="globalStore.baseMarketFilters.value.maxPrice"
                       class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
              </div>
            </div>
            <div class="mt-2 ml-4">
              <div class="filterLabel">Season cost:</div>
              <div class="flex gap-3">
                <input typeof="number" v-model="globalStore.baseMarketFilters.value.minSalary"
                       class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
                <input typeof="number" v-model="globalStore.baseMarketFilters.value.maxSalary"
                       class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
              </div>
            </div>
            <div class="mt-2 ml-4">
              <div class="filterLabel">Age:</div>
              <div class="flex gap-3">
                <input typeof="number" v-model="globalStore.baseMarketFilters.value.minAge"
                       class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
                <input typeof="number" v-model="globalStore.baseMarketFilters.value.maxAge"
                       class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
              </div>
            </div>
            <div class="mt-2 ml-4" v-if="globalStore.marketPlayerTypeSelected.value !== 'consumables'">
              <div class="filterLabel">Luck:</div>
              <div class="flex gap-3">
                <input typeof="number" v-model="globalStore.baseMarketFilters.value.minLuck"
                       class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
                <input typeof="number" v-model="globalStore.baseMarketFilters.value.maxLuck"
                       class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
              </div>
            </div>
            <div class="mt-2 ml-4" v-if="globalStore.marketPlayerTypeSelected.value === 'players' || globalStore.marketPlayerTypeSelected.value === 'managers'">
              <div class="filterLabel">Skills:</div>
              <div class="flex gap-3">
                <input typeof="number" v-model="globalStore.baseMarketFilters.value.minSkills"
                       class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
                <input typeof="number" v-model="globalStore.baseMarketFilters.value.maxSkills"
                       class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
              </div>
            </div>
            <div class="mt-2 ml-4" v-if="globalStore.marketPlayerTypeSelected.value === 'players' || globalStore.marketPlayerTypeSelected.value === 'managers'">
              <div class="filterLabel">Multiplier:</div>
              <div class="flex gap-3">
                <input typeof="number" v-model="globalStore.baseMarketFilters.value.minMultiplier"
                       class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
                <input typeof="number" v-model="globalStore.baseMarketFilters.value.maxMultiplier"
                       class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
              </div>
            </div>
            <div class="mt-2 ml-4" v-if="globalStore.marketPlayerTypeSelected.value === 'stadiums'">
              <div class="filterLabel">Weather:</div>
              <div class="flex gap-3">
                <input typeof="number" v-model="globalStore.baseMarketFilters.value.minWeather"
                       class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
                <input typeof="number" v-model="globalStore.baseMarketFilters.value.maxWeather"
                       class="px-3 py-1.5 rounded-md bg-transparent border-2 border-neobit-greenlight w-1/2 focus:border-blue-600 focus:outline-none">
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/* eslint-disable max-len */
import store from '@/store';
import osAPI from '@/os/APIs/osAPI';
import ItemCard from '@/apps/globalComponents/components/ItemCard.vue';
import globalStore from '@/os/utils/global';
import { computed, ref, watch } from 'vue';

export default {
  name: 'my-players',
  components: { ItemCard },
  setup() {
    const showFilters = ref(false);
    store.dispatch('marketplaceStore/getItemsOnSale', { itemType: globalStore.marketPlayerTypeSelected.value });

    watch(globalStore.marketPlayerTypeSelected, (newItem) => {
      store.dispatch('marketplaceStore/getItemsOnSale', { itemType: newItem });
    });

    function getSortedList() {
      if (globalStore.filterSelected.value === 'lowPrice' && globalStore.buy.value && globalStore.marketPlayerTypeSelected.value !== 'consumables') {
        return store.state.marketplaceStore.itemsForSale.sort((b, a) => (b.on_sale_price + b.season_cost) - (a.on_sale_price + a.season_cost));
      }
      if (globalStore.filterSelected.value === 'lowPrice' && !globalStore.buy.value && globalStore.marketPlayerTypeSelected.value !== 'consumables') {
        return store.state.marketplaceStore.itemsForSale.sort((b, a) => (b.on_loan_price + b.season_cost) - (a.on_loan_price + a.season_cost));
      }
      if (globalStore.filterSelected.value === 'lowPrice' && globalStore.buy.value && globalStore.marketPlayerTypeSelected.value === 'consumables') {
        return store.state.marketplaceStore.itemsForSale.sort((b, a) => b.on_sale_price - a.on_sale_price);
      }
      if (globalStore.filterSelected.value === 'highPrice' && globalStore.buy.value && globalStore.marketPlayerTypeSelected.value === 'consumables') {
        return store.state.marketplaceStore.itemsForSale.sort((b, a) => a.on_sale_price - b.on_sale_price);
      }
      if (globalStore.filterSelected.value === 'highPrice' && globalStore.buy.value && globalStore.marketPlayerTypeSelected.value !== 'consumables') {
        return store.state.marketplaceStore.itemsForSale.sort((b, a) => (a.on_sale_price + a.season_cost) - (b.on_sale_price + b.season_cost));
      }
      if (globalStore.filterSelected.value === 'highPrice' && !globalStore.buy.value && globalStore.marketPlayerTypeSelected.value !== 'consumables') {
        return store.state.marketplaceStore.itemsForSale.sort((b, a) => (a.on_loan_price + a.season_cost) - (b.on_loan_price + b.season_cost));
      }
      return store.state.marketplaceStore.itemsForSale.sort((b, a) => a.on_sale - b.on_sale);
    }

    function filterPlayers() {
      return getSortedList().filter((o) => (
        globalStore.playerTypes.value?.includes(o.position)
      ));
    }

    const sortedItems = computed(() => {
      if (globalStore.buy.value) {
        if (globalStore.marketPlayerTypeSelected.value === 'consumables') {
          if (globalStore.itemConsumableTypeSelected.value === 'all') {
            return getSortedList();
          }
          if (globalStore.itemConsumableTypeSelected.value === 'sponsor') {
            return getSortedList().filter((o) => o.for_skill.startsWith('sponsor'));
          }
          return getSortedList().filter((o) => o.for_skill === globalStore.itemConsumableTypeSelected.value);
        }
        if (globalStore.marketPlayerTypeSelected.value === 'stadiums') {
          const tmp = getSortedList().filter((o) => (
            o.on_sale_price >= (globalStore.baseMarketFilters.value.minPrice * 100000000) && o.on_sale_price <= (globalStore.baseMarketFilters.value.maxPrice * 100000000)
            && o.age >= globalStore.baseMarketFilters.value.minAge && o.age <= globalStore.baseMarketFilters.value.maxAge
            && o.season_cost >= (globalStore.baseMarketFilters.value.minSalary * 100000000) && o.season_cost <= (globalStore.baseMarketFilters.value.maxSalary * 100000000)
            && ((o.luck?.base + o.luck?.inc) - o.luck?.dec) >= (globalStore.baseMarketFilters.value.minLuck * 10) && ((o.luck?.base + o.luck?.inc) - o.luck?.dec) <= (globalStore.baseMarketFilters.value.maxLuck * 10)
            && o.weather?.base >= (globalStore.baseMarketFilters.value.minWeather * 10) && o.weather?.base <= (globalStore.baseMarketFilters.value.maxWeather * 10)
          ));
          return tmp;
        }
        let playerList = getSortedList();
        if (globalStore.marketPlayerTypeSelected.value === 'players' && globalStore.playerTypes.value.length > 0) {
          playerList = filterPlayers();
        }
        return playerList.filter((o) => (
          o.on_sale_price >= (globalStore.baseMarketFilters.value.minPrice * 100000000) && o.on_sale_price <= (globalStore.baseMarketFilters.value.maxPrice * 100000000)
          && o.age >= globalStore.baseMarketFilters.value.minAge && o.age <= globalStore.baseMarketFilters.value.maxAge
          && o.season_cost >= (globalStore.baseMarketFilters.value.minSalary * 100000000) && o.season_cost <= (globalStore.baseMarketFilters.value.maxSalary * 100000000)
          && ((o.luck?.base + o.luck?.inc) - o.luck?.dec) >= (globalStore.baseMarketFilters.value.minLuck * 10) && ((o.luck?.base + o.luck?.inc) - o.luck?.dec) <= (globalStore.baseMarketFilters.value.maxLuck * 10)
          && ((o.skills?.base + o.skills?.inc) - o.skills?.dec) >= (globalStore.baseMarketFilters.value.minSkills * 10) && ((o.skills?.base + o.skills?.inc) - o.skills?.dec) <= (globalStore.baseMarketFilters.value.maxSkills * 10)
          && ((o.multiplier?.base + o.multiplier?.inc) - o.multiplier?.dec) >= (globalStore.baseMarketFilters.value.minMultiplier * 10) && ((o.multiplier?.base + o.multiplier?.inc) - o.multiplier?.dec) <= (globalStore.baseMarketFilters.value.maxMultiplier * 10)
        ));
      }
      if (globalStore.marketPlayerTypeSelected.value === 'stadiums') {
        return getSortedList().filter((o) => (
          o.on_loan_price >= (globalStore.baseMarketFilters.value.minPrice * 100000000) && o.on_loan_price <= (globalStore.baseMarketFilters.value.maxPrice * 100000000)
          && o.age >= globalStore.baseMarketFilters.value.minAge && o.age <= globalStore.baseMarketFilters.value.maxAge
          && o.season_cost >= (globalStore.baseMarketFilters.value.minSalary * 100000000) && o.season_cost <= (globalStore.baseMarketFilters.value.maxSalary * 100000000)
          && ((o.luck?.base + o.luck?.inc) - o.luck?.dec) >= (globalStore.baseMarketFilters.value.minLuck * 10) && ((o.luck?.base + o.luck?.inc) - o.luck?.dec) <= (globalStore.baseMarketFilters.value.maxLuck * 10)
          && o.weather?.base >= (globalStore.baseMarketFilters.value.minWeather * 10) && o.weather?.base <= (globalStore.baseMarketFilters.value.maxWeather * 10)
        ));
      }
      let playerList = getSortedList();
      if (globalStore.marketPlayerTypeSelected.value === 'players' && globalStore.playerTypes.value.length > 0) {
        playerList = filterPlayers();
      }
      return playerList.filter((o) => (
        o.on_loan_price >= (globalStore.baseMarketFilters.value.minPrice * 100000000) && o.on_loan_price <= (globalStore.baseMarketFilters.value.maxPrice * 100000000)
        && o.age >= globalStore.baseMarketFilters.value.minAge && o.age <= globalStore.baseMarketFilters.value.maxAge
        && o.season_cost >= (globalStore.baseMarketFilters.value.minSalary * 100000000) && o.season_cost <= (globalStore.baseMarketFilters.value.maxSalary * 100000000)
        && ((o.luck?.base + o.luck?.inc) - o.luck?.dec) >= (globalStore.baseMarketFilters.value.minLuck * 10) && ((o.luck?.base + o.luck?.inc) - o.luck?.dec) <= (globalStore.baseMarketFilters.value.maxLuck * 10)
        && ((o.skills?.base + o.skills?.inc) - o.skills?.dec) >= (globalStore.baseMarketFilters.value.minSkills * 10) && ((o.skills?.base + o.skills?.inc) - o.skills?.dec) <= (globalStore.baseMarketFilters.value.maxSkills * 10)
        && ((o.multiplier?.base + o.multiplier?.inc) - o.multiplier?.dec) >= (globalStore.baseMarketFilters.value.minMultiplier * 10) && ((o.multiplier?.base + o.multiplier?.inc) - o.multiplier?.dec) <= (globalStore.baseMarketFilters.value.maxMultiplier * 10)
      ));
    });

    const filterAmount = computed(() => {
      let amount = 0;
      Object.keys(globalStore.baseMarketFilters.value).forEach((key) => {
        if (globalStore.baseMarketFilters.value[key] !== globalStore.coreMarketFilters[key]) {
          amount += 1;
        }
      });
      amount += globalStore.playerTypes.value.length;
      return amount;
    });

    function switchItemMenu(itemType) {
      globalStore.marketPlayerTypeSelected.value = itemType;
      if (itemType === 'managers') {
        globalStore.baseMarketFilters.value.maxAge = 65;
        globalStore.coreMarketFilters.maxAge = 65;
        globalStore.baseMarketFilters.value.minAge = 15;
        globalStore.coreMarketFilters.minAge = 15;
      }
      if (itemType === 'stadiums') {
        globalStore.baseMarketFilters.value.maxAge = 100;
        globalStore.coreMarketFilters.maxAge = 100;
        globalStore.baseMarketFilters.value.minAge = 1;
        globalStore.coreMarketFilters.minAge = 1;
      }
      if (itemType === 'players') {
        globalStore.baseMarketFilters.value.maxAge = 35;
        globalStore.coreMarketFilters.maxAge = 35;
        globalStore.baseMarketFilters.value.minAge = 15;
        globalStore.coreMarketFilters.minAge = 15;
      }
    }

    function clearFilters() {
      globalStore.playerTypes.value = [];
      globalStore.baseMarketFilters.value = {
        ...globalStore.coreMarketFilters,
      };
    }

    function togglePlayerType(playerType) {
      if (!globalStore.playerTypes.value?.includes(playerType)) {
        globalStore.playerTypes.value.push(playerType);
        return;
      }
      if (globalStore.playerTypes.value?.includes(playerType)) {
        const index = globalStore.playerTypes.value.indexOf(playerType);
        if (index > -1) {
          globalStore.playerTypes.value.splice(index, 1);
        }
      }
    }

    return {
      store,
      osAPI,
      globalStore,
      switchItemMenu,
      sortedItems,
      showFilters,
      togglePlayerType,
      clearFilters,
      filterAmount,
    };
  },
};
</script>

<style scoped>

.dropdown {
  border: 2px solid #C8EBE0;
  border-radius: 6px;
}

/*To remove button from IE11, thank you Matt */
select::-ms-expand {
  display: none;
  background: rgba(0, 0, 0, 0.3);
}

.select option {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.selectOne:after {
  content: '<>';
  font: 17px "Consolas", monospace;
  color: #00AF92;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 210px;
  /*Adjust for position however you want*/

  top: 12px;
  padding: 0 0 2px;
  border-bottom: 1px solid #00AF92;
  /*left line */

  position: absolute;
  pointer-events: none;
}

.selectOne select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */

  margin: 5px 0px;
  padding: 0px 12px;
  font-size: 16px;
  line-height: 1.75;
  background-color: #ffffff;
  background-image: none;
  -ms-word-break: normal;
  word-break: normal;
}

.selectTwo:after {
  content: '<>';
  font: 17px "Consolas", monospace;
  color: #00AF92;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 20px;
  /*Adjust for position however you want*/

  top: 12px;
  padding: 0 0 2px;
  border-bottom: 1px solid #00AF92;
  /*left line */

  position: absolute;
  pointer-events: none;
}

.selectTwo select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */

  margin: 5px 0px;
  padding: 0px 12px;
  font-size: 16px;
  line-height: 1.75;
  background-color: #ffffff;
  background-image: none;
  -ms-word-break: normal;
  word-break: normal;
}

.close {
  position: fixed;
  right: 11px;
  top: 11px;
}

.slide-menu-enter-active {
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  transform: translate(275px, 0);
  -webkit-transform: translate(275px, 0);
}

.slide-menu-leave-active {
  transform: translate(275px, 0);
  -webkit-transform: translate(275px, 0);
}

.slideLayer {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
}

@media (min-width: 768px) {
  .slideLayer {
    position: absolute;
  }
}

.filterLabel {
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #333333;
}

</style>
