import SearchClubs from './views/SearchClubs.vue';

const routes = [
  {
    path: '/search/clubs',
    name: 'SearchClubs',
    component: SearchClubs,
    meta: { title: 'Lineup - Neo FC' },
  },
];

export default routes;
