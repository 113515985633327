<template>
  <div class="h-full flex">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'IntroApp',
  setup() {
    return {};
  },
};
</script>
