<template>
  <div class="flex-1 bg-white md:rounded-2xl flex p-2">
    <div class="flex-1 overflow-auto nice-scroll pr-0">
      <div class="overflow-hidden md:min-h-[670px] px-4 pt-8 pb-4 flex flex-col items-center justify-center text-center">
        <img src="../assets/guide_lineup.png" class="mt-4 w-[70%]" />
        <div class="mt-12 font-bold text-2xl">Select a Lineup</div>
        <div class="mt-4">
          After buying some items, it is time to configure your club by selecting a lineup. Your lineup determents which items will affect your matches.
        </div>
        <div class="mt-4">
          You can manage your lineup by visiting the <span class="font-semibold text-green-700">Lineup</span> page.
        </div>
        <img src="../assets/lineup_navbar.png" class="my-4" />
        <div>Filter through the different item types and add the ones you like to your lineup by clicking the top right corner of the item:</div>
        <img src="../assets/lineup_card.png" class="my-4" />
        <div class="mt-4">
          To have a full club, you need to select:
        </div>
        <span class="font-bold opacity-90 mt-4">1 Goalkeeper</span>
        <span class="font-bold opacity-90">4 Defenders</span>
        <span class="font-bold opacity-90">3 Midfielders</span>
        <span class="font-bold opacity-90">3 Attackers</span>
        <span class="font-bold opacity-90">1 Manager</span>
        <span class="font-bold opacity-90">1 Stadium</span>
      </div>
      <div class="p-6">
        <button
          @click="goToNextStep()"
          class="text-white bg-[#00AF92] w-full p-4 font-bold rounded-lg"
        >
          NEXT
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  name: 'IntroStep6',
  setup() {
    const router = useRouter();

    function goToNextStep() {
      router.push({ name: 'IntroStep7' });
    }

    return {
      goToNextStep,
    };
  },
};
</script>
