export default {
  1: {
    rank: '1',
    name: 'Flamingo.Finance League',
    logo: '/img/tokens/transparent/FLM.svg',
    url: 'https://flamingo.finance',
  },
  2: {
    rank: '2',
    name: 'Meme2Earn.com League A',
    logo: '/img/tokens/transparent/NUDES.svg',
    url: 'https://nudes.army',
  },
  3: {
    rank: '2',
    name: 'Meme2Earn.com League B',
    logo: '/img/tokens/transparent/NUDES.svg',
    url: 'https://nudes.army',
  },
  4: {
    rank: '3',
    name: 'NEO League A',
    logo: '/img/tokens/transparent/NEO.svg',
  },
  5: {
    rank: '3',
    name: 'NEO League B',
    logo: '/img/tokens/transparent/NEO.svg',
  },
  6: {
    rank: '3',
    name: 'NEO League C',
    logo: '/img/tokens/transparent/NEO.svg',
  },
  7: {
    rank: '3',
    name: 'NEO League D',
    logo: '/img/tokens/transparent/NEO.svg',
  },
};
