<template>
  <div class="w-full h-[140px] flex flex-col justify-center footer">
    <div class="flex items-center justify-center gap-12">
      <a href="https://twitter.com/NEOBITS_" target="_blank">
        <img src="/img/social/twitter-white.svg" width="44" height="44"/>
      </a>
      <a href="https://discord.gg/a5R97QJnS6" target="_blank">
        <img src="/img/social/discord-white.svg" width="44" height="44"/>
      </a>
    </div>
    <div class="mt-4 text-lg font-bold tracking-wide text-white text-center">Copyright © NEO-SOCCER 2022</div>
  </div>
</template>

<script>

export default {
  name: 'AppFooter',
  setup() {
    return {};
  },
};
</script>

<style scoped>
.footer {
  background-image: url('/img/ui/footer-back.png');
  background-size: cover;
}
</style>
