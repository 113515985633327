<template>
  <div class="front-landpage h-full flex flex-1 flex-col items-center justify-center md:rounded-2xl relative">
    <div class="w-full h-full absolute opacity-50">
      <div class="neo-back w-full h-full rounded-2xl"></div>
    </div>
    <div class="z-10 relative w-full px-4">
      <slot></slot>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'onboarding-template',
  setup() {
    return {};
  },
};
</script>

<style scoped>

.front-landpage {
  background: conic-gradient(from 181.78deg at 50% 50%, #97FF57 0deg, #000000 46.06deg, #97FF57 85.09deg, #000000 137.72deg, #97FF57 196.38deg, #000000 256.44deg, #97FF57 292.56deg, #000000 329.12deg, #97FF57 360deg), conic-gradient(from 181.78deg at 50% 50%, #85FE26 0deg, #000000 46.06deg, #85FE26 85.09deg, #000000 137.72deg, #85FE26 196.38deg, #000000 256.44deg, #85FE26 292.56deg, #000000 329.12deg, #85FE26 360deg), radial-gradient(164.07% 148.29% at 48.05% -41.58%, #22DD9A 0%, #E1F664 17.66%, #67FC99 38.62%, #4FFF80 60.91%, #5DF7A4 84.51%, #58F6C7 100%);
  background-blend-mode: screen, difference, normal;
}

.neo-back {
  background-image: url('/img/icons/NeoSoccer.svg');
}

</style>
