<template>
  <div class="relative p-2 flex flex-col bg-white rounded-2xl mx-4">
    <div class="flex items-center mb-2">
      <div v-if="!item.on_loan" class="flex flex-grow text-[18px] font-bold mt-1 text-neobit-green uppercase">Lend out {{ item.item_type }}</div>
      <div v-if="item.on_loan" class="flex flex-grow text-[18px] font-bold mt-1 text-neobit-green uppercase">Edit {{ item.item_type }} listing</div>
      <div class="" @click="$emit('close')">
        <img :src="`/img/ui/cross.svg`" width="26"/>
      </div>
    </div>
    <div>
      <div class="text-[12px] font-bold" v-if="!item.on_loan">You’re about to lend out {{ item.name }}.</div>
      <div class="text-[12px] text-red-400" v-if="!item.on_loan">The {{item.itemType}} will be removed from your lineup if it is there</div>
      <div class="text-[12px] font-bold" v-if="item.on_loan">You’re about to edit loan listing of {{ item.name }}.</div>
      <div class="text-[12px] mt-1">The {{ item.item_type }} will return to you after the current season.</div>
      <div class="text-[12px] mt-1">If the {{ item.item_type }} is loaned out the club will pay 1 season contract extension.</div>
      <div class="text-[12px] mt-4 ml-1">1 Season loan price:</div>
      <div class="relative">
        <input class="pl-2 w-full input h-10 rounded-lg" placeholder="Amount" type="number" v-model="price">
        <div class="absolute right-4 top-2">bNEO</div>
      </div>
      <div class="text-[12px] mt-2 ml-1">Price including salary will be:</div>
      <div class="text-[12px] ml-1">{{price + (item.season_cost / 100000000) }}</div>

    </div>
    <div v-if="price >= 0.0001" class="confirm flex items-center justify-center text-[15px] mt-4" @click="$emit('lend', price)">CONFIRM</div>
    <div class="cancel flex items-center justify-center text-[15px] mt-3" @click="$emit('close')">CANCEL</div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'loan-item',
  props: {
    item: {
      type: Object,
    },
  },
  setup() {
    const price = ref(null);
    return {
      price,
    };
  },
};
</script>

<style scoped>
.input {
  border: 2px solid #C8EBE0;
}

.confirm {
  background: #00AF92;
  border-radius: 12px;
  height: 46px;
  color: #FFFFFF;
}

.cancel {
  background: #C8EBE0;
  border-radius: 12px;
  height: 46px;
  color: #00AF92;
}
</style>
