<template>
  <div class="flex-1 bg-white md:rounded-2xl flex p-2">
    <div class="flex-1 overflow-auto nice-scroll pr-0">
      <div class="overflow-hidden md:min-h-[670px] px-4 pt-8 pb-4 flex flex-col items-center justify-center text-center">
        <img src="../assets/guide_deposit.png" class="w-[60%]"/>
        <div class="mt-8 font-bold text-2xl">Deposit to Play</div>
        <div class="mt-6">
          You need some money to buy (or loan) a team to set up your team. The currency of NEO-SOCCER is a cryptocurrency called <span class="font-bold text-[#FFC13D]">bNEO</span> (<span class="font-bold text-[#00AF92]">NEO</span>).
        </div>
        <div class="mt-6">
          You can get it by sending <span class="font-bold text-[#FFC13D]">bNEO</span>
          or <span class="font-bold text-[#00AF92]">NEO</span> from your Neo wallet to your in-game wallet or sending
          <span class="font-bold text-[#00AF92]">NEO</span> from an exchange to your in-game wallet.
        </div>
        <div class="mt-8 font-bold text-xl">
          Depositing Crypto
        </div>
        <div class="mt-6">
          To deposit to your account, click the wallet icon in the top right corner when you log into your account.
        </div>
        <img src="../assets/guide_wallet_icon.svg" class="mt-8 mb-4 w-full" />
        <div class="mt-6">
          Then click <span class="font-bold">DEPOSIT OR WITHDRAW</span> and follow the instructions.
        </div>
      </div>
      <div class="p-6">
        <button
          @click="goToNextStep()"
          class="text-white bg-[#00AF92] w-full p-4 font-bold rounded-lg"
        >
          NEXT
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  name: 'IntroStep9',
  setup() {
    const router = useRouter();

    function goToNextStep() {
      router.push({ name: 'IntroStep10' });
    }

    return {
      goToNextStep,
    };
  },
};
</script>
