<template>
  <div class="relative p-2 flex flex-col bg-white rounded-2xl mx-4 w-96">
    <div class="flex items-center mb-2">
      <div v-if="!item.on_sale" class="flex flex-grow text-[18px] font-bold mt-1 text-neobit-green uppercase">Sell {{ item.itemType }}</div>
      <div v-if="item.on_sale" class="flex flex-grow text-[18px] font-bold mt-1 text-neobit-green uppercase">Edit {{ item.itemType }} Listing</div>
      <div class="" @click="$emit('close')">
        <img :src="`/img/ui/cross.svg`" width="26"/>
      </div>
    </div>
    <div>
      <div v-if="!item.on_sale && item.item_type !== 'consumable'">
        <div class="text-[12px]">You’re about to sell: {{ item.name }}</div>
        <div class="text-[12px]">The {{item.item_type}} will be removed from any lineup.</div>
      </div>
      <div v-if="!item.on_sale && item.item_type === 'consumable'">
        <div class="text-[12px]">You’re about to sell: {{ item.for_item }} {{ item.for_skill }}</div>
      </div>
      <div class="mt-2 font-bold text-[12px]" v-if="price && price < (item.base_burn_amount / item.age) / 100000000">
        Terminating the contract will give you <span class="text-red-500">{{(item.base_burn_amount / item.age) / 100000000}} bNEO</span><br />
        Sell the {{item.item_type}} for at least that amount.
      </div>
      <div class="text-[12px]" v-if="item.on_sale">Edit sell listing for: {{ item.name }}</div>
      <div class="text-[12px] mt-4 ml-1">Sell price:</div>
      <div class="relative">
        <input class="pl-2 w-full input h-10 rounded-lg" placeholder="Amount" type="number" v-model="price">
        <div class="absolute right-4 top-2">bNEO</div>
      </div>

    </div>
    <div
      v-if="price >= 0.0001 && price >= (item.base_burn_amount / item.age) / 100000000 && item.item_type !== 'consumable'"
      class="confirm flex items-center justify-center text-[15px] mt-4" @click="$emit('sell', price)">
      CONFIRM
    </div>
    <div v-if="price >= 0.0001 && item.item_type === 'consumable'" class="confirm flex items-center justify-center text-[15px] mt-4" @click="$emit('sell', price)">CONFIRM</div>
    <div class="cancel flex items-center justify-center text-[15px] mt-3" @click="$emit('close')">CANCEL</div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'sell-item',
  props: {
    item: {
      type: Object,
    },
  },
  setup() {
    const price = ref(null);
    return {
      price,
    };
  },
};
</script>

<style scoped>
.input {
  border: 2px solid #C8EBE0;
}

.confirm {
  background: #00AF92;
  border-radius: 12px;
  height: 46px;
  color: #FFFFFF;
}

.cancel {
  background: #C8EBE0;
  border-radius: 12px;
  height: 46px;
  color: #00AF92;
}
</style>
