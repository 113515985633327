<template>
  <div class="h-full relative">
    <div
      v-if="loading"
      class="absolute inset-0 w-full h-full"
    >
      <spinner></spinner>
    </div>
    <div class="absolute inset-0 bg-[#ECECEC] p-2 overflow-y-scroll nice-scroll">
      <!-- Search Input -->
      <div class="mb-2">
        <input
          v-model="searchQuery"
          class="w-full rounded shadow px-4 py-2 text-lg"
          placeholder="Search for club..."
          type="text"
          autocomplete="off"
        >
      </div>
      <!-- EOF Search Input -->

      <!-- Clubs -->
      <div>
        <div
          v-for="club in clubs"
          :key="club.id"
          @click="$router.push({ name: 'ClubView', params: { clubId: club.id } })"
        >
          <div v-if="!store.state.userStore.clubBots.includes(club.id)" class="flex items-center cursor-pointer p-4 bg-white mb-2 rounded shadow">
            <club-logo
              :club-id="club.id"
              class="w-12 h-12"
            />
            <div class="ml-4 text-left text-lg">
              {{ club.name }}
            </div>
          </div>
        </div>
      </div>
      <!-- EOF Clubs -->

      <div class="pb-32"></div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import Fuse from 'fuse.js';
import store from '@/store';
import ClubLogo from '@/apps/globalComponents/components/clubLogo.vue';

export default {
  name: 'SearchUsers',
  components: { ClubLogo },
  setup() {
    const searchQuery = ref('');
    const allClubs = store.state.userStore.clubNames;
    const clubObjects = Object.entries(allClubs).map(([id, name]) => ({ id, name }));

    const fuseOptions = {
      threshold: 0.5,
      keys: ['name'],
    };

    const clubs = computed(() => {
      if (searchQuery.value === '') return clubObjects;
      const fuse = new Fuse(clubObjects, fuseOptions);
      const result = fuse.search(searchQuery.value);

      return result.map((item) => item.item);
    });

    return {
      store,
      clubs,
      searchQuery,
    };
  },
};

</script>
