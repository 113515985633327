<template>
  <div v-if="loading" class="absolute inset-0 mx-4 rounded-[20px] overflow-hidden">
    <spinner></spinner>
  </div>
  <div v-if="step === 1" class="bg-white shadow-md p-4 flex flex-col items-center justify-center font-montserrat rounded-[20px] w-full">
    <div class="text-lg font-bold text-neobit-green text-center">RESET PASSWORD</div>
    <div class="mt-1 text-neobit-black font-medium text-center text-[15px]">Reset your NeoBits password</div>
    <input class="border-2 border-neobit-greenlight rounded-md bg-transparent outline-none mt-4 w-full px-3 py-2.5" v-model="email" placeholder="Email..." type="email"/>
    <button class="bg-neobit-green rounded-xl py-2.5 text-center w-full text-white text-[15px] font-bold mt-6" @click="reset">
      RESET PASSWORD
    </button>
    <button class="bg-neobit-greenlight rounded-xl py-2.5 text-center w-full text-neobit-green text-[15px] font-bold mt-4" @click="$router.push({ name: 'Login' })">
      CANCEL
    </button>
  </div>
  <div v-if="step === 2" class="bg-white shadow-md p-4 flex flex-col items-center justify-center font-montserrat rounded-[20px] w-full">
    <div class="text-lg font-bold text-neobit-green text-center">CHECK YOUR EMAIL</div>
    <div class="mt-1 text-neobit-black font-medium text-center mb-4 text-[15px]">We sent you a link to reset your password</div>
    <img src="/img/ui/activate.png">
  </div>
</template>

<script>
import { ref } from 'vue';
import backendAPI from '@/os/APIs/backendAPI';
import Spinner from '@/os/components/Spinner.vue';

export default {
  name: 'login-user',
  components: { Spinner },
  setup() {
    const step = ref(1);
    const email = ref('');
    const loading = ref(false);

    async function reset() {
      loading.value = true;
      try {
        await backendAPI.userForgotPassword(email.value);
        window.toast.success('Reset link sent');
        step.value = 2;
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        throw err;
      } finally {
        loading.value = false;
      }
    }

    return {
      email,
      reset,
      loading,
      step,
    };
  },
};
</script>

<style scoped>

</style>
