import axios from 'axios';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

let cdn = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

if (!process.env.VUE_APP_API_URL.startsWith('https://')) {
  cdn = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
  });
}

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('api_key');
  // eslint-disable-next-line
  config.headers.key = token;
  return config;
});

// <editor-fold desc="GAME INFO>

const GAME_INFO = '/game-info';
const LEAGUES = '/leagues';
const MATCH = '/matches';
const MATCHES = '/matches/league';

async function gameInfo() {
  const response = await api.get(`${GAME_INFO}`);
  return response.data;
}

async function leagues(season, league) {
  const response = await cdn.get(`${LEAGUES}?season=${season}&league=${league}`);
  return response.data;
}

async function match(matchId) {
  const response = await cdn.get(`${MATCH}?match_id=${matchId}`);
  return response.data;
}

async function matches(season, league) {
  const response = await cdn.get(`${MATCHES}?season=${season}&league=${league}`);
  return response.data;
}

// </editor-fold>

// <editor-fold desc="USER>

const USER_GET = '/user';
const USER_NEW = '/user';
const USER_LOGIN = '/user/login';
const USER_WALLET = '/user/wallet';
const USER_CHECK_DEPOSIT = '/user/wallet/check-deposit';
const USER_QUOTE = '/user/wallet/quote';
const USER_CONVERT = '/user/wallet/convert';
const USER_WITHDRAW = '/user/wallet/withdraw';
const USER_LOGOUT = '/user/logout';
const USER_FORGOT = '/user/forgot-password';
const USER_RESET_PASSWORD = '/user/change-password-code';
const USER_ACTIVATE = '/user/activate';
const USER_COMPLETE_INTRO = '/neo-soccer-user/intro';
const USER_GET_BITS_HISTORY = '/user/bits/history';
const USER_CLAIM_BITS = '/user/claim-bits';

async function userGet() {
  const response = await api.get(`${USER_GET}`);
  return response.data;
}

async function userCompleteIntro() {
  const response = await api.post(`${USER_COMPLETE_INTRO}`);
  return response.data;
}

async function userNew(username, email, password) {
  const data = {
    username,
    email,
    password,
  };
  const response = await api.post(`${USER_NEW}`, data);
  return response.data;
}

async function userActivate(code) {
  const response = await api.post(`${USER_ACTIVATE}?code=${code}`);
  return response.data;
}

async function userGetWallet(chain = 'NEO') {
  const response = await api.get(`${USER_WALLET}?chain=${chain}`);
  return response.data;
}

async function userCheckDeposit(chain = 'NEO') {
  const response = await api.get(`${USER_CHECK_DEPOSIT}?chain=${chain}`);
  return response.data;
}

async function userQuote(chain = 'NEO', token = 'FLM', amount = 0) {
  const response = await api.get(`${USER_QUOTE}?chain=${chain}&token=${token}&amount=${amount}`);
  return response.data;
}

async function userConvert(chain = 'NEO', token = 'FLM', amount = 0, minAmount = 0) {
  const response = await api.get(`${USER_CONVERT}?chain=${chain}&token=${token}&amount=${amount}&min_amount=${minAmount}`);
  return response.data;
}

async function userWithdraw(amount = 0, wallet = '', token = 'bNEO') {
  const response = await api.get(`${USER_WITHDRAW}?amount=${amount}&wallet=${wallet}&token=${token}`);
  return response.data;
}

async function userLogin(email, password) {
  const data = {
    email,
    password,
  };
  const response = await api.post(`${USER_LOGIN}`, data);
  return response.data;
}

async function userLogout() {
  const response = await api.post(`${USER_LOGOUT}`);
  return response.data;
}

async function userForgotPassword(email) {
  const data = {
    email,
  };
  const response = await api.post(`${USER_FORGOT}`, data);
  return response.data;
}

async function userResetPassword(code, password) {
  const data = {
    code,
    password,
  };
  const response = await api.post(`${USER_RESET_PASSWORD}`, data);
  return response.data;
}

async function userGetBitsHistory() {
  const response = await api.get(`${USER_GET_BITS_HISTORY}`);
  return response.data;
}

async function userClaimBits() {
  const response = await api.post(`${USER_CLAIM_BITS}`);
  return response.data;
}

// </editor-fold>

// <editor-fold desc="CLUB>

const CLUB_GET = '/club';
const CLUB_GET_MATCHES = '/club/matches';
const CLUB_GET_STATS = '/club/stats';
const CLUB_GET_NAMES = '/club/names';
const CLUB_CREATE = '/club/create';
const CLUB_CHECK_CODE = '/club/check-code';
const CLUB_EDIT_LINEUP = '/club/edit-lineup';
const CLUB_EDIT_BADGE = '/club/edit-badge';
const CLUB_SIGN_UP_FOR_LEAGUE = '/club/sign-up-for-league';
const CLUB_PLAY_FRIENDLY = '/club/play-friendly';
const CLUB_CUP_SIGNUP = '/club/sign-up-for-cup';
const CLUB_FRIENDLY_PLAYED = '/club/today-friendlies';

async function clubGet(clubId) {
  const response = await cdn.get(`${CLUB_GET}?club_id=${clubId}`);
  return response.data;
}

async function clubGetMatches(clubId, season) {
  const response = await cdn.get(`${CLUB_GET_MATCHES}?club_id=${clubId}&season=${season}`);
  return response.data;
}

async function clubGetStats(clubId) {
  const response = await cdn.get(`${CLUB_GET_STATS}?club_id=${clubId}`);
  return response.data;
}

async function clubGetNames() {
  const response = await cdn.get(`${CLUB_GET_NAMES}`);
  return response.data;
}

async function clubCreate(clubName, inviteCode) {
  const response = await api.post(`${CLUB_CREATE}?club_name=${clubName}&invite_code=${inviteCode}`);
  return response.data;
}

async function clubCheckCode(inviteCode) {
  const response = await api.post(`${CLUB_CHECK_CODE}?invite_code=${inviteCode}`);
  return response.data;
}

async function clubEditLineup(itemType, itemId, position, clubId) {
  const response = await api.post(`${CLUB_EDIT_LINEUP}?item_type=${itemType}&item_id=${itemId}&position=${position}&club_id=${clubId}`);
  return response.data;
}

async function clubEditBadge(clubId, badgeFile) {
  const data = new FormData();
  data.append('files', badgeFile);
  const headers = {
    // eslint-disable-next-line no-underscore-dangle
    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    key: localStorage.getItem('api_key'),
  };
  const response = await api.post(`${CLUB_EDIT_BADGE}?club_id=${clubId}`, data, headers);
  return response.data;
}

async function clubSignUpForLeague(clubId) {
  const response = await api.post(`${CLUB_SIGN_UP_FOR_LEAGUE}?club_id=${clubId}`);
  return response.data;
}

async function clubPlayFriendly(home, away) {
  const response = await api.post(`${CLUB_PLAY_FRIENDLY}?home=${home}&away=${away}`);
  return response.data;
}

async function clubSignUpForCup(clubId, cupId) {
  const response = await api.post(`${CLUB_CUP_SIGNUP}?club_id=${clubId}&cup_id=${cupId}`);
  return response.data;
}

async function clubFriendlyPlayed(clubId) {
  const response = await api.post(`${CLUB_FRIENDLY_PLAYED}?club=${clubId}`);
  return response.data;
}
// </editor-fold>

// <editor-fold desc="LIVE>

const LIVE_MATCHES = '/live/matches';
const GET_CUP = '/cup';

async function getLiveMatches() {
  const response = await cdn.get(LIVE_MATCHES);
  return response.data;
}

async function getCupData() {
  const response = await cdn.get(GET_CUP);
  return response.data;
}

// </editor-fold>

// <editor-fold desc="ITEMS>

const ITEM_GET = '/item';
const ITEM_USER_GET_ALL = '/item/user-items';
const ITEM_CLUB_GET_ALL = '/item/club-items';
const ITEM_GET_FOR_SALE = '/item/market-listed';
const ITEM_STATS = '/item/stats';
const ITEM_TRANSFERS = '/item/transfers';
const ITEM_MATCHES = '/item/matches';
const ITEM_MINT = '/item/mint';
const ITEM_MINTED = '/item/minted';
const ITEM_BUY = '/item/buy';
const ITEM_LEND = '/item/lend';
const ITEM_SELL = '/item/sell';
const ITEM_LOAN = '/item/loan';
const ITEM_TRANSFER = '/item/transfer';
const ITEM_CANCEL_MARKET = '/item/cancel-market';
const ITEM_BURN = '/item/burn';
const ITEM_EXTEND = '/item/extend';
const ITEM_USE = '/item/use';

async function itemGet(itemId) {
  const response = await cdn.get(`${ITEM_GET}?item_id=${itemId}`);
  return response.data;
}

async function itemGetStats(itemId) {
  const response = await cdn.get(`${ITEM_STATS}?item_id=${itemId}`);
  return response.data;
}

async function itemGetTransfers(itemId) {
  const response = await cdn.get(`${ITEM_TRANSFERS}?item_id=${itemId}`);
  return response.data;
}

async function itemUserGetAll(userId) {
  const response = await api.get(`${ITEM_USER_GET_ALL}?user_id=${userId}`);
  return response.data;
}

async function itemClubGetAll(clubId) {
  const response = await cdn.get(`${ITEM_CLUB_GET_ALL}?club_id=${clubId}`);
  return response.data;
}

async function itemGetForSale(itemType) {
  const response = await cdn.get(`${ITEM_GET_FOR_SALE}?item_type=${itemType}`);
  return response.data;
}

async function itemGetMatches(matchIds) {
  const response = await api.post(`${ITEM_MATCHES}`, matchIds);
  return response.data;
}

async function itemMint(itemType, clubId) {
  const response = await api.post(`${ITEM_MINT}?item_type=${itemType}&club_id=${clubId}`);
  return response.data;
}

async function itemBuy(itemType, itemId, ClubID) {
  const response = await api.post(`${ITEM_BUY}?item_type=${itemType}&item_id=${itemId}&club_id=${ClubID}`);
  return response.data;
}

async function itemLoan(itemType, itemId, ClubID) {
  const response = await api.post(`${ITEM_LEND}?item_type=${itemType}&item_id=${itemId}&club_id=${ClubID}`);
  return response.data;
}

async function itemSell(itemType, itemId, price) {
  const response = await api.post(`${ITEM_SELL}?item_type=${itemType}&item_id=${itemId}&price=${price}`);
  return response.data;
}

async function itemLend(itemType, itemId, price) {
  const response = await api.post(`${ITEM_LOAN}?item_type=${itemType}&item_id=${itemId}&price=${price}`);
  return response.data;
}

async function itemTransfer(itemType, itemId, toClub) {
  const response = await api.post(`${ITEM_TRANSFER}?item_type=${itemType}&item_id=${itemId}&to_club_id=${toClub}`);
  return response.data;
}

async function itemCancelMarket(itemType, itemId) {
  const response = await api.post(`${ITEM_CANCEL_MARKET}?item_type=${itemType}&item_id=${itemId}`);
  return response.data;
}

async function itemBurn(itemType, itemId) {
  const response = await api.post(`${ITEM_BURN}?item_type=${itemType}&item_id=${itemId}`);
  return response.data;
}

async function itemExtend(itemType, itemId, seasons) {
  const response = await api.post(`${ITEM_EXTEND}?item_type=${itemType}&item_id=${itemId}&seasons=${seasons}`);
  return response.data;
}

async function itemUse(consumableId, itemId, newName) {
  const response = await api.post(`${ITEM_USE}?consumable_id=${consumableId}&item_id=${itemId}&new_name=${newName}`);
  return response.data;
}

async function itemMinted(itemId, image) {
  const data = {
    item_id: itemId,
  };
  if (image !== null) {
    data.image = image;
  }
  const response = await api.post(ITEM_MINTED, data);
  return response.data;
}

// </editor-fold>

export default {
  gameInfo,
  leagues,
  match,
  matches,
  userGet,
  userNew,
  userActivate,
  userGetWallet,
  userCheckDeposit,
  userQuote,
  userConvert,
  userWithdraw,
  userCompleteIntro,
  userLogin,
  userLogout,
  userForgotPassword,
  userResetPassword,
  userGetBitsHistory,
  userClaimBits,
  clubGet,
  clubGetMatches,
  clubGetStats,
  clubGetNames,
  clubCreate,
  clubCheckCode,
  clubEditLineup,
  clubEditBadge,
  clubSignUpForLeague,
  clubPlayFriendly,
  clubSignUpForCup,
  clubFriendlyPlayed,
  getLiveMatches,
  itemGet,
  itemGetStats,
  itemGetTransfers,
  itemGetMatches,
  itemUserGetAll,
  itemClubGetAll,
  itemGetForSale,
  itemMint,
  itemBuy,
  itemLend,
  itemSell,
  itemLoan,
  itemTransfer,
  itemCancelMarket,
  itemBurn,
  itemExtend,
  itemMinted,
  itemUse,
  getCupData,
};
