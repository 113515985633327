<template>
  <div
    v-if="user.userId && user.active && user.intro && !offline"
    class="text-black flex h-full flex-col relative appContainer bg-[#ECECEC] md:rounded-2xl"
  >
    <div v-if="!store.state.userStore.production" class="bg-red-500 text-center w-full text-white font-bold">TESTING ENVIRONMENT</div>
    <div class="w-full flex items-center px-6 pb-4 pt-8 relative bg-white md:rounded-t-2xl shadow-sm">
      <div class="flex-grow flex items-center">
        <img src="/img/icons/item/back.svg" @click="goBack()" height="16" class="cursor-pointer mr-1"
             v-if="backButton"/>
        <img src="/img/ui/logo.svg" class="h-4"/>
        <button
          v-if="isLive"
          class="bg-[#E80054] rounded-xl px-1.5 text-white text-[11px] font-bold ml-2"
          @click="$router.push({ name: 'LiveMatch', params: { type: liveType, id: liveId } })"
        >
          LIVE
        </button>
      </div>
      <router-link
        :to="{name: 'SearchClubs'}"
        class="px-2 py-1 mx-2"
        tag="div"
      >
        <i class="fa-solid fa-magnifying-glass text-green-500"></i>
      </router-link>
      <div
        @click="showUserMenu = true"
        class="border-2 rounded-xl flex cursor-pointer -mt-2"
      >
        <div>
          <div class="px-2 -mb-1 border-r-2 flex items-center justify-end">
            <sexy-number
              :number="balance / 100000000"
              :decimals="4"
              class="text-neobit-green text-[12px] font-semibold"
            />
            <img src="/img/tokens/transparent/bNEO.svg" class="w-5 h-5"/>
          </div>
          <div class="px-2 border-r-2 flex items-center justify-end">
            <sexy-number
              :number="bits"
              :decimals="0"
              class="text-neobit-green text-[12px] font-semibold"
            />
            <img src="/img/tokens/transparent/BITS.svg" class="w-5 h-5"/>
          </div>
        </div>
        <div v-if="!showUserMenu" class="flex items-center">
          <div class="w-min-8">
            <club-logo
              :club-id="store.state.userStore.club.id"
              class="w-8 h-8 p-1"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!signedUp" @click="openSeasonSignup = true"
         class="bg-red-600 cursor-pointer text-white h-10 flex items-center justify-center font-bold border-b">
      You are not signed up for season {{ user.currentSeason + 1 }}
    </div>
    <div v-if="store.state.userStore.needNewContract > 0" @click="router.push({'name': 'Lineup'})"
         class="bg-red-600 cursor-pointer text-white h-10 flex items-center justify-center font-bold border-b">
      {{ store.state.userStore.needNewContract }} of your Items need new contract
    </div>
    <div v-if="showUserMenu" class="absolute inset-0 bg-opacity-50 z-50 bg-black md:rounded-2xl">
      <div class="h-[470px] w-full bg-white absolute left-0 z-50 pt-9 px-6 md:rounded-2xl">
        <div>
          <div class="flex items-center pb-4">
            <div class="flex-grow"><img src="/img/ui/logo.svg" class="h-4"/></div>
            <div @click="showUserMenu = false"><img src="/img/icons/close.svg" class="w-6 h-6"/></div>
          </div>
          <div class="user-border"></div>
          <div class="flex py-2 text-neobit-green items-center cursor-pointer" @click="goToAndClose('Wallet')">
            <div class="wallet w-8 h-8"></div>
            <div class="flex-grow ml-1 font-bold">Wallet</div>
            <div class="font-medium text-[15px] mr-2 opacity-80">Balance:</div>
            <div class="">
              <sexy-number
                :number="balance / 100000000"
                :decimals="4"
                class="text-[15px] font-bold"
              />
            </div>
          </div>
          <button
            class="px-4 py-2.5 bg-neobit-greenlight text-neobit-green rounded-xl text-[15px] font-bold w-full mb-3"
            @click="goToAndClose('Wallet')"
          >
            DEPOSIT OR WITHDRAW
          </button>
        </div>

        <div class="user-border"></div>
        <div class="flex py-2 text-neobit-green font-semibold items-center cursor-pointer"
             @click="goToAndClose('MyClubs')">
          <div class="clubs w-8 h-8"></div>
          <div class="flex-grow ml-1">Clubs</div>
          <div class="font-normal mr-2">Count:</div>
          <div class="">
            <sexy-number
              :number="user.clubIds.length"
              :decimals="0"
              class="text-neobit-green text-[15px] font-semibold"
            />
          </div>
        </div>
        <div class="user-border"></div>
        <div class="flex py-2 text-neobit-green font-semibold items-center cursor-pointer"
             @click="goToAndClose('InviteCode')">
          <div class="invite w-8 h-8"></div>
          <div class="ml-1 flex-grow">Invite Codes</div>
          <div class="font-normal mr-2">Available:</div>
          <div class="">
            <sexy-number
              :number="user.invites.length"
              :decimals="0"
              class="text-neobit-green text-[15px] font-semibold"
            />
          </div>
        </div>
        <div class="user-border"></div>
        <div class="flex py-2 text-neobit-green font-semibold items-center cursor-pointer"
             @click="goToAndClose('MyBits')">
          <img src="/img/tokens/transparent/BITS.svg" class="w-6 h-6 mx-1"/>
          <div class="ml-1 flex-grow">BITS</div>
          <div class="font-normal mr-2">Count:</div>
          <div class="">
            <sexy-number
              :number="store.state.userStore.tokens.BITS"
              :decimals="0"
              class="text-neobit-green text-[15px] font-semibold"
            />
          </div>
        </div>
        <div class="user-border"></div>
        <div class="flex py-2 text-neobit-green font-semibold items-center cursor-pointer"
             @click="goToAndClose('MyStonks')">
          <img src="/img/tokens/transparent/STONKS.svg" class="w-6 h-6 mx-1"/>
          <div class="ml-1 flex-grow">STONKS</div>
          <div class="font-normal mr-2">Count:</div>
          <div class="">
            <sexy-number
              :number="store.state.userStore.tokens.STONKS"
              :decimals="0"
              class="text-neobit-green text-[15px] font-semibold"
            />
          </div>
        </div>
        <div class="user-border"></div>
        <a class="flex py-2 text-neobit-green font-semibold items-center cursor-pointer"
           href="https://coda.io/d/_dK-7wooOq3T/NEO-FC_suw3M" target="_blank">
          <div class="docs w-8 h-8"></div>
          <div class="ml-1 flex-grow">Documentation</div>
        </a>
        <div class="user-border"></div>
        <div
          class="flex py-2 text-neobit-green font-semibold items-center cursor-pointer"
          @click="logout"
        >
          <div class="logout w-6 h-6 p-1 ml-2"></div>
          <div class="flex-grow ml-1">Logout</div>
        </div>
      </div>
    </div>
    <!-- Main Screen -->
    <div class="flex-grow h-full overflow-y-scroll no-scroll">
      <slot></slot>
    </div>
    <div class="subMenu fixed md:absolute" v-if="subMenu">
      <div
        class="border bg-white md:rounded-b-2xl relative items-center flex h-16 p-1 justify-between rounded-t-3xl font-bold text-[11px]">
        <div class="pl-2">
          <router-link :to="{ name: 'Overview' }" class="cursor-pointer flex flex-col items-center hover:bg-green-400 hover:bg-opacity-20 transition duration-200 rounded p-1">
            <div :class="currentPath === 'OverviewMain' ? 'tab' : 'no-tab'"/>
            <div class="w-8 h-8 mt-1 overview" :class="currentPath === 'OverviewMain' ? 'activeIcon' : ''"/>
            <div :class="currentPath === 'OverviewMain' ? 'active-text' : 'inactive-text'">Overview</div>
          </router-link>
        </div>
        <div>
          <router-link :to="{ name: 'Lineup' }" class="cursor-pointer flex flex-col items-center hover:bg-green-400 hover:bg-opacity-20 transition duration-200 rounded p-1">
            <div :class="currentPath === 'LineupMain' ? 'tab' : 'no-tab'"/>
            <div class="w-8 h-8 mt-1 lineup" :class="currentPath === 'LineupMain' ? 'activeIcon' : ''"/>
            <div :class="currentPath === 'LineupMain' ? 'active-text' : 'inactive-text'">Lineup</div>
          </router-link>
        </div>
        <div>
          <router-link :to="{ name: 'Tournament' }" class="cursor-pointer flex flex-col items-center hover:bg-green-400 hover:bg-opacity-20 transition duration-200 rounded p-1">
            <div :class="tournamentRoutes.includes(currentPath) ? 'tab' : 'no-tab'"/>
            <div class="w-8 h-8 mt-1 ranking"
                 :class="tournamentRoutes.includes(currentPath) ? 'activeIcon' : ''"/>
            <div :class="tournamentRoutes.includes(currentPath) ? 'active-text' : 'inactive-text'">
              Tournaments
            </div>
          </router-link>
        </div>
        <div>
          <router-link :to="{ name: 'Market' }" class="cursor-pointer flex flex-col items-center hover:bg-green-400 hover:bg-opacity-20 transition duration-200 rounded p-1">
            <div :class="currentPath === 'MarketMain' ? 'tab' : 'no-tab'"/>
            <div class="w-8 h-8 mt-1 market" :class="currentPath === 'MarketMain' ? 'activeIcon' : ''"/>
            <div :class="currentPath === 'MarketMain' ? 'active-text' : 'inactive-text'">Market</div>
          </router-link>
        </div>
        <div class="pr-2">
          <router-link :to="{ name: 'Recruit' }" class="cursor-pointer flex flex-col items-center hover:bg-green-400 hover:bg-opacity-20 transition duration-200 rounded p-1">
            <div :class="currentPath === 'RecruitMain' ? 'tab' : 'no-tab'"/>
            <div class="w-8 h-8 mt-1 recruit" :class="currentPath === 'RecruitMain' ? 'activeIcon' : ''"/>
            <div :class="currentPath === 'RecruitMain' ? 'active-text' : 'inactive-text'">Recruit</div>
          </router-link>
        </div>
      </div>
      <div v-if="!store.state.userStore.production" class="bg-red-500 text-center w-full text-white font-bold">TESTING ENVIRONMENT</div>
    </div>
    <div
      v-if="openSeasonSignup"
      class="absolute flex items-center justify-center inset-0 bg-black bg-opacity-40 text-black z-50 md:rounded-2xl"
    >
      <sign-up-for-season
        :season="user.currentSeason"
        @close="openSeasonSignup = false"
        @signup="signUp()"
      />
    </div>
  </div>
  <onboarding-template v-if="!user.userId && !offline">
    <login-user></login-user>
  </onboarding-template>
  <item-modal v-if="user.userId && !offline"></item-modal>
  <spinner v-if="loading"></spinner>
  <div v-if="offline" class="bg-white flex items-center m-6 justify-center rounded-lg">
    <div class="flex flex-col items-center justify-center">
      <img src="img/ui/offline.png" width="200">
      <div class="text-center mt-4 text-lg p-4 ">{{ offlineMessage }}</div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import LoginUser from '@/apps/user/views/Login.vue';
import { computed, ref, watch } from 'vue';
import ItemModal from '@/apps/globalComponents/modals/ItemModal.vue';
import { useRouter } from 'vue-router';
import backendAPI from '@/os/APIs/backendAPI';
import Spinner from '@/os/components/Spinner.vue';
import SignUpForSeason from '@/os/components/templates/modals/signUpForSeason.vue';
import ClubLogo from '@/apps/globalComponents/components/clubLogo.vue';
import OnboardingTemplate from './OnboardingTemplate.vue';

export default {
  name: 'app-interface',
  components: {
    SignUpForSeason,
    Spinner,
    ItemModal,
    LoginUser,
    OnboardingTemplate,
    ClubLogo,
  },
  props: {
    subMenu: {
      type: Boolean,
      default: true,
    },
    backButton: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter();
    const showUserMenu = ref(false);
    const loading = ref(false);
    const codeChecked = ref(false);
    const openSeasonSignup = ref(false);
    const i1 = ref('');
    const i2 = ref('');
    const i3 = ref('');
    const i4 = ref('');
    const i5 = ref('');
    const i6 = ref('');
    const offline = computed(() => store.state.userStore.offline);
    const offlineMessage = computed(() => store.state.userStore.offlineMessage);
    const clubName = ref('');
    const balance = computed(() => store.state.userStore.balance);
    const bits = computed(() => store.state.userStore.tokens.BITS);
    const user = computed(() => store.state.userStore);
    const currentPath = computed(() => router.currentRoute.value.name);
    const signedUp = computed(() => {
      if (user.value.currentSeason === 0 && user.value.club.signed_up_for_season === 0) {
        return false;
      }
      if (user.value.club.signed_up_for_season <= user.value.currentSeason) {
        if (user.value.currentRound > 38 && (user.value.club.signed_up_for_season < user.value.currentSeason + 1)) {
          return false;
        }
      }
      return user.value.club.signed_up_for_season >= user.value.currentSeason;
    });
    const liveType = ref('');
    const liveId = ref('');
    const isLive = computed(() => {
      const liveData = store.state.userStore.liveMatches;
      if (!liveData) return false;
      let flag = false;
      const userClubId = store.state.userStore.club.id;
      /* eslint-disable consistent-return */
      if (liveData.cup) {
        Object.keys(liveData.cup).forEach((cupId) => {
          Object.keys(liveData.cup[cupId]).forEach((matchId) => {
            const match = liveData.cup[cupId][matchId];
            if (match.home === userClubId || match.away === userClubId) {
              liveType.value = 'cup';
              liveId.value = cupId;
              flag = true;
            }
          });
        });
      }
      if (flag) return true;
      if (liveData.league) {
        Object.keys(liveData.league).forEach((leagueId) => {
          Object.keys(liveData.league[leagueId]).forEach((matchId) => {
            const match = liveData.league[leagueId][matchId];
            if (match.home === userClubId || match.away === userClubId) {
              liveType.value = 'league';
              liveId.value = leagueId;
              flag = true;
            }
          });
        });
      }
      if (flag) return true;
      if (liveData.practice) {
        Object.keys(liveData.practice[1]).forEach((matchId) => {
          const match = liveData.practice[1][matchId];
          if (match.home === userClubId || match.away === userClubId) {
            liveType.value = 'league';
            liveId.value = 1;
            flag = true;
          }
        });
      }
      return flag;
    });
    const tournamentRoutes = ['TableMain', 'NeoLeague', 'RoundMain', 'FriendlyMatch', 'SearchOpponent', 'LiveMatch', 'CupOverview', 'CupDetails'];

    function userWatchHandler(updatedUser) {
      // Check if user is activated.
      if (updatedUser.userId && !updatedUser.active) {
        router.push({ name: 'EnterInviteCode' });
        return;
      }

      // Check if user has completed intro.
      if (updatedUser.userId && !updatedUser.intro) {
        router.push({ name: 'Intro' });
      }
    }

    watch(
      user,
      userWatchHandler,
      {
        immediate: true,
        deep: true,
      },
    );

    async function makeClub() {
      loading.value = true;
      try {
        const code = i1.value.toUpperCase() + i2.value.toUpperCase() + i3.value.toUpperCase() + i4.value.toUpperCase() + i5.value.toUpperCase() + i6.value.toUpperCase();
        await backendAPI.clubCreate(clubName.value, code);
        await store.dispatch('userStore/getUserInfo');
        await store.dispatch('userStore/getUserClub', { clubId: false });
        loading.value = false;
      } catch (err) {
        console.log(err.response.data.detail);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function logout() {
      loading.value = true;
      try {
        await backendAPI.userLogout();
        localStorage.removeItem('api_key');
        store.commit('userStore/logOut');
        await router.push({ name: 'Onboarding' });
        loading.value = false;
      } catch (err) {
        console.log(err.response.data.detail);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    async function signUp() {
      loading.value = true;
      try {
        await backendAPI.clubSignUpForLeague(store.state.userStore.club.id);
        await store.dispatch('userStore/getUserInfo');
        await store.dispatch('userStore/getUserClub', { clubId: store.state.userStore.club.id });
        window.toast.success('Signed-up successfully');
        openSeasonSignup.value = false;
      } catch (err) {
        console.log(err);
        window.toast.error(err.response.data.detail);
        throw err;
      } finally {
        loading.value = false;
      }
    }

    function goToAndClose(app) {
      showUserMenu.value = false;
      router.push({ name: app });
    }

    function goBack() {
      const history = window.history.length;
      if (history > 2) {
        router.back();
      } else {
        router.push({ name: 'Overview' });
      }
    }

    return {
      router,
      store,
      balance,
      bits,
      user,
      currentPath,
      showUserMenu,
      logout,
      makeClub,
      goBack,
      loading,
      clubName,
      codeChecked,
      signedUp,
      signUp,
      openSeasonSignup,
      goToAndClose,
      offline,
      offlineMessage,
      tournamentRoutes,
      isLive,
      liveType,
      liveId,
    };
  },
};
</script>

<style scoped lang="scss">
@media (max-width: 768px) {
  .appContainer {
    height: calc(100vh);
    padding-bottom: 64px;
  }
}

@media (min-width: 768px) {
  .appContainer {
    padding-bottom: 4rem;
  }
}

.user-border {
  border: 1px solid rgba(0, 175, 146, 0.1);
}

.overview {
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-mask: url(resources/overview.svg) no-repeat center;
  mask: url(resources/overview.svg) no-repeat center;
}

.lineup {
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-mask: url(resources/lineup.svg) no-repeat center;
  mask: url(resources/lineup.svg) no-repeat center;
}

.market {
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-mask: url(resources/transfer.svg) no-repeat center;
  mask: url(resources/transfer.svg) no-repeat center;
}

.ranking {
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-mask: url(resources/ranking.svg) no-repeat center;
  mask: url(resources/ranking.svg) no-repeat center;
}

.recruit {
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-mask: url(resources/recruit.svg) no-repeat center;
  mask: url(resources/recruit.svg) no-repeat center;
}

.wallet {
  background-color: #00E599;
  -webkit-mask: url(/img/ui/wallet.svg) no-repeat center;
  mask: url(/img/ui/wallet.svg) no-repeat center;
}

.logout {
  background-color: #00E599;
  -webkit-mask: url(/img/ui/logout.svg) no-repeat center;
  mask: url(/img/ui/logout.svg) no-repeat center;
}

.invite {
  background-color: #00E599;
  -webkit-mask: url(/img/ui/invite.svg) no-repeat center;
  mask: url(/img/ui/invite.svg) no-repeat center;
}

.docs {
  background-color: #00E599;
  -webkit-mask: url(/img/ui/docs.svg) no-repeat center;
  mask: url(/img/ui/docs.svg) no-repeat center;
}

.clubs {
  background-color: #00E599;
  -webkit-mask: url(/img/ui/clubs.svg) no-repeat center;
  mask: url(/img/ui/clubs.svg) no-repeat center;
}

.activeIcon {
  background-color: #00AF92;
}

.active-text {
  color: #00AF92;
}

.inactive-text {
  color: rgba(0, 0, 0, 0.25);
}

.tab {
  background: #00AF92;
  border-radius: 0px 0px 4px 4px;
  width: 42px;
  height: 3px;
  margin-top: -7px;
}

.no-tab {
  background: rgba(0, 0, 0, 0.0);
  border-radius: 0px 0px 4px 4px;
  width: 42px;
  height: 3px;
  margin-top: -11px;
}

.subMenu {
  overflow: hidden;
  filter: drop-shadow(0px -2px 12px rgba(51, 51, 51, 0.12));
  z-index: 11;
  bottom: 0;
  width: 100%;
}

.icon {
  background: #00AF92;
  margin-top: -0.5px;
  border-radius: 0px 11px 11px 0px;
}

.box {
  width: 30px;
  height: 45px;
  border: 2px solid #C8EBE0;
  border-radius: 6px;
  margin-right: 3px;
  margin-left: 3px;
  text-align: center;
  text-transform: uppercase;
}

.clubBox {
  width: 180px;
  height: 45px;
  border: 2px solid #C8EBE0;
  border-radius: 6px;
  margin-right: 3px;
  margin-left: 3px;
  text-align: center;
}

input:focus {
  outline: 2px solid #00AF92;
}
</style>
