<template>
  <simple-modal
    modalName="signup-cup"
  >
    <div class="bg-white px-4 py-3 rounded-lg shadow relative w-[378px]">
      <div class="z-10 absolute inset-0 w-full h-full rounded-lg blur-back flex flex-col items-center justify-center" v-if="page === 1">
        <simple-spinner></simple-spinner>
        <span class="font-bold text-xl mt-5 text-neobit-black tracking-wide">WORKING</span>
        <span class="text-sm font-medium tracking-wide text-neobit-black mt-1">Manager signs the contract...</span>
      </div>
      <div class="z-10 absolute inset-0 w-full h-full rounded-lg blur-back flex flex-col items-center justify-between p-4" v-if="page === 2">
        <div class="flex flex-col items-center justify-center flex-grow">
          <img src="../../assets/success.svg" width="104" height="104" />
          <span class="font-bold text-xl mt-5 text-neobit-black tracking-wide">SUCCESSFUL</span>
          <span class="text-sm font-medium tracking-wide text-neobit-black mt-1">You've signed up for the cup!</span>
        </div>
        <button class="text-white bg-neobit-green w-full text-[15px] font-bold tracking-wide py-2.5 rounded-xl" @click="closeModal()">
          DONE
        </button>
      </div>
      <div class="z-10 absolute inset-0 w-full h-full rounded-lg blur-back flex flex-col items-center justify-between p-4" v-if="page === 3">
        <div class="flex flex-col items-center justify-center flex-grow">
          <img src="../../assets/fail.svg" width="104" height="104" />
          <span class="font-bold text-xl mt-5 text-neobit-black tracking-wide">FAILED</span>
          <span class="text-sm font-medium tracking-wide text-neobit-black mt-1">Something went wrong...</span>
        </div>
        <button class="text-white bg-neobit-green w-full text-[15px] font-bold tracking-wide py-2.5 rounded-xl" @click="page = 0">
          CANCEL
        </button>
      </div>
      <div class="text-lg font-bold text-neobit-green tracking-wide">SIGN UP FOR CUP</div>
      <div class="mx-4 border-t border-neobit-green border-opacity-10 my-3"></div>
      <div class="text-[15px] font-medium text-neobit-black">Selected Cup:</div>
      <div class="mt-2 border-neobit-greenlight border-2 rounded-lg p-2 flex items-center gap-2" :class="`border-theme-${cup.theme} theme-${cup.theme}`">
        <img :src="`/img/ui/cup/${cup.theme}.svg`" class="w-[42px] h-[42px]" />
        <span class="text-[17px] font-semibold text-neobit-black">{{ cup.cup_name }}</span>
      </div>
      <div class="text-[15px] font-medium text-neobit-black mt-4">Entry Cost:</div>
      <div class="mt-2 border-neobit-greenlight border-2 rounded-lg py-2 px-4 flex items-center justify-between">
        <sexy-number
          :number="helpers.getHumanSum(cup.registration_fee, cup.payment_token)"
          :decimals="helpers.getTokenDisplayDecimals(cup.payment_token)"
          class="text-[15px] font-medium text-neobit-black"
        />
        <div class="flex items-center gap-1">
          <img :src="`/img/tokens/transparent/${helpers.getPriceLogo(cup.payment_token)}.svg`" class="w-8 h-8"/>
          <span class="text-[15px] font-medium text-neobit-black">{{ cup.payment_token }}</span>
        </div>
      </div>
      <button class="mt-6 rounded-xl bg-neobit-green w-full py-2.5 text-white text-[15px] font-bold" @click="confirm()">
        CONFIRM
      </button>
      <button @click="closeModal()" class="mt-4 rounded-xl bg-neobit-greenlight py-2.5 text-neobit-green text-[15px] font-bold tracking-wide w-full">CANCEL</button>
    </div>
  </simple-modal>
</template>

<script>

import { computed, ref } from 'vue';
import SimpleSpinner from '@/os/components/SimpleSpinner.vue';
import SimpleModal from '@/os/components/modals/SimpleModal.vue';
import store from '@/store';
import osAPI from '@/os/APIs/osAPI';
import backendAPI from '@/os/APIs/backendAPI';
import helpers from '../helpers';

export default {
  name: 'signup-cup-modal',
  components: {
    SimpleSpinner,
    SimpleModal,
  },
  setup() {
    const page = ref(0);
    const cup = computed(() => store.state.osStore.modalData);

    async function confirm() {
      page.value = 1;
      try {
        await backendAPI.clubSignUpForCup(store.state.userStore.club.id, cup.value.id);
        await store.dispatch('userStore/getUserInfo');
        await store.dispatch('userStore/getCups');
        page.value = 2;
      } catch (error) {
        console.log(error, 'Cup Signup error');
        window.toast.error('Cup Signup error');
        page.value = 3;
      }
    }

    function closeModal() {
      page.value = 0;
      osAPI.triggerModal('');
    }

    return {
      page,
      store,
      confirm,
      closeModal,
      cup,
      helpers,
    };
  },
};
</script>

<style scoped>
.blur-back {
  background: rgba(255, 255, 255, 0.82);
  backdrop-filter: blur(8px);
}

.border-theme-green {
  border: 1px solid #00af924f;
}

.border-theme-pink {
  border: 1px solid #9435e244;
}

.theme-green {
  background: linear-gradient(133.61deg, rgba(0, 229, 153, 0.32) 0%, rgba(0, 175, 146, 0.32) 99.62%), linear-gradient(92.64deg, #F8F8F8 0%, rgba(248, 248, 248, 0) 100%);
}

.theme-pink {
  background: linear-gradient(218.88deg, rgba(148, 53, 226, 0.16) 10.9%, rgba(100, 36, 153, 0.16) 89.1%), #FFFFFF;
}
</style>
