<template>
  <div class="flex font-number items-baseline">
    <div>{{ integerPart }}</div>
    <div class="opacity-75">{{ fractionalPart }}</div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import {
  getInteger,
  getFractional,
  getAutomaticDecimals,
  getNumberWithUnitSuffix,
} from '@/os/APIs/utilsAPI';

export default {
  name: 'FlmNumber',
  props: {
    number: {
      default: null,
    },
    decimals: {
      type: Number,
      default: 8,
    },
    autoDecimals: {
      type: Boolean,
      default: false,
    },
    autoDecimalsSensitivity: {
      type: Number,
      default: 2,
    },
    unitSuffix: {
      type: Boolean,
      default: false,
    },
    minDecimals: {
      type: Number,
      default: 0,
    },
    animate: {
      type: Boolean,
      default: false,
    },
    animationDuration: {
      type: Number,
      default: 300,
    },
  },
  setup(props) {
    const localNumber = computed(() => {
      const baseNumber = props.number;

      if (props.unitSuffix) {
        const suffixInfo = getNumberWithUnitSuffix(baseNumber);
        return suffixInfo.number;
      }

      return baseNumber;
    });

    const animatedNumber = ref(localNumber.value);

    let animationInterval = null;

    function animateTo(newNumber, prevNumber) {
      if (animationInterval != null) {
        clearInterval(animationInterval);
      }

      animatedNumber.value = prevNumber;
      const intervalCount = 30;
      const intervalDurationMs = props.animationDuration / intervalCount;
      const increment = (newNumber - prevNumber) / intervalCount;
      const isCountingDown = newNumber < prevNumber;

      animationInterval = setInterval(
        () => {
          const isFinished = isCountingDown
            ? animatedNumber.value <= newNumber
            : animatedNumber.value >= newNumber;

          if (isFinished) {
            clearInterval(animationInterval);
            animatedNumber.value = newNumber;
            return;
          }

          animatedNumber.value += increment;
        },
        intervalDurationMs,
      );
    }

    if (props.animate) {
      watch(localNumber, (newNumber, oldNumber) => {
        animateTo(newNumber, oldNumber);
      });
    }

    const integerPart = computed(() => {
      if (Number.isNaN(props.number) || typeof props.number !== 'number') return '-';

      const baseNumber = (props.animate) ? animatedNumber.value : localNumber.value;
      const number = getInteger(baseNumber);
      const numberString = number.toFormat().replace('-', '');
      const sign = number.lt(0) ? '-' : '';

      const prefix = '';

      return `${sign}${prefix}${numberString}`;
    });

    const fractionalPart = computed(() => {
      const baseNumber = props.number;
      let suffix = '';

      if (props.unitSuffix) {
        const suffixInfo = getNumberWithUnitSuffix(baseNumber);
        suffix = suffixInfo.suffix;
      }

      if (Number.isNaN(props.number) || typeof props.number !== 'number' || props.decimals === 0 || props.decimals === '0') {
        return suffix;
      }

      let decimals = getAutomaticDecimals(localNumber.value, props.minDecimals, props.autoDecimalsSensitivity);

      if (decimals < props.minDecimals) {
        decimals = props.minDecimals;
      }

      const number = props.animate ? animatedNumber.value : localNumber.value;

      if (props.autoDecimals) {
        if (decimals === 0) return suffix;
        return `.${getFractional(number, decimals)}${suffix}`;
      }

      return `.${getFractional(number, props.decimals)}${suffix}`;
    });

    if (props.animate) {
      animateTo(localNumber.value, 0);
    }

    return {
      integerPart,
      fractionalPart,
      animatedNumber,
      animateTo,
    };
  },
};

</script>
