import Overview from './views/Overview.vue';
import Details from './views/Details.vue';

const routes = [
  {
    path: '/tournament/cup',
    name: 'CupOverview',
    component: Overview,
    meta: { title: 'Cup Overview - Neo FC' },
  },
  {
    path: '/tournament/cup/details/:id',
    name: 'CupDetails',
    component: Details,
    meta: { title: 'Cup Details - Neo FC' },
  },
];

export default routes;
