function Items({
  id,
  name,
  age,
  ownerId,
  rank,
  itemType,
  position,
  avatar,
  seasonCost,
  contractToSeason,
  luck,
  aggression,
  skills,
  weather,
  multiplier,
  onSale,
  capacity,
  onSalePrice,
  onLoan,
  onLoanPrice,
  onLoanFrom,
  burnable,
  inLineup,
  clubId,
}) {
  this.id = id;
  this.name = name;
  this.age = age;
  this.ownerId = ownerId;
  this.avatar = avatar;
  this.rank = rank;
  this.itemType = itemType;
  this.position = position;
  this.seasonCost = seasonCost;
  this.contractToSeason = contractToSeason;
  this.luck = luck;
  this.aggression = aggression;
  this.skills = skills;
  this.weather = weather;
  this.multiplier = multiplier;
  this.capacity = capacity;
  this.onSale = onSale;
  this.onSalePrice = onSalePrice;
  this.onLoan = onLoan;
  this.onLoanPrice = onLoanPrice;
  this.onLoanFrom = onLoanFrom;
  this.burnable = burnable;
  this.inLineup = inLineup;
  this.clubId = clubId;
}

export function mapPlayerData(itemData) {
  const weather = itemData.item_type === 'stadium' ? itemData.weather : 0;
  const skills = itemData.item_type !== 'stadium' ? itemData.skills : 0;
  const aggression = itemData.item_type !== 'stadium' ? itemData.aggression : 0;
  const multiplier = itemData.item_type !== 'stadium' ? itemData.multiplier : 0;
  const position = itemData.item_type === 'player' ? itemData.position : itemData.item_type;
  const capacity = itemData.item_type === 'stadium' ? itemData.capacity : 0;
  return new Items({
    id: itemData.id,
    name: itemData.name,
    age: itemData.age,
    ownerId: itemData.owner_id,
    rank: itemData.rank,
    avatar: itemData.avatar,
    position,
    itemType: itemData.item_type,
    seasonCost: itemData.season_cost,
    contractToSeason: itemData.contract_to_season,
    luck: itemData.luck,
    skills,
    weather,
    multiplier,
    aggression,
    capacity,
    onSale: itemData.on_sale,
    onSalePrice: itemData.on_sale_price,
    onLoan: itemData.on_loan,
    onLoanPrice: itemData.on_loan_price,
    onLoanFrom: itemData.on_loan_from_club,
    burnable: itemData.burnable,
    inLineup: itemData?.inLineup,
    clubId: itemData.club_id,
  });
}

export default Items;
