<template>
  <div class="h-full relative p-4">
    <div
      v-if="loading"
      class="absolute inset-0 w-full h-full"
    >
      <spinner></spinner>
    </div>
    <div class="bg-[#ECECEC] overflow-y-scroll nice-scroll">
      <!-- Search Input -->
      <div class="mb-2">
        <div class="flex items-center gap-2 w-full rounded-lg shadow px-4 py-2 bg-white">
          <i class="fas fa-search opacity-50"></i>
          <input
            v-model="searchQuery"
            class="text-[15px] font-medium bg-transparent flex-grow outline-none"
            placeholder="Search for opponent..."
            type="text"
            autocomplete="off"
          >
        </div>
      </div>
      <!-- EOF Search Input -->

      <!-- Clubs -->
      <div>
        <button
          v-for="club in clubs"
          :key="club.id"
          @click="openModal(club)"
          class="flex items-center cursor-pointer w-full px-3 py-2 bg-white mb-2 rounded shadow"
        >
          <club-logo
            :club-id="club.id"
            class="w-10 h-10"
          />
          <div class="ml-4 text-left text-[17px] font-semibold flex-grow">
            {{ club.name }}
          </div>
          <i class="fas fa-chevron-right text-neobit-green"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="absolute inset-0 bg-black bg-opacity-40 text-black z-30" v-if="modalOpen">
    <play-friendly-match @close="modalOpen = false"></play-friendly-match>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import Fuse from 'fuse.js';
import store from '@/store';
import globalStore from '@/os/utils/global';
import ClubLogo from '@/apps/globalComponents/components/clubLogo.vue';
import playFriendlyMatch from '../modals/playFriendlyMatch.vue';

export default {
  name: 'SearchOpponent',
  components: { ClubLogo, playFriendlyMatch },
  setup() {
    const searchQuery = ref('');
    const modalOpen = ref(false);
    const allClubs = store.state.userStore.clubNames;
    const clubObjects = Object.entries(allClubs).map(([id, name]) => ({ id, name }));

    const fuseOptions = {
      threshold: 0.5,
      keys: ['name'],
    };

    const clubs = computed(() => {
      if (searchQuery.value === '') return clubObjects;

      const fuse = new Fuse(clubObjects, fuseOptions);
      const result = fuse.search(searchQuery.value);

      return result.map((item) => item.item);
    });

    function openModal(opponentClub) {
      globalStore.friendlyMatchOpponent = opponentClub;
      modalOpen.value = true;
    }

    return {
      clubs,
      searchQuery,
      openModal,
      modalOpen,
    };
  },
};

</script>
