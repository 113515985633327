<template>
  <spinner v-if="loading"></spinner>
  <div class="flex justify-center" v-if="!loading">
    <div class="w-full px-[18px] py-4 bg-[#ECECEC]">
      <div class="info-box w-full tracking-wide mb-3">
        <div class="px-4 p-2 bg-neobit-greenlight flex items-center justify-between text-neobit-green">
          <span class="text-[13px] font-bold">GAME INFO</span>
        </div>
        <div class="flex items-center flex-wrap gap-2 tracking-wide px-3 pt-3">
          <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
            <span class="">Current Season</span>
            <span>{{ currentSeason }}</span>
          </div>
          <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
            <span class="">Club Signed Up?</span>
            <span class="text-green-500 font-bold" v-if="currentSeason <= store.state.userStore.club.signed_up_for_season">Yes</span>
            <span class="text-red-500 font-bold" v-if="currentSeason > store.state.userStore.club.signed_up_for_season">No</span>
          </div>
          <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
            <span class="">Current Round</span>
            <span v-if="store.state.userStore.currentRound > 38">0</span>
            <span v-if="store.state.userStore.currentRound < 38">{{ store.state.userStore.currentRound }}</span>
          </div>
          <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
            <span class="">Season Started?</span>
            <span class="text-green-500 font-bold" v-if="store.state.userStore.currentRound < 39">Yes</span>
            <span class="text-red-500 font-bold" v-if="store.state.userStore.currentRound > 38">No</span>
          </div>
        </div>
        <div class="w-full px-3 pb-3 pt-1.5">
          <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 flex items-center justify-between text-[11px] font-semibold text-neobit-black">
            <span class="">Items on loan return in <b>{{ loanReturn }}</b> rounds</span>
          </div>
          <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 mt-1.5 flex items-center justify-between text-[11px] font-semibold text-neobit-black">
            <span class="">Recruited Items contract last to season <b>{{contractToSeason}}</b></span>
          </div>
        </div>
      </div>
      <div class="info-box w-full tracking-wide">
        <div class="px-4 py-2 bg-neobit-greenlight text-[13px] font-bold text-neobit-green">
          MY CLUB
        </div>
        <div class="p-3">
          <div class="w-full flex flex-col p-3 border-2 relative border-neobit-greenlight border-opacity-30 items-center">
            <club-logo :club-id="store.state.userStore.club.id" class="w-16 h-16 mt-4"></club-logo>
            <span class="text-[20px] font-semibold text-neobit-black flex-grow mt-4">{{ store.state.userStore.club.name }}</span>
            <button @click="uploadOpen = true" class="bg-neobit-greenlight text-neobit-green font-bold flex items-center w-full justify-center px-2 py-2 rounded-lg text-[15px] mt-4">MANAGE CLUB</button>
          </div>
          <div class="mt-2 flex items-center flex-wrap gap-2 tracking-wide">
            <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
              <span class="">Matches</span>
              <span>{{ clubStats?.total_matches }}</span>
            </div>
            <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
              <span class="">W/L Ratio</span>
              <span>{{ (clubStats?.won / clubStats?.total_matches * 100).toFixed(2) }}%</span>
            </div>
            <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
              <span class="">Players</span>
              <span>{{ clubStats?.players }}</span>
            </div>
            <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
              <span class="">Avg. Player Age</span>
              <span>{{ clubStats?.average_player_age }}</span>
            </div>
            <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
              <span class="">Managers</span>
              <span>{{ clubStats?.managers }}</span>
            </div>
            <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
              <span class="">Stadiums</span>
              <span>{{ clubStats?.stadiums }}</span>
            </div>
            <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
              <span class="">Season cost</span>
              <div class="flex items-center">
                <img src="/img/tokens/transparent/bNEO.svg" class="w-5 h-5"/>
                <span>{{ (clubStats?.upkeep / (10 ** 8)).toFixed(4) }}</span>
              </div>
            </div>
            <div class="bg-neobit-greenlight bg-opacity-30 rounded-md px-2.5 py-1.5 overinfo-box flex items-center justify-between text-[11px] font-semibold text-neobit-black">
              <span class="">Revenue</span>
              <div class="flex items-center">
                <img src="/img/tokens/transparent/bNEO.svg" class="w-5 h-5"/>
                <span>{{ ((clubStats?.total_match_income + clubStats?.total_ticket_income + clubStats?.prize) / (10 ** 8)).toFixed(4) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="info-box w-full tracking-wide mt-3">
        <div class="px-4 py-2 bg-neobit-greenlight flex items-center justify-between text-neobit-green">
          <span class="text-[13px] font-bold">SEASON RANKING</span>
          <button class="text-[11px] font-semibold" @click="$router.push({ name: 'TableMain', params: { league: store.state.userStore.club.league } })">VIEW ALL</button>
        </div>
        <div class="px-3 py-2">
          <table class="w-full ranking-table">
            <thead>
            <tr>
              <th class="!text-center">#</th>
              <th>Club</th>
              <th>Current Prize</th>
              <th class="!text-right">Points</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(_club, index) of ranking" :key="index" :class="rankRowStyle(index, _club.club_id)">
              <td width="24" class="text-center">{{ startRank + index + 1 }}</td>
              <td class="flex items-center gap-1 cursor-pointer" @click="$router.push({ name: 'ClubView', params: { clubId: _club.club_id } })">
                <club-logo :club-id="_club.club_id" class="w-5 h-5"></club-logo>
                <span class="text-[11px] font-semibold text-neobit-black">{{ store.state.userStore.clubNames[_club.club_id] }}</span>
              </td>
              <td>
                <div class="flex items-center gap-1">
                  <img src="/img/tokens/transparent/bNEO.svg" class="w-5 h-5"/>
                  <sexy-number
                    :number="thisLeaguePrice * tableShare[(index + startRank + 1)]"
                    :decimals="4"
                  />
                </div>
              </td>
              <td class="">
                <div class="bg-neobit-greenlight rounded-md px-3 py-0.5 w-fit mr-1 float-right">
                  {{ _club.points }}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="info-box w-full tracking-wide mt-3">
        <div class="px-4 py-2 bg-neobit-greenlight flex items-center justify-between text-neobit-green">
          <span class="text-[13px] font-bold">RECENT MATCHES</span>
          <button class="text-[11px] font-semibold" @click="$router.push({ name: 'RoundMain', params: { league: store.state.userStore.club.league, round: store.state.userStore.currentRound - 1 } })">VIEW ALL</button>
        </div>
        <div class="p-2">
          <div class="bg-[#F8F8F8] rounded-lg px-1 py-0.5 tracking-wide" v-for="match in store.state.userStore.clubMatches?.slice(0, 5)" :key="match.id">
            <div class="mt-2 border-[#C8EBE052] border-2 rounded-lg px-1 py-1 flex items-center justify-center"
                 @click="$router.push({ name: 'MatchView', params: { match: match.id } })"
            >
              <span class="text-xs font-semibold w-[35%] text-right text-[10px] text-neobit-black">{{ store.state.userStore.clubNames[match.home] }}</span>
              <club-logo :club-id="match.home" class="w-5 h-5 ml-1"></club-logo>
              <span class="mx-2 font-bold text-center w-12 text-[10px] text-neobit-black">{{ match.home_score }} - {{ match.away_score }}</span>
              <club-logo :club-id="match.away" class="w-5 h-5 mr-1"></club-logo>
              <span class="text-xs font-semibold w-[35%] text-[10px] text-neobit-black">{{ store.state.userStore.clubNames[match.away] }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="absolute inset-0 bg-black bg-opacity-40 text-black" v-if="uploadOpen">
    <upload-modal @close="uploadOpen = false"></upload-modal>
  </div>

</template>

<script>
import store from '@/store';
import osAPI from '@/os/APIs/osAPI';
import { computed, ref } from 'vue';
import Spinner from '@/os/components/Spinner.vue';
import { tableShare } from '@/os/utils/config';
import ClubLogo from '@/apps/globalComponents/components/clubLogo.vue';
import uploadModal from '../modals/uploadModal.vue';

export default {
  name: 'home-player',
  components: {
    ClubLogo,
    Spinner,
    uploadModal,
  },
  setup() {
    const loading = ref(false);
    const uploadOpen = ref(false);
    const ranking = ref([]);
    const leagueId = computed(() => store.state.userStore.club.league);
    const thisLeaguePrice = computed(() => store.state.userStore.leaguePrizes[String(leagueId.value)] / 100000000);
    const startRank = ref(0);
    const clubStats = computed(() => store.state.userStore.clubStats);
    const contractToSeason = store.state.userStore.currentRound < 20 ? store.state.userStore.currentSeason : store.state.userStore.currentSeason + 1;
    const currentSeason = store.state.userStore.currentRound > 38 ? store.state.userStore.currentSeason + 1 : store.state.userStore.currentSeason;
    const loanReturn = store.state.userStore.currentRound > 38 ? 38 : 38 - store.state.userStore.currentRound;

    async function getData() {
      loading.value = true;
      await store.dispatch('userStore/getUserInfo');
      await store.dispatch('userStore/getUserClub', { clubId: store.state.userStore.club.id });
      await store.dispatch('userStore/getClubStats', { clubId: store.state.userStore.club.id });
      await store.dispatch('userStore/getLeagues', { league: (leagueId.value) });
      await store.dispatch('userStore/getClubMatches', { clubId: store.state.userStore.club.id, season: false });
      // eslint-disable-next-line
      if (Object.keys(store.state.userStore.leagues).includes(String(leagueId.value))) {
        ranking.value = store.state.userStore.leagues[String(leagueId.value)].sort((b, a) => a.points - b.points
          || (a.goals_for - a.goals_against) - (b.goals_for - b.goals_against));
      }
      const myRank = ranking.value.findIndex((item) => item.club_id === store.state.userStore.club.id);
      startRank.value = (myRank - 2) > 0 ? (myRank - 2) : 0;
      const endRank = (myRank + 2) < ranking.value.length ? (myRank + 2) : (ranking.value.length - 1);
      ranking.value = ranking.value?.slice(startRank.value, endRank + 1);
      loading.value = false;
    }

    getData();

    function rankRowStyle(index, rowClubId) {
      let rankClass = '';
      if (index > 0) rankClass = 'border-t border-neobit-green border-opacity-10';
      if (rowClubId === store.state.userStore.club.id) rankClass += ' rounded-sm bg-neobit-greenlight bg-opacity-50';
      return rankClass;
    }

    return {
      store,
      loading,
      ranking,
      tableShare,
      thisLeaguePrice,
      rankRowStyle,
      contractToSeason,
      currentSeason,
      loanReturn,
      startRank,
      clubStats,
      osAPI,
      uploadOpen,
    };
  },
};
</script>

<style scoped>
.info-box {
  border-radius: 8px;
  overflow: hidden;
  background: white;
  color: black;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.06);
}

.overinfo-box {
  width: calc(50% - 4px);
}

.ranking-table th {
  font-size: 13px;
  font-weight: 600;
  color: #33333380;
  text-align: left;
}

.ranking-table td {
  font-size: 11px;
  font-weight: 600;
  color: #333333;
  padding: 4px 0px;
}

.w-fit {
  width: fit-content;
}
</style>
