<template>
  <transition name="tooltip-fade">
    <div
      v-show="showTooltip"
      class="tooltip bg-gray-900 text-xs px-3 py-2 rounded fixed mb-1 shadow-card z-50"
      :style="style"
    >
      <slot></slot>
    </div>
  </transition>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'FlmTooltip',
  props: {
    target: {
      type: String,
      default: null,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const showTooltip = ref(false);
    const style = ref({
      bottom: '0px',
      left: '0px',
    });

    function bindTooltip() {
      if (props.target === null) {
        return;
      }

      const element = document.getElementById(props.target);
      if (!element) return;

      element.addEventListener('mouseenter', () => {
        showTooltip.value = true;
        const position = element.getBoundingClientRect();
        const bottom = window.innerHeight - position.top;
        style.value.bottom = `${bottom}px`;
        style.value.left = `${position.left}px`;
      });

      element.addEventListener('mouseleave', () => {
        showTooltip.value = false;
      });

      element.addEventListener('click', () => {
        showTooltip.value = props.clickable;
        if (props.clickable) {
          const position = element.getBoundingClientRect();
          const bottom = window.innerHeight - position.top;
          style.value.bottom = `${bottom}px`;
          style.value.left = `${position.left}px`;
        }
      });
    }

    setTimeout(bindTooltip, 200);

    return {
      showTooltip,
      style,
    };
  },
};
</script>

<style scoped>
.tooltip-fade-enter-active, .tooltip-fade-leave-active {
  transition: all 200ms;
}

.tooltip-fade-enter-from, .tooltip-fade-leave-to {
  opacity: 0;
  transform: translate(0, -5px);
}
</style>
