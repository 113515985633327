<template>
  <router-link
    class="hover:text-white"
    :to="{ name: 'Profile', params: { id: userId } }"
  >
    <span class="text-sm md:text-base">@{{ store.state.stats.users[userId] }}</span>
  </router-link>
</template>

<script>
import store from '@/store';

export default {
  name: 'username-component',
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      store,
    };
  },
};
</script>

<style scoped>

</style>
