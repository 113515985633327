<template>
  <div class="flex-1 bg-white md:rounded-2xl flex p-2">
    <div class="flex-1 overflow-auto nice-scroll pr-0">
      <div class="overflow-hidden md:min-h-[670px] px-4 pt-8 pb-4 flex flex-col items-center justify-center text-center">
        <img src="../assets/guide_standings.png" class="mt-4 w-[90%]" />
        <div class="mt-8 font-bold text-2xl">Who wins a match?</div>
        <div class="mt-8">
          In a match, two clubs go head to head with their lineup. The match results are determined by random values based on the stats of each item in the lineup.
        </div>
        <div class="mt-8 font-bold text-xl">
          1. Match Performances
        </div>
        <div class="mt-4">
          Players and managers of each team will have a
          <span class="font-bold">match performance.</span>
          For example, if an attacker has
          <span class="font-bold text-[#E3B100]">15 skill</span>,
          <span class="font-bold text-[#00AF92]">25 luck</span> and a
          <span class="font-bold text-[#9600AF]">multiplier of 30</span>
          the attacker might get these match results:
        </div>
        <img src="../assets/guide_match_performance.svg" class="my-4 w-full" />
        <div class="mt-8 font-bold text-xl">
          2. Team Score
        </div>
        <div class="mt-4">
          After all the match performances are calculated, they are added together to make a <span class="font-bold">Team Score.</span>
        </div>
        <img src="../assets/guide_team_score_1.svg" class="mt-8 mb-4 w-full" />
        <div class="mt-4">
          The home team gets a home-field advantage by using the <span class="font-bold text-[#00E3BE]">stadium's luck</span> to increase their Team Score.
        </div>
        <img src="../assets/guide_team_score_2.svg" class="mt-8 mb-4 w-full" />
        <div class="mt-4">
          Then the <span class="font-bold text-[#DF00E3]">managers multipliers</span> are used to increase each Team Score to get the final score.
        </div>
        <img src="../assets/guide_team_score_3.svg" class="mt-8 mb-4 w-full" />
        <div class="mt-8 font-bold text-xl">
          3. Match Result
        </div>
        <div class="mt-4">
          After both <span class="font-bold text-[#0FBE00]">home</span>
          and <span class="font-bold text-[#0072DC]">away</span> team scores are set, we calculate the match results that can be either
          <span class="font-bold">home, draw, or away.</span>
        </div>
        <img src="../assets/guide_match_result.svg" class="mt-8 mb-4 w-[70%]" />
        <div class="mt-4">
          The difference in home and away team scores decides the winner; or a draw if the difference is small.
        </div>
        <div class="mt-4">
          An algorithm decides the number of goals to have fun and realistic goal counts, but the algorithm <span class="font-bold">does not affect the match result.</span>
        </div>
      </div>
      <div class="p-6">
        <button
          @click="goToNextStep()"
          class="text-white bg-[#00AF92] w-full p-4 font-bold rounded-lg"
        >
          NEXT
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  name: 'IntroStep8',
  setup() {
    const router = useRouter();

    function goToNextStep() {
      router.push({ name: 'IntroStep9' });
    }

    return {
      goToNextStep,
    };
  },
};
</script>
