<template>
  <div class="flex flex-col gap-4 p-4 mb-8">
    <div class="box w-full">
      <div class="flex items-center">
        <button
          class="flex-1 px-4 py-1.5 text-[13px] font-bold"
          :class="activeTab === 'upcoming' ? 'text-white bg-neobit-green' : 'bg-neobit-greenlight text-neobit-green'"
          @click="activeTab = 'upcoming'"
        >
          UPCOMING
        </button>
        <button
          class="flex-1 px-4 py-1.5 text-[13px] font-bold"
          :class="activeTab === 'active' ? 'text-white bg-neobit-green' : 'bg-neobit-greenlight text-neobit-green'"
          @click="activeTab = 'active'"
        >
          ACTIVE
        </button>
        <button
          class="flex-1 px-4 py-1.5 text-[13px] font-bold"
          :class="activeTab === 'completed' ? 'text-white bg-neobit-green' : 'bg-neobit-greenlight text-neobit-green'"
          @click="activeTab = 'completed'"
        >
          COMPLETED
        </button>
      </div>
      <div class="bg-white rounded-b-lg p-3 flex flex-col gap-4">
        <cup-item
          v-for="item in showData"
          :key="item.id"
          :cupData=item
        />
      </div>
    </div>
  </div>
  <sign-up-for-cup/>
</template>

<script>
import {
  computed,
  ref,
  watch,
} from 'vue';
import store from '@/store';
import CupItem from '../components/CupItem.vue';
import SignUpForCup from '../modals/SignUpForCup.vue';

export default {
  name: 'CupOverview',
  components: {
    CupItem,
    SignUpForCup,
  },
  setup() {
    const activeTab = ref('upcoming');
    const cupData = computed(() => store.state.userStore.cups);
    const activeCups = computed(() => cupData.value?.filter((cup) => cup.signed_up.includes(store.state.userStore.club.id) && !cup.prize_paid));
    const upcomingCups = computed(() => cupData.value?.filter((cup) => !cup.signed_up.includes(store.state.userStore.club.id) && !cup.prize_paid).sort((a, b) => (a.start_time < b.start_time)));
    const finishedCups = computed(() => cupData.value?.filter((cup) => cup.prize_paid).sort((a, b) => (a.start_time < b.start_time)));

    if (upcomingCups.value.length < 1) {
      activeTab.value = 'active';
    }

    function getSortedList(cupList, asc = false) {
      if (!asc) {
        return cupList.sort((a, b) => (a.start_time - b.start_time));
      }
      return cupList.sort((a, b) => (b.start_time - a.start_time));
    }

    const showData = computed(() => {
      if (activeTab.value === 'upcoming') return getSortedList(upcomingCups.value);
      if (activeTab.value === 'active') return getSortedList(activeCups.value);
      return getSortedList(finishedCups.value, true);
    });

    watch(store.state.userStore.liveMatches, () => {
      if (store.state.userStore.liveMatches.cup) activeTab.value = 'active';
    });

    return {
      activeCups,
      upcomingCups,
      finishedCups,
      activeTab,
      showData,
    };
  },
};
</script>

<style scoped>
.box {
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.06);
}
</style>
