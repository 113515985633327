<template>
  <div v-if="!loading" class="flex-1 bg-white md:rounded-2xl flex p-2">
    <div class="flex-1 overflow-auto nice-scroll pr-0">
      <div class="overflow-hidden md:min-h-[670px] px-4 pt-6 pb-4 flex flex-col items-center justify-center text-center">
        <div class="mt-8 font-bold text-3xl">You are ready!</div>
        <div class="mt-6">
          Thank you for finishing the intro. You should now be ready to play the game.
          We recommend that you read the docs if you have any questions and that you join our Discord server.
        </div>

        <a
          href="https://coda.io/d/_dK-7wooOq3T/NEO-FC_suw3M"
          target="_blank"
          class="border-[#00AF92] border-2 rounded-xl bg-[#C6F3EC] p-4 w-full flex flex-col items-center mt-4 mb-2"
        >
          <img
            src="../assets/guide_read_the_docs.png"
            alt="Read the Docs"
            class="w-1/3 mb-4 mt-2"
          >
          <div class="text-lg font-bold text-[#00AF92]">
            Read the Docs
          </div>
          <div class="text-sm font-bold">
            All you need to know about the game is provided to you in our documentation.
          </div>
        </a>

        <a
          href="https://discord.gg/a5R97QJnS6"
          target="_blank"
          class="border-[#5865F2] border-2 rounded-xl bg-[#DEE1FF] p-4 w-full flex flex-col items-center mt-2"
        >
          <img
            src="../assets/guide_join_discord.png"
            alt="Read the Docs"
            class="w-[50%] mb-4 mt-2"
          >
          <div class="text-lg font-bold text-[#5865F2]">
            Join our Discord Server
          </div>
          <div class="text-sm font-bold">
            Meet other players, and see live scores, transfers, announcements, and more...
          </div>
        </a>

      </div>
      <div class="p-6">
        <button
          @click="completeIntro()"
          class="text-white bg-[#00AF92] w-full p-4 font-bold rounded-lg"
        >
          COMPLETE INTRO
        </button>
      </div>
    </div>
  </div>
  <div
    v-if="loading"
    class="flex-1 bg-white md:rounded-2xl flex p-2"
  >
    <spinner></spinner>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import backendAPI from '@/os/APIs/backendAPI';
import store from '@/store';
import Spinner from '@/os/components/Spinner.vue';

export default {
  name: 'IntroStep10',
  components: {
    Spinner,
  },
  setup() {
    const router = useRouter();
    const loading = ref(false);

    async function completeIntro() {
      loading.value = true;
      try {
        await backendAPI.userCompleteIntro();
        await store.dispatch('userStore/getClubItems', { clubId: false });
        await store.dispatch('userStore/getLeagues', { league: false });
        await store.dispatch('userStore/getMatches', { league: false });
        await store.dispatch('userStore/getUserClub', { clubId: false });
        await store.dispatch('userStore/getUserInfo');
        router.push({ name: 'Overview' });
        loading.value = false;
      } catch (err) {
        console.log(err.response.data.detail);
        window.toast.error(err.response.data.detail);
        loading.value = false;
        throw err;
      }
    }

    return {
      completeIntro,
      loading,
    };
  },
};
</script>
