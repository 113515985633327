<template>
  <img :src="getImage()">
</template>

<script>
import { useStore } from 'vuex';

export default {
  name: 'clubLogo',
  props: {
    clubId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    function getImage() {
      if (Object.keys(store.state.userStore.clubBadges).includes(props.clubId) && store.state.userStore.clubBadges[props.clubId] !== 'regular') {
        return `https://neosoccer.b-cdn.net/badges/${store.state.userStore.clubBadges[props.clubId]}`;
      }
      if (store.state.userStore.clubBots.includes(props.clubId)) {
        return '/img/ui/ball.svg';
      }
      return '/img/ui/football-club.svg';
    }

    return {
      getImage,
    };
  },
};
</script>

<style scoped>

</style>
